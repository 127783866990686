<template>
  <Layout>
    <div class="mx-auto max-w-[800px] py-7 h-[calc(100vh-60px)]">
      <div class="bg-white shadow-default rounded-lg">
        <section id="header" class="border-b border-main-divider p-4">
          <h1 class="text-xl font-semibold">Blocked Users</h1>
        </section>
        <section id="list" class="h-[calc(100vh-165px)] flex flex-col gap-4 px-6 py-2.5">
          <UserCard v-if="blocked.length" v-for="user in blocked" :key="user.id" :user="user" :showLocation="true"
            :showRelationship="false">
            <template #actions>
              <div class="flex gap-2 items-center justify-end">
                <Button type="button" class="!bg-switcherBlue-500" size="small" label="Unblock"
                  @click="() => handleUnblock(user)"></Button>
              </div>
            </template>
          </UserCard>
          <div v-else class="flex-1 flex flex-col items-center justify-center gap-3">
            <i class="fad fa-user-slash text-6xl text-primaryBlue-500"></i>
            <p class="text-xl text-primaryBlue-500">No blocked users</p>
          </div>
        </section>
      </div>
    </div>
  </Layout>
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch } from "vue";
import Layout from "@/components/contacts/Layout.vue";
import { useContactsStore } from "@/store/Contacts";
import FullCard from "@/components/contacts/content/FullCard.vue";
import CompactCard from "@/components/contacts/content/CompactCard.vue";
import UserCard from "@/components/general/UserCard.vue";
import { API } from "@/core/api";
import type { PropType } from "vue";
import type { UserInterface } from "@/core/interfaces";
import { useToastr } from "@/composables/useToastr";

const { success, error } = useToastr();
const contactsStore = useContactsStore();
const userAPI = new API.Users();
const props = defineProps({
  blocked: {
    type: Array as PropType<UserInterface[]>,
    default: []
  }
});

const blocked = ref<UserInterface[]>(props.blocked);

const handleUnblock = async (user: UserInterface) => {
  try {
    const response = await userAPI.unblock(user.id);
    if (response.hasOwnProperty("success")) {
      success(response.success);
      blocked.value = blocked.value.filter((u) => u.id !== user.id);
    }
    if (response.hasOwnProperty("error")) {
      error(response.error);
    }
  } catch (err) {
    error(err.message);
  }
}
</script>