<template>
    <Layout>

        <div
            class="block justify-between items-center p-4 mx-4 mt-4 mb-6 bg-white rounded-2xl shadow-xl shadow-gray-200 lg:p-5 sm:flex h-[100%]">
            <div class="mb-1 w-full">
                <div class="mb-4">
                    <nav class="flex mb-5" aria-label="Breadcrumb">
                        <ol class="inline-flex items-center space-x-1 md:space-x-2">
                            <li>
                                <div class="flex items-center">
                                    <i class="fad fa-users text-primary-500"></i>
                                    <Link :href="route('admin.users.index')"
                                        class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2">Users
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <div class="flex items-center">
                                    <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                    <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2" aria-current="page">{{
                                        route().current('admin.users.edit') ? 'Edit' : 'Create' }}</span>
                                </div>
                            </li>
                        </ol>
                    </nav>
                    <div class="flex items-center justify-between">
                        <h1 class="text-xl font-semibold text-gray-900 sm:text-2xl">{{
                            route().current('admin.users.edit') ?
                                'Edit user' : 'Create user' }}</h1>
                        <div class="flex gap-2">
                            <Button type="button" @click="window.history.back()" icon="fad fa-arrow-left" label="Back"
                                severity="secondary"></Button>
                            <Divider layout="vertical" type="dashed" severity="secondary" />
                            <Button @click="submit" icon="fad fa-check" label="Save"></Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-col mt-6 mb-8 mx-4 rounded-2xl bg-white shadow-xl shadow-gray-200 p-10">
            <div class="grid grid-cols-10 gap-x-6 gap-y-8 border-b border-gray-900/10 pb-12">

                <div class="col-span-4">
                    <label for="first_name" class="block text-sm font-medium leading-6 text-gray-900">First name</label>
                    <div class="mt-2">
                        <InputText v-model="form.first_name" placeholder="First name" class="w-full"
                            :invalid="form.errors.first_name != null" />
                        <ValidationError :errors="form.errors" name="first_name" class="self-start" />
                    </div>
                </div>

                <div class="col-span-4">
                    <label for="last_name" class="block text-sm font-medium leading-6 text-gray-900">Last name</label>
                    <div class="mt-2">
                        <InputText v-model="form.last_name" placeholder="Last name" class="w-full"
                            :invalid="form.errors.last_name != null" />
                        <ValidationError :errors="form.errors" name="last_name" class="self-start" />
                    </div>
                </div>

                <div class="col-span-4">
                    <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email</label>
                    <div class="mt-2">
                        <InputText v-model="form.email" placeholder="Email" class="w-full"
                            :invalid="form.errors.email != null" />
                        <ValidationError :errors="form.errors" name="email" class="self-start" />
                    </div>
                </div>

                <div class="col-span-4">
                    <label for="phone_number" class="block text-sm font-medium leading-6 text-gray-900">Phone
                        number</label>
                    <div class="mt-2">
                        <InputText v-model="form.phone_number" placeholder="Phone number" class="w-full"
                            :invalid="form.errors.phone_number != null" />
                        <ValidationError :errors="form.errors" name="phone_number" class="self-start" />
                    </div>
                </div>

                <div class="col-span-4">
                    <label for="public_username" class="block text-sm font-medium leading-6 text-gray-900">Public
                        username</label>
                    <div class="mt-2">
                        <InputText v-model="form.public_username" placeholder="Public username" class="w-full"
                            :invalid="form.errors.public_username != null" />
                        <ValidationError :errors="form.errors" name="public_username" class="self-start" />
                    </div>
                </div>
            </div>
            <div class="mt-6 space-y-6 border-gray-900/10">
                <div class="relative flex gap-x-3">
                    <div class="flex h-6 items-center">
                        <input id="is_staff" name="is_staff" type="checkbox" v-model="form.is_staff"
                            class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            :invalid="form.errors.is_staff != null">
                    </div>
                    <div class="text-sm leading-6">
                        <label for="is_staff" class="font-medium text-gray-900">Is staff</label>
                        <ValidationError :errors="form.errors" name="is_staff" class="self-start" />
                    </div>
                </div>
                <div class="relative flex gap-x-3">
                    <div class="flex h-6 items-center">
                        <input id="is_active" name="is_active" type="checkbox" v-model="form.is_active"
                            class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            :invalid="form.errors.is_active != null">
                    </div>
                    <div class="text-sm leading-6">
                        <label for="is_active" class="font-medium text-gray-900">Is active</label>
                        <ValidationError :errors="form.errors" name="is_active" class="self-start" />
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<script lang="ts" setup>

import Layout from "@/components/layout/admin/Layout.vue";
import { route } from "ziggy-js";
import { useForm, router } from "@inertiajs/vue3";
import ValidationError from "@/components/forms/validationError.vue";
import { Link } from "@inertiajs/vue3";

const props = defineProps({
    user: Object
});

const form = useForm({
    first_name: props.user?.first_name,
    last_name: props.user?.last_name,
    email: props.user?.email,
    phone_number: props.user?.phone_number,
    public_username: props.user?.public_username,
    is_staff: props.user?.is_staff ?? false,
    is_active: props.user?.is_active ?? false,
});

const submit = () => {
    if (route().current('admin.users.create')) {
        form.post(route('admin.users.store'), {
            preserveScroll: true,
        });
    } else {
        form.post(route('admin.users.update', props.user?.id), {
            preserveScroll: true,
        });
    }
};

</script>
