import baseApi from "./Base";
import { route } from "ziggy-js";

export class ConnectionsService extends baseApi {
  async accept(connection: number) {
    return await this.request(
      route("user.connections.accept", connection),
      "POST",
    );
  }

  async decline(connection: number) {
    return await this.request(
      route("user.connections.decline", connection),
      "POST",
    );
  }

  async withdraw(connection: number) {
    return await this.request(
      route("user.connections.withdraw", connection),
      "POST",
    );
  }

  async get() {
    return await this.request(route("user.connections"), "GET");
  }

  async getRequestsCount() {
    return await this.request(route("user.connections.count"), "GET");
  }
}
