<template>
  <div
    :class="
      cn(
        'absolute top-3 bg-black shadow-lg bg-opacity-60 rounded border border-white border-opacity-20 justify-end items-center gap-1.5 inline-flex text-white text-[7px] lg:text-xs font-light leading-none',
        props.class,
        {
          'rounded-full !p-2': !$slots.count,
        },
        {
          'py-1 px-1.5': $slots.count,
        },
        {
          'left-3': !props.position || props.position === 'left',
          'right-3': props.position === 'right',
        },
        {
          'rounded-full !p-2': props.rounded,
        },
      )
    ">
    <span class="text-xs flex items-center gap-1 text-center">
      <slot name="icon" class=""></slot>
      <i v-if="!$slots.icon && type" :class="`${TypeMap[type]} text-white`"></i>
      <slot name="count" class=""></slot>
    </span>
  </div>
</template>

<script lang="ts" setup>
import { cn } from "@/utils/cn";
const props = defineProps(["class", "type", "position", "rounded"]);
const TypeMap: Object = {
  link: "fal fa-link",
  link_gallery: "fal fa-link",
  image: "fa-thin fa-images",
  image_gallery: "fa-thin fa-images",
  video: "fal fa-clapperboard-play",
  video_gallery: "fal fa-clapperboard-play",
  collection: "fa-thin fa-rectangle-history",
};
</script>
