<template>
  <Layout>
    <div
      class="w-full relative h-full overflow-y-auto py-[28px] scroll-hidden sm:pl-[60px] xl:pl-[90px] pr-[4px] sm:pr-[60px] min[1350px]:[180px]">
      <div
        v-if="contactsStore.layout === 'full'"
        class="grid grid-cols-1 min-[1366px]:grid-cols-2 gap-[20px] w-full">
        <FullCard
          v-for="favorite in favorites"
          :key="favorite.id"
          :user="favorite" />
      </div>
      <div v-else class="flex flex-wrap gap-4">
        <CompactCard
          v-for="favorite in favorites"
          :key="favorite.id"
          :user="favorite">
          <template #actions>
            <!-- Content for the actions slot -->
            <i
              class="text-white/80 fa-regular fa-comment fa-xl"
              @click="handleMobileDialog"></i>
            <i
              class="text-white/80 fa-regular fa-star fa-xl"
              :class="{
                'fa-solid text-red-500': is_favorite(favorite),
              }"
              @click="() => toggleFavorite(favorite)"></i>
          </template>
        </CompactCard>
      </div>
    </div>
  </Layout>
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch } from "vue";
import Layout from "@/components/contacts/Layout.vue";
import { useContactsStore } from "@/store/Contacts";
import FullCard from "@/components/contacts/content/FullCard.vue";
import CompactCard from "@/components/contacts/content/CompactCard.vue";
import { API } from "@/core/api";
import { useDialogStore, DialogType } from "@/store/Dialog";
import _debounce from "lodash/debounce";
import { useToastr } from "@/composables/useToastr";
import type { UserInterface } from "@/core/interfaces";
const contactsAPI = new API.Contacts();
const contactsStore = useContactsStore();
const props = defineProps(["friends"]);
const favorites = computed(() => contactsStore.favorites || []);
const { success, error } = useToastr();
const dialogStore = useDialogStore();
const is_favorite = (user: UserInterface): Boolean => {
  return favorites.value.findIndex((f) => f.id === user.id) !== -1;
};
const handleMobileDialog = () => {
  dialogStore.show(DialogType.MOBILE_APP);
  dialogStore.setEvents(DialogType.MOBILE_APP, {
    close: () => dialogStore.hide(DialogType.MOBILE_APP),
  });
};
const toggleFavorite = _debounce(async (user: any) => {
  contactsStore.toggleFavorite(user);
}, 250);
</script>
