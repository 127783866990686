<template>
    <aside id="sidebar"
        class="flex fixed top-0 left-0 z-20 flex-col flex-shrink-0 pt-16 w-64 h-full duration-200 lg:flex transition-width lg:w-64"
        aria-label="Sidebar">
        <div class="flex relative flex-col flex-1 pt-0 min-h-0 bg-gray-50">
            <div class="flex overflow-y-auto flex-col flex-1 pt-8 pb-4">
                <div class="flex-1 px-3 bg-gray-50" id="sidebar-items">
                    <Menu :model="items" :pt="AdminMenuPreset">
                    </Menu>
                </div>
            </div>
        </div>
    </aside>
</template>
<script setup lang="ts">
import { ref } from "vue";
import AdminMenuPreset from "@/assets/tailwind-preset/menu/admin-menu";
import { route } from "ziggy-js";
import { router } from "@inertiajs/vue3";
import { cn } from "@/utils/cn";

const items = ref([
    {
        label: 'Dashboard',
        icon: 'fad fa-home',
        command: () => router.visit(route('admin.dashboard')),
        active: route().current('admin.dashboard'),
        class: (route().current('admin.dashboard')) ? AdminMenuPreset.activeMenu.class : ''
    },
    {
        label: 'System',
        items: [
            {
                label: 'Users',
                icon: 'fad fa-users',
                command: () => router.visit(route('admin.users.index')),
                class: (route().current('admin.users.*')) ? AdminMenuPreset.activeMenu.class : ''
            },
            {
                label: 'OTP Manager',
                icon: 'fad fa-paper-plane',
                command: () => router.visit(route('admin.otp.index')),
                class: (route().current('admin.otp.*')) ? AdminMenuPreset.activeMenu.class : ''
            },
            {
                label: 'Roles',
                icon: 'fad fa-shield-check',
                command: () => router.visit(route('admin.roles.index')),
                class: (route().current('admin.roles.*')) ? AdminMenuPreset.activeMenu.class : ''
            },
            {
                label: 'Industries',
                icon: 'fad fa-industry',
                command: () => router.visit(route('admin.industries.index')),
                class: (route().current('admin.industries.*')) ? AdminMenuPreset.activeMenu.class : ''
            },
            {
                label: 'Companies',
                icon: 'fad fa-building',
                command: () => router.visit(route('admin.companies.index')),
                class: (route().current('admin.companies.*')) ? AdminMenuPreset.activeMenu.class : ''
            },
            {
                label: 'Social Posts',
                icon: 'fad fa-sign-posts',
                command: () => router.visit(route('admin.social_posts.index')),
                class: (route().current('admin.social_posts.*')) ? AdminMenuPreset.activeMenu.class : ''
            },
        ]
    }
]);
</script>
