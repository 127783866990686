<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-0 transition-all duration-300 ease-in-out',
      root: '!bg-white max-w-[550px] w-full rounded-[16px] overflow-x-hidden ',
    }"
    :visible="true"
    modal
    :closable="false">
    <Transition
      :enter-active-class="'animate-in slide-in-from-right-full'"
      :leave-active-class="'animate-out slide-out-to-left-full'"
      mode="out-in"
      v-if="activeComponent">
      <component
        :is="activeComponent.component"
        v-model="form"
        :model="
          manageRepresentationIndex != null
            ? form.representations[manageRepresentationIndex]
            : null
        "
        @changeTo="handleChangeTo"
        @save="handleSave"
        @close="() => $emit('close')" />
    </Transition>
  </Dialog>
</template>

<script lang="ts" setup>
import type { RepresentationsInterface } from "@/core/interfaces";
import { useForm } from "@inertiajs/vue3";
import type { PropType } from "vue";
import Representations from "./components/Representations.vue";
import { markRaw, onMounted } from "vue";
import { ref } from "vue";
import ManageRepresentation from "./components/ManageRepresentation.vue";
import { computed } from "vue";
import { watch } from "vue";

/* -------- PROPS -------- */
const props = defineProps({
  reps: {
    type: Array as PropType<RepresentationsInterface[]>,
    required: true,
  },
});
/* -------- COMPOSABLE -------- */

/* -------- STATE -------- */
const manageRepresentationIndex = ref<number | null>(null);

const form = useForm({
  representations: props.reps,
});

const components = ref([
  {
    id: "list",
    component: markRaw(Representations),
    active: true,
  },
  {
    id: "manage_representation",
    component: markRaw(ManageRepresentation),
    active: false,
  },
]);
const activeComponent = computed(() => components.value.find((c) => c.active));

/* -------- METHODS -------- */
const handleSave = (data: RepresentationsInterface) => {
  if (manageRepresentationIndex.value != null) {
    form.representations[manageRepresentationIndex.value] = {
      ...form.representations[manageRepresentationIndex.value],
      ...data,
    };
  } else {
    form.representations.push(data);
  }
  handleChangeTo("list");
};

const handleChangeTo = (component: string, index: number | null = null) => {
  manageRepresentationIndex.value = null;
  if (index != null) manageRepresentationIndex.value = index;
  components.value.forEach((c) => {
    c.active = c.id === component;
  });
};
/* -------- WATCHERS -------- */
</script>
