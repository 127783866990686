export enum NotificationType {
  Unknown = "unknown_type",
  ConnectionRequest = "connection_request",
  ConnectionAccepted = "connection_accepted",
  OwnerConnectionAccepted = "owner_connection_accepted",
  CollaborationRequest = "collaboration_request",
  CollaborationAccepted = "collaboration_accepted",
  OwnerCollaborationAccepted = "owner_collaboration_accepted",
  CollaborationDeclined = "collaboration_declined",
  SocialPostComment = "social_post_comment",
  SocialPostMention = "social_post_mention",
  SocialPostContentMention = "social_post_content_mention",
  SocialPostCommentMention = "social_post_comment_mention",
  SocialPostLike = "social_post_like",
  NewContentUploaded = "new_content_uploaded",
}
