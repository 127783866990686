<template>
  <Dropdown v-model="model" :invalid="props.invalid" editable @change="handleChange" :options="model && !filter
    ? [
      options.find((o) => o[props.value] === model),
      ...visibleOptions
        .filter((v) => v[props.value] !== model)
        .slice(0, numberToShow),
    ]
    : visibleOptions.slice(0, numberToShow)
    " :optionLabel="props.filterProperty" :optionValue="props.value" :placeholder="props.placeholder"
    class="w-full md:w-14rem" :showClear="props.showClear" @show="initInfiniteScroll"
    panelClass="overflow-y-auto scroll-hidden max-h-[200px]" :panelProps="{
      id: 'dropdown-scroll',
    }" :loading="loading">
    <template #option="{ option }">
      <div class="flex gap-2 items-center">
        <i :class="cn('text-inherit', props.icon.value)" v-if="props.icon && props.icon.type === 'icon'"></i>
        <img :src="option[props.icon.value] ?? NoCompanyImage" class="rounded-full w-6 h-6" alt="" v-if="
          props.icon &&
          props.icon.type === 'image' &&
          props.icon.value !== null
        " />
        <p class="text-inherit">
          {{ option[props.filterProperty] }}
        </p>
      </div>
    </template>

    <template #empty>
      <div class="p-1 text-sm" v-if="allowCustom">
        <p>
          Option does not exist. Adding
          <strong class="text-switcherBlue-500">{{ filter }}</strong> on submit
        </p>
      </div>
    </template>
  </Dropdown>
</template>

<script setup>
import { computed, ref, onMounted } from "vue";
import { vInfiniteScroll } from "@vueuse/components";
import { cn } from "@/utils/cn";
import NoCompanyImage from "@/assets/images/new_icons/company_dummy.svg";
import { useInfiniteScroll } from "@vueuse/core";

const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  filterProperty: {
    type: String,
    default: "name",
  },
  value: {
    type: String,
    default: "value",
  },
  placeholder: {
    type: String,
    default: "Select an option",
  },
  icon: {
    type: Object,
    default: null,
  },
  invalid: {
    type: Boolean,
    default: false,
  },
  showClear: {
    type: Boolean,
    default: true,
  },
  allowCustom: {
    type: Boolean,
    default: true,
  },
  loading: {
    type: Boolean,
    default: false,
  }
});

const numberToShow = ref(15);
const infiniteDisabled = ref(false);

const onLoadMore = () => {
  if (visibleOptions.value.length > numberToShow.value) {
    numberToShow.value += 10;
  }
};

const model = defineModel();
const filter = ref("");
const visibleOptions = computed(() =>
  props.options.filter((option) =>
    option[props.filterProperty]
      .toLowerCase()
      .includes(filter.value.toLowerCase()),
  ),
);

const handleChange = (e) => {
  if (e.originalEvent.type === "input") {
    filter.value = e.value;
  }
  if (e.originalEvent.type === "click" && !e.value) {
    filter.value = "";
  }
  numberToShow.value = 15;
};

const initInfiniteScroll = () => {
  useInfiniteScroll(document.getElementById("dropdown-scroll"), onLoadMore, {
    distance: 10,
  });
};
</script>

<style>
.dropdown-scroll {
  max-height: 200px;
  overflow-y: auto;
}
</style>
