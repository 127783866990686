<template>
  <div class="activity-feed-footer-comment">
    <div class="flex w-full min-h-max flex-col">
      <div
        class="bg-[white] flex text-sm font-medium w-full items-center justify-evenly h-[38px] sticky top-0 z-[10]">
        <div
          @click="selectedTab = 'comments'"
          class="w-full text-center text-[14px] cursor-pointer h-full flex items-center justify-center !text-primaryBlue-500 border-b-[2px] transition-all"
          :class="{ 'border-b-primaryBlue-500': selectedTab === 'comments' }">
          {{ props.data.comments_count }} Comment{{
            props.data.comments_count > 1 || props.data.comments_count == 0
              ? "s"
              : ""
          }}
        </div>
        <div
          @click="selectedTab = 'likes'"
          :class="{ 'border-b-primaryBlue-500': selectedTab === 'likes' }"
          class="w-full text-center text-[14px] cursor-pointer h-full flex items-center justify-center !text-[#595959] border-b-[2px] transition-all">
          {{ props.data.likes_count }} Like{{
            props.data.likes_count > 1 || props.data.likes_count == 0 ? "s" : ""
          }}
        </div>
      </div>

      <div class="h-full">
        <div class="h-full flex flex-col gap-[15px]">
          <component :is="Tab" v-bind="{ ...props, user }" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import { usePage } from "@inertiajs/vue3";
import Comments from "./Interactions/Comments.vue";
import Likes from "./Interactions/Likes.vue";
import { useAuthStore } from "@/store/Auth";
const authStore = useAuthStore();
const props = defineProps({ data: { type: Object, required: true } }); // TODO ADD INTERFACE
const user = computed(() => authStore.getUser);
const selectedTab = ref("comments");
const Tab = computed(() =>
  selectedTab.value === "comments" ? Comments : Likes,
);
</script>
