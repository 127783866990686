<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-0 w-full',
      root: cn(
        'mx-auto my-auto flex flex-col items-center bg-[#F8F5FA] justify-center max-w-[450px] overflow-y-auto w-full rounded-[16px] p-[30px]',
        props.class,
      ),
    }"
    :visible="true"
    modal
    :closable="false">
    <div class="flex justify-center items-center">
      <slot name="icon"></slot>
    </div>
    <h1 class="mb-0 mt-4 mx-auto leading-7 text-center text-lg font-medium">
      <slot name="title" />
    </h1>
    <div class="mt-2.5 flex items-center justify-center text-center text-sm">
      <slot name="description" />
    </div>
    <div
      :class="
        cn('flex gap-2 mt-4 w-full', {
          'flex-col-reverse': props.layout === 'vertical',
        })
      ">
      <button
        :class="
          cn(
            'font-normal text-sm px-[15px] h-[40px] border-[1px] rounded-md  py-[10px] w-full md:w-50',
            {
              'border-primary-600 text-primary-500': !props.color,
              'border-white text-white': props.color === 'primary-light',
              'border-primary-600 text-primary-500': props.color === 'danger',
            },
          )
        "
        @click="$emit('close')">
        {{ cancelText }}
      </button>
      <button
        :class="
          cn(
            'font-normal text-sm px-[15px] h-[40px] py-[10px] rounded-md w-full md:w-50',
            {
              'bg-primary-500 hover:bg-primary-700 text-white': !props.color,
              'bg-switcherBlue-500 hover:bg-switcherBlue-500/80 text-white':
                props.color === 'primary-light',
              'bg-red-600 hover:bg-red-700 text-white':
                props.color === 'danger',
            },
          )
        "
        @click="$emit('confirm')">
        {{ action }}
      </button>
    </div>
  </Dialog>
</template>
<script setup>
import { cn } from "@/utils/cn";
const props = defineProps({
  title: {
    type: String,
    default: "Are you sure?",
  },
  class: {
    type: String,
    default: "",
  },
  cancelText: {
    type: String,
    default: "Cancel",
  },
  action: {
    type: String,
    default: "Confirm",
  },
  layout: {
    type: String,
    default: "horizontal",
  },
  color: {
    type: String,
    default: "",
  },
});
const emit = defineEmits(["close", "confirm"]);
</script>
