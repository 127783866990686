<template>
  <CustomCard :pt="cardPt" customClass="relative">
    <template #title>
      <div class="flex justify-between">
        <span class="font-semibold text-lg">Professional Skills</span>
        <div class="flex gap-2" v-if="form.isDirty">
          <div v-tooltip.top="'Cancel'" @click="form.reset()">
            <i class="fa fa-times text-lg cursor-pointer text-primary-200"></i>
          </div>
          <div v-tooltip.top="'Save'" @click="handleSave">
            <i class="fa fa-check text-lg cursor-pointer text-primary-500"></i>
            <div
              v-if="form.processing"
              class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center bg-white bg-opacity-50 w-full h-full">
              <i class="fas fa-spinner-third fa-spin text-primary-500"></i>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #subtitle v-if="isEditing">
      <span class="text-sm text-main-secondary">
        What skills do you want others to know you have?
      </span>
    </template>
    <template #content>
      <div class="flex flex-col gap-3">
        <h3 class="text-sm text-primary-500" v-if="isEditing">
          You have selected
          <b>{{ form.skills.length }}/10</b>
          skills.
        </h3>
        <CustomCard
          v-if="isEditing && canAdd"
          customClass="px-2 py-1 !w-full bg-primary-50 rounded-lg"
          :pt="{
            root: '!shadow-none',
          }">
          <template #content>
            <div class="flex justify-between items-center gap-2">
              <SkillsSelectGroup
                :pt="{
                  root: '!border-none !border-0 !bg-transparent',
                  input: '!text-primary-500 placeholder:!text-primary-200',
                }"
                :selected="form.skills"
                placeholder="Add a skill"
                v-model="skill"
                :clearCallback="clearSkill" />
              <CircleIcon
                :class="
                  cn('ms-auto', {
                    'bg-surface-100 text-primary-500 hover:bg-surface-100 cursor-not-allowed':
                      !skill,
                    'bg-primary-400 text-white hover:bg-primary-600':
                      skill !== undefined,
                  })
                "
                size="1.5rem"
                @click="handleAdd">
                <i class="far fa-plus text-xs"></i>
              </CircleIcon>
            </div>
          </template>
        </CustomCard>
        <div class="flex flex-wrap gap-2">
          <CustomChip
            v-for="(value, index) in form.skills"
            :key="value"
            :label="value"
            :removable="isEditing"
            @remove="() => handleRemove(index)"
            rootClass="text-primary-500 bg-primary-50" />
        </div>
      </div>
    </template>
  </CustomCard>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import CustomCard from "@/components/general/CustomCard.vue";
import type { BizCardInterface, UserInterface } from "@/core/interfaces";
import type { PropType } from "vue";
import SkillsSelectGroup from "@/components/forms/SelectGroups/Skills.vue";
import { router, useForm } from "@inertiajs/vue3";
import { cn } from "@/utils/cn";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { useUserBizcardStore } from "@/store/UserBizcard";
import CustomChip from "@/components/general/CustomChip.vue";
import { API } from "@/core/api";
import { useToastr } from "@/composables/useToastr";
import { watch } from "vue";

/* -------- PROPS -------- */
const props = defineProps({
  card: {
    type: Object as PropType<BizCardInterface>,
    required: true,
  },
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
});
/* -------- STORE -------- */
const bizCardStore = useUserBizcardStore();
const bizCardServicesAPI = new API.BizcardServices();
const { success, error } = useToastr();

/* -------- STATE -------- */
const isEditing = computed(() => bizCardStore.isEditing);
const canAdd = computed(() => isEditing.value && form.skills.length < 10);

const skill = ref();
const form = useForm<any>({
  skills: props.card.professional_skills,
});

const cardPt = computed(() => ({
  root: {
    "border border-primary-200": form.isDirty,
  },
}));
/* -------- METHODS -------- */
const handleAdd = async () => {
  if (skill.value === undefined || skill.value === "") return;
  form.skills.push(skill.value.name);
  skill.value = undefined;
};
const clearSkill = () => {
  skill.value = undefined;
};

const handleRemove = (index: number) => {
  form.skills.splice(index, 1);
};

const handleSave = async () => {
  if (form.processing) return;
  form.processing = true;
  try {
    const response = await bizCardServicesAPI.updateSkills(form);
    if (response.error) {
      error(response.error);
    } else {
      success("Professional skills updated successfully");
      form.defaults();
      form.reset();
      router.reload();
    }
  } catch (e: any) {
    console.error(e);
    error("Error: " + e.message);
  } finally {
    form.processing = false;
  }
};

/* -------- HOOKS -------- */
</script>
