<template>
  <swiper
    id="content-slider"
    :slides-per-view="'auto'"
    :space-between="0"
    navigation
    :modules="modules"
    class="w-full"
    navigationDisabledClass="hidden">
    <swiper-slide v-for="(child, index) in content" :key="child.id">
      <Link
        :href="route('user.profile.index', user.public_username)"
        :preserveScroll="true"
        v-if="total && max && index === max - 1 && total > max"
        class="relative rounded-lg">
        <img
          :src="
            child.thumbnail_image_kit_id ??
            child.image_kit_id_16_9 ??
            child.image_kit_ids?.[0] ??
            child.image_kit_id
          "
          alt=""
          class="size-[175px] object-cover object-center bg-no-repeat cursor-pointer rounded-lg" />
        <div
          class="absolute z-10 bg-black opacity-60 inset-0 flex items-center justify-center text-white text-4xl rounded-lg">
          +{{ total - 10 }}
        </div>
      </Link>
      <Link
        v-else
        class="relative"
        :href="route('bizcards.content.get', child.id)"
        :preserveScroll="true">
        <img
          :src="
            child.thumbnail_image_kit_id ??
            child.image_kit_id_16_9 ??
            child.image_kit_ids?.[0] ??
            child.image_kit_id
          "
          alt=""
          class="size-[175px] object-cover object-center bg-no-repeat rounded-lg cursor-pointer"
          @error="handleImageError" />
        <div
          class="absolute top-2 left-3 bg-black/50 opacity-90 rounded-full flex items-center justify-center gap-2 p-2 text-xs text-white">
          <i
            class="leading-none"
            :class="{
              'fa fa-chain': child.content_type?.includes('link'),
              'fa fa-images': child.content_type?.includes('image'),
              'fa fa-clapperboard-play': child.content_type?.includes('video'),
              'fa-thin fa-rectangle-history':
                child.content_type === 'collection',
            }"></i>
          <span v-if="child.is_gallery">
            {{ child.total_children_count }}
          </span>
        </div>
      </Link>
    </swiper-slide>
  </swiper>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import type { ContentInterface, UserInterface } from "@/core/interfaces";
import { router, Link } from "@inertiajs/vue3";
import type { PropType } from "vue";
import { route } from "ziggy-js";
import DefaultBg from "@/assets/images/bgPics/web-bg-min.jpg";

/* -------- PROPS -------- */
const props = defineProps({
  content: {
    type: Array as PropType<ContentInterface[]>,
    default: [],
  },
  user: {
    type: Object as PropType<UserInterface>,
    required: true,
  },
  total: Number,
  max: Number,
});
/* -------- COMPOSABLE -------- */

/* -------- STATE -------- */
const modules = [Navigation];

/* -------- METHODS -------- */
const handleImageError = (e: any) => {
  e.target.src = DefaultBg;
};
/* -------- HOOKS -------- */

/* -------- WATCHERS -------- */
</script>
