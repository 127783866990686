<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-0 w-full',
      root: 'mx-auto my-auto flex flex-col max-h-[90vh] items-center bg-lightBg-500 justify-center max-w-[600px] overflow-y-auto w-full rounded-[16px]',
    }"
    :visible="true"
    modal
    :closable="false"
  >
    <div class="flex justify-between items-center p-3" id="header">
      <CircleIcon @click="$emit('close')">
        <i class="fa-solid fa-arrow-left"></i>
      </CircleIcon>
      <h1 class="text-xl font-medium">Post Audience</h1>
      <br />
    </div>
    <hr />
    <div id="content" class="space-y-2.5 px-[15px] py-2.5">
      <div
        class="p-[15px] rounded-[8px] flex flex-col gap-[6px] bg-[#FeFeFe] boxShadowClass"
      >
        <span class="text-[18px] font-medium text-[#141414]">
          Accessibility </span
        ><span class="text-[14px] text-main-secondary"
          >Who can see and interact with this post?</span
        >
        <div class="rounded-[4px] p-[12px]">
          <div
            class="cursor-pointer mb-2 p-2 rounded-lg"
            @click="form.accessibility = false"
            :class="{ 'bg-[#F7F8FB]': !form.accessibility }"
          >
            <div class="flex gap-[6px]">
              <i class="fa-sharp fa-light fa-globe"></i
              ><span class="font-medium text-[16px] contents text-[#141414]"
                >Everyone</span
              >
            </div>
            <span class="text-[14px] text-main-secondary"
              >Everyone on bizly will be able to see, access, or interact with
              your post.</span
            >
          </div>
          <div
            @click="form.accessibility = true"
            class="cursor-pointer mb-2 p-2 rounded-lg"
            :class="{ 'bg-[#F7F8FB]': form.accessibility }"
          >
            <div class="flex gap-[6px]">
              <i class="fa-light fa-user-group"></i
              ><span class="font-medium text-[16px] contents text-[#141414]"
                >Network</span
              >
            </div>
            <span class="text-[14px] text-main-secondary"
              >Only those within your network can see and interact with your
              post. While it can be shared externally, outsiders cannot comment
              or interact with it.</span
            >
          </div>
        </div>
      </div>
      <div id="footer">
        <div
          class="py-2.5 px-4 flex justify-end items-center gap-2 border-t-[1px] content-modal-footer bg-[#F7F8FB] border-[#CDD5E1]"
        >
          <button
            @click="$emit('change', form.accessibility)"
            :disabled="!form.isDirty"
            class="text-sm text-white px-4 h-[36px] w-[185px] rounded disabled:bg-[#434343] bg-switcherBlue-600 hover:text-white cursor-pointer"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  </Dialog>
</template>
<script setup>
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { useForm } from "@inertiajs/vue3";
const props = defineProps(["isVisible", "accessibility"]);
const emit = defineEmits(["close", "change"]);
const form = useForm({
  accessibility: props.accessibility,
});
</script>
