<template>
  <div class="flex flex-col gap-6 px-20 py-4">
    <div class="flex flex-col gap-2 self-center items-center">
      <Avatar
        :image="user.avatar_image"
        size="xlarge"
        shape="circle"
        :pt="{
          root: 'w-32 h-32',
        }" />
      <h1 class="text-main-primary text-2xl">
        {{ user.full_name }}
      </h1>
    </div>
    <div class="flex flex-col gap-2">
      <Dropdown
        v-model="form.pronouns"
        :options="pronouns"
        class="w-full md:w-14rem"></Dropdown>
      <div class="flex flex-col gap-1">
        <Dropdown
          v-model="form.gender"
          :options="genders"
          class="w-full md:w-14rem"
          v-if="
            form.gender != genders.at(-1) && genders.includes(form.gender)
          "></Dropdown>
        <div
          v-else
          class="flex items-center justify-between border-[1px] px-3.5 border-input-border rounded-md">
          <div
            class="flex items-stretch overflow-x-auto scroll-hidden w-[90%] md:w-full innerDomain">
            <InputText
              v-model="form.gender"
              class="w-full !pl-0 !border-transparent focus:!ring-0"></InputText>
          </div>
          <div
            class="cursor-pointer"
            @click="() => (form.gender = genders.at(0))">
            <i class="far fa-times text-input-border"></i>
          </div>
        </div>
        <span class="text-main-lightGray text-xs mr-auto">
          Your gender will not display on your intro section, but it will be
          used to help others find you.
        </span>
      </div>
    </div>
    <div class="flex flex-col gap-1">
      <div class="flex justify-between">
        <span class="text-xl text-main-primary font-medium">
          Would you like to be discoverable?
        </span>
        <label class="relative inline-flex items-center cursor-pointer h-max">
          <input
            type="checkbox"
            class="sr-only peer"
            v-model="form.is_discoverable" />
          <div
            class="w-[36px] h-[20px] bg-gray-400 rounded-full peer-focus:ring-none peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-main-success disabled:peer-checked:bg-main-disabled"></div>
        </label>
      </div>
      <span class="text-sm text-main-lightGray">
        When enabled, you'll be clearly visible to others on the main network
        search page.
      </span>
    </div>
    <div class="flex flex-col gap-1">
      <span class="text-xl text-main-primary font-medium">
        Your personalized domain
      </span>
      <span class="text-sm text-main-lightGray">
        Claim your personalized URL and establish your unique presence online.
      </span>
      <div
        :class="
          cn(
            'flex items-center mt-4 justify-between border-[1px] px-4 py-0.5 rounded-md',
            {
              'border-input-border':
                !loading.domain &&
                user.public_username === form.public_username,
              'border-green-500':
                !loading.domain &&
                domainAvailable == true &&
                user.public_username !== form.public_username,
              'border-red-500':
                !loading.domain &&
                domainAvailable == false &&
                user.public_username !== form.public_username,
            },
          )
        ">
        <div
          class="flex items-stretch overflow-x-auto scroll-hidden w-[90%] md:w-full innerDomain">
          <span
            class="flex items-center whitespace-nowrap text-center text-[12px] md:text-sm font-normal text-[#8F9FB8]"
            id="basic-addon3">
            {{ domain }}
          </span>
          <InputText
            @input="handleSearchDomain"
            v-model="form.public_username"
            class="w-full !pl-0 !border-transparent focus:!ring-0"></InputText>
        </div>
        <div
          :class="
            cn('cursor-pointer', {
              'cursor-default': loading.domain,
            })
          "
          @click="handleCopy">
          <i
            :class="
              cn('', {
                'fal fa-clipboard text-input-border':
                  !loading.domain &&
                  user.public_username === form.public_username,
                'fal fa-spinner-third animate-spin': loading.domain,
                'fa fa-check text-green-500':
                  !loading.domain &&
                  domainAvailable == true &&
                  user.public_username !== form.public_username,
                'fa fa-times text-red-500':
                  !loading.domain &&
                  domainAvailable == false &&
                  user.public_username !== form.public_username,
              })
            "></i>
        </div>
      </div>
      <span
        v-if="
          !loading.domain &&
          domainAvailable !== null &&
          form.public_username !== user.public_username
        "
        :class="
          cn('text-xs text-main-lightGray', {
            'text-green-500':
              !loading.domain &&
              domainAvailable == true &&
              user.public_username !== form.public_username,
            'text-red-500':
              !loading.domain &&
              domainAvailable == false &&
              user.public_username !== form.public_username,
          })
        ">
        {{
          domainAvailable == true
            ? "This domain is available! Save your changes to claim it."
            : domainAvailable == false
            ? "This domain is not available"
            : ""
        }}
      </span>
    </div>
    <div class="flex flex-col gap-1">
      <span class="text-xl text-main-primary font-medium">Roles</span>
      <span class="text-sm text-main-lightGray">
        Select up to three roles that you partake in.
      </span>
      <section id="roles">
        <div class="section-question-group w-full">
          <div class="flex flex-col gap-1.5">
            <div class="flex flex-col gap-1">
              <RolesSelectGroup
                :selected="form.roles"
                v-for="index in 3"
                :placeholder="`Role # ${index} ${
                  index == 1 ? '*' : '(Optional)'
                }`"
                v-model="form.roles[index - 1]"
                :clearCallback="clearRole"
                :key="`roles_${index}`" />
              <ValidationError :errors="form.errors" name="roles" />
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="flex flex-col gap-1">
      <span class="text-xl text-main-primary font-medium">
        Where are you based?
      </span>
      <Dropdown
        v-model="locationFilter"
        editable
        @change="searchLocation"
        :options="locationsList"
        optionLabel="name"
        optionValue="name"
        placeholder="New York, NY"
        class="w-full md:w-14rem"
        :pt="{ wrapper: 'max-h-[200px] overflow-auto' }"
        :ptOptions="{ mergeProps: true }"
        :loading="loading.locations">
        <template #option="{ option }">
          <div class="flex gap-2 items-center">
            <i class="text-inherit fa-sharp far fa-location-dot"></i>
            <p class="text-inherit">
              {{ option["name"] }}
            </p>
          </div>
        </template>
      </Dropdown>
    </div>
    <div class="flex flex-col gap-1">
      <span class="text-xl text-main-primary font-medium">Pitch yourself</span>
      <span class="text-sm text-main-lightGray">
        Provide a short description of who you are, what you do, and anything
        else you’d like people to know!
      </span>
      <Textarea
        v-model="form.summary"
        placeholder="E.G. I'm a filmmaker at heart, with a true passion to reinvent the marketing industry through combining real stories with amazing brands."
        class="w-full hover:!border-[#3578F8] border-[2px] resize-none text-[#000bf] border-[#8F9FB8] p-[10px] rounded-[5px] focus:outline-[#3578F8] placeholder:text-[#8F9FB8] mx-auto focus:!ring-0 focus:!outline-0"
        rows="3"
        maxlength="150"
        :invalid="form.errors.summary != null"></Textarea>
      <div class="flex w-full mb-0 self-center">
        <p class="text-right flex-1 text-[#8F9FB8] text-[14px]">
          <span
            :class="{
              'text-red-700': charCount === 150,
            }">
            {{ charCount }}
          </span>
          / 150
        </p>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import type { UserInterface } from "@/core/interfaces";
import { useAuthStore } from "@/store/Auth";
import { cn } from "@/utils/cn";
import { useForm } from "@inertiajs/vue3";
import { ref } from "vue";
import { computed } from "vue";
import { route } from "ziggy-js";
import { API } from "@/core/api";
import _debounce from "lodash/debounce";
import RolesSelectGroup from "@/components/forms/SelectGroups/Roles.vue";
import { reactive } from "vue";
import { onMounted } from "vue";
import ValidationError from "@/components/forms/validationError.vue";
import type { PropType } from "vue";

/** -------- PROPS -------- */
const props = defineProps({
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
});
/** -------- API -------- */
const userAPI = new API.Users();
const locationsAPI = new API.Locations();

/** -------- STORES -------- */
const authStore = useAuthStore();

/** -------- STATIC -------- */
const pronouns = ["She / Her", "He / Him", "They / Them", "Ze / Zir"];
const genders = ["Female", "Male", "Prefer not to say", "Custom"];

/** -------- REACTIVE -------- */
const user = computed(() => props.user);

const fullUrl = computed(() =>
  route("user.profile.index", authStore.user?.public_username),
);
const domain = computed(
  () => fullUrl.value.split("/").slice(0, -1).join("/") + "/",
);
const domainAvailable = ref();
const loading = reactive<any>({
  domain: false,
  locations: false,
});
const form = defineModel({
  type: Object,
  required: true,
});
const locationFilter = ref(user?.value.location);
const locationsList = ref();
const charCount = computed(() => form.value.summary.length);

/** -------- METHODS -------- */
const handleCopy = () => {
  if (loading.domain) return;
  navigator.clipboard.writeText(
    route("user.profile.index", form.value.public_username),
  );
};
const handleSearchDomain = _debounce(async () => {
  if (form.value.public_username === user.value.public_username) return;
  if (!form.value.public_username) {
    domainAvailable.value = false;
    return;
  }
  loading.domain = true;
  try {
    const response = await userAPI.validateDomain({
      domain: form.value.public_username,
    });
    if (response.success) {
      domainAvailable.value = true;
    } else {
      domainAvailable.value = false;
    }
  } catch (err) {
    domainAvailable.value = false;
  }
  loading.domain = false;
}, 500);

const clearRole = (value: any) => {
  form.value.roles = form.value.roles.filter((el: any) => el !== value);
};

const searchLocation = _debounce(async (e: any) => {
  if (e.originalEvent.type === "input" && e.value) getLocations(e.value);
  if (e.originalEvent.type === "input" && !e.value) {
    form.value.location = null;
    getLocations("");
  }
  if (e.originalEvent.type === "click" && e.value) {
    form.value.location = e.value;
  }
}, 250);

const getLocations = async (keyword: string = "") => {
  loading.locations = true;
  const response = await locationsAPI.get({ keyword: keyword });

  locationsList.value = response;
  loading.locations = false;
};
/** -------- WATCHERS -------- */

/** -------- LIFECYCLE HOOKS -------- */
onMounted(() => {
  getLocations();
});
</script>
