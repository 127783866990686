<template>
  <Avatar shape="circle" @click="togglePanel" class="group">
    <template #default>
      <div
        class="size-[30px] rounded-full overflow-hidden border border-text-500 cursor-pointer group-hover:border-white"
      >
        <img
          :src="user?.avatar_image"
          alt="User Avatar"
          class="object-cover w-full h-full"
        />
        <div class="absolute -right-[4px] bottom-0.5 z-10 rounded-full size-3 m-0 p-0">
          <img :src="dropdownIcon" />
        </div>
      </div>
    </template>
  </Avatar>
  <OverlayPanel
    ref="op"
    :pt="{
      content: '!p-0',
    }"
    :ptOptions="{ mergeProps: true }"
  >
    <UserPanel />
  </OverlayPanel>
</template>
<script setup lang="ts">
import dropdownIcon from "@/assets/images/new_icons/active_status_dropdown_icon.svg";
import UserPanel from "@/components/overlay-panels/UserPanel.vue";
import type { UserInterface } from "@/core/interfaces";
import { useAuthStore } from "@/store/Auth";
import { computed, ref } from "vue";
const authStore = useAuthStore();
const user = computed(() => authStore.getUser as UserInterface);

const op = ref();

const togglePanel = (e: any) => {
  if (op.value) {
    op.value.toggle(e);
  }
};
</script>
