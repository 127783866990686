<template>
  <div class="flex flex-col h-full divide-y-[1px] divide-surface-600/95">
    <section id="header" class="p-4 space-y-2">
      <div class="flex flex-wrap gap-2">
        <CustomChip :label="tag.tag" removable v-for="(tag, index) in tags" :key="tag.tag"
          @remove="() => handleRemove(index)" />
      </div>
      <p class="uppercase text-xs" :class="{
        'text-surface-400/95': tags.length < max,
        'text-red-500': tags.length >= max,
      }">
        <strong>{{ tags.length }}/10</strong> content tags have been selected
      </p>
    </section>
    <section id="content" class="p-4 space-y-2 scroll-hidden overflow-y-auto scroll-smooth flex-1">
      <IconField iconPosition="left" class="relative">
        <i class="far fa-magnifying-glass !text-surface-400"></i>
        <InputText v-model="lazyLoad.keyword" placeholder="Search a tag by name" @input="search"
          class="bg-white/10 w-full focus:!outline-none focus:!border-0 focus:!ring-0 hidden md:block transition-all duration-150 ease-in-out text-white" />
      </IconField>
      <h2 class="text-xs uppercase text-surface-400/95">
        popular content tags
      </h2>
      <div class="space-y-4" v-if="!isLoading">
        <AddItem v-if="!tagsList.length && lazyLoad.keyword" :disabled="tags.length >= max" :callback="handleAdd"
          :value="lazyLoad.keyword.toLowerCase()">
        </AddItem>
        <div v-for="tag in tagsList" :key="tag.id"
          class="flex justify-between items-center text-white cursor-pointer">
          <label :for="`tags_${tag.id}`" class="flex-1 cursor-pointer hover:text-switcherBlue-500" :class="{
            'text-switcherBlue-500': tags.includes(tag.tag),
          }">{{ tag.tag }}</label>
          <Checkbox :value="tag" v-model="tags" :inputId="`tags_${tag.id}`" :outlined="true" color="lightBlue" size="lg"
            :disabled="tags.length >= max && !tags.includes(tag)" />
        </div>
      </div>
      <div class="flex flex-col justify-center gap-4" v-else>
        <div class="flex items-center justify-between" v-for="i in 5">
          <Skeleton width="15rem"></Skeleton>
          <Skeleton size="2rem" shape="circle"></Skeleton>
        </div>
      </div>
    </section>
  </div>
</template>
<script setup lang="ts">
import _debounce from "lodash/debounce";
import { API } from "@/core/api";
import { ref, onMounted, watch, reactive, computed } from "vue";
import CustomChip from "@/components/general/CustomChip.vue";
import AddItem from "@/components/general/AddItem.vue";

const TagsAPI = new API.Tags();

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
});

const isLoading = ref(true);
const max = ref(10);
const tags = ref(props.modelValue.tags || []);
const tagsList = ref([]);
const lazyLoad = reactive<any>({
  keyword: null,
  initialValues: props.modelValue.tags || []
});

watch(tags, () => {
  props.modelValue.tags = tags.value;
  lazyLoad.initialValues = tags.value.length ? tags.value : null;
});
const handleAdd = () => {
  //Make sure that the ID is different than other customs tags
  const tag = { id: -tags.value.length, tag: lazyLoad.keyword.toLowerCase() };
  const exists = tags.value.findIndex((i) => i.tag === tag.tag);
  if (tags.value.length < max.value) {
    lazyLoad.keyword = null;
    if (exists === -1) tags.value.push(tag);
    getData();
  } else return;
};

const getData = async () => {
  isLoading.value = true;
  try {
    let response = await TagsAPI.get({ ...lazyLoad, keyword: lazyLoad.keyword?.toLowerCase() });
    tagsList.value = response.data;

    isLoading.value = false;
  } catch (error) {
    console.error(error);
    isLoading.value = false;
  }
};

const search = _debounce(() => {
  getData()
},250)
const handleRemove = (index: number) => {
  tags.value.splice(index, 1);
}
onMounted(async () => {
  getData();
});
</script>
<style></style>
