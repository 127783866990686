<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-0',
      root: 'overflow-hidden w-screen h-screen bg-black ',
    }"
    :visible="true"
    :closable="false"
  >
    <div class="relative" v-if="content.content_type === 'image_gallery'">
      <swiper
        :slides-per-view="1"
        :space-between="0"
        :initialSlide="selected"
        navigation
        :modules="modules"
      >
        <swiper-slide v-for="child in content.children"
          ><img
            :src="
              child.thumbnail_image_kit_id ??
              child.image_kit_id ??
              child.image_kit_id_16_9 ??
              child.image_kit_ids?.[0]
            "
            alt=""
            class="object-contain h-screen mx-auto"
        /></swiper-slide>
      </swiper>
      <div class="absolute top-2 right-5 z-50 text-white cursor-pointer">
        <i class="fa-regular fa-xmark text-xl" @click="$emit('close')"></i>
      </div>
    </div>
    <div class="relative" v-else>
      <img
        :src="
          content.thumbnail_image_kit_id ??
          content.image_kit_id ??
          content.image_kit_id_16_9 ??
          content.image_kit_ids?.[0]
        "
        alt=""
        class="object-contain h-screen mx-auto"
      />
      <div class="absolute top-2 right-5 z-50 text-white cursor-pointer">
        <i class="fa-regular fa-xmark text-xl" @click="$emit('close')"></i>
      </div>
    </div>
  </Dialog>
</template>
<script setup>
import { reactive, ref, computed, onMounted, watch, mergeProps } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
const modules = [Navigation];
const props = defineProps({
  content: { required: true, type: Object },
  selected: { default: 0 },
});
const emit = defineEmits(["close"]);
</script>
<style>
.swiper-button-prev,
.swiper-button-next {
  color: white !important;
}
</style>
