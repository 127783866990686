import baseApi from "../Base";
import { route } from "ziggy-js";

export class SocialPostCommentsService extends baseApi {

    async get(post: number, form: any) {
        return await this.request(route("admin.api.social_posts.comments", post), "GET", form);
    }

    async remove(comment: number) {
        return await this.request(
            route("admin.social_posts.comments.remove", comment ),
            "POST",
        );
    }
}
