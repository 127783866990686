<template>
  <InfoCard customClass="!w-full">
    <template #title>
      <div class="flex justify-between items-center">
        <h3 class="text-lg font-medium">Bio</h3>
        <i
          v-if="is_editing && is_own_profile"
          class="fal fa-pencil text-primary-500 cursor-pointer text-base"
          @click="manageBioVisible = true"></i>
      </div>
    </template>
    <template #content>
      <div class="flex gap-7 py-4">
        <div
          class="aspect-[2/2.5] bg-center bg-cover rounded-2xl w-[200px] max-h-[250px] min-w-[200px]"
          :style="'background-image: url(' + user.avatar_image + ')'"></div>
        <div class="text-sm text-surface-500 line-clamp-[19] leading-normal">
          {{ card.about }}
        </div>
      </div>
    </template>
  </InfoCard>
  <ManageBio
    :about="card.about"
    v-if="manageBioVisible"
    @close="manageBioVisible = false" />
</template>

<script lang="ts" setup>
import { reactive, ref, computed, onMounted, watch, PropType } from "vue";
import InfoCard from "@/components/general/CustomCard.vue";
import type { BizCardInterface, UserInterface } from "@/core/interfaces";
import ManageBio from "@/components/dialogs/bizcard/info/ManageBio.vue";
import { useUserBizcardStore } from "@/store/UserBizcard";

/* -------- PROPS -------- */
const props = defineProps({
  card: {
    required: true,
    type: Object as PropType<BizCardInterface>,
  },
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
});
/* -------- COMPOSABLE -------- */
const bizcardStore = useUserBizcardStore();
/* -------- STATE -------- */
const manageBioVisible = ref(false);
const is_editing = computed(() => bizcardStore.isEditing);
const is_own_profile = computed(() => bizcardStore.own_profile);

/* -------- METHODS -------- */

/* -------- HOOKS -------- */

/* -------- WATCHERS -------- */
</script>
