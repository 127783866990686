<template>
    <div class="cursor-pointer size-5 relative" @click="togglePanel">
        <Badge :value="countParsed" severity="danger" class="absolute -right-1.5 -top-2" v-if="count"></Badge>
        <i class="fa-regular fa-bell text-[20px] text-main-lightGray hover:text-white"></i>
    </div>
    <OverlayPanel ref="op" :pt="{
        content: {
            class: cn(OverlayPanelPreset.content.class, 'p-0'),
        },
        root: {
            class: cn(OverlayPanelPreset.root.class, 'before:-ml-[20px] after:-ml-[20px]')
        }
    }">
        <NotificationsPanel :count="count" @readAll="clearCount" />
    </OverlayPanel>
</template>
<script lang="ts" setup>
import NotificationsPanel from "@/components/overlay-panels/NotificationsPanel.vue";
import { computed, ref } from "vue";
import OverlayPanelPreset from "@/assets/tailwind-preset/overlaypanel";
import { cn } from "@/utils/cn";
import { API } from "@/core/api";
import { onMounted } from "vue";
import { useAuthStore } from "@/store/Auth";

const auth = useAuthStore();
const op = ref();

const togglePanel = (e: any) => {
    if (op.value) {
        op.value.toggle(e);
    }
}

const count = ref(0);
const countParsed = computed(() => (count.value > 9) ? "9+" : count.value.toString());

const getCount = () => {
    const notificationApi = new API.Notifications();
    notificationApi.count().then((res) => {
        count.value = res.unread_notifications;
    });
}

const clearCount = () => {
    count.value = 0;
}
onMounted(() => {
    getCount();
});
</script>