<template>
  <div
    class="bg-no-repeat bg-center relative cursor-pointer aspect-video video_view">
    <div>
      <img
        :src="
          props.data?.content?.image_kit_id_16_9 ??
          props.data?.content?.thumbnail_image_kit_id ??
          props.data?.content?.image_kit_id ??
          props.data?.content?.image_kit_ids?.[0] ??
          'https://storage.googleapis.com/demo-api.bizly.net/static/app-templates/web-bg-min.jpg'
        "
        alt=""
        class="w-full h-full object-cover aspect-video" />
      <InfoBallon class="!py-0.5">
        <template #icon>
          <i class="fal fa-clapperboard-play text-md"></i>
        </template>
        <template #count>{{ getBalloonInfo }}</template>
      </InfoBallon>
    </div>

    <div class="" v-if="props.data?.content?.content_type == 'video_gallery'">
      <div class="relative w-full flex items-end bg-black h-[130px] z-[50]">
        <div class="w-full flex scroll-hidden gap-2.5 py-[15px] relative">
          <swiper
            :slides-per-view="props.data?.content?.children.length > 2 ? 3 : 2"
            :space-between="15"
            class="w-full !px-[15px] !scroll-hidden !mx-0">
            <swiper-slide
              v-for="child in props.data?.content?.children"
              :key="child.id"
              class="w-fit aspect-video bg-transparent transition-all relative overflow-hidden rounded-[8px]">
              <img
                :src="child.thumbnail_image_kit_id ?? child.external_type"
                alt=""
                class="w-full h-full block object-cover" />
              <InfoBallon class="!-top-0.5 !-left-0.5">
                <template #count>
                  {{ child?.external_url || child?.external_type || "N/A" }}
                </template>
              </InfoBallon>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <div
        class="w-full min-h-full gradient font-[600] text-white absolute bottom-0 leading-[23px] px-[15px] py-[10px] mb-0 flex justify-start items-end"></div>
    </div>

    <div
      v-else
      class="w-full min-h-full video_gallery_overlay font-[600] text-white absolute bottom-0 leading-[23px] px-[15px] py-[10px] mb-0 flex justify-start items-end">
      {{ props.data?.content?.title }}
    </div>
  </div>
</template>

<script setup>
import "swiper/css";
import InfoBallon from "../parts/InfoBallon.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { computed } from "vue";
const props = defineProps(["data"]);
const getBalloonInfo = computed(() => {
  if (!props.data?.content?.children?.length)
    return (
      props.data?.content?.external_url || props.data?.content?.external_type
    );
  if (
    props.data?.content?.children?.length &&
    props.data?.content?.children?.length > 1
  )
    return props.data?.content?.children?.length;
});
</script>
<style scoped lang="scss">
video {
  width: 80%;
  height: 100%;
}

.video_view {
  .video_gallery_overlay {
    opacity: 0;
  }
  &:hover {
    .video_gallery_overlay {
      transition: all 0.2s ease-in;
      opacity: 1;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 42.08%,
        #000000 85.76%
      );
    }
  }
}

.gradient {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50.08%, #000000 85.76%);
}
</style>
