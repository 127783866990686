export default {
  root: ({ props, context, parent }) => ({
    class: [
      "relative",

      // Alignments
      "items-center inline-flex text-center align-bottom justify-center",

      //min-w-32 leading-5 text-center text-sm capitalize py-3 px-2 md:px-0
      // Sizes & Spacing
      "leading-5",
      "min-w-32",
      "text-xs py-3 px-3",
      "tracking-wide font-semibold",

      // Shapes
      { "shadow-lg": props.raised },
      { rounded: !props.rounded, "rounded-full": props.rounded },
      {
        "rounded-none first:rounded-l-md last:rounded-r-md":
          parent.instance.$name == "InputGroup",
      },

      // --- Severity Buttons ---

      // Primary Button
      "bg-transparent",
      {
        "text-primaryBlue-500":
          !props.link &&
          props.severity === null &&
          !props.text &&
          !props.outlined &&
          !props.plain,
      },
      // --- Severity Button States ---
      "focus:outline-none focus:outline-offset-0 focus:ring-0",

      // Primary
      "hover:bg-switcherBlue-500/15 hover-hover",
      // Disabled
      {
        "opacity-60 pointer-events-none cursor-default": context.disabled,
      },

      // Transitions
      "transition duration-200 ease-in-out",

      // Misc
      "cursor-pointer overflow-hidden select-none",
    ],
  }),
  label: ({ props }) => ({
    class: [
      "duration-200",
      {
        "hover:underline": props.link,
      },
      {
        "flex-1": props.label !== null,
        "invisible w-0": props.label == null,
      },
    ],
  }),
  icon: ({ props }) => ({
    class: [
      "mx-0",
      {
        "mr-2": props.iconPos == "left" && props.label != null,
        "ml-2 order-1": props.iconPos == "right" && props.label != null,
        "mb-2": props.iconPos == "top" && props.label != null,
        "mt-2": props.iconPos == "bottom" && props.label != null,
      },
    ],
  }),
  loadingicon: ({ props }) => ({
    class: [
      "h-4 w-4",
      "mx-0",
      {
        "mr-2": props.iconPos == "left" && props.label != null,
        "ml-2 order-1": props.iconPos == "right" && props.label != null,
        "mb-2": props.iconPos == "top" && props.label != null,
        "mt-2": props.iconPos == "bottom" && props.label != null,
      },
      "animate-spin",
    ],
  }),
  badge: ({ props }) => ({
    class: [
      {
        "ml-2 w-4 h-4 leading-none flex items-center justify-center":
          props.badge,
      },
    ],
  }),
};
