<template>
  <section class="mx-auto p-0 w-[90%]">
    <section
      class="flex flex-column justify-center items-center flex-nowrap h-[70vh] text-center"
    >
      <div class="section-question-group w-full px-[2rem] flex flex-col gap-1">
        <h1 class="text-3xl font-medium tracking-[-.7px] text-[#212529] mb-2">
          Pitch yourself
        </h1>
        <h5 class="text-base tracking-[-.7px] text-text-700 mb-2">
          In other words, how would you describe yourself?
        </h5>
        <Textarea
          v-model="form.summary"
          placeholder="E.G. I'm a filmmaker at heart, with a true passion to reinvent the marketing industry through combining real stories with amazing brands."
          class="w-[95%] hover:!border-[#3578F8] border-[2px] resize-none text-[#000bf] border-[#8F9FB8] p-[10px] rounded-[5px] focus:outline-[#3578F8] placeholder:text-[#8F9FB8] mx-auto focus:!ring-0 focus:!outline-0"
          rows="3"
          maxlength="150"
          :invalid="form.errors.summary != null"
        >
        </Textarea>
        <div class="flex w-[95%] mb-0 self-center">
          <ValidationError :errors="form.errors" name="summary" class="self-start" />
          <p class="text-right flex-1 text-[#8F9FB8] text-[14px]">
            <span
              :class="{
                'text-red-700': charCount === 150,
              }"
              >{{ charCount }}</span
            >
            / 150
          </p>
        </div>
      </div>
    </section>
    <div class="flex items-center justify-center gap-3">
      <Button
        label="Back"
        @click="props.prevCallback"
        type="button"
        class="w-full sm:w-[25%] py-2"
      />

      <Button label="Next" @click="submit" type="button" class="w-full sm:w-[25%] py-2" />
    </div>
  </section>
</template>
<script setup>
import ValidationError from "@/components/forms/validationError.vue";
import { useForm } from "@inertiajs/vue3";
import { computed } from "vue";
const props = defineProps(["nextCallback", "prevCallback"]);
const form = useForm({
  summary: "",
});
const charCount = computed(() => form.summary.length);
const submit = () => {
  form.post(route("onboarding.pitch_yourself_info.submit"), {
    only: [],
    onSuccess: () => {
      props.nextCallback();
    },
    preserveState: true,
  });
};
</script>
