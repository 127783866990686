<template>
  <div class="p-4">
    <Accordion
      expandIcon="pi pi-plus"
      collapseIcon="pi pi-minus"
      @tab-open="(e) => handleOpenTab(e.index)"
      @tab-close="activeIndex = null"
    >
      <AccordionTab id="project type">
        <template #header>
          <div
            class="bg-main-lightBg rounded-lg flex justify-between items-center px-4 pt-4"
            :class="{ '!rounded-b-none': activeIndex === 0 }"
          >
            <div class="flex flex-col gap-2 text-white">
              <h1 class="text-base">Project Type</h1>
              <p class="text-main-disabled text-sm">
                What kind of project is this?
              </p>
              <div
                class="flex gap-2 flex-wrap mt-2"
                :class="{
                  'mb-2': form.projectTypes.length,
                }"
              >
                <CustomChip
                  v-for="(item, index) in form.projectTypes"
                  :key="item.id"
                  :label="item.title"
                  inputId="projectTypesChips"
                  :removable="true"
                  @remove.stop="() => form.projectTypes.splice(index, 1)"
                  :index="index"
                />
              </div>
            </div>
            <div class="">
              <button
                class="text-white h-[30px] py-2 px-4 leading-2.5 flex items-center gap-4 font-light text-[13px] rounded-[30px] bg-switcherBlue-500/60"
              >
                <i class="fa-light fa-plus"></i>
                Add
              </button>
            </div>
          </div>
        </template>
        <div class="m-0 px-4 pb-4 bg-main-lightBg rounded-b-md">
          <p
            :class="
              cn('uppercase text-xs text-surface-400/95 py-0.5 pl-1', {
                'text-red-500': form.projectTypes.length >= max,
              })
            "
            v-if="data?.projectTypes?.length"
          >
            <strong>{{ form.projectTypes.length }}/{{ max }}</strong> type{{
              form.projectTypes.length > 1 ? "s" : ""
            }}
            selected
          </p>
          <IconField
            iconPosition="left"
            class="bg-black"
            :ptOptions="{ mergeProps: true }"
            :pt="{ root: '!rounded-t-md' }"
          >
            <i class="far fa-magnifying-glass !text-surface-400"></i>
            <InputText
              v-model="lazyload.projectTypes.keyword"
              @input="() => filter('projectTypes')"
              placeholder="Search project type"
              class="bg-transparent !text-white/90 focus:!ring-0 focus:!border-0 focus:!outline-0 !w-full"
            />
          </IconField>
          <div class="bg-black rounded-b-md space-y-2">
            <div class="max-h-[300px] scroll-hidden overflow-y-auto py-2">
              <div
                class=""
                v-for="item in data.projectTypes"
                v-if="data?.projectTypes?.length"
              >
                <h1 class="text-white text-base font-medium my-2 px-2.5">
                  {{ item.title }}
                </h1>

                <div
                  v-for="child in item.children"
                  class="flex justify-between items-center pl-5 hover:bg-surface-500/50 pr-2.5"
                  :key="child.id"
                >
                  <label
                    class="text-sm text-white/90 flex-1 cursor-pointer py-1.5"
                    :for="`type_${child.id}`"
                    >{{ child.title }}</label
                  >
                  <Checkbox
                    v-model="form.projectTypes"
                    :value="child"
                    :inputId="`type_${child.id}`"
                    :outlined="true"
                    color="lightBlue"
                    size="lg"
                    :disabled="
                      form.projectTypes.length >= max &&
                      !form.projectTypes.includes(child)
                    "
                  />
                </div>
              </div>

              <div
                class="px-2.5"
                v-if="
                  !loading &&
                  data?.projectTypes?.length == 0 &&
                  lazyload['projectTypes'].keyword
                "
              >
                <AddItem
                  :value="lazyload['projectTypes'].keyword"
                  :disabled="form.projectTypes.length >= max"
                  :callback="
                    () => handleAdd('projectTypesChips', 'projectTypes')
                  "
                />
              </div>
              <div
                class="px-2.5 flex items-center justify-center"
                v-if="loading"
              >
                <i class="fa fa-spinner-third fa-spin text-white"></i>
              </div>
            </div>
          </div>
        </div>
      </AccordionTab>
      <AccordionTab id="project roles">
        <template #header>
          <div
            class="bg-main-lightBg rounded-lg flex justify-between items-center px-4 pt-4"
            :class="{ '!rounded-b-none': activeIndex === 1 }"
          >
            <div class="flex flex-col gap-2 text-white">
              <h1 class="text-base">Role on project</h1>
              <p class="text-main-disabled text-sm">
                Add up to 3 roles that you did on this project.
              </p>
              <div
                class="flex gap-2 flex-wrap mt-2"
                :class="{
                  'mb-2': form.projectRoles.length,
                }"
              >
                <CustomChip
                  v-for="(item, index) in form.projectRoles"
                  :key="item.id"
                  inputId="projectRoles"
                  :label="item.name"
                  :removable="true"
                  @remove.stop="() => form.projectRoles.splice(index, 1)"
                  :index="index"
                />
              </div>
            </div>
            <div class="">
              <button
                class="text-white h-[30px] py-2 px-4 leading-2.5 flex items-center gap-4 font-light text-[13px] rounded-[30px] bg-switcherBlue-500/60"
              >
                <i class="fa-light fa-plus"></i>
                Add
              </button>
            </div>
          </div>
        </template>
        <div class="m-0 px-4 pb-4 bg-main-lightBg rounded-b-md">
          <p
            :class="
              cn('uppercase text-xs text-surface-400/95 py-0.5 pl-1', {
                'text-red-500': form.projectRoles.length >= max,
              })
            "
            v-if="data?.projectRoles?.length"
          >
            <strong>{{ form.projectRoles.length }}/{{ max }}</strong> role{{
              form.projectRoles.length > 1 || form.projectRoles.length == 0
                ? "s"
                : ""
            }}
            selected
          </p>
          <IconField
            iconPosition="left"
            class="bg-black"
            :ptOptions="{ mergeProps: true }"
            :pt="{ root: '!rounded-t-md' }"
          >
            <i class="far fa-magnifying-glass !text-surface-400"></i>
            <InputText
              @input="() => filter('projectRoles')"
              v-model="lazyload.projectRoles.keyword"
              placeholder="Search role"
              class="bg-transparent !text-white/90 focus:!ring-0 focus:!border-0 focus:!outline-0 !w-full"
            />
          </IconField>
          <div class="bg-black rounded-b-md">
            <div
              class="scroll-hidden overflow-y-auto max-h-[300px]"
              v-infinite-scroll="() => getData('projectRoles')"
              v-if="data?.projectRoles?.length"
            >
              <div
                class="flex justify-between items-center hover:bg-surface-500/50 px-2.5 py-0.5"
                :key="item.id"
                v-for="item in data.projectRoles"
              >
                <label
                  class="text-sm text-white flex-1 cursor-pointer py-1.5"
                  :for="`item_${item.id}`"
                  >{{ item.name }}</label
                >
                <Checkbox
                  v-model="form.projectRoles"
                  :value="item"
                  :inputId="`item_${item.id}`"
                  :outlined="true"
                  color="lightBlue"
                  size="lg"
                  :disabled="
                    form.projectRoles.length >= max &&
                    !form.projectRoles.includes(item)
                  "
                />
              </div>
            </div>
            <div
              class="px-2.5 pb-2.5"
              v-if="
                !loading &&
                data?.projectRoles?.length == 0 &&
                lazyload['projectRoles'].keyword
              "
            >
              <AddItem
                :value="lazyload['projectRoles'].keyword"
                :disabled="form.projectRoles.length >= max"
                :callback="() => handleAdd('projectRolesChips', 'projectRoles')"
              />
            </div>
            <div
              class="px-2.5 pb-2.5 flex items-center justify-center"
              v-if="loading"
            >
              <i class="fa fa-spinner-third fa-spin text-white"></i>
            </div>
          </div>
        </div>
      </AccordionTab>
      <AccordionTab id="industries">
        <template #header>
          <div
            class="bg-main-lightBg rounded-lg flex justify-between items-center px-4 pt-4"
            :class="{ '!rounded-b-none': activeIndex === 2 }"
          >
            <div class="flex flex-col gap-2 text-white">
              <h1 class="text-base">Client/Organization</h1>
              <p class="text-main-disabled text-sm">
                Who was this project done for or with?
              </p>
              <div
                class="flex gap-2 flex-wrap mt-2"
                :class="{
                  'mb-2': form.projectClients.length,
                }"
              >
                <CustomClientChip
                  v-for="(item, index) in form.projectClients"
                  inputId="projectClientsChips"
                  :key="item.id"
                  :client="item"
                  :removable="true"
                  :imageFallback="NoCompanyImage"
                  @remove.stop="() => form.projectClients.splice(index, 1)"
                  :index="index"
                />
              </div>
            </div>
            <div class="">
              <button
                class="text-white h-[30px] py-2 px-4 leading-2.5 flex items-center gap-4 font-light text-[13px] rounded-[30px] bg-switcherBlue-500/60"
              >
                <i class="fa-light fa-plus"></i>
                Add
              </button>
            </div>
          </div>
        </template>
        <div class="m-0 px-4 pb-4 bg-main-lightBg rounded-b-md">
          <IconField
            iconPosition="left"
            class="bg-black"
            :ptOptions="{ mergeProps: true }"
            :pt="{ root: '!rounded-t-md' }"
          >
            <i class="far fa-magnifying-glass !text-surface-400"></i>
            <InputText
              @input="() => filter('projectClients')"
              v-model="lazyload.projectClients.keyword"
              placeholder="Search company/organization"
              class="bg-transparent !text-white/90 focus:!ring-0 focus:!border-0 focus:!outline-0 !w-full"
            />
          </IconField>
          <div class="bg-black rounded-b-md space-y-2">
            <div class="p-2.5 pb-0" v-if="lazyload['projectClients'].keyword">
              <AddItem
                :value="lazyload['projectClients'].keyword"
                :disabled="form.projectClients.length >= max"
                :callback="
                  () => handleAdd('projectClientsChips', 'projectClients')
                "
              />
            </div>
            <VirtualScroller
              class="scroll-hidden overscroll-contain"
              :items="data.projectClients"
              style="height: 250px"
              :showLoader="true"
              :lazy="true"
              :loading="loading"
              :itemSize="40"
              v-if="data?.projectClients?.length || loading"
            >
              <template v-slot:item="{ item }">
                <div
                  class="flex justify-between items-center hover:bg-surface-500/50 px-2.5"
                  :key="item.id"
                >
                  <label
                    class="flex gap-4 items-center flex-1 cursor-pointer py-2"
                    :for="`client_${item.id ?? item.brandId}`"
                  >
                    <img
                      :src="item.image ?? NoCompanyImage"
                      class="rounded-full h-[30px] aspect-square"
                      :alt="item.name"
                    />
                    <p class="text-sm text-white">{{ item.name }}</p>
                  </label>
                  <Checkbox
                    v-model="form.projectClients"
                    :value="item"
                    :inputId="`client_${item.id ?? item.brandId}`"
                    :outlined="true"
                    color="lightBlue"
                    size="lg"
                  />
                </div>
              </template>
            </VirtualScroller>
          </div>
        </div>
      </AccordionTab>
    </Accordion>
    <div class="bg-main-lightBg rounded-md p-4">
      <h1 class="text-white font-medium text-base">Completed on</h1>
      <p class="text-main-disabled text-sm">
        What year was this project completed on?
      </p>
      <InputNumber
        :pt="{
          root: 'w-full mt-3',
          input: {
            root: `!bg-main-lightBg text-white text-sm !rounded-md !w-full ${
              !isValidYear && form.completedOn ? 'border-red-500' : ''
            }`,
          },
        }"
        :ptOptions="{ mergeProps: true }"
        v-model="form.completedOn"
        :min="0"
        inputId="integeronly"
        :useGrouping="false"
        placeholder="Type a year"
      />
      <p
        v-if="!isValidYear && form.completedOn"
        class="text-red-500 text-xs mt-2"
      >
        {{
          isYearInFuture()
            ? "Year cannot be in the future"
            : "Please enter a valid year."
        }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, computed, watch } from "vue";
import { API } from "@/core/api";
import CustomChip from "@/components/general/CustomChip.vue";
import CustomClientChip from "./chips/ProjectClient.vue";
import NoCompanyImage from "@/assets/images/new_icons/company_dummy.svg";

import _debounce from "lodash/debounce";
import AddItem from "@/components/general/AddItem.vue";
import { vInfiniteScroll } from "@vueuse/components";

import { cn } from "@/utils/cn";

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
});

/* ---------- APIs ----------*/
const APIS: any = {
  projectTypes: new API.ProjectTypes(),
  projectRoles: new API.ClientRoles(),
  projectClients: new API.ClientCompanies(),
};
/* ---------- REACTIVE DATA ----------*/
const data = reactive<any>({
  projectTypes: null,
  projectRoles: [],
  projectClients: null,
});
const activeIndex = ref();
const max = 3;
const loading = ref(false);
const lazyload = reactive<any>({
  projectTypes: {
    start: 0,
    size: 25, // rows to fetch from the api
    keyword: "", // the search keyword
    no_more_results: false, // no more results to be fetched so we dont overwhelm the server with useless requests
    is_capped: true, //Should have a max selected count
    initialValues: props.modelValue.project_types,
  },
  projectRoles: {
    start: 0,
    size: 25,
    keyword: "",
    no_more_results: false,
    is_capped: true,
    initialValues: props.modelValue.project_roles,
  },
  projectClients: {
    start: 0,
    size: 25,
    keyword: "",
    no_more_results: false,
    initialValues: props.modelValue.project_companies,
  },
});
const isValidYear = computed(() => {
  let isValid =
    form.completedOn === null ||
    (form.completedOn > 1000 && form.completedOn < 9999 && !isYearInFuture());
  props.modelValue.isValid.completedOn = isValid;
  return isValid;
});
const isYearInFuture = () => {
  const currentYear = new Date().getFullYear();
  return form.completedOn <= currentYear ? false : true;
};
const form = reactive<any>({
  projectTypes: props.modelValue.project_types || [],
  projectRoles: props.modelValue.project_roles || [],
  projectClients: props.modelValue.project_companies || [],
  completedOn: props.modelValue.project_completed_on || null,
});
watch(
  form,
  () => {
    props.modelValue.project_types = form.projectTypes;
    props.modelValue.project_roles = form.projectRoles;
    props.modelValue.project_companies = form.projectClients;
    props.modelValue.project_completed_on = form.completedOn;
  },
  { deep: true },
);

/* ---------- FUNCTIONS ----------*/
const handleOpenTab = async (index: number) => {
  activeIndex.value = index;
  switch (index) {
    case 0:
      if (!data.projectTypes) {
        loading.value = true;
        let response = await APIS.projectTypes.get(lazyload.projectTypes);
        data.projectTypes = response.data;
        loading.value = false;
      }
      break;
    case 1:
      if (data.projectRoles.length === 0) {
        getData("projectRoles");
      }
      break;
    case 2:
      if (!data.projectClients) {
        loading.value = true;
        let response = await APIS.projectClients.get(lazyload.projectClients);
        data.projectClients = response.data;
        loading.value = false;
      }
      break;
  }
};

/**
 * Description gets the data for a specific data set
 * @param 'projectTypes' | 'projectRoles' | 'projectClients' lazyfor
 * @returns void
 */
const getData = async (lazyfor: any) => {
  if (!lazyload[lazyfor].no_more_results && !loading.value) {
    loading.value = true;
    let response = await APIS[lazyfor].get(lazyload[lazyfor]);
    response = response.data;
    loading.value = false;

    data[lazyfor].push(...response);
    if (response.length < lazyload[lazyfor].size)
      lazyload[lazyfor].no_more_results = true;
    lazyload[lazyfor].start = data[lazyfor].length;
  }
};

/**
 * Description gets the data for a specific data set
 * @param 'projectTypes' | 'projectRoles' | 'projectClients' filterfor
 * @returns void
 */
const filter = _debounce(async (filterfor) => {
  lazyload[filterfor].no_more_results = false;
  lazyload[filterfor].start = 0;
  loading.value = true;
  let response = await APIS[filterfor].get(lazyload[filterfor]);
  data[filterfor] = response.data;
  if (response.length < lazyload[filterfor].size)
    lazyload[filterfor].no_more_results = true;
  lazyload[filterfor].start = data[filterfor].length;
  loading.value = false;
}, 250);

const handleAdd = (inputId: string, listType: string) => {
  if (lazyload[listType].is_capped && data[listType].length >= max) return;
  const value = lazyload[listType].keyword;
  lazyload[listType].keyword = "";
  lazyload[listType].no_more_results = false;
  filter(listType);
  const input = document.getElementById(inputId) as HTMLInputElement;
  if (input) {
    input.value = "";
    const event = new Event("input", {
      bubbles: true,
    });
    input.dispatchEvent(event);
  }
  switch (listType) {
    case "projectTypes":
      if (form[listType].findIndex((i: any) => i.title === value) === -1)
        form[listType].push({ title: value.toLowerCase(), id: -1 });
      break;
    case "projectClients":
      if (form[listType].findIndex((i: any) => i.name === value) === -1)
        form[listType].push({
          image: null,
          name: value.toLowerCase(),
          brandId: -1,
        });
      break;
    default:
      if (form[listType].findIndex((i: any) => i.name === value) === -1)
        form[listType].push({ name: value.toLowerCase(), id: -1 });
  }
};
</script>
