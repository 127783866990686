export const Interests = [
  {
    icon: "fa-circle-nodes",
    text: "Networking",
    value: "networking",
  },
  {
    icon: "fa-user-magnifying-glass",
    text: "Hiring & Recruiting",
    value: "hiring_recruiting",
  },
  {
    icon: "fa-briefcase",
    text: "Looking for Opportunities",
    value: "opportunities",
  },
  {
    icon: "fa-handshake",
    text: "Collaborating",
    value: "collaboration",
  },
];
export const Category_tabs = [
  {
    text: "Creative",
    value: "creative",
  },
  {
    text: "Professional",
    value: "professional",
  },
  {
    text: "Talent / Performer",
    value: "talent",
  },
];
export const OccupationStatus = [
  {
    id: "employed",
    text: "Employed",
  },
  {
    id: "student",
    text: "Student",
  },
  {
    id: "freelancer",
    text: "Freelancer",
  },
];
export const EMPLOYMENT_TYPES = [
  {
    id: 1,
    name: "Full-time",
    value: "full-time",
  },
  {
    id: 2,
    name: "Part-time",
    value: "part-time",
  },
  {
    id: 3,
    name: "Self-employed",
    value: "self-employed",
  },
  {
    id: 4,
    name: "Freelance",
    value: "freelance",
  },
  {
    id: 5,
    name: "Contract",
    value: "contract",
  },
  {
    id: 6,
    name: "Internship",
    value: "internship",
  },
  {
    id: 7,
    name: "Apprenticeship",
    value: "apprenticeship",
  },
  {
    id: 8,
    name: "Seasonal",
    value: "seasonal",
  },
];
