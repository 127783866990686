<template>
  <div class="p-2 bg-transparent h-full">
    <div
      v-if="!content.is_gallery"
      class="relative cursor-pointer rounded-[16px] overflow-hidden max-w-[900px] w-full shadow-default">
      <div class="aspect-video rounded-lg">
        <div class="w-full h-full">
          <img
            :src="
              content.image_kit_id_16_9 ??
              content.thumbnail_image_kit_id ??
              content.image_kit_ids?.[0]
            "
            alt=""
            class="size-full object-cover rounded-t-[16px]"
            @error="handleFallback" />
        </div>
        <h2
          class="p-[1rem] m-0 text-[#546B91] font-[400] text-[0.75rem] border-t-[1px] border-[#CDD5E1] bg-[#F7F8FB] uppercase">
          {{ extractDomain(content.image_kit_id) }}
        </h2>
      </div>
      <div
        class="absolute top-3 left-3 size-10 rounded-3xl bg-black/70 flex items-center justify-center text-white">
        <i class="fa fa-chain"></i>
      </div>
    </div>
    <div
      v-else-if="content.children.length <= 2"
      class="flex gap-2 items-center">
      <div
        v-for="(child, index) in content.children"
        :key="child.id"
        class="relative cursor-pointer rounded-[16px] overflow-hidden shadow-default w-1/2">
        <div class="aspect-video rounded-lg">
          <div class="w-full h-full">
            <img
              :src="
                child.image_kit_id_16_9 ??
                child.thumbnail_image_kit_id ??
                child.image_kit_ids?.[0]
              "
              alt=""
              class="size-full object-cover rounded-t-[16px]" />
          </div>
          <h2
            class="p-[1rem] m-0 text-[#546B91] font-[400] text-[0.75rem] border-t-[1px] border-[#CDD5E1] bg-[#F7F8FB] uppercase">
            {{ extractDomain(child.image_kit_id) }}
          </h2>
        </div>
        <div
          class="absolute top-3 left-3 py-1.5 px-2 h-6 rounded bg-black/70 flex items-center justify-center text-white text-sm gap-2">
          <i class="fa fa-chain"></i>
          <p>{{ index + 1 }}/{{ content.children.length }}</p>
        </div>
      </div>
    </div>
    <div v-else class="max-w-[900px]">
      <swiper
        id="content-slider"
        :slides-per-view="'auto'"
        :space-between="0"
        navigation
        :modules="[Navigation]"
        class="!size-full !p-0"
        navigationDisabledClass="hidden">
        <swiper-slide
          v-for="(child, index) in content.children"
          :key="child.id"
          class="!w-[420px] !h-full py-4">
          <div
            class="relative cursor-pointer rounded-[16px] overflow-hidden shadow-default">
            <div class="aspect-video rounded-lg">
              <div class="w-full h-full">
                <img
                  :src="
                    child.image_kit_id_16_9 ??
                    child.thumbnail_image_kit_id ??
                    child.image_kit_ids?.[0]
                  "
                  alt=""
                  class="size-full object-cover rounded-t-[16px]" />
              </div>
              <h2
                class="p-[1rem] m-0 text-[#546B91] font-[400] text-[0.75rem] border-t-[1px] border-[#CDD5E1] bg-[#F7F8FB] uppercase">
                {{ extractDomain(child.image_kit_id) }}
              </h2>
            </div>
            <InfoBallon>
              <template #icon>
                <i class="fal fa-chain"></i>
              </template>
              <template #count>
                {{ index + 1 }}/{{ content.children.length }}
              </template>
            </InfoBallon>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch } from "vue";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import InfoBallon from "@/components/parts/InfoBallon.vue";
import DefaultBg from "@/assets/images/bgPics/web-bg-min.jpg";

const props = defineProps({
  content: { required: true, type: Object },
});
const extractDomain = (url) => {
  const regex = /^(?:https?:\/\/)?(?:www\.)?([^\/]+)/i;
  const match = url.match(regex);
  return match ? match[1] : null;
};

const handleFallback = (event: any) => {
  event.target.src = DefaultBg;
};
</script>
<style>
#content-slider .swiper-wrapper {
  padding: 0 !important;
}
</style>
