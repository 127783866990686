<template>
  <Dialog :pt="{
    header: 'hidden',
    content: 'p-0 w-full',
    root: cn(
      'mx-auto my-auto flex flex-col items-center bg-white justify-center max-w-[400px] overflow-y-auto w-full rounded-[16px] p-[30px]',
      props.class,
    ),
  }" :visible="true" modal :closable="false">
    <div class="flex justify-center items-center">
      <div class="bg-[#E5111126] w-[60px] h-[60px] rounded-full flex justify-center items-center"
        style="border: 1px solid rgb(229, 17, 17)">
        <i :class="cn('text-2xl text-red-600', icon)"></i>
      </div>
    </div>
    <h1 class="mb-0 mt-4 text-black text-[22px] max-w-[300px] mx-auto font-normal leading-7 text-center">
      {{ title }}
    </h1>
    <div class="mt-2.5 flex items-center justify-center">
      <slot />
    </div>
    <div class="flex gap-2 mt-4 w-full">
      <button @click="$emit('close')"
        class="text-[#546B91] font-normal text-sm px-[15px] h-[40px] border-[1px] rounded-md border-[#39537D] py-[10px] w-full md:w-50">
        Cancel</button>
      <Button @click="handleConfirm"
        class="text-white !bg-red-600 font-normal text-sm px-[15px] h-[40px] py-[10px] rounded-md w-full md:w-50 hover:!bg-red-500 focus:ring-red-600"
        :loading="loading" :label="action">
      </Button>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import { cn } from "@/utils/cn";
import { ref } from "vue";
const props = defineProps(["title", "icon", "class", "action"]);
const emit = defineEmits(["close", "confirm"]);

const loading = ref(false);

const handleConfirm = () => {
  emit('confirm');
  loading.value = true;
}
</script>
