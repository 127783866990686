<template>
  <div
    :class="
      cn(
        ` bg-main-secondary/10 hover:bg-main-secondary/30 flex items-center justify-center rounded-full cursor-pointer`,
        props.class,
      )
    "
    :style="`width: ${size}; height: ${size}; padding: calc(${size}/3);`">
    <slot />
  </div>
</template>
<script setup>
import { cn } from "@/utils/cn";
const props = defineProps({
  class: {
    type: [Object, String],
    default: "",
  },
  size: { type: String, default: "2rem" },
});
</script>
