<template>
    <div class="text-main-secondary text-xs font-normal uppercase leading-3">
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
});
</script>