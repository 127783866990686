export default {
  root: {
    class: [
      // Flexbox
      "inline-flex items-center",

      // Spacing
      "px-3",

      // Shape
      "rounded-[1.14rem]",

      // Colors
      "text-white bg-white/20",
    ],
  },
  label: {
    class: "leading-4 text-sm my-1.5 mx-0 font-normal",
  },
  icon: {
    class: "leading-6 mr-2",
  },
  image: {
    class: ["w-9 h-9 -ml-3 mr-2", "rounded-full"],
  },
  removeIcon: {
    class: [
      // Spacing
      "ml-2",

      "text-xs",

      // Transition
      "transition duration-200 ease-in-out",

      // Misc
      "cursor-pointer",
    ],
  },
};
