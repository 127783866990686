<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-0 w-full flex flex-col relative',
      root: ' !bg-main-primary max-w-[550px] w-full rounded-[16px]  scroll-hidden',
    }"
    :visible="true"
    modal
    :closable="false">
    <section
      id="header"
      class="p-2 !bg-main-primary text-white border-b-[1px] border-surface-500 sticky top-0 z-20 rounded-t-[16px]">
      <div class="flex justify-between items-center">
        <h1 class="font-poppins text-xl flex-1 text-center">
          Portfolio Content
        </h1>
        <CircleIcon class="bg-main-secondary" @click="$emit('close')">
          <i class="fa fa-x"></i>
        </CircleIcon>
      </div>
    </section>
    <section id="content" class="px-4 py-2 flex flex-col gap-2">
      <draggable
        v-if="!form.loading"
        class="draggable-container"
        item-key="order"
        :component-data="{ tag: 'ul', name: 'flip-list', type: 'transition' }"
        v-model="form.categories"
        v-bind="dragOptions">
        <template #item="{ element, index }">
          <li
            class="input-wrapper"
            :class="{ '!border-red-500': Invalid[index] }">
            <span class="drag-icon">
              <img
                src="@/assets/images/upload_content_icon/drag-icon.svg"
                alt="Drag icon" />
            </span>
            <InputText
              @input="(e) => handleInput(e, index)"
              v-model="element.name"
              placeholder="Name"
              maxlength="25"
              autofocus
              class="categoryInput focus:!ring-0" />
            <span
              class="count"
              :class="{ '!text-[#FF0000]': element.name?.length === 25 }">
              {{ element.name?.length }}/25
            </span>
            <button @click="handleDeleteClick(element)" class="delete-btn">
              <img
                src="@/assets/images/upload_content_icon/delete-cross-icon.svg"
                alt="Delete Icon" />
            </button>
          </li>
        </template>
      </draggable>
      <div class="space-y-2" v-else>
        <Skeleton width="full" height="4rem" v-for="i in 3" :key="i"></Skeleton>
      </div>
      <div v-if="!form.loading && form.categories.length < 5">
        <div class="add-category-btn">
          <button @click="handleAdd" :disabled="form.categories.length >= 5">
            <div class="flex gap-[0.75rem]">
              <span class="plus-icon">
                <img
                  src="@/assets/images/upload_content_icon/plus-icon.svg"
                  alt="Plus icon" />
              </span>
              <span class="text">Add a category</span>
            </div>
            <div>
              <span class="limit-count">
                ( {{ form.categories.length }} / 5 )
              </span>
            </div>
          </button>
        </div>
      </div>
    </section>
    <section
      id="footer"
      class="!bg-main-primary sticky bottom-0 z-20 rounded-b-[16px]">
      <div class="flex justify-end items-center py-2.5 px-4 gap-2">
        <Button
          @click="handleSave"
          label="Save"
          :disabled="!form.isDirty || Object.values(Invalid).includes(true)"
          :loading="form.loading"
          class="border-[1px] border-white text-white rounded text-xs w-[180px] h-[40px] disabled:bg-transparent disabled:hover:bg-transparent hover:bg-switcherBlue-500 disabled:hover:border-[1px] hover:border-0 disabled:border-main-disabled disabled:text-main-disabled"></Button>
      </div>
    </section>
  </Dialog>
</template>
<script setup lang="ts">
import { ref, watch, onMounted, reactive } from "vue";
import draggable from "vuedraggable";
import { API } from "@/core/api";
import _debounce from "lodash/debounce";
import ConfirmDialog from "@/components/dialogs/Confirm.vue";
import { useToastr } from "@/composables/useToastr";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { useForm } from "@inertiajs/vue3";
const emit = defineEmits(["close", "update"]);
const { success, error } = useToastr();
const CategoriesAPI = new API.Categories();
const form = useForm({
  categories: [],
  loading: false,
});
const Invalid = reactive({});

const dragOptions = {
  animation: 200,
  group: "description",
  disabled: false,
  ghostClass: "ghost",
};
onMounted(async () => {
  try {
    form.loading = true;
    let response = await CategoriesAPI.get();
    if (response.data) form.categories = response.data;
    form.loading = false;
  } catch (error) {
    console.error(error);
    form.loading = false;
  }
});

const handleAdd = () => {
  form.categories.push({
    name: "",
    id: -1,
    order: form.categories.length,
  });
};

const handleInput = (e: any, index: number) => {
  const temp = [...form.categories.filter((_, idx: number) => idx !== index)];
  const category = temp.find((c) => c.name === e.target.value);
  if (category) {
    Invalid[index] = true;
  } else {
    Invalid[index] = false;
  }
};

const handleSave = async () => {
  try {
    form.loading = true;
    const response = await CategoriesAPI.update(form.categories);
    if (response.hasOwnProperty("error")) {
      error(response.error);
    } else {
      success("Categories updated successfully");
      emit("update", response.data);
      emit("close");
    }
  } catch (err) {
    console.error(err);
  }

  form.loading = false;
};
</script>
<style lang="scss" scoped>
.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  z-index: 9999999999;

  .loader {
    border: 5px solid #2998ff80;
    border-top: 5px solid #fff;
    width: 5rem;
    height: 5rem;
    animation: spin 2s linear infinite;
    background: radial-gradient(
      58.99% 58.99% at 50% 50%,
      rgba(41, 152, 255, 0.19) 0%,
      rgba(41, 152, 255, 0) 100%
    );
    position: absolute;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.error {
  font-size: 0.75rem;
  color: #ff0000;
  font-weight: 400;
}

.category-header {
  height: 60px;
  position: relative;
  background: #141414;
  border-radius: 0.5rem 0.5rem 0 0;
  padding-block: 0.75rem;
  position: sticky;
  top: 0;
  z-index: 1;

  h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .close-btn {
    width: 2.1875rem;
    height: 2.1875rem;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 1rem;

    &.back-btn {
      right: auto;
      left: 1rem;
    }
  }
}

.content-modal-footer {
  border-radius: 0 0 1rem 1rem;

  button {
    width: 11.25rem;
    height: 2.25rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    color: #fff;
    font-weight: 400;
    position: relative;

    &.btn-outlined {
      background: transparent;
      border: 0.0625rem solid #fff;
    }

    &.btn-primary {
      background-color: #2998ff;
      display: flex;
      align-items: center;
      justify-content: center;

      .loader {
        border: 5px solid transparent;
        border-top: 5px solid #fff;
        width: 2rem;
        height: 2rem;
        animation: spin 2s linear infinite;
        background: radial-gradient(
          58.99% 58.99% at 50% 50%,
          rgba(41, 152, 255, 0.19) 0%,
          rgba(41, 152, 255, 0) 100%
        );
        position: absolute;

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }
      }

      &:disabled {
        background-color: #3a3a3a;
        color: #b0b0b0;
      }
    }
  }
}

.category-lists {
  li {
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
  }
}

.input-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: rgba(38, 38, 38, 1);
  border: 0.0625rem solid rgba(67, 67, 67, 1);
  box-shadow: 0rem 0.125rem 0.9375rem 0rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  padding: 1rem;
  width: 100%;
  margin-bottom: 0.75rem;

  input {
    flex-grow: 1;
    height: 100%;
    border: none;
    outline: none;
    font-size: 1rem;
    color: #fff;
    font-weight: 400;
    background: transparent;

    &::placeholder {
      color: rgba(191, 191, 191, 1);
      opacity: 1;
    }

    &::-ms-input-placeholder {
      color: rgba(191, 191, 191, 1);
    }
  }

  .count {
    font-size: 0.75rem;
    color: rgba(140, 140, 140, 1);
    font-weight: 400;
  }

  .drag-icon {
    cursor: grabbing;
  }
}

.add-category-btn {
  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(38, 38, 38, 1);
    border: 0.0625rem solid rgba(67, 67, 67, 1);
    box-shadow: 0rem 0.125rem 0.9375rem 0rem rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    padding: 1rem;
    width: 100%;

    .limit-count {
      font-size: 0.75rem;
      color: #8c8c8c;
      font-weight: 400;
    }

    .text {
      font-size: 1rem;
      color: #fff;
      font-weight: 500;
    }

    .plus-icon {
      width: 1.25rem;
      height: 1.25rem;
      // border-radius: 50%;
      // border: 0.0625rem solid #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.categoryInput {
  & + .count {
    display: none;
  }

  &:focus {
    & + .count {
      display: block !important;
    }
  }
}
</style>
