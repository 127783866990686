<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-0 overflow-x-hidden',
      root: '!bg-white max-w-[550px] w-full rounded-[16px]  scroll-hidden',
    }"
    :visible="true"
    modal
    :closable="false">
    <div class="w-full flex flex-col relative">
      <section
        id="header"
        class="p-2 bg-surface-50 border-b-[1px] border-surface-300 sticky top-0 z-20 rounded-t-[16px]">
        <div class="flex justify-between items-center">
          <h1 class="font-poppins text-xl flex-1 text-center">
            {{ model?.name ? "Edit" : "Add" }} Representative
          </h1>
          <CircleIcon
            class="bg-surface-100 text-primary-500 hover:bg-surface-200"
            @click="$emit('close')">
            <i class="far fa-times"></i>
          </CircleIcon>
        </div>
      </section>
      <section
        id="content"
        class="px-6 py-4 flex flex-col gap-2 overflow-y-auto scroll-hidden max-h-[80vh]">
        <div class="flex flex-col gap-2">
          <InputText
            v-model="form.first_name"
            label="Name"
            placeholder="Name*"
            :pt="{ wrapper: 'w-full' }"
            :rules="{ required: true }" />

          <CountryInput
            class="!w-full sm:w-[400px]"
            :errors="[]"
            errorName="phone"
            :initValue="form.phone"
            @isValid="(v) => (form.phone_valid = v)"
            @getPhoneValue="
              (v) => {
                form.phone = v.number;
              }
            " />
          <div class="flex flex-col">
            <InputText
              v-model="form.email"
              label="Email"
              placeholder="Email"
              :pt="{ wrapper: 'w-full' }"
              :invalid="validateStore.errors.email != null"
              @change="(e) => handleValidation(e, 'email')" />
            <ValidationError :errors="validateStore.errors" name="email" />
          </div>

          <LocationSelectGroup v-model="form.location" placeholder="Location" />
        </div>
      </section>
      <section
        id="footer"
        class="bg-surface-50 border-t border-surface-300 sticky bottom-0 z-20 rounded-b-[16px]">
        <div class="flex justify-end items-center py-2.5 px-4 gap-2">
          <Button
            label="Save"
            :disabled="!canSave"
            :loading="form.processing"
            class="min-w-[150px]"
            @click="handleSave"></Button>
        </div>
      </section>
    </div>
  </Dialog>
</template>

<script lang="ts" setup>
import { useForm } from "@inertiajs/vue3";
import { ref } from "vue";
import CountryInput from "@/components/forms/countryInput.vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import LocationSelectGroup from "@/components/forms/SelectGroups/Location.vue";
import { computed } from "vue";
import { useValidateStore } from "@/store/Validate";
import ValidationError from "@/components/forms/validationError.vue";
import _debounce from "lodash/debounce";
import { onUnmounted } from "vue";
import type { RepresentativeInterface } from "@/core/interfaces";
import type { PropType } from "vue";

/* -------- PROPS -------- */
const props = defineProps({
  model: {
    type: Object as PropType<RepresentativeInterface>,
    default: null,
  },
});
const emit = defineEmits(["close", "save"]);

/* -------- COMPOSABLE -------- */
const validateStore = useValidateStore();

/* -------- STATE -------- */
const validationSchema = {
  email: { type: "email" },
};
const canSave = computed(
  () =>
    form.isDirty &&
    !form.processing &&
    !validateStore.hasErrors &&
    form.first_name !== "" &&
    ((form.phone !== "" && form.phone_valid === true) ||
      form.phone_valid === undefined),
);

const form = useForm({
  first_name: props.model?.first_name ?? "",
  email: props.model?.email ?? "",
  phone: props.model?.phone ?? "",
  phone_valid: undefined,
  location: props.model?.location ?? "",
});
/* -------- METHODS -------- */
const handleSave = () => {
  emit("save", form.data());
};

const handleValidation = _debounce((e, inputName) => {
  if (!form.isDirty) return;
  validateStore.resetInputValidation(inputName);
  validateStore.validate(form, validationSchema);
}, 250);
/* -------- HOOKS -------- */
onUnmounted(() => {
  form.reset();
  validateStore.reset();
});
/* -------- WATCHERS -------- */
</script>
