<template>
  <Dialog
    :visible="isOptVisible"
    modal
    :pt="otpDialogPreset"
    :closable="false"
    :draggable="false"
  >
    <div
      class="text-[18px] sm:text-[28px] text-black text-center font-medium leading-5 pb-[15px]"
    >
      Insert code below
    </div>
    <div
      class="text-xs sm:text-[18px] leading-4 sm:leading-7 text-center text-[#112F60] font-medium"
    >
      {{ formatPhoneNumber(props.form.phone_number) }}
    </div>
    <div
      class="text-[10px] md:text-[14px] leading-1 md:leading-5 font-normal text-center"
    >
      A verification text is on the way!
    </div>

    <form @submit.prevent="submit">
      <div class="flex flex-col justify-center items-center pt-[20px]">
        <InputOtp v-model="props.form.code" :length="6" />
        <div
          class="text-[10px] md:text-[14px] font-normal pt-[10px] flex items-center gap-2"
        >
          Haven't received a verification code?
          <button
            v-if="timer <= 0"
            type="button"
            class="text-switcherBlue-500"
            @click="() => sendOtp()"
          >
            Resend
          </button>
          <span class="text-surface-900 font-bold" v-if="timer > 0"
            >{{ timer }}s</span
          >
        </div>
      </div>
      <div class="w-full flex">
        <Button
          type="submit"
          class="self-center text-white text-sm font-normal px-[12px] py-[12px] text-center w-full cursor-pointer sm:w-[200px] h-[40px] flex items-center justify-center mx-auto mt-[15px] hover:!bg-switcherBlue-500 !border-0"
          :class="
            props.form.code?.length == 6 ? 'bg-[#39537D]' : 'bg-[#adadad]'
          "
          :disabled="!props.form.code || props.form.code?.length < 6"
          :loading="props.form.processing"
        >
          Verify
        </Button>
      </div>
    </form>
  </Dialog>
</template>
<script setup lang="ts">
import { formatPhoneNumber } from "@/utils/formatter";
import otpDialogPreset from "@/assets/tailwind-preset/dialog/otpDialog";
import { router, useForm } from "@inertiajs/vue3";
import { onUnmounted } from "vue";
import { onMounted } from "vue";
import { ref } from "vue";
import InputOtp from "primevue/inputotp";
import { route } from "ziggy-js";
import axios from "axios";
import { useToastr } from "@/composables/useToastr";

const { error, success } = useToastr();

const props = defineProps(["form"]);

// TIMER //
const _TIMER = 60;
const interval = ref();
const timer = ref(_TIMER);
const isOptVisible = ref(false);
const beginTimer = () => {
  timer.value = _TIMER;
  interval.value = setInterval(() => {
    if (timer.value > 0) {
      timer.value--;
    } else {
      stopTimer();
    }
  }, 1000);
};
const stopTimer = () => {
  clearInterval(interval.value);
  interval.value = null;
};

// END TIMER //
const emit = defineEmits(["completed", "close"]);
function close() {
  emit("close");
}

const submit = () => {
  axios
    .post(route("otp.verify"), props.form)
    .then((res) => {
      emit("completed", res.data);
    })
    .catch((err) => {
      let message = err.response.data.message;
      if (err.remaining_tries) {
        error(`Invalid OTP. ${err.remaining_tries} attempts left.`);
      } else error(err.response.data.message);
    });
};
const sendOtp = (url: string | null = null) => {
  url = url || route("otp.send");
  props.form.code = null;
  axios
    .post(url, { phone_number: props.form.phone_number })
    .then((res) => {
      success("OTP sent successfully");
      beginTimer();
      isOptVisible.value = true;
    })
    .catch((err) => {
      emit("close");
      error(err.response.data.message);
    });
};
const show = (url: string | null = null) => sendOtp(url);

defineExpose({ show });

onUnmounted(() => {
  stopTimer();
});
</script>
