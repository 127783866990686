<template>
    <section class="flex flex-col bg-main-bg">
        <Header></Header>
        <div class="my-10 relative flex flex-col lg:flex-row w-4/5 lg:w-3/4 self-center gap-12">
            <div class="grow flex flex-col gap-7">
                <slot></slot>
            </div>
            <SideBar></SideBar>
        </div>
        <Footer></Footer>
    </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Header from './Header.vue';
import SideBar from './SideBar.vue';
import Footer from './Footer.vue';

export default defineComponent({
    components: {
        Header,
        SideBar,
        Footer
    },
    data() {
        return {
            title: 'Hello',
            message: 'Welcome to my Vue component!',
        };
    },
});
</script>