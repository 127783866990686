<template>
  <div
    class="flex flex-col max-h-full divide-y-[1px] divide-surface-600/95 gap-4"
  >
    <section
      id="content"
      class="space-y-4 scroll-hidden overflow-y-auto scroll-smooth flex-1 pb-4"
      v-infinite-scroll="getData"
    >
      <div class="flex flex-col gap-4 px-4 pt-4">
        <CollaboratorCard
          v-for="collaborator in collaborators"
          :collaborator="collaborator"
          :key="collaborator.id"
          :max="max"
          :handleRemoveCollab="handleRemoveCollab"
          :handleRemoveRole="handleRemoveRole"
          :handleSelectUser="handleSelectUser"
          v-if="!selectedUser"
          :showAddRole="collaborator.roles.length < max"
        />
        <CollaboratorCard
          :collaborator="selectedUser"
          :handleRemoveCollab="handleRemoveCollab"
          :handleRemoveRole="handleRemoveRole"
          :handleSelectUser="handleSelectUser"
          v-if="selectedUser"
          :showAddRole="false"
        />
      </div>
      <div v-if="tab === 'collaborators'" class="flex flex-col gap-4">
        <div
          class="sticky top-0 p-4 bg-title w-full z-10 shadow-md flex flex-col gap-2"
        >
          <p
            class="uppercase text-xs text-surface-400/95"
            v-if="collaborators.length"
          >
            <strong>{{ collaborators.length }}</strong> collaborator{{
              collaborators.length > 1 || collaborators.length == 0 ? "s" : ""
            }}
            selected
          </p>
          <IconField iconPosition="left" class="relative">
            <i class="far fa-magnifying-glass !text-surface-400"></i>
            <InputText
              @input="handleSearch"
              v-model="lazyLoad.keyword"
              placeholder="Search a collaborator by name"
              class="bg-white/10 w-full focus:!outline-none focus:!border-0 focus:!ring-0 hidden md:block transition-all duration-150 ease-in-out text-white"
            />
          </IconField>
        </div>
        <div
          class="flex flex-col gap-2 px-3"
          v-if="collaboratorsList.length > 0"
        >
          <label
            :for="`collabs_${collaborator.id}`"
            v-for="collaborator in collaboratorsList"
            class="py-2 hover:bg-surface-500/50 px-1 rounded-md cursor-pointer"
          >
            <UserCard
              :user="collaborator"
              :key="collaborator.id"
              :userProps="{
                class: 'text-white',
              }"
              :roleProps="{
                textColor: '#FFF',
                separatorColor: '#FFF',
              }"
              :showLocation="false"
              :showRelationship="false"
            >
              <template #actions>
                <Checkbox
                  :value="collaborator.id"
                  v-model="collaboratorIds"
                  :inputId="`collabs_${collaborator.id}`"
                  :outlined="true"
                  color="lightBlue"
                  size="lg"
                />
              </template>
            </UserCard>
          </label>
        </div>
      </div>
      <RolesList
        :collaborators="collaborators"
        v-model:user="selectedUser"
        v-model="roleIds"
        v-else-if="tab === 'roles' && selectedUser"
        @back="() => (tab = 'collaborators')"
      />

      <div
        class="flex flex-col justify-center gap-4 px-4"
        v-if="loading && !lazyLoadEmpty"
      >
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-4">
            <Skeleton size="3rem" shape="circle"></Skeleton>
            <div class="flex flex-col gap-2">
              <Skeleton width="15rem"></Skeleton>
              <Skeleton width="8rem"></Skeleton>
            </div>
          </div>
          <Skeleton size="2rem" shape="circle"></Skeleton>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch } from "vue";
import type { RolesInterface, UserInterface } from "@/core/interfaces";
import UserCard from "@/components/general/UserCard.vue";
import { vInfiniteScroll } from "@vueuse/components";
import { API } from "@/core/api";
import _debounce from "lodash/debounce";
import _ from "lodash";
import CollaboratorCard from "./CollaboratorCard.vue";
import RolesList from "./RolesList.vue";
import axios from "axios";

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
});

/* ---------- APIs ---------- */
const usersAPI = new API.Users();

/* ---------- REACTIVE DATA ----------*/
const collaboratorIds = ref<number[]>(
  props.modelValue.collaborators?.map((v) => v.id) ?? [],
);
const roleIds = ref<number[]>([]);
const selectedUser = ref<UserInterface | null>();
const collaborators = ref<UserInterface[]>(
  props.modelValue.collaborators || [],
);
const max = ref(3);
const tab = ref("collaborators");
const collaboratorsList = ref<UserInterface[]>([]);

const loading = ref(false);

const lazyLoad = reactive<any>({
  start: 0,
  size: 15,
  keyword: null,
  initialValues: [],
});

const lazyLoadEmpty = ref(false);

/* ---------- WATCHERS ----------*/
watch(
  () => tab.value,
  (newVal) => {
    lazyLoad.keyword = null;
    if (newVal !== "roles") selectedUser.value = null;
  },
);

watch(
  collaboratorIds,
  (newVal, oldVal) => {
    const addedIds = newVal.filter((id) => !oldVal.includes(id));
    const removedIds = oldVal.filter((id) => !newVal.includes(id));
    if (addedIds.length) {
      if (lazyLoad.keyword) {
        collaborators.value.push(
          ...collaboratorsList.value.filter((collab) =>
            addedIds.includes(collab.id),
          ),
        );
      } else {
        collaborators.value.push(
          ...collaboratorsList.value.filter((collab) =>
            addedIds.includes(collab.id),
          ),
        );
      }
    }
    if (removedIds.length) {
      collaborators.value = collaborators.value.filter(
        (collab) => !removedIds.includes(collab.id),
      );
    }
    emitUpdate();
  },
  { deep: true },
);

watch(selectedUser, (newVal) => {
  if (newVal) {
    const index = collaborators.value.findIndex((v) => v.id === newVal.id);
    if (index != -1) collaborators.value[index] = newVal;
  }
});
/* ---------- FUNCTIONS ---------- */
let abortController: AbortController | null = null;

onMounted(() => {
  collaboratorIds.value = props.modelValue.collaborators.map((i) => i.id);
  getCollaboratorsData();
});

const handleRemoveCollab = (collaboratorId: number) => {
  collaboratorIds.value = collaboratorIds.value.filter(
    (i) => i !== collaboratorId,
  );
  if (selectedUser.value?.id === collaboratorId) tab.value = "collaborators";
};

const handleRemoveRole = (
  collaborator: UserInterface,
  role: RolesInterface,
) => {
  let roleIndex = collaborator.roles.findIndex((r) => r.id === role.id);
  let Collaborator = collaborators.value.find(
    (collab) => collab.id === collaborator.id,
  );
  if (Collaborator) {
    Collaborator.roles.splice(roleIndex, 1);
    roleIds.value = roleIds.value.filter((r) => r !== role.id);
    emitUpdate();
  }
};

const handleSearch = _debounce(() => {
  if (tab.value === "collaborators") CollaboratorSearch();
}, 350);

const CollaboratorSearch = () => {
  lazyLoad.start = 0;
  collaboratorsList.value = [];
  lazyLoadEmpty.value = false;
  loading.value = false;
  getCollaboratorsData();
};

const getCollaboratorsData = async () => {
  if (abortController && lazyLoad.keyword) {
    abortController.abort();
  }
  abortController = new AbortController();
  const signal = abortController.signal;

  if (!loading.value) {
    loading.value = true;
    if (!lazyLoadEmpty.value) {
      try {
        let { data: response } = await usersAPI.getOrdered(lazyLoad, {
          signal,
        });
        if (response.length) {
          collaboratorsList.value.push(...response);
        }

        if (response.length < (lazyLoad.size ?? 0)) {
          lazyLoadEmpty.value = true;
        }
        lazyLoad.start = collaboratorsList.value.length;

        if (loading.value === true) {
          loading.value = false;
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          console.error("Fetch error:", error);
        }
      }
    }
  }
};

const getData = () => {
  if (tab.value === "collaborators") getCollaboratorsData();
};

const handleSelectUser = (user: UserInterface) => {
  const roles = user.roles.map((role) => ({ id: role.id, name: role.name }));
  lazyLoad.initialValues = roles;
  tab.value = "roles";
  selectedUser.value = user;
  roleIds.value = roles.map((role) => role.id);
};

const emitUpdate = () => {
  props.modelValue.collaborators = collaborators.value;
};
</script>
