import baseApi from "./Base";
import { route } from "ziggy-js";

export class SocialPostService extends baseApi {
  async get(form: any) {
    return await this.request(route("social_posts.get"), "GET", form);
  }
  async store(form: any) {
    return await this.request(route("social_posts.store"), "POST", form);
  }

  async like(post: number) {
    return await this.request(route("social_posts.like", { post }), "POST");
  }
  async unlike(post: number) {
    return await this.request(route("social_posts.unlike", { post }), "POST");
  }
  async hide(post: number) {
    return await this.request(route("social_posts.hide", { post }), "POST");
  }
  async unhide(post: number) {
    return await this.request(route("social_posts.unhide", { post }), "POST");
  }
}
