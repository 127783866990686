<template>
  <Layout
    :header="{
      title: props.user.full_name,
      subtitle: props.user.roles.map((v) => v.name),
      user: props.user,
    }">
    <template #subheaderActions>
      <CircleIcon
        v-for="action in ACTIONS.filter((action) => action.show)"
        :key="action.icon"
        :class="
          cn(
            'bg-main-secondary hover:bg-main-secondary/80 text-white',
            action.class,
          )
        "
        @click="action.clickEvent"
        size="2.5rem">
        <i :class="action.icon"></i>
      </CircleIcon>
      <CircleIcon
        v-if="!owned_profile"
        class="bg-main-secondary hover:bg-main-secondary/80 text-white"
        size="2.5rem">
        <i class="fa fa-ellipsis-vertical"></i>
      </CircleIcon>
    </template>
    <div
      class="flex flex-wrap lg:flex-nowrap gap-6 max-w-[1080px] mx-auto p-8 min-h-screen lg:px-0">
      <div id="left" class="flex flex-col gap-6 flex-1 lg:basis-[80%] shrink-0">
        <component v-for="component in left" :is="component" v-bind="props" />
      </div>
      <div id="right" class="flex flex-col gap-6 lg:basis-[20%] shrink-0">
        <component v-for="component in right" :is="component" v-bind="props" />
      </div>
    </div>
  </Layout>
</template>

<script lang="ts" setup>
import { reactive, ref, computed, onMounted, watch, markRaw } from "vue";
import Layout from "@/components/bizcard/home/Layout.vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { useToastr } from "@/composables/useToastr";
import type { BizCardInterface, UserInterface } from "@/core/interfaces";
import { useUserBizcardStore } from "@/store/UserBizcard";
import { cn } from "@/utils/cn";
import type { Component, PropType } from "vue";
import Availability from "./components/Availability.vue";
import Bio from "./components/Bio.vue";
import Contact from "./components/Contact.vue";
import GeneralInfo from "./components/GeneralInfo.vue";
import Keywords from "./components/Keywords.vue";
import MemberSince from "./components/MemberSince.vue";
import Representation from "./components/Representation.vue";
import Socials from "./components/Socials.vue";

/* ---------- REACTIVE DATA ----------*/

/* -------- PROPS -------- */
const props = defineProps({
  card: {
    required: true,
    type: Object as PropType<BizCardInterface>,
  },
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
});
/* -------- COMPOSABLE -------- */

/* -------- STATE -------- */
const { success, error } = useToastr();
const bizcardStore = useUserBizcardStore();
const is_editing = computed(() => bizcardStore.isEditing);
const owned_profile = computed(() => bizcardStore.own_profile);
const ContentDialogVisible = ref(false);

const left = ref<Component[]>([
  markRaw(Socials),
  markRaw(Bio),
  markRaw(GeneralInfo),
  markRaw(MemberSince),
]);
const right = ref<Component[]>([
  markRaw(Availability),
  markRaw(Contact),
  markRaw(Representation),
  markRaw(Keywords),
]);

const ACTIONS = computed(() => [
  {
    icon: "fa-regular fa-circle-plus",
    show: is_editing.value,
    clickEvent: () => {
      ContentDialogVisible.value = true;
    },
  },
  {
    icon: "fa-regular fa-pen",
    class: is_editing.value
      ? "bg-switcherBlue-500 hover:bg-switcherBlue-600"
      : "",
    show: owned_profile.value,
    clickEvent: bizcardStore.toggleEditing,
  },
  {
    icon: "fa-regular fa-share",
    show: true,
    clickEvent: async () => {
      const permissionStatus = await navigator.permissions.query({
        name: "clipboard-write",
      });
      if (
        permissionStatus.state === "granted" ||
        permissionStatus.state === "prompt"
      ) {
        const url = route("user.profile.info", props.user?.public_username); // TODO route for services
        await navigator.clipboard.writeText(url);
        success(`Copied to clipboard: ${url}`);
      }
    },
  },
]);
/* -------- METHODS -------- */

/* -------- HOOKS -------- */
onMounted(() => {
  bizcardStore.init(props.card, props.user);
});
/* -------- WATCHERS -------- */
</script>
