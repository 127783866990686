export const formatTime = (timestamp) => {
  const now = Math.floor(Date.now() / 1000); // Current time in seconds
  const secondsAgo = now - timestamp;

  if (secondsAgo < 60) {
    return "Just now";
  } else if (secondsAgo < 3600) {
    const minutes = Math.floor(secondsAgo / 60);
    return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
  } else if (secondsAgo < 86400) {
    const hours = Math.floor(secondsAgo / 3600);
    return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
  } else {
    const days = Math.floor(secondsAgo / 86400);
    return `${days} day${days !== 1 ? "s" : ""} ago`;
  }
};

export const usePostCreationTime = (param) => {
  // Get the current time
  const currentTime = new Date();

  // Parse the given timestamp
  const uploadTime = new Date(param);

  // Calculate the time difference in milliseconds
  const timeDiff = currentTime - uploadTime;

  // Convert milliseconds to minutes, hours, days, months, and years
  const minutesDiff = Math.floor(timeDiff / (1000 * 60));
  const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const monthsDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 30));
  const yearsDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365));

  // Generate the appropriate output string
  let output = "";
  if (yearsDiff > 0) {
    output = yearsDiff + "y";
  } else if (monthsDiff > 0) {
    output = monthsDiff + " months";
  } else if (daysDiff > 0) {
    output = daysDiff + "d";
  } else if (hoursDiff > 0) {
    output = hoursDiff + "h";
  } else if (minutesDiff > 0) {
    output = minutesDiff + "m";
  } else {
    output = "Just now";
  }

  return output;
};

export const UnixToDate = (timestamp) => {
  // Convert timestamp to milliseconds
  var timestampInMillis = timestamp * 1000;

  // Create a new Date object with the timestamp
  var date = new Date(timestampInMillis);

  // Get the parts of the date
  var year = date.getUTCFullYear();
  var month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
  var day = ("0" + date.getUTCDate()).slice(-2);
  var hours = ("0" + date.getUTCHours()).slice(-2);
  var minutes = ("0" + date.getUTCMinutes()).slice(-2);
  var seconds = ("0" + date.getUTCSeconds()).slice(-2);

  // Format the date as a string
  var formattedDate =
    year +
    "-" +
    month +
    "-" +
    day +
    "T" +
    hours +
    ":" +
    minutes +
    ":" +
    seconds +
    ".000Z";

  return formattedDate;
};
