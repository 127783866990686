<template>
    <div class="w-full mx-auto py-3 flex flex-col gap-[30px] items-center justify-center">
        <div class="flex flex-col w-full gap-[5px] items-center justify-center">
            <h4 class="text-white mb-0 text-[22px]"> Choose your gallery
                style </h4>
            <p class="mb-0 text-[#bfbfbf] text-center text-[14px]"> How would
                you like your images to be presented <br> on your collection
                page? </p>
        </div>
        <div class="flex gap-[16px] justify-center">
            <SelectButton v-model="value" :options="options" optionValue="value" dataKey="value"
                aria-labelledby="custom" :pt="GalleryStylePreset">
                <template #option="slotProps">
                    <p class="text-xs">{{ slotProps.option.title }}</p>
                    <img :src="slotProps.option.icon" alt="1x1">
                </template>
            </SelectButton>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import GalleryStylePreset from "@/assets/tailwind-preset/selectbutton/gallery-style";
import gallerySquare from "@/assets/images/upload_content_icon/1x1.svg";
import galleryVideo from "@/assets/images/upload_content_icon/16x9.svg";
import { watch } from "vue";

const model = defineModel({ type: Object, required: true });

const value = ref(model.value.aspect_ratio);
const options = ref([
    { icon: gallerySquare, value: 'square', title: '1 X 1' },
    { icon: galleryVideo, value: 'video', title: '16 X 9' },
]);

watch(value, (val) => {
    model.value.aspect_ratio = val;
});
</script>