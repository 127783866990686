<template>
  <Dropdown
    v-model="optionsModel"
    :invalid="props.invalid"
    editable
    @change="handleChange"
    :options="options"
    optionLabel="name"
    optionValue="id"
    :placeholder="props.placeholder"
    dataKey="id"
    :class="cn('w-full md:w-14rem', pt.root)"
    :pt="{
      input: pt.input,
    }"
    :ptOptions="{ mergeProps: true }"
    :showClear="true"
    @show="initInfiniteScroll"
    panelClass="overflow-y-auto scroll-hidden max-h-[300px]"
    :optionDisabled="
      (e) =>
        ((selected.length && selected.find((v) => v?.id === e.id)) ||
          selected.find((v) => v?.name === e.name)) &&
        optionsModel !== e.id
    "
    :panelProps="{
      id: 'dropdown-scroll',
    }"
    :loading="loading">
    <template #header="{ value, options }">
      <AddItem
        v-if="value && typeof value === 'string' && !options.length && !loading"
        :value="value"
        :callback="handleAddCustom" />
    </template>
    <template #option="{ option }">
      <div class="flex gap-2 items-center">
        <p class="text-inherit">
          {{ option.name }}
        </p>
      </div>
    </template>
    <template #empty>
      <span></span>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch, type PropType } from "vue";
import { vInfiniteScroll } from "@vueuse/components";
import { cn } from "@/utils/cn";
import NoCompanyImage from "@/assets/images/new_icons/company_dummy.svg";
import { useInfiniteScroll } from "@vueuse/core";
import _debounce from "lodash/debounce";
import { API } from "@/core/api";
import { usePage } from "@inertiajs/vue3";
import AddItem from "@/components/general/AddItem.vue";
import type { UserInterface } from "@/core/interfaces";
import { useAuthStore } from "@/store/Auth";
const authStore = useAuthStore();
const SharedDataUser = computed(() => authStore.getUser);
const props = defineProps({
  pt: {
    type: Object as PropType<{
      root?: string | object;
      input?: string | object;
      optionLabel?: string | object;
    }>,
    default: () => ({}),
  },
  selected: {
    type: Array,
    default: [],
  },
  placeholder: {
    type: String,
    default: "Select an option",
  },
  icon: {
    type: Object,
    default: null,
  },
  invalid: {
    type: Boolean,
    default: false,
  },
  clearCallback: {
    type: Function,
    default: () => {},
  },
});
const skillsAPI = new API.Skills();

/* ---------- REACTIVE DATA ---------- */
const loading = ref(false);
const model = defineModel();
const optionsModel = ref(
  typeof model.value === "object" ? model.value?.id : model.value,
);
const lazyLoad = reactive({
  start: 0,
  size: 25,
  keyword: "",
  no_more_results: false,
  initialValues: SharedDataUser.value.roles
    .filter((i) => i.id === model.value?.id)
    .map((i) => ({
      id: i.id,
      name: i.name,
    })),
});
const options = ref([]);

/* ---------- FUNCTIONS ---------- */

// Clear is used when filtering so we clear previous results instead of pushing to them
const getData = async (clear = false) => {
  try {
    if (!lazyLoad.no_more_results && !loading.value) {
      loading.value = true;
      let response = await skillsAPI.get(lazyLoad);
      response = response.data;
      if (response && response.length) {
        if (clear) {
          options.value = response;
        } else {
          options.value.push(...response);
        }

        lazyLoad.start = options.value.length;
        if (response.length < lazyLoad.size) lazyLoad.no_more_results = true;
      } else {
        options.value = [];
        lazyLoad.no_more_results = true;
      }
      loading.value = false;
    }
  } catch (error) {
    loading.value = false;
    console.error(error);
  }
};

onMounted(() => {
  getData(true);
});

const filter = _debounce((clear: boolean = true) => {
  lazyLoad.no_more_results = false;
  lazyLoad.start = 0;
  if (clear) options.value = [];
  getData(true);
}, 200);
const handleChange = (e) => {
  if (e.originalEvent.type === "input" && e.value) {
    lazyLoad.keyword = e.value;
    filter();
  }
  if (e.originalEvent.type === "input" && !e.value) {
    lazyLoad.keyword = null;
    optionsModel.value = undefined;
    filter();
  }
  if (e.originalEvent.type === "click" && !e.value) {
    lazyLoad.keyword = "";
    props.clearCallback(model.value);
  }
  if (e.originalEvent.type === "click" && e.value) {
    lazyLoad.keyword = "";
    lazyLoad.initialValues = [options.value.find((i) => i.id === e.value)];
    filter(false);
  }
};

const initInfiniteScroll = () => {
  useInfiniteScroll(
    document.getElementById("dropdown-scroll"),
    () => getData(),
    {
      distance: 10,
    },
  );
};

const handleAddCustom = async () => {
  const custom = {
    id: -1,
    name: optionsModel.value,
  };
  lazyLoad.keyword = "";
  lazyLoad.initialValues = [custom];
  options.value.unshift(custom);
  model.value = custom;
  optionsModel.value = custom.id;
  filter();
};

/* ---------- WATCHERS ---------- */
watch(optionsModel, (value) => {
  const item = options.value.find((i) => i.id === value);
  if (item) model.value = item;
});
// When the model is undefined, we clear the dropdown
watch(model, (newVal) => {
  if (newVal === undefined) {
    lazyLoad.keyword = "";
    optionsModel.value = undefined;
    lazyLoad.initialValues = [];
    filter();
  }
});
</script>

<style>
.dropdown-scroll {
  max-height: 200px;
  overflow-y: auto;
}
</style>
