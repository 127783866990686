<template>
  <Dialog :pt="{
    header: 'hidden',
    content: 'p-0 w-full flex flex-col !relative h-[720px]',
    root: 'bg-white max-w-[800px] w-full rounded-[16px]  scroll-hidden',
  }" :visible="true" modal :closable="false">
    <section id="header" class="bg-lightBg-500 rounded-t-[16px]">
      <div class="flex justify-between items-center p-4 border-b border-main-divider">
        <br />
        <h1 class="text-main-primary text-xl font-semibold">Manage Groups</h1>
        <i class="text-lg flex items-center justify-center fa fa-xmark size-8 bg-main-disabled rounded-full cursor-pointer"
          @click="$emit('close')"></i>
      </div>
    </section>
    <section id="groups list" class="flex-1 py-5 px-8 space-y-4 overflow-y-auto scroll-hidden">
      <div v-for="group in groups" :key="group.id"
        class="bg-white shadow-default rounded-lg flex items-center justify-between hover:bg-[#2998ff26] transition-colors">
        <label :for="`group_${group.id}`" class="block p-4 cursor-pointer flex-1">
          <h1 class="text-main-primary text-base font-semibold mb-2" v-text="group.name"></h1>
          <div class="flex justify-between items-center">
            <div class="flex gap-4 items-center">
              <div class="flex items-center">
                <img v-for="(user, index) in group.users.slice(0, 5)" :key="user.id" :src="user.avatar_image" alt=""
                  class="size-6 rounded-full border border-white" :class="{ '-ml-3': index !== 0 }" />
              </div>
              <p class="text-xs text-main-disabled">
                {{ group.users.length }} member{{ group.users.length > 1 ? 's' : '' }}
              </p>
            </div>
          </div>
        </label>
        <Checkbox v-model="form.groups" class="mr-2" :value="group.id" :inputId="`group_${group.id}`" :outlined="true"
          color="lightBlue" size="lg" />
      </div>
    </section>
    <section id="footer" class="bg-lightBg-500 flex justify-end items-center px-4 py-2 sticky bottom-0 z-50">
      <Button :disabled="!form.groups.length" :loading="form.processing" label="Delete"
        :badge="form.groups.length.toString()"
        class="rounded text-white text-sm font-medium min-h-[40px] py-1.5 px-6 text-center disabled:bg-[#bfbfbf] bg-red-500"
        @click="handleDelete"></Button>
    </section>
  </Dialog>
</template>
<script setup lang="ts">
import { router, useForm } from "@inertiajs/vue3";
import { reactive, ref, computed, onMounted, watch } from "vue";
import { useContactsStore } from "@/store/Contacts";
import { API } from "@/core/api";
import { useToastr } from "@/composables/useToastr";

const contactsStore = useContactsStore();
const groupsAPI = new API.ContactGroups();
const emit = defineEmits(["close"]);
const { success, error } = useToastr();

const form = useForm({
  groups: [],
});
const groups = computed(() => contactsStore.groups ?? []);

const handleDelete = async () => {
  try {
    const response = await groupsAPI.deleteMany(form.groups);
    if (response.hasOwnProperty("success")) {
      success(response.success);
      form.groups.forEach((id) => contactsStore.removeGroup(id));
      router.visit(route("contacts.index"));
    }
    if (response.hasOwnProperty("error")) {
      error(response.error);
    }
  } catch (err) {
    console.error(err);
  }
}
</script>
