export default {
  root: ({ props, attrs }) => ({
    class: [
      "relative",
      "[&>input]:border-0",
      {
        "[&>input]:rounded-full": !attrs.shape,
        "[&>input]:rounded": attrs.shape === "rounded",
        "[&>input]:rounded-md": attrs.shape === "rounded-md",
        "[&>input]:rounded-lg": attrs.shape === "rounded-lg",
        "[&>input]:rounded-xl": attrs.shape === "rounded-xl",
        "[&>input]:rounded-2xl": attrs.shape === "rounded-2xl",
        "[&>input]:rounded-3xl": attrs.shape === "rounded-3xl",
        "[&>input]:rounded-none": attrs.shape === "rounded-none",
      },
      "[&>input]:w-full",

      "[&>*:first-child]:absolute",
      "[&>*:first-child]:top-1/2",
      "[&>*:first-child]:-mt-2",
      "[&>*:first-child]:leading-[normal]",
      "[&>*:first-child]:text-surface-900/60 dark:[&>*:first-child]:text-white/60",
      {
        "[&>*:first-child]:right-3": props.iconPosition === "right",
        "[&>*:first-child]:left-3": props.iconPosition === "left",
      },
      {
        "[&>*:last-child]:pr-10": props.iconPosition === "right",
        "[&>*:last-child]:pl-10": props.iconPosition === "left",
      },
      {
        "[&>*:first-child]:left-3": props.iconPosition === "both",
        "[&>input]:px-10": props.iconPosition === "both",
        "[&>*:last-child]:right-3": props.iconPosition === "both",
        "[&>*:last-child]:absolute": props.iconPosition === "both",
        "[&>*:last-child]:top-1/2": props.iconPosition === "both",
        "[&>*:last-child]:-mt-2": props.iconPosition === "both",
        "[&>*:last-child]:leading-[normal]": props.iconPosition === "both",
        "[&>*:last-child]:text-surface-900/60 dark:[&>*:last-child]:text-white/60":
          props.iconPosition === "both",
      },
    ],
  }),
};
