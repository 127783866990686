<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-0',
      root: 'overflow-y-auto w-[80%] max-w-[1440px] h-[90vh] sm:mx-4 bg-white shadow-lg z-40 rounded-t-xl rounded-b-xl my-4 px-[20px] md:px-[40px] flex flex-col scroll-hidden',
    }"
    :visible="props.visible"
    modal
    :closable="false">
    <div
      class="flex justify-between w-full items-center border-b border-main-divider py-3">
      <div class="text-[#141414] font-[500] text-[16px] md:text-[22px]">
        Invite dashboard
      </div>
      <img
        @click="close"
        :src="ModalClose"
        alt="ModalClose"
        width="30"
        height="30"
        class="cursor-pointer" />
    </div>
    <div
      class="w-full flex flex-col md:flex-row overflow-auto md:overflow-hidden">
      <div
        class="w-full md:w-[50%] h-auto box-border pr-[20px] md:pr-[40px] pt-[30px] flex flex-col">
        <div class="relative h-max">
          <div
            class="text-[16px] md:text-[26px] text-black font-normal text-center w-full">
            Invite up to five friends to
            <br />
            <b>create an account!</b>
          </div>
          <div
            class="text-main-secondaryBlue text-center text-[16px] w-[90%] mt-[8px] mb-[8px] md:mb-[16px] box-border mx-auto">
            As a full access member, you have the opportunity to give 5
            collaborators the ability to skip the line and create their account
            today!
          </div>
          <p
            class="text-[#fff] text-center w-[95%] text-[10px] px-2 py-2 md:text-sm md:px-1 md:py-1 rounded-full m-auto mb-0 bg-primaryBlue-500">
            <b>0/5</b>
            people have been invited to join the waitlist
          </p>
        </div>
        <div class="relative overflow-y-auto hidden-scroll">
          <div class="w-full h-auto">
            <div
              class="ticket add-new w-[90%] mx-auto mb-[1rem] relative h-[300px] xl:h-[280px]">
              <div
                class="ticket-detail-side w-[75%] absolute h-full p-3 text-left">
                <div class="data-container w-full">
                  <div
                    class="data-container-group w-[80%] border-b-[1px] order-main-divider mb-[11px] pb-[11px]">
                    <p class="text-primaryBlue-500/75 text-[10px] mb-0.5">
                      Subject
                    </p>
                    <p class="font-semibold mb-0 text-sm">
                      CREATE A BIZLY ACCOUNT
                    </p>
                  </div>
                  <div class="data-container-group w-[80%] mb-4">
                    <p class="text-primaryBlue-500/75 text-[10px] mb-0.5">
                      From
                    </p>
                    <p class="font-semibold text-sm mb-3.5 uppercase">
                      {{ user?.first_name }} {{ user?.last_name }}
                    </p>
                    <p class="text-primaryBlue-500/75 text-[10px]">To</p>
                    <div
                      class="py-0.5 border-b border-primaryBlue-500 mb-6"
                      @click="isInvitationDialogVisible = true">
                      <p class="text-primaryBlue-500/70 text-sm leading-tight">
                        Enter information
                      </p>
                    </div>
                  </div>
                  <div
                    class="data-container-group d-flex justify-content-between items-center">
                    <div>
                      <p class="text-primaryBlue-500/75 text-[10px]">Date</p>
                      <div class="flex justify-between items-center">
                        <p class="font-semibold text-sm uppercase">
                          {{ formatDateLong(new Date()) }}
                        </p>

                        <p
                          role="button"
                          class="mb-0 invite-count ml-2 px-3 py-1 border border-primaryBlue-500 font-[600] text-[10px] rounded-lg cursor-pointer">
                          INVITE 1 of 5
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="border-l-[5px] border-dotted border-[#d9d9d9] w-[25%] absolute h-full right-0">
                <div class="flex items-center justify-center h-full w-full">
                  <img
                    :src="Logo"
                    alt=""
                    width="90"
                    height="25"
                    class="scale-[1.6] rotate-[270deg] align-middle" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-[1px] bg-main-divider my-[30px]"></div>
      <div
        class="w-full md:w-[50%] h-auto flex flex-col items-center box-border md:pl-[40px] mt-[30px]">
        <div
          class="text-[16px] md:text-[26px] text-black font-normal text-center">
          Invite your friends to
          <br />
          <span class="font-semibold">join the waitlist!</span>
        </div>
        <div
          class="text-main-secondaryBlue text-center text-[16px] mt-[8px] mb-[8px] md:mb-[16px]">
          Invite your network to join the exclusive waitlist and help build the
          bizly community!
        </div>
        <button
          @click="isInvitationDialogVisible = true"
          class="bg-[#2998FF] text-white w-[40%] py-[10px] rounded text-[14px] md:text-[16px] mb-[30px]">
          Invite
        </button>
        <div class="w-[100%] mx-auto h-[50vh] overflow-y-auto hidden-scroll">
          <div
            class="flex justify-center md:justify-between items-center py-3 flex-col lg:flex-row"
            v-for="invite in invites">
            <div class="flex items-center lg:items-start flex-col">
              <p class="font-bold m-0">
                {{ invite.first_name }} {{ invite.last_name }}
              </p>
              <p class="text-sm text-primaryBlue-500 m-0">
                {{ invite.contact_information }}
              </p>
            </div>
            <p class="text-main-secondaryBlue mr-2">
              {{ useFormattedDate(invite.created_on) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
  <InvitationDialog
    :visible="isInvitationDialogVisible"
    @close="isInvitationDialogVisible = false"
    @appendInvite="appendInvite"></InvitationDialog>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { usePage } from "@inertiajs/vue3";
import { formatDateLong } from "@/utils/formatter";
import InvitationDialog from "./Invitation.vue";
import Logo from "@/assets/images/bizlyLogoBlue.svg";
import ModalClose from "@/assets/images/modalClose.svg";
import { API } from "@/core/api";
import { useFormattedDate } from "@/utils/formatter";
import type { InvitationInterface, UserInterface } from "@/core/interfaces";
import { useAuthStore } from "@/store/Auth";
const authStore = useAuthStore();
const user = computed(() => authStore.getUser as UserInterface);
const props = defineProps(["visible"]);
const emit = defineEmits(["close"]);
const invitationsAPI = new API.Invitations();

const isInvitationDialogVisible = ref(false);

const invites = ref<Array<InvitationInterface>>([]);

onMounted(async () => {
  const response = await invitationsAPI.get();
  invites.value = response.data;
});

const close = () => emit("close");
const appendInvite = (inviteData: InvitationInterface) => {
  invites.value.unshift(inviteData);
};
</script>
<style scoped>
.custom-scroll::-webkit-scrollbar {
  width: 5px;
  background-color: rgb(177, 178, 178);
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: rgb(177, 178, 178);
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: rgb(136, 133, 133);
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb:vertical:first {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb:vertical:last {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.hidden-scroll::-webkit-scrollbar {
  display: none;
}

.ticket:is(.add-new) {
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.001),
    hsla(0, 0%, 86%, 0.2)
  );
  border: 1px solid #d4d4d4;
  box-shadow: -5px 7px 10px #00000026;
}

.ticket:first-of-type {
  margin-top: 20px !important;
}

.ticket {
  background: linear-gradient(122.86deg, #002058 -23.73%, #687a9a 103.9%),
    linear-gradient(346.97deg, #c2d8ff -44.23%, #cecece 142.01%);
  border-radius: 20px;
  box-shadow: -5px 7px 10px #00000026;
  margin-bottom: 2rem;
  overflow: hidden;
}
</style>
