<template>
  <Dialog :pt="{
    header: 'hidden',
    content: 'p-0 w-full flex-1 flex flex-col ',
    root: 'flex flex-col bg-main-lightBg max-w-[600px] max-h-[670px] w-full rounded-[16px]',
  }" :visible="true" modal :closable="false">
    <section id="header"
      class="p-2 !bg-main-primary text-white border-b-[1px] border-surface-500 sticky top-0 z-20 rounded-t-[16px]">
      <div class="flex justify-between items-center">
        <h1 class="font-poppins text-xl flex-1 text-center">Description</h1>
        <CircleIcon class="bg-main-secondary" @click="handleClose">
          <i class="fa fa-x"></i>
        </CircleIcon>
      </div>
    </section>
    <section id="content"
      class="py-5 px-8 w-full flex-1 bg-black/40 overflow-y-auto overflow-x-hidden relative scroll-hidden max-h-[60vh]">
      <Textarea ref="textarea" v-model="model" autoResize
        class="!bg-main-lightBg overflow-y-auto min-h-[379px] w-full resize-none text-white border-0 ring-0 focus:border-0 focus:ring-0 outline-0 rounded-md" />
    </section>
    <section id="footer" class="!bg-main-primary rounded-b-[16px]">
      <div class="flex justify-end items-center py-2.5 px-4 gap-2">
        <button @click="handleClose"
          class="border-[1px] border-white text-white rounded text-xs w-[180px] h-[40px] disabled:bg-transparent disabled:hover:bg-transparent hover:bg-switcherBlue-500 disabled:hover:border-[1px] hover:border-0 disabled:border-main-disabled disabled:text-main-disabled">
          Back
        </button>
        <Button
          class="!bg-switcherBlue-500 hover:!bg-switcherBlue-500/80 text-white disabled:!border-[1px] disabled:!bg-transparent disabled:!border-main-disabled disabled:!text-main-disabled rounded text-xs w-[180px] h-[40px]"
          @click="$emit('close')" label="Save">
        </Button>
      </div>
    </section>
  </Dialog>
</template>
<script setup>
import {
  reactive,
  ref,
  computed,
  onMounted,
  watch,
  watchEffect,
  nextTick,
} from "vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
const model = defineModel();
const emit = defineEmits(["close"]);
const initialValue = ref();
const textarea = ref();

onMounted(() => {
  nextTick(() => {
    textarea.value.$el.focus();
  });
  initialValue.value = model.value;
});
const handleClose = () => {
  model.value = initialValue.value;
  emit("close");
};
</script>
