<template>
  <div class="flex flex-col gap-4">
    <div class="px-4 py-2 flex flex-col gap-2" v-if="(!loading.list && categoriesList.length === 0) || editing">
      <draggable class="draggable-container" item-key="order"
        :component-data="{ tag: 'ul', name: 'flip-list', type: 'transition' }" v-model="categoriesList"
        v-bind="dragOptions">
        <template #item="{ element, index }">
          <li class="input-wrapper" :class="{ '!border-red-500': Invalid[index] }">
            <span class="drag-icon">
              <img src="@/assets/images/upload_content_icon/drag-icon.svg" alt="Drag icon" />
            </span>
            <InputText @input="(e) => handleInput(e, index)" v-model="element.name" type="text" placeholder="Name"
              maxlength="25" autofocus class="categoryInput focus:!ring-0" />
            <span class="count" :class="{ '!text-[#FF0000]': element.name?.length === 25 }">{{ element.name?.length
              }}/25</span>
            <button @click="handleDeleteClick(element)" class="delete-btn">
              <img src="@/assets/images/upload_content_icon/delete-cross-icon.svg" alt="Delete Icon" />
            </button>
          </li>
        </template>
      </draggable>
      <div v-if="categoriesList.length < 5">
        <div class="add-category-btn">
          <button @click="handleAdd" :disabled="categoriesList.length >= 5">
            <div class="flex gap-[0.75rem]">
              <span class="plus-icon">
                <img src="@/assets/images/upload_content_icon/plus-icon.svg" alt="Plus icon" />
              </span>
              <span class="text"> Add a category </span>
            </div>
            <div>
              <span class="limit-count">( {{ categoriesList.length }} / 5 )</span>
            </div>
          </button>
        </div>
      </div>
      <div class="flex justify-end items-center">
        <Button @click="handleSave" label="Save" :disabled="Object.values(Invalid).includes(true)"
          :loading="loading.update"
          class="border-[1px] !bg-transparent !border-white !text-white rounded text-xs w-[180px] h-[40px] disabled:bg-transparent disabled:hover:bg-transparent hover:!bg-switcherBlue-500 disabled:hover:border-[1px] hover:border-0 disabled:border-main-disabled disabled:text-main-disabled">
        </Button>
      </div>
    </div>
    <div class="px-4 py-2" v-else>
      <div class="space-y-4" v-if="loading.list">
        <Skeleton height="3rem" v-for="i in 3"></Skeleton>
      </div>
      <Listbox v-if="!loading.list && categoriesList.length" @change="handleChange" v-model="categories"
        :options="categoriesList" optionLabel="name" multiple :pt="{
          root: '!bg-transparent !border-0 ',
          item: '!bg-main-lightBg !text-white !p-4 !border-[1px] border-surface-600 !rounded-md !font-medium ',
          list: '!space-y-4',
        }" :ptOptions="{ mergeProps: true }" class="w-full md:w-14rem" />
      <div class="w-full flex justify-end items-center mr-4" v-if="categoriesList.length">
        <button @click="editing = true"
          class="border-[1px] border-white text-white rounded text-xs w-[180px] h-[40px] disabled:bg-transparent disabled:hover:bg-transparent hover:bg-switcherBlue-500 disabled:hover:border-[1px] hover:border-0 disabled:border-main-disabled disabled:text-main-disabled">
          Manage Categories
        </button>
      </div>
    </div>
    <ConfirmDialog v-if="ConfirmDialogVisible" color="danger" @confirm="handleDelete"
      @close="ConfirmDialogVisible = false" action="Delete" cancel="cancel">
      <template #title>Delete Category?</template>
      <template #description>Exiting now will result in the loss of any unsaved changes. Are you sure
        you wish to continue?</template>
    </ConfirmDialog>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, reactive } from "vue";
import draggable from "vuedraggable";
import { API } from "@/core/api";
import _debounce from "lodash/debounce";
import ConfirmDialog from "@/components/dialogs/Confirm.vue";
import { useToastr } from "@/composables/useToastr";

interface Category {
  id: number;
  name: string;
  order: number;
}

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
});

const { success, error } = useToastr();
const CategoriesApi = new API.Categories();
const selectedForDelete = ref<Category>();
const editing = ref(false);
const categoriesList = ref<Category[]>([]);
const ConfirmDialogVisible = ref(false);

const loading = reactive({
  list: false,
  update: false
});
const categories = ref<Category[]>(props.modelValue.categories || []);
const Invalid = reactive<any>({});
const dragOptions = {
  animation: 200,
  group: "description",
  disabled: false,
  ghostClass: "ghost",
};

onMounted(async () => {
  try {
    loading.list = true;
    let response = await CategoriesApi.get();
    categoriesList.value = response.data;
    let custom = props.modelValue.categories.filter((c: Category) => c.id === -1);
    if (custom) {
      categoriesList.value.push(...custom);
    }

    loading.list = false;
  } catch (error) {
    console.error(error);
    loading.list = false;
  }
});

const handleInput = (e: any, index: number) => {
  const temp = [...categoriesList.value.filter((_, idx: number) => idx !== index)];
  const category = temp.find((c) => c.name === e.target.value);
  if (category) {
    Invalid[index] = true;
  } else {
    Invalid[index] = false;
  }
};

const handleDeleteClick = (item: Category) => {
  if (item.id !== -1) {
    selectedForDelete.value = item;
    ConfirmDialogVisible.value = true;
  } else {
    DeleteFromList(item);
  }
};

const handleDelete = async () => {
  if (selectedForDelete.value === undefined) return;
  try {
    await CategoriesApi.delete(selectedForDelete.value.id);
    DeleteFromList(selectedForDelete.value);

    //   RESET
    selectedForDelete.value = undefined;
    success("Category delete successfully");
    ConfirmDialogVisible.value = false;
  } catch (err) {
    error("Something went wrong");
    console.error(err);
  }
};
const handleAdd = () => {
  categoriesList.value.push({
    name: "",
    id: -1,
    order: categoriesList.value.length,
  });
  editing.value = true; //Make sure its editing
};
const handleSave = async () => {
  try {
    loading.update = true;
    const response = await CategoriesApi.update(categoriesList.value);
    if (response.hasOwnProperty("error")) {
      error(response.error);
    } else {
      success("Categories updated successfully");
      editing.value = false;
      categoriesList.value = response.data;
    }
    editing.value = false;
  } catch (err) {
    console.error(err);
  }

  loading.update = false;
};
const handleChange = () => {
  props.modelValue.categories = categories.value;
};

const DeleteFromList = (item: Category) => {
  // SPLICE CATEGORIES LIST
  let idx = categoriesList.value.findIndex((i) => i === item);
  categoriesList.value.splice(idx, 1);
  Invalid[idx] = false;

  //   SPLICE SELECTED
  idx = categories.value.findIndex((i) => i === item);
  categories.value.splice(idx, 1);
};
watch(
  categories,
  (newVal) => {
    props.modelValue.categories = newVal;
  },
  { deep: true },
);
</script>

<style lang="scss" scoped>
.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  z-index: 9999999999;

  .loader {
    border: 5px solid #2998ff80;
    border-top: 5px solid #fff;
    width: 5rem;
    height: 5rem;
    animation: spin 2s linear infinite;
    background: radial-gradient(58.99% 58.99% at 50% 50%,
        rgba(41, 152, 255, 0.19) 0%,
        rgba(41, 152, 255, 0) 100%);
    position: absolute;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.error {
  font-size: 0.75rem;
  color: #ff0000;
  font-weight: 400;
}

.category-header {
  height: 60px;
  position: relative;
  background: #141414;
  border-radius: 0.5rem 0.5rem 0 0;
  padding-block: 0.75rem;
  position: sticky;
  top: 0;
  z-index: 1;

  h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .close-btn {
    width: 2.1875rem;
    height: 2.1875rem;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 1rem;

    &.back-btn {
      right: auto;
      left: 1rem;
    }
  }
}

.content-modal-footer {
  border-radius: 0 0 1rem 1rem;

  button {
    width: 11.25rem;
    height: 2.25rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    color: #fff;
    font-weight: 400;
    position: relative;

    &.btn-outlined {
      background: transparent;
      border: 0.0625rem solid #fff;
    }

    &.btn-primary {
      background-color: #2998ff;
      display: flex;
      align-items: center;
      justify-content: center;

      .loader {
        border: 5px solid transparent;
        border-top: 5px solid #fff;
        width: 2rem;
        height: 2rem;
        animation: spin 2s linear infinite;
        background: radial-gradient(58.99% 58.99% at 50% 50%,
            rgba(41, 152, 255, 0.19) 0%,
            rgba(41, 152, 255, 0) 100%);
        position: absolute;

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }
      }

      &:disabled {
        background-color: #3a3a3a;
        color: #b0b0b0;
      }
    }
  }
}

.category-lists {
  li {
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
  }
}

.input-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: rgba(38, 38, 38, 1);
  border: 0.0625rem solid rgba(67, 67, 67, 1);
  box-shadow: 0rem 0.125rem 0.9375rem 0rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  padding: 1rem;
  width: 100%;
  margin-bottom: 0.75rem;

  input {
    flex-grow: 1;
    height: 100%;
    border: none;
    outline: none;
    font-size: 1rem;
    color: #fff;
    font-weight: 400;
    background: transparent;

    &::placeholder {
      color: rgba(191, 191, 191, 1);
      opacity: 1;
    }

    &::-ms-input-placeholder {
      color: rgba(191, 191, 191, 1);
    }
  }

  .count {
    font-size: 0.75rem;
    color: rgba(140, 140, 140, 1);
    font-weight: 400;
  }

  .drag-icon {
    cursor: grabbing;
  }
}

.add-category-btn {
  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(38, 38, 38, 1);
    border: 0.0625rem solid rgba(67, 67, 67, 1);
    box-shadow: 0rem 0.125rem 0.9375rem 0rem rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    padding: 1rem;
    width: 100%;

    .limit-count {
      font-size: 0.75rem;
      color: #8c8c8c;
      font-weight: 400;
    }

    .text {
      font-size: 1rem;
      color: #fff;
      font-weight: 500;
    }

    .plus-icon {
      width: 1.25rem;
      height: 1.25rem;
      // border-radius: 50%;
      // border: 0.0625rem solid #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.categoryInput {
  &+.count {
    display: none;
  }

  &:focus {
    &+.count {
      display: block !important;
    }
  }
}
</style>
