<template>
  <SideImage :image="imagebg" :bg="bg">
    <div class="flex flex-col gap-[15px] max-w-[35%]">
      <h1 class="font-semibold text-[28px] text-center">Reset password</h1>

      <h2 class="text-main-secondaryBlue text-sm text-center">
        Please enter the new password you would like to use for your account.
      </h2>
      <hr class="my-[10px]" />
      <PasswordInput v-model="form.password" placeholder="New password" />
      <PasswordStrength
        v-if="form.password"
        :value="form.password"
        @getPasswordStatus="
          (value) => {
            passwordStatus = value;
          }
        "
      />
      <PasswordInput
        v-model="form.password_confirmation"
        placeholder="Retype new password"
      />
      <Button
        label="Submit"
        class="w-1/2 mx-auto"
        @click="submit"
        :loading="form.processing"
        :disabled="
          !(
            form.password?.length &&
            form.password_confirmation?.length &&
            passwordStatus == 'Good'
          )
        "
      />
    </div>
  </SideImage>
</template>
<script setup>
import SideImage from "@/components/layout/SideImage.vue";
import imagebg from "@/assets/images/bgPics/forget_password.png";
import bg from "@/assets/images/bgPics/bg2.png";
import { useForm } from "@inertiajs/vue3";
import PasswordInput from "@/components/forms/passwordInput.vue";
import { ref } from "vue";
import PasswordStrength from "@/components/forms/passwordStrength.vue";
import { useToastr } from "@/composables/useToastr";
const { error } = useToastr();
const passwordStatus = ref("");
const props = defineProps(["token", "email"]);
const form = useForm({
  password: null,
  password_confirmation: null,
  token: props.token,
  email: props.email,
});
const submit = () => {
  form.post(route("forgot_password.submit_reset"), {
    onError: (e) => {
      if (e.password) {
        error(e.password);
      }
    },
  });
};
</script>
