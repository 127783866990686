<template>
  <div class="flex w-full">
    <button
      @click.stop="debouncedLikePost"
      :disabled="loading.like"
      class="flex w-full items-center justify-center gap-[8px]"
      v-if="!user?.is_on_waitlist"
      @mouseover="hovers.like.status = true"
      @mouseout="hovers.like.status = false">
      <i
        :class="[
          liked
            ? 'fas fa-heart text-red-500'
            : 'far fa-heart text-main-secondary',
          !liked && hovers.like.status
            ? `fas fa-heart ${hovers.like.color}`
            : '',
        ]"></i>
      <span
        class="text-[14px] font-[400]"
        :class="liked ? 'text-red-500' : 'text-main-secondary'">
        Like
      </span>
    </button>
    <button
      class="flex w-full items-center justify-center gap-[8px]"
      @click="router.get(route('social_posts.index', data.id))"
      v-if="!route().current()?.includes('social_posts')"
      @mouseover="hovers.comment.status = true"
      @mouseout="hovers.comment.status = false">
      <i
        :class="[
          'far fa-comment text-main-secondary',
          hovers.comment.status ? `fas fa-comment ${hovers.comment.color}` : '',
        ]"></i>
      <span class="text-[14px] text-main-secondary">Comment</span>
    </button>
    <button
      @click="handleSharePost"
      class="flex w-full items-center justify-center gap-[8px]"
      @mouseover="hovers.share.status = true"
      @mouseout="hovers.share.status = false">
      <i
        :class="[
          'far fa-share text-main-secondary',
          hovers.share.status ? `fas fa-share ${hovers.share.color}` : '',
        ]"></i>
      <span class="text-[14px] text-main-secondary">Share</span>
    </button>
  </div>
</template>
<script setup lang="ts">
import _debounce from "lodash/debounce";
import { computed, onMounted, reactive, ref } from "vue";
import { router, usePage } from "@inertiajs/vue3";
import type { SocialPostInterface, UserInterface } from "@/core/interfaces";
import { route } from "ziggy-js";
import { API } from "@/core/api";
import { useToastr } from "@/composables/useToastr";
import { useAuthStore } from "@/store/Auth";
const authStore = useAuthStore();
const user = computed(() => authStore.getUser as UserInterface);
const { success } = useToastr();
const SocialPostsAPI = new API.SocialPost();
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(["updated"]);

const hovers = reactive({
  like: {
    color: "text-red-500",
    status: false,
  },
  comment: {
    color: "text-primary-500",
    status: false,
  },
  share: {
    color: "text-primary-500",
    status: false,
  },
});
const liked = ref(false);
const loading = reactive({
  like: false,
  comment: false,
});

onMounted(() => {
  liked.value = props.data.likes?.some(
    (item: any) => item?.id === user.value?.id,
  );
});

let response: any;
const debouncedLikePost = _debounce(async () => {
  loading.like = true;
  if (liked.value) {
    response = await SocialPostsAPI.unlike(props.data?.id);
    liked.value = false;
    props.data.likes_count--;
    if (props.data.likes)
      emit(
        "updated",
        (props.data.likes = props.data.likes.filter(
          (el: SocialPostInterface) => el.id !== user.value.id,
        )),
      );
  } else {
    response = await SocialPostsAPI.like(props.data?.id);
    liked.value = true;
    props.data.likes_count++;
    if (props.data.likes) emit("updated", props.data.likes.push(response.data));
  }
  loading.like = false;
}, 250);

const handleSharePost = async () => {
  const permissionStatus = await navigator.permissions.query({
    name: "clipboard-write",
  });
  if (
    permissionStatus.state === "granted" ||
    permissionStatus.state === "prompt"
  ) {
    const url = route("social_posts.index", props.data.id);
    await navigator.clipboard.writeText(
      `Hey! Check out ${props?.data?.owner?.full_name} post on bizly! ${url}`,
    );
    success(`Share message copied to clipboard!`);
  }
};
</script>
