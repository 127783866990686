<template>
  <div class="flex flex-col gap-2">
    <InputText
      v-model="form.degree"
      placeholder="Degree*"
      :invalid="form.errors.degree != null" />
    <ValidationError :errors="form.errors" name="degree" class="self-start" />

    <CompaniesSelectGroup
      v-model="form.college"
      placeholder="College / University*"
      :invalid="form.errors.college != null" />
    <ValidationError :errors="form.errors" name="college" class="self-start" />
  </div>
</template>

<script lang="ts" setup>
import CompaniesSelectGroup from "@/components/forms/SelectGroups/Companies.vue";
import ValidationError from "@/components/forms/validationError.vue";
/* -------- PROPS -------- */
const form = defineModel({
  type: Object,
  required: true,
});
/* -------- COMPOSABLE -------- */

/* -------- STATE -------- */

/* -------- METHODS -------- */

/* -------- HOOKS -------- */

/* -------- WATCHERS -------- */
</script>
