<template>
  <div
    class="max-w-[1000px] mx-auto w-full flex flex-col items-center gap-[30px] px-0 max-[640px]:px-3 mt-[30px]"
  >
    <h1 class="w-full text-start text-xl font-bold mb-2">
      {{ content.title }}
    </h1>
    <p
      v-if="content.description"
      class="w-full text-sm line-clamp-3"
      ref="clamped"
      :class="{ 'line-clamp-none': ReadmoreToggle }"
    >
      <span ref="full">{{ content.description }}</span>
    </p>
    <span
      v-if="Readmore"
      class="text-blue-400 cursor-pointer"
      @click="ReadmoreToggle = !ReadmoreToggle"
    >
      Read {{ ReadmoreToggle ? "less" : "more" }} ...
    </span>
    <hr class="bg-main-divider h-[1px] w-full" />
    <section
      v-if="content.content_type === 'link_gallery'"
      id="links"
      class="flex gap-8 items-start flex-wrap w-full"
    >
      <div class="max-w-[1000px] w-full">
        <div class="link-gallery-wrapper">
          <div
            v-for="(child, index) in content.children"
            :key="index"
            class="link-card relative cursor-pointer"
          >
            <div class="link-card-img">
              <img
                :src="
                  child.image_kit_id_16_9 ??
                  child.thumbnail_image_kit_id ??
                  child.image_kit_ids?.[0] ??
                  child.image_kit_id
                "
                alt=""
              />
            </div>
            <div class="py-[0.625rem] px-[1.5rem] h-[145px]">
              <h4
                class="font-[400] text-[0.75rem] text-[#546B91] m-0 pb-[0.75rem] uppercase break-all"
              >
                {{ extractDomain(child.image_kit_id) }}
              </h4>
              <div class="flex flex-col w-full gap-[10px]">
                <h2 class="font-[500] text-[1.125rem] text-[#141414] m-0 line-clamp-1">
                  {{ child.title }}
                </h2>
                <p class="font-[400] text-[0.875rem] text-[#8C8C8C] m-0 line-clamp-2">
                  {{ child.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="w-full" v-else>
      <div
        class="relative cursor-pointer rounded-[16px] overflow-hidden max-w-[1000px] w-full shadow"
      >
        <div class="aspect-video rounded-lg">
          <div class="w-full h-full">
            <img
              :src="
                content.image_kit_id_16_9 ??
                content.thumbnail_image_kit_id ??
                content.image_kit_ids?.[0]
              "
              alt=""
              class="size-full object-cover"
            />
          </div>
          <h2
            class="p-[1rem] m-0 text-[#546B91] font-[400] text-[0.75rem] border-t-[1px] border-[#CDD5E1] bg-[#F7F8FB] uppercase"
          >
            {{ extractDomain(content.image_kit_id) }}
          </h2>
        </div>
        <div
          class="absolute top-3 left-3 size-10 rounded-3xl bg-black/70 flex items-center justify-center text-white"
        >
          <i class="fa fa-chain"></i>
        </div>
      </div>
    </div>

    <div class="flex justify-between w-full gap-[40px] flex-wrap">
      <section
        class="space-y-4 w-full lg:max-w-[530px]"
        id="left"
        v-if="showProjectDetails || content.tags.length"
      >
        <ProjectDetails :content="content" v-if="showProjectDetails" />
        <Tags v-if="content.tags?.length" :tags="content.tags" />
      </section>
      <section
        class="flex flex-col gap-4 w-full"
        id="right"
        :class="{
          'lg:max-w-[400px]': showProjectDetails || content.tags.length,
        }"
      >
        <Collaborators
          v-if="collaborators.length || content.owner_roles?.length"
          :collaborators="collaborators"
          :owner="content.owner_roles?.length ? content.bizcard.user : null"
          :ownerRoles="content.owner_roles"
        />
        <Publisher
          :published="content.created_on"
          :publisher="content.bizcard.user"
          :full="!showProjectDetails && !content.tags.length"
        />
      </section>
    </div>
  </div>
</template>
<script setup>
import InfoBallon from "@/components/parts/InfoBallon.vue";
import { computed, onMounted, ref } from "vue";
import Collaborators from "../parts/Collaborators.vue";
import ProjectDetails from "../parts/ProjectDetails.vue";
import Publisher from "../parts/Publisher.vue";
import Tags from "../parts/Tags.vue";
const props = defineProps({ content: { required: true, type: Object } });
const clamped = ref();
const full = ref();
const Readmore = ref(false);
const ReadmoreToggle = ref(false);
const collaborators = computed(
  () => props.content?.collaborators?.filter((c) => c.accepted_on) ?? []
);
onMounted(() => {
  if (clamped.value.offsetHeight < full.value.offsetHeight) {
    Readmore.value = true;
  }
});
const showProjectDetails = computed(
  () =>
    !!props.content.project_types?.length ||
    !!props.content.organizations?.length ||
    props.content.completion_year
);
const extractDomain = (url) => {
  const regex = /^(?:https?:\/\/)?(?:www\.)?([^\/]+)/i;
  const match = url.match(regex);
  return match ? match[1] : null;
};
</script>
<style scoped>
.shadow {
  box-shadow: 0 2px 20px #0000001a;
}

.link-gallery-wrapper {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(2, calc(50% - 15px));
  width: 100%;
}

.link-card {
  background-color: #f7f8fb;
  border-radius: 8px !important;
  box-shadow: 0 2px 20px #0000001a;
  height: -moz-max-content;
  height: max-content;
  overflow: hidden;
}

.link-card .link-card-img {
  aspect-ratio: 16/9;
  background: #fff;
  border-bottom: 1px solid #cdd5e1;
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
}
</style>
