import type { SocialPostInterface } from "@/core/interfaces";
import { HomeFeedType } from "@/core/enums";
import { defineStore } from "pinia";
import { API } from "@/core/api";
import { useToastr } from "@/composables/useToastr";
const { success, error } = useToastr();
interface FeedData {
  start: number;
  size: number | null;
  items: SocialPostInterface[];
  scrollPosition: number;
}

interface DataType {
  community: FeedData;
  connections: FeedData;
}

interface State {
  no_more_results: boolean;
  initialized: boolean;
  selectedTab: HomeFeedType;
  data: {
    [HomeFeedType.Community]: FeedData;
    [HomeFeedType.Connections]: FeedData;
  };
}

export const useHomeFeedStore = defineStore("homeFeed", {
  state: (): State => ({
    no_more_results: false,
    initialized: false,
    selectedTab: HomeFeedType.Community,
    data: {
      [HomeFeedType.Community]: {
        start: 0,
        size: 15,
        items: [],
        scrollPosition: 0,
      },
      [HomeFeedType.Connections]: {
        start: 0,
        size: 15,
        items: [],
        scrollPosition: 0,
      },
    },
  }),
  getters: {
    items: (state): SocialPostInterface[] =>
      state.data[state.selectedTab].items,
  },
  actions: {
    async reset() {
      this.$reset();
    },
    async init() {
      if (!this.initialized) {
        await this.fetch(HomeFeedType.Community);
        await this.fetch(HomeFeedType.Connections);
        this.initialized = true;
      }
    },
    removePost(tab: HomeFeedType, postId: number) {
      this.data[tab].items = this.data[tab].items.filter(
        (post) => post.id !== postId,
      );
    },
    async hidePost(tab: HomeFeedType, postId: number) {
      const socialPostsAPI = new API.SocialPost();
      try {
        let response = await socialPostsAPI.hide(postId);
        let post = this.data[tab].items.find((el) => el.id === postId);
        if (post) post.hidden = true;
      } catch (error) {
        console.log(error);
      }
    },
    async unhidePost(tab: HomeFeedType, postId: number) {
      const socialPostsAPI = new API.SocialPost();

      try {
        let response = await socialPostsAPI.unhide(postId);
        let post = this.data[tab].items.find((el) => el.id === postId);
        if (post) post.hidden = false;
      } catch (error) {
        console.log(error);
      }
    },
    async hidePostsForUser(tab: HomeFeedType, userId: number) {
      this.data[tab].items = this.data[tab].items.filter(
        (item: SocialPostInterface) => item.owner.id !== userId,
      );
    },
    async editPost(
      tab: HomeFeedType,
      postId: number,
      payload: { title: string; is_network_only: boolean },
    ) {
      let item = this.data[tab].items.find((item) => item.id === postId);
      if (item) {
        item.title = payload.title;
        item.is_network_only = payload.is_network_only;
      }
      success("Post updated successfully!");
    },
    addPost(tab: HomeFeedType, post: SocialPostInterface) {
      this.data[tab].items.unshift(post);
    },
    async fetch(type: HomeFeedType | null = null) {
      if (!this.no_more_results) {
        type = type ?? this.selectedTab;

        const api = new API.SocialPost();
        const data: FeedData = this.data[type];
        let response = await api.get({
          start: data.start,
          size: data.size,
          type: type,
        });
        if (data.start) {
          if (response.data.length) {
            data.items.push(...response.data);
          }
        } else {
          data.items = response.data;
        }
        if (response.length < (data.size ?? 0)) {
          this.no_more_results = true;
        }
        data.start = data.items.length;
        data.size = 5;
      }
    },
    setSelectedTab(tab: HomeFeedType) {
      this.selectedTab = tab;
    },
    setScroll(position: number) {
      this.data[this.selectedTab].scrollPosition = position;
    },
    editContent(content: any, tab: HomeFeedType = HomeFeedType.Community) {
      console.log(content);
      let item = this.data[tab].items.find(
        (item) => item.content.id === content.id,
      );
      if (item) item.content = content;
    },
  },
});
