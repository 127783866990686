<template>
  <div
    @click="() => onClick(notification)"
    class="p-[1rem] cursor-pointer flex gap-[0.75rem] border-b-[0.0625rem] border-b-[#CDD5E1] last:border-b-[0rem]"
  >
    <Avatar shape="circle" size="large">
      <template #default>
        <div class="size-[50px] rounded-full overflow-hidden cursor-pointer">
          <img
            :src="notification.sender.avatar_image"
            alt="User Avatar"
            class="object-cover"
          />
        </div>
      </template>
    </Avatar>
    <div class="flex flex-col gap-1">
      <div
        class="xl:text-[0.875rem] text-[0.75rem] font-[500] text-main-primary m-0 pb-[0.625rem]"
        v-html="titles[props.notification.notification_type].format()"
      ></div>
      <div class="pb-[0.5rem]">
        <div class="w-[150px] h-[150px] relative mb-[0.5rem]" v-if="showContent">
          <div
            class="absolute top-[0.625rem] left-[0.625rem] h-[1.5625rem] w-[1.5625rem] text-white text-xs rounded-full bg-black/50 flex justify-center items-center shadow-lg backdrop-blur"
          >
            <i :class="['fal', contentIcon]"></i>
          </div>
          <img
            class="rounded-[0.25rem] w-full h-full object-cover"
            :src="
              content.thumbnail_image_kit_id ??
              content.image_kit_id ??
              content.image_kit_id_16_9
            "
            alt="Preview thumbnail"
          />
        </div>
        <h4
          v-if="showContent"
          class="p-[0.5rem] border-s-[0.125rem] border-s-[#3578F8] m-0 mb-2 text-[0.75rem] font-[500] text-[#696F8C]"
        >
          {{ props.notification.object?.title }}
        </h4>
        <div
          class="flex items-center gap-[0.5rem]"
          v-if="acceptCallback && declineCallback"
        >
          <Button
            :loading="ActionButtonsLoading"
            size="small"
            @click.stop="
              async () => {
                ActionButtonsLoading = true;
                await acceptCallback(notification.id);
                ActionButtonsLoading = false;
              }
            "
          >
            Accept
          </Button>
          <Button
            :loading="ActionButtonsLoading"
            size="small"
            text
            outlined
            @click.stop="
              async () => {
                ActionButtonsLoading = true;
                await declineCallback(notification.id);
                ActionButtonsLoading = false;
              }
            "
            >Decline</Button
          >
        </div>
        <!---->
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch, type PropType } from "vue";
import { NotificationType } from "@/core/enums";
import { type NotificationInterface } from "@/core/interfaces";

import { ContentType } from "@/core/enums";

const props = defineProps({
  notification: {
    type: Object as PropType<NotificationInterface>,
    required: true,
  },
  acceptCallback: {
    type: Function,
    required: false,
  },
  declineCallback: {
    type: Function,
    required: false,
  },
  onClick: {
    type: Function,
    required: false,
  },
  showContent: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const contentIcon = computed(() => {
  switch (content.value.content_type) {
    case "video":
    case "video_gallery":
      return "fa-clapperboard-play";
    case "image":
    case "image_gallery":
      return "fa-image";
    case "link":
    case "link_gallery":
      return "fa-link";
    case "collection":
      return "fa-rectangle-history";
    default:
      return "fa-file-alt";
  }
});
const ActionButtonsLoading = ref(false);
const contentType = computed(() => {
  switch (content.value.content_type) {
    case "video":
      return "Video";
    case "video_gallery":
      return "Video Gallery";
    case "image":
      return "Image";
    case "image_gallery":
      return "Image Gallery";
    case "link":
      return "Link";
    case "link_gallery":
      return "Link Gallery";
    case "collection":
      return "Collection";
    default:
      return "File";
  }
});
const content = computed(() => {
  return props.notification.object_type === ContentType.SocialPosts
    ? props.notification.object?.content
    : props.notification.object;
});

const titles: {
  [key in NotificationType]: {
    title: string;
    format: Function;
  };
} = {
  [NotificationType.SocialPostMention]: {
    title: "<b>%0</b> mentioned you in their post.",
    format: () => parseTitle(props.notification.sender.full_name),
  },
  [NotificationType.CollaborationRequest]: {
    title:
      "<b>%0</b> has added you as a collaborator %1 on their <b>%2</b> titled <b>'%3'</b>",
    format: () =>
      parseTitle(
        props.notification.sender.full_name,
        parseRoles(props.notification.object?.collaborators[0]?.roles),
        contentType.value,
        content.value.title,
      ),
  },

  [NotificationType.CollaborationAccepted]: {
    title:
      "You've accepted <b>%0's</b> invitation to collaborate on the <b>%1</b> titled <b>'%2'</b>.",
    format: () =>
      parseTitle(
        props.notification.sender.full_name,
        contentType.value,
        content.value.title,
      ),
  },
  [NotificationType.CollaborationDeclined]: {
    title:
      "You've declined <b>%0's</b> invitation to collaborate on the <b>%1</b> titled <b>'%2'</b>.",
    format: () =>
      parseTitle(
        props.notification.sender.full_name,
        contentType.value,
        content.value.title,
      ),
  },
  [NotificationType.OwnerCollaborationAccepted]: {
    title:
      "<b>%0</b> has accepted your collaboration request for the <b>%1</b> titled <b>'%2'</b>.",
    format: () =>
      parseTitle(
        props.notification.sender.full_name,
        contentType.value,
        content.value.title,
      ),
  },

  [NotificationType.NewContentUploaded]: {
    title:
      "<b>%0</b> just uploaded a new <b>%1</b>: <b>'%2'</b>. Check it out!",
    format: () =>
      parseTitle(
        props.notification.sender.full_name,
        contentType.value,
        content.value.title,
      ),
  },
  [NotificationType.ConnectionRequest]: {
    title: "<b>%0</b> wants to connect with you!",
    format: () => parseTitle(props.notification.sender.full_name),
  },

  [NotificationType.ConnectionAccepted]: {
    title: "You've accepted <b>%0's</b> connection request.",
    format: () => parseTitle(props.notification.sender.full_name),
  },
  [NotificationType.OwnerConnectionAccepted]: {
    title: "<b>%0</b> accepted your connection request.",
    format: () => parseTitle(props.notification.sender.full_name),
  },

  [NotificationType.SocialPostLike]: {
    title: "<b>%0</b> liked your post.",
    format: () => parseTitle(props.notification.sender.full_name),
  },
  [NotificationType.SocialPostComment]: {
    title: "<b>%0</b> commented in your post. '%1'",
    format: () =>
      parseTitle(props.notification.sender.full_name, content.value.comment),
  },
  [NotificationType.SocialPostCommentMention]: {
    title: "<b>%0</b> mentioned you in a comment. <b>'%1'</b>",
    format: () =>
      parseTitle(props.notification.sender.full_name, content.value.comment),
  },
  [NotificationType.SocialPostContentMention]: {
    title: "<b>%0</b> mentioned you in a content. <b>'%1'</b>",
    format: () =>
      parseTitle(props.notification.sender.full_name, content.value.title),
  },
  [NotificationType.Unknown]: {
    title: "Unknown notification type: %0",
    format: () => parseMentions(props.notification.notification_type),
  },
};

const parseTitle = (...placeholders: any[]) => {
  const type = props.notification.notification_type as NotificationType;
  const typeData = titles.hasOwnProperty(type)
    ? titles[type]
    : titles[NotificationType.Unknown];
  let formattedMessage = typeData.title;
  // Replace each placeholder with its corresponding value
  placeholders.forEach((value, index) => {
    formattedMessage = formattedMessage.replace(`%${index}`, value);
  });

  return formattedMessage;
};

const parseMentions = (str: string) => {
  return str?.replace(/<([^:]+):([^>]+)>/g, (match, fullName) => {
    return `${fullName}`;
  });
};

const parseRoles = (roles: string[] = []) => {
  let formattedRoles = "";
  const numRoles = roles.length;
  switch (numRoles) {
    case 0:
      break;
    case 1:
      formattedRoles = `with the role of <strong>${roles[0]}</strong>`;
      break;
    case 2:
      formattedRoles = `with the roles of <strong>${roles[0]}</strong> and <strong>${roles[1]}</strong>`;
      break;
    default:
      const displayedRoles = `${roles[0]}, ${roles[1]}`;
      const remainingCount = numRoles - 2;
      formattedRoles = `with the roles of <strong>${displayedRoles}</strong> and <strong>${remainingCount} more</strong>`;
      break;
  }

  return formattedRoles;
};
</script>
