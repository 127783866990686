<template>
  <Dropdown
    v-model="model"
    :options="props.options"
    :filter="props.filter || false"
    :placeholder="props.placeholder"
    :class="cn('w-full md:w-14rem', props.classes)"
  >
    <template #option="slotProps">
      <div class="flex align-items-center">
        <img
          :alt="slotProps.option[props.label]"
          :src="slotProps.option[props.image]"
          class="mr-2 w-[18px]"
        />
        <div>{{ slotProps.option[props.label] }}</div>
      </div>
    </template>
  </Dropdown>
</template>
<script setup>
const model = defineModel();
const props = defineProps([
  "options",
  "label",
  "image",
  "placeholder",
  "classes",
  "filter",
]);
</script>
