<template>
    <Confirm class="bg-white max-w-[550px]" action="Continue"
        @close="dialogStore[DialogType.EXTERNAL_LINK].events?.close" @confirm="handleConfirm">
        <template #icon>
            <CircleIcon class="bg-primary-200/30 text-primary-500" size="3.5rem">
                <i class="fa fa-link text-2xl"></i>
            </CircleIcon>
        </template>
        <template #title>
            <h1 class="font-semibold text-[28px] text-black m-0 tracking-tight">
                External Link
            </h1>
        </template>
        <template #description>
            <div class="flex flex-col gap-5">
                <div class="text-main-secondaryBlue text-center text-sm w-full mt-4">
                    You are about to leave bizly! The link you are trying to access is hosted by a different
                    organization.
                    Please be cautious as we cannot guarantee its safety or endorse its content.
                </div>
                <Divider class="before:border-primary-200" />
                <div class="flex items-center gap-1.5 text-main-secondaryBlue">
                    <Checkbox v-model="rememberChoice" inputId="remember_choice" :binary="true" />
                    <label for="remember_choice" class="text-xs flex items-center">
                        Remember my choice and don't show this warning again.
                    </label>
                </div>
            </div>
        </template>
    </Confirm>
</template>
<script setup lang="ts">
import { DialogType, useDialogStore } from '@/store/Dialog';
import CircleIcon from '../icons/CircleIcon.vue';
import Confirm from './Confirm.vue';
import { ref } from 'vue';
import { useUserStore } from '@/store/User';

const dialogStore = useDialogStore();
const userStore = useUserStore();
const rememberChoice = ref(false);

const handleConfirm = () => {
    if (rememberChoice.value) {
        userStore.openExternalLinks = true;
    }
    dialogStore[DialogType.EXTERNAL_LINK].events?.confirm();
}
</script>