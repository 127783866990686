import { defineStore } from "pinia";
import _ from "lodash";
import { startCase, camelCase } from "lodash";
interface State {
  errors: Record<string, string>;
}

export const useValidateStore = defineStore("validateStore", {
  state: (): State => ({
    errors: {},
  }),
  getters: {
    hasErrors: (state): boolean => Object.keys(state.errors).length > 0,
  },
  actions: {
    validate(form: any, fields: any) {
      const keys = Object.keys(fields);
      keys.forEach((element) => {
        /* CHECKS FOR VALIDATE_IF WHICH ONLY VALIDATES AN INPUT IF CONDITION IS MET*/
        if (
          fields[element]["validate_if"] &&
          form[fields[element]["validate_if"]["field"]] !==
            fields[element]["validate_if"]["value"]
        )
          return;

        /* CHECKS FOR THE REQUIRED FIELD <BOOLEAN>*/
        if (fields[element]["required"]) {
          this.validateRequired(form, element);
        }
        /* CHECKS FOR REQUIRED_IF WHICH MAKES THE INPUT REQUIRED IF CONDITION IS MET*/
        if (fields[element]["required_if"])
          this.requiredIf(form, element, fields[element]["required_if"]);

        /* ONLY VALIDATE INPUT IF ITS NOT EMPTY*/
        if (
          form[element] !== null &&
          typeof form[element] !== undefined &&
          form[element] !== ""
        ) {
          if (fields[element]["type"])
            this.validateType(form, element, fields[element]["type"]);
          if (fields[element]["max"])
            this.validateMaxSize(form, element, fields[element]["max"]);
          if (fields[element]["min"])
            this.validateMinSize(form, element, fields[element]["min"]);
        }

        /** Validations that does NOT require a value to validate */
        if (fields[element]["in_array"])
          this.validateInArray(form, element, fields[element]["in_array"]);
      });
    },
    validateEmail(email: string, element: string) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        //if (!this.errors[element]) this.errors[element] = [];
        this.errors[element] = `${formatElementName(
          element,
        )} should be a valid email`;
        return;
      }
      return true;
    },

    validateURL(url: string, element: string) {
      const urlRegex =
        /^(https?:\/\/)?([a-z\d.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/i;
      if (!urlRegex.test(url)) {
        this.errors[element] = `${formatElementName(
          element,
        )} should be a valid URL`;
        return;
      }
      return true;
    },

    validateRequired(form: any, element: string) {
      if (!form[element] || form[element] === "") {
        //if (!this.errors[element]) this.errors[element] = [];
        this.errors[element] = `${formatElementName(element)} is required`;
        return;
      }
    },

    validateInArray(form: any, element: string, value: string | Array<string>) {
      const contains = Array.isArray(value) ? value : value.split(",");
      contains.forEach((contain) => {
        if (!form[element].includes(contain)) {
          this.errors[`${element}.${contain}`] = `${formatElementName(
            contain,
          )} option is required`;
          return;
        }
      });
    },

    validateType(form: any, element: string, type: string) {
      if (type === "email") {
        this.validateEmail(form[element], element);
      } else if (type === "url") {
        this.validateURL(form[element], element);
      } else {
        if (typeof form[element] !== type) {
          this.errors[element] = `${formatElementName(
            element,
          )} should be of type ${type}`;
          return;
        }
      }
    },

    validateMaxSize(form: any, element: string, size: number) {
      if (form[element].length > size) {
        //if (!this.errors[element]) this.errors[element] = [];
        this.errors[element] = `${formatElementName(
          element,
        )} should be max ${size}`;
        return;
      }
    },
    validateMinSize(form: any, element: string, size: number) {
      if (form[element].length < size) {
        //if (!this.errors[element]) this.errors[element] = [];
        this.errors[element] = `${formatElementName(
          element,
        )} should be min ${size}`;
        return;
      }
    },

    /* Example : */
    /* condition : {field:email, value:null} */
    requiredIf(form: any, element: string, condition: any) {
      if (
        form[condition["field"]] === condition["value"] &&
        (!form[element] || form[element] === "")
      ) {
        //if (!this.errors[element]) this.errors[element] = [];
        this.errors[element] = `${formatElementName(element)} is required`;
        return;
      }
    },
    reset() {
      this.errors = {};
    },
    setErrors(errors: any) {
      this.errors = errors;
    },
    setError(error: any) {
      this.errors = { ...this.errors, ...error };
    },
    resetInputValidation(inputName: string) {
      this?.errors.hasOwnProperty(inputName) && delete this?.errors[inputName];
    },
  },
});

const formatElementName = (elementName: string) => {
  return startCase(camelCase(elementName));
};
