<template>
  <div class="w-full flex flex-col relative">
    <section
      id="header"
      class="p-2 bg-surface-50 border-b-[1px] border-surface-300 sticky top-0 z-20 rounded-t-[16px]">
      <div class="flex justify-between items-center">
        <h1 class="font-poppins text-xl flex-1 text-center">
          Add Representation
        </h1>
        <CircleIcon
          class="bg-surface-100 text-primary-500 hover:bg-surface-200"
          @click="$emit('close')">
          <i class="far fa-times"></i>
        </CircleIcon>
      </div>
    </section>
    <section
      id="content"
      class="px-6 py-4 flex flex-col gap-2 overflow-y-auto scroll-hidden max-h-[80vh]">
      <div class="flex flex-col gap-2">
        <span class="text-lg font-medium">Representation type</span>
        <Dropdown
          v-model="form.type"
          :options="types"
          optionLabel="label"
          optionValue="value"
          placeholder="Representation Type*"
          :pt="{
            wrapper: 'max-h-[200px] overflow-auto scroll-hidden',
          }"
          :ptOptions="{ mergeProps: true }"
          @change="handleUpdateTitle">
          <template #option="{ option }">
            <div class="flex gap-2 items-center">
              <p class="text-inherit">
                {{ option["label"] }}
              </p>
            </div>
          </template>
        </Dropdown>
        <Dropdown
          v-if="form.type === 'talent_agent'"
          v-model="form.field"
          :options="fields"
          optionLabel="label"
          optionValue="value"
          placeholder="Select Field*"
          :showClear="true"
          :pt="{
            wrapper: 'max-h-[200px] overflow-auto scroll-hidden',
          }"
          :ptOptions="{ mergeProps: true }"
          @change="handleUpdateTitle">
          <template #option="{ option }">
            <div class="flex gap-2 items-center">
              <p class="text-inherit">
                {{ option["label"] }}
              </p>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="flex flex-col gap-2">
        <span class="text-lg font-medium">Company</span>
        <CompaniesSelectGroup
          v-model="form.company"
          placeholder="Company / Organization*" />
        <div class="flex flex-col">
          <InputText
            v-model="form.website"
            placeholder="Website"
            :invalid="validateStore.errors.website != null"
            @change="(e) => handleValidation(e, 'website')" />
          <ValidationError :errors="validateStore.errors" name="website" />
        </div>
        <CountryInput
          class="!w-full sm:w-[400px]"
          :errors="validateStore.errors"
          errorName="phone"
          :initValue="form.phone"
          @isValid="(v) => (form.phone_valid = v)"
          @getPhoneValue="
            (v) => {
              form.phone = v.number;
            }
          " />
        <div class="flex flex-col">
          <InputText
            v-model="form.email"
            placeholder="Email"
            :invalid="validateStore.errors.email != null"
            @change="(e) => handleValidation(e, 'email')" />
          <ValidationError :errors="validateStore.errors" name="email" />
        </div>
        <LocationSelectGroup v-model="form.location" placeholder="Location" />
      </div>
      <div class="flex flex-col gap-2">
        <span class="text-lg font-medium">Representatives</span>
        <InfoCard
          customClass="bg-switcherBlue-500/20 rounded-lg py-2 cursor-pointer hover:bg-switcherBlue-500/30"
          @click="() => handleOpenRepresentative()">
          <template #content>
            <div class="flex gap-2 items-center">
              <i class="fal fa-plus-circle text-switcherBlue-500 text-lg"></i>
              <span class="text-switcherBlue-500">Add Representative</span>
            </div>
          </template>
        </InfoCard>
        <draggableComponent
          class="draggable-container flex flex-col gap-2"
          item-key="order"
          :component-data="{ tag: 'ul', name: 'flip-list', type: 'transition' }"
          v-model="form.representatives"
          v-bind="dragOptions"
          handle=".drag-handle">
          <template #item="{ element, index }">
            <InfoCard>
              <template #content>
                <div class="flex gap-4 items-center">
                  <i
                    class="fa fa-grip-dots-vertical text-xl text-main-disabled drag-handle cursor-grabbing"></i>
                  <div class="flex flex-col flex-1 drag-handle cursor-grabbing">
                    <span class="text-lg font-medium">
                      {{ element.first_name }}
                    </span>
                    <span class="text-sm font-normal" v-if="element.email">
                      {{ element.email }}
                    </span>
                    <span class="text-sm font-normal" v-if="element.phone">
                      {{ element.phone }}
                    </span>
                    <span class="text-sm font-normal" v-if="element.location">
                      {{ element.location }}
                    </span>
                  </div>
                  <i
                    class="fal fa-pencil text-primary-500 cursor-pointer text-lg hover:text-primary-700"
                    @click="handleOpenRepresentative(index)"></i>
                  <CircleIcon
                    class="bg-primary-500 text-white hover:bg-primary-600"
                    @click="handleRemove(index)"
                    size="1.5rem">
                    <i class="far fa-times text-xs"></i>
                  </CircleIcon>
                </div>
              </template>
            </InfoCard>
          </template>
        </draggableComponent>
      </div>
    </section>
    <section
      id="footer"
      class="bg-surface-50 border-t border-surface-300 sticky bottom-0 z-20 rounded-b-[16px]">
      <div class="flex items-center py-2.5 px-4 gap-2">
        <Button
          label="Cancel"
          class="min-w-[150px]"
          severity="secondary"
          :outlined="true"
          @click="handleCancel"></Button>
        <Button
          label="Save"
          :disabled="!canSave"
          :loading="form.processing"
          class="min-w-[150px] ms-auto"
          @click="handleSave"></Button>
      </div>
    </section>
    <ManageRepresentative
      v-if="manageRepresentativeVisible"
      :model="
        manageRepresentative !== null
          ? form.representatives[manageRepresentative]
          : null
      "
      @close="() => (manageRepresentativeVisible = false)"
      @save="handleSaveRepresentative" />
  </div>
</template>

<script lang="ts" setup>
import { useForm } from "@inertiajs/vue3";
import { ref } from "vue";
import { useValidateStore } from "@/store/Validate";
import type {
  RepresentationsInterface,
  RepresentativeInterface,
} from "@/core/interfaces";
import { computed } from "vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import CountryInput from "@/components/forms/countryInput.vue";
import CompaniesSelectGroup from "@/components/forms/SelectGroups/Companies.vue";
import LocationSelectGroup from "@/components/forms/SelectGroups/Location.vue";
import ManageRepresentative from "./ManageRepresentative.vue";
import InfoCard from "@/components/general/CustomCard.vue";
import draggableComponent from "vuedraggable";
import ValidationError from "@/components/forms/validationError.vue";
import _debounce from "lodash/debounce";
import type { PropType } from "vue";
import { onUnmounted } from "vue";
import { onMounted } from "vue";

/* -------- PROPS -------- */
const props = defineProps({
  model: {
    type: Object as PropType<RepresentationsInterface>,
    default: null,
  },
});
const emit = defineEmits(["close", "save", "changeTo"]);

/* -------- COMPOSABLE -------- */
const validateStore = useValidateStore();

/* -------- STATE -------- */
const dragOptions = {
  animation: 200,
  group: "description",
  disabled: false,
  ghostClass: "ghost",
};

const types = [
  { label: "Legal Representative", value: "legal_representative" },
  { label: "Manager", value: "manager" },
  { label: "Publicist", value: "publicist" },
  { label: "Booking Agent", value: "booking_agent" },
  { label: "Talent Agent", value: "talent_agent" },
];

const fields = [
  { label: "Commercial", value: "commercial" },
  { label: "Hosting", value: "hosting" },
  { label: "Modeling", value: "modeling" },
  { label: "Personal Appearance", value: "personal_appearance" },
  { label: "Influencer", value: "influencer" },
  { label: "Social Media", value: "social_media" },
  { label: "Voice", value: "voice" },
  { label: "Television", value: "television" },
];

const validationSchema = {
  email: { type: "email" },
  website: { type: "url" },
};

const manageRepresentative = ref<number | null>(null);
const manageRepresentativeVisible = ref(false);

/** Mandatory to be added here */
const getTypeAndField = (title: string) => {
  const [typeLabel, fieldLabel] = title.split(" - ");

  const type = types.find((t) => t.label === typeLabel)?.value || null;
  const field = fields.find((f) => f.label === fieldLabel)?.value || null;

  return { type, field };
};
const { type, field } = getTypeAndField(props.model?.title || "");
/** ----------------------------- */

const canSave = computed(
  () =>
    form.isDirty &&
    !form.processing &&
    !validateStore.hasErrors &&
    form.type !== null &&
    form.company !== null &&
    ((form.type === "talent_agent" && form.field !== null) ||
      form.type !== "talent_agent") &&
    ((form.phone && form.phone_valid === true) || !form.phone),
);
const form = useForm<any>({
  title: "",
  type: type,
  field: field,
  company: props.model?.contact_information?.company || null,
  website: props.model?.contact_information?.website || null,
  phone: props.model?.contact_information?.phone || null,
  email: props.model?.contact_information?.email || null,
  location: props.model?.contact_information?.location || null,
  representatives: props.model?.representatives || [],
});
/* -------- METHODS -------- */

const handleSave = () => {
  const data = form.data();

  emit("save", {
    title: data.title,
    contact_information: {
      company: data.company,
      website: data.website,
      phone: data.phone,
      email: data.email,
      location: data.location,
    },
    representatives: data.representatives,
  });
};

const handleUpdateTitle = () => {
  const type = types.find((v) => v.value === form.type);
  const field = fields.find((v) => v.value === form.field);
  if (!type) return;
  form.title =
    type.value === "talent_agent"
      ? `${type.label} - ${field?.label}`
      : type.label;
};

const handleOpenRepresentative = (index: number | null = null) => {
  manageRepresentative.value = index;
  manageRepresentativeVisible.value = true;
};

const handleSaveRepresentative = (value: RepresentativeInterface) => {
  if (manageRepresentative.value !== null)
    form.representatives.splice(manageRepresentative.value, 1, value);
  else form.representatives.push(value);
  manageRepresentativeVisible.value = false;
  manageRepresentative.value = null;
};

const handleRemove = (index: number) => {
  form.representatives.splice(index, 1);
};

const handleCancel = () => {
  emit("changeTo", "list");
  form.reset();
  validateStore.reset();
};

const handleValidation = _debounce((e, inputName) => {
  if (!form.isDirty) return;
  validateStore.resetInputValidation(inputName);
  validateStore.validate(form, validationSchema);
}, 250);

/* -------- HOOKS -------- */
onMounted(() => {
  handleUpdateTitle();
});
onUnmounted(() => {
  form.reset();
  validateStore.reset();
});
/* -------- WATCHERS -------- */
</script>
