<template>
  <Dropdown
    v-model="optionsModel"
    :invalid="props.invalid"
    editable
    :options="options"
    optionGroupLabel="label"
    optionGroupChildren="items"
    optionLabel="name"
    optionValue="id"
    :placeholder="props.placeholder"
    dataKey="id"
    class="w-full md:w-14rem"
    :showClear="true"
    panelClass="overflow-y-auto max-h-[300px]"
    :optionDisabled="
      (e) =>
        (selected.find((v) => v?.id === e.id) ||
          selected.find((v) => v?.name === e.name)) &&
        optionsModel !== e.id
    "
    :panelProps="{
      id: 'dropdown-scroll',
    }"
    :pt="{ emptyMessage: '', list: '' }"
    @change="handleChange"
    @show="initInfiniteScroll">
    <template #header="{ value, options }">
      <AddItem
        v-if="value && typeof value === 'string' && !options.length"
        :value="value"
        :callback="handleAddCustom" />
    </template>
    <template #option="{ option }">
      <div class="flex gap-2 items-center">
        <p class="text-inherit">
          {{ option.name }}
        </p>
      </div>
    </template>
    <template #empty>
      <span></span>
    </template>
  </Dropdown>
</template>

<script setup>
import { reactive, ref, computed, onMounted, watch } from "vue";
import { vInfiniteScroll } from "@vueuse/components";
import { cn } from "@/utils/cn";
import NoCompanyImage from "@/assets/images/new_icons/company_dummy.svg";
import { useInfiniteScroll } from "@vueuse/core";
import _debounce from "lodash/debounce";
import { API } from "@/core/api";
import { usePage } from "@inertiajs/vue3";
import AddItem from "@/components/general/AddItem.vue";

const props = defineProps({
  selected: {
    type: Array,
  },
  placeholder: {
    type: String,
    default: "Select an option",
  },
  icon: {
    type: Object,
    default: null,
  },
  invalid: {
    type: Boolean,
    default: false,
  },
  clearCallback: {
    type: Function,
    default: () => {},
  },
});

const industriesAPI = new API.ClientIndustries();

/* ---------- REACTIVE DATA ---------- */
const loading = ref(false);
const model = defineModel();
const optionsModel = ref(
  typeof model.value === "object" ? model.value?.id : model.value,
);

const lazyLoad = reactive({
  start: 0,
  size: 25,
  keyword: "",
  no_more_results: false,
  initialValues: props.selected.hasOwnProperty("items")
    ? props.selected.items.map((v) => ({ id: v.id, name: v.name }))
    : props.selected,
});

const options = ref([]);

/* ---------- FUNCTIONS ---------- */
// Clear is used when filtering so we clear previous results instead of pushing to them
const getData = async (clear = false) => {
  try {
    if (!lazyLoad.no_more_results && !loading.value) {
      loading.value = true;
      let response = await industriesAPI.get(lazyLoad);
      response = response.data;
      if (response && response.length) {
        if (clear) {
          options.value = response;
        } else {
          response.forEach((newGroup) => {
            // Find the existing group in the options
            const existingGroup = options.value.find(
              (group) => group.code === newGroup.code,
            );

            if (existingGroup) {
              // Append new items to the existing group
              existingGroup.items.push(...newGroup.items);
            } else {
              // If the group doesn't exist, add it as a new group
              options.value.push(newGroup);
            }
          });
        }

        // Calculate the total number of items across all groups
        const totalItems = response.reduce(
          (count, group) => count + group.items.length,
          0,
        );

        // Update lazyLoad.start based on the new total items
        lazyLoad.start += totalItems;

        // Check if fewer items were returned than requested, indicating no more results
        if (totalItems < lazyLoad.size) {
          lazyLoad.no_more_results = true;
        }
      } else {
        if (clear) {
          options.value = [];
        }
        lazyLoad.no_more_results = true;
      }
      loading.value = false;
    }
  } catch (error) {
    loading.value = false;
    console.error(error);
  }
};

onMounted(() => {
  getData(true);
});

const filter = _debounce(() => {
  lazyLoad.no_more_results = false;
  lazyLoad.start = 0;
  getData(true);
}, 200);

const handleChange = (e) => {
  if (e.originalEvent.type === "input" && e.value) {
    lazyLoad.keyword = e.value;
    filter();
  }
  if (e.originalEvent.type === "input" && !e.value) {
    lazyLoad.keyword = null;
    props.clearCallback(model.value);
    optionsModel.value = undefined;
  }
  if (e.originalEvent.type === "click" && !e.value) {
    lazyLoad.keyword = "";
    props.clearCallback(model.value);
  }
  if (e.originalEvent.type === "click" && e.value) {
    // Reset keyword since we're working with an exact selection
    lazyLoad.keyword = "";

    // Find the selected item within the options array
    const selectedItem = options.value
      .flatMap((group) => group.items)
      .find((item) => item.id === e.value);

    // Set initialValues to the selected item only
    if (selectedItem) {
      lazyLoad.initialValues = [selectedItem];
    } else {
      lazyLoad.initialValues = []; // Or handle this case accordingly
    }

    // Trigger the filter function
    filter();
  }
};

const handleAddCustom = async () => {
  const custom = {
    id: -1,
    name: optionsModel.value,
  };
  lazyLoad.keyword = "";
  lazyLoad.initialValues = [custom];
  options.value[0].items.unshift(custom);
  model.value = custom;
  optionsModel.value = custom.id;
  filter();
};

const initInfiniteScroll = () => {
  useInfiniteScroll(
    document.getElementById("dropdown-scroll"),
    () => getData(),
    {
      distance: 10,
    },
  );
};

/* ---------- WATCHERS ---------- */
watch(optionsModel, (value) => {
  const item = options.value
    .flatMap((group) => group.items)
    .find((i) => i.id === value);
  if (item) model.value = item;
});
watch(model, (newVal) => {
  if (newVal === undefined) {
    optionsModel.value = undefined;
    filter();
  }
});
</script>

<style>
.dropdown-scroll {
  max-height: 200px;
  overflow-y: auto;
}
</style>
