<template>
  <Chip :label="client.name" :removable="removable" :pt="{ ...preset, image: '!w-8 !h-8' }"
    :ptOptions="{ mergeProps: true }" @remove="handleRemove" :image="client.image ?? imageFallback">
    <template #removeicon="slotProps">
      <i :class="cn('far fa-times', preset.removeIcon.class)" @click="slotProps.removeCallback"
        @keydown.esc="slotProps.keydownCallback"></i>
    </template>
  </Chip>
</template>

<script lang="ts" setup>
import CustomChipPreset from "@/assets/tailwind-preset/chip/custom-chip";
import { cn } from "@/utils/cn";

const emit = defineEmits(["remove"]);
const props = defineProps({
  client: {
    type: Object,
    required: true,
  },
  imageFallback: {
    type: String
  },
  removable: {
    type: Boolean,
    default: false,
  },
  rootClass: {
    type: String,
  },
});

const preset = {
  ...CustomChipPreset,
  root: {
    ...CustomChipPreset.root,
    class: cn(CustomChipPreset.root.class, props.rootClass),
  },
};

const handleRemove = (e: any) => {
  emit("remove", e);
};
</script>
