<template>
  <section id="content" class="flex border-b border-surface-600 flex-1">
    <section
      id="left"
      :class="
        cn(
          'bg-main-lightBg divide-x-[1px] divide-surface-500 overflow-y-auto scroll-hidden',
          getClass('left'),
        )
      ">
      <div class="">
        <div
          class="border-b-[1px] border-surface-600"
          v-if="contentTypeSelector">
          <Button :button="contentTypeButton" v-if="!form.content_type" />
          <Button
            :button="contentTypeButton"
            v-if="form.content_type"
            @click="handleActive(0, true)" />
        </div>
        <div class="relative">
          <div
            class="absolute top-0 left-0 w-full h-full bg-main-lightBg/70 z-[20]"
            v-if="contentTypeSelector && (!form.content_type || !form.items.some((u: any) => u.valid))"
            id="overlay"></div>
          <div
            class="px-5 py-4 border-b-[1px] border-surface-600"
            v-if="galleryManager">
            <div class="flex flex-col gap-1 relative">
              <label for="title" class="text-main-disabled">
                Gallery Title
              </label>
              <Textarea
                v-model="form.title"
                rows="1"
                autoResize
                maxlength="100"
                placeholder="Add a gallery title*"
                class="resize-none scroll-hidden !bg-white/10 border-[1px] !border-main-darkGray rounded text-white text-sm font-normal overflow-y-auto py-2 pr-14 pl-4 w-full focus:outline-0 focus:ring-0 focus:border-0" />
              <div
                class="absolute bottom-2.5 right-3 text-main-disabled text-xs"
                :class="{ '!text-red-500': form.title?.length === 100 }">
                {{ form.title?.length || 0 }} / 100
              </div>
            </div>
          </div>
          <div class="divide-y-[1px] divide-surface-600">
            <div
              v-for="(button, index) in sideMenu"
              :key="index"
              :class="{
                '!border-b-0': sideMenu[index + 1]?.separator,
                '!border-t-0': sideMenu[index - 1]?.separator,
                '!border-y-0': button.separator,
              }">
              <Button
                :button="button"
                @click="handleActive(index)"
                v-if="!button.separator" />
              <div class="pt-2 pb-2 w-full" v-else>
                <Divider
                  severity="secondary"
                  :pt="{ content: '!bg-main-lightBg' }"
                  :ptOptions="{
                    mergeProps: true,
                  }">
                  <span class="text-main-disabled text-sm uppercase">
                    {{ button.separator }}
                  </span>
                </Divider>
              </div>
            </div>
            <div
              class="flex justify-between items-center px-5 py-3 gap-2 min-h-16">
              <div class="flex flex-col gap-1 flex-1">
                <p class="text-main-disabled text-[16px] font-normal leading-5">
                  Feature content
                  <span
                    class="text-xs"
                    :class="{
                      'text-red-500': LoggedUser.featured_count >= 5,
                    }">
                    ({{ LoggedUser.featured_count }}/5)
                  </span>
                </p>
                <p
                  class="text-main-lightGray text-xs leading-4 max-h-[50px] overflow-hidden text-ellipsis font-light">
                  Pin content to the top of your portfolio
                </p>
              </div>
              <InputSwitch
                v-model="form.is_featured"
                :pt="{ root: '!w-11 !h-6' }"
                :ptOptions="{ mergeProps: true }"
                :disabled="
                  LoggedUser.featured_count >= 5 && !form.is_featured
                " />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      id="right"
      :class="
        cn(
          'bg-title overflow-x-hidden relative scroll-hidden overflow-y-auto',
          getClass('right'),
        )
      ">
      <Transition
        :enter-active-class="'animate-in slide-in-from-right-full'"
        :leave-active-class="'animate-out slide-out-to-left-full'"
        mode="out-in">
        <component
          :is="activeMenu.component"
          v-if="activeMenu != null"
          v-model="form"
          :key="activeMenu.id" />
      </Transition>
    </section>
  </section>
</template>
<script setup lang="ts">
import { computed, markRaw, ref, Transition, watch, type Component } from "vue";
import Button from "@/components/dialogs/content/parts/Button.vue";
import { usePage } from "@inertiajs/vue3";
import GalleryDescription from "@/components/dialogs/content/components/GalleryDescription.vue";
import Categories from "@/components/dialogs/content/components/Categories.vue";
import ContentTags from "@/components/dialogs/content/components/ContentTags.vue";
import Collaborators from "@/components/dialogs/content/components/collaborators/Index.vue";
import ContentOptions from "@/components/dialogs/content/components/ContentOptions.vue";
import ImageGallery from "@/components/dialogs/content/components/ImageGallery.vue";
import VideoGallery from "@/components/dialogs/content/components/VideoGallery.vue";
import { BizCardContentType } from "@/core/enums";
import LinkGallery from "@/components/dialogs/content/components/LinkGallery.vue";
import ProjectDetails from "@/components/dialogs/content/components/ProjectDetails.vue";
import RelatedServices from "@/components/dialogs/content/components/RelatedServices.vue";
import type { UserInterface } from "@/core/interfaces";
import { useDialogStore, DialogType } from "@/store/Dialog";
import type { PropType } from "vue";
import { cn } from "@/utils/cn";
import { onMounted } from "vue";
import { useAuthStore } from "@/store/Auth";
const authStore = useAuthStore();
const dialogStore = useDialogStore();
interface Button {
  id?: String;
  title?: String;
  description?: String;
  icon?: String;
  separator?: String;
  component?: Component;
  active?: Boolean;
}

interface formInterface {
  id: number | null;
  isValid: any;
  content_type: BizCardContentType | null;
  title: string | null;
  description: string | null;
  categories: any[];
  tags: any[];
  project_types: any[];
  collaborators: any[];
  project_roles: any[];
  project_companies: any[];
  project_completed_on: number | null;
  related_services: number[];
  items: any[];
  thumbnail: {
    video: number | null;
    square: number | null;
    default: number | null;
  };
  is_featured: boolean;
}
interface classesInterface {
  [key: string]: string | object;
}
const emit = defineEmits(["close", "setMenu"]);
const props = defineProps({
  contentTypeSelector: {
    type: Boolean,
    default: false,
  },
  galleryManager: {
    type: Boolean,
    default: false,
  },
  classes: {
    type: Object as PropType<classesInterface>,
    default: {},
  },
  initialMenu: {
    type: String,
    default: "content-type-select",
  },
});
/* ---------- REACTIVE DATA ----------*/

const form = defineModel<formInterface>({ required: true });
const LoggedUser = computed(() => authStore.getUser as UserInterface);
const activeMenu = computed(
  () =>
    sideMenu.value.find((button) => button.active) || contentTypeButton.value,
);
const hasSideMenuActive = computed(() =>
  sideMenu.value.some((button) => button.active),
);

const contentTypeButton = computed(() => {
  const contentType = form.value.content_type as BizCardContentType;
  let button: Button = props.contentTypeSelector
    ? {
        id: "content-type-select",
        title: "Content Type",
        description: "Choose the content type",
        active: !form.value.content_type,
        component: markRaw(ContentOptions),
      }
    : {};

  if (contentType) {
    switch (contentType) {
      case BizCardContentType.ImageGallery:
      case BizCardContentType.Image:
        button = {
          title: "Image Gallery",
          icon: "fa fa-images",
          component: markRaw(ImageGallery),
          active: !hasSideMenuActive.value,
        };
        break;
      case BizCardContentType.VideoGallery:
      case BizCardContentType.Video:
        button = {
          title: "Video Gallery",
          icon: "fa fa-clapperboard-play",
          component: markRaw(VideoGallery),
          active: !hasSideMenuActive.value,
        };
        break;
      case BizCardContentType.LinkGallery:
      case BizCardContentType.Link:
        button = {
          title: "Link Gallery",
          icon: "fa fa-link",
          component: markRaw(LinkGallery),
          active: !hasSideMenuActive.value,
        };
        break;
      case BizCardContentType.Collection:
        dialogStore.hide(DialogType.UPLOAD_CONTENT);
        dialogStore.show(DialogType.UPLOAD_CONTENT_COLLECTION);
        dialogStore.setEvents(DialogType.UPLOAD_CONTENT_COLLECTION, {
          close: (id?: number) => {
            dialogStore.hide(DialogType.UPLOAD_CONTENT_COLLECTION);
            if (id !== undefined) {
              dialogStore.show(DialogType.CONTENT_SHARE_POST, id, { id });
            }
          },
        });
        break;
    }
  }
  return button;
});

const sideMenu = ref<Button[]>([
  {
    id: "category",
    title: "Category",
    description: "Sort content into different sub-menus.",
    component: markRaw(Categories),
  },
  {
    id: "collaborators",
    title: "Collaborators",
    description: "Credit and mention those who worked on this.",
    component: markRaw(Collaborators),
  },
  {
    id: "content-tags",
    title: "Content tags",
    description: "Help others discover your content",
    component: markRaw(ContentTags),
  },
  {
    separator: "Advanced details",
  },
  {
    id: "project-details",
    title: "Project details",
    description: "Add details that personalize this page.",
    component: markRaw(ProjectDetails),
  },
  {
    id: "related-services",
    title: "Related services",
    description: "Add this content to a service you have listed.",
    component: markRaw(RelatedServices),
  },
]);

/* ---------- FUNCTIONS ----------*/
onMounted(() => {
  if (props.galleryManager)
    sideMenu.value.unshift({
      id: "description",
      title: "Gallery Description",
      description: "Add a gallery description",
      component: markRaw(GalleryDescription),
    });
  if (props.initialMenu != "content-type-select") {
    const index = sideMenu.value.findIndex(
      (button) => button.id === props.initialMenu,
    );
    handleActive(index);
  }
});
const handleActive = (index: number, isContentType: Boolean = false) => {
  sideMenu.value.forEach((button, i) => {
    if (i === index && !isContentType) {
      if (button.active) {
        //if menu is already active, close it
        button.active = false;
        const defaultIndex = sideMenu.value.findIndex(
          (button) => button.id === props.initialMenu,
        );
        handleActive(defaultIndex);
        emit("setMenu", null);
      } else {
        button.active = true;
        emit("setMenu", button.id);
      }
    } else {
      button.active = false;
    }
  });
};
const getClass = (key: string) => {
  return props.classes[key] || "";
};
/* ---------- WATCHERS ----------*/
watch(
  () => form.value.description,
  (newVal) => {
    sideMenu.value[0] = {
      ...sideMenu.value[0],
      description: newVal,
    };
  },
);
</script>
