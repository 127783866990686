import { toast, type ToastContainerOptions } from "vue3-toastify";

export const useToastr = () => {
    return {
        info(msg: string) {
            toast.info(msg);
        },
        success(msg: string) {
            toast.success(msg);
        },
        error(msg: string) {
            toast.error(msg);
        },
        warn(msg: string) {
            toast.warn(msg);
        },
        closeAll() {
            toast.clearAll();
        },
    };
};
