<template>
  <div
    class="max-w-[1000px] mx-auto w-full flex flex-col items-center gap-[30px] px-0 max-[640px]:px-3"
  >
    <section
      id="images"
      class="flex gap-8 items-start flex-wrap w-screen"
      v-if="content.content_type === 'image_gallery'"
    >
      <div class="py-4 bg-black w-screen overflow-visible">
        <div class="max-w-[1000px] w-full mx-auto">
          <div class="grid_container">
            <div
              class="group relative w-full h-full cursor-pointer"
              v-for="(child, index) in content.children"
              @click="() => handleSelectImage(index)"
            >
              <InfoBallon
                class="!p-1.5 z-50"
                :rounded="true"
                size="3rem"
                position="right"
              >
                <template #icon
                  ><i class="fa-solid fa-up-right-and-down-left-from-center text-md"></i
                ></template>
                <template #count>{{
                  content?.external_url || content?.external_type
                }}</template>
              </InfoBallon>
              <img
                :src="
                  child.thumbnail_image_kit_id ??
                  child.image_kit_id ??
                  child.image_kit_id_16_9 ??
                  child.image_kit_ids?.[0]
                "
                :alt="child.title"
                class="w-full object-cover object-center rounded-lg group-hover:scale-105 transition-all z-10"
              />
              <div
                class="bg-transparent group-hover:bg-black/40 group-hover:scale-105 flex transition-all absolute inset-0 justify-center items-center rounded-lg group z-40"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="w-screen bg-black py-4" v-else>
      <div class="">
        <div
          class="max-w-[1000px] mx-auto flex items-center justify-center aspect-video overflow-hidden"
        >
          <div
            class="relative group w-max h-full cursor-pointer hover:scale-105 transition-all"
            @click="ImageGalleryDialogVisible = true"
          >
            <img
              :src="
                content.image_kit_id ??
                content.thumbnail_image_kit_id ??
                content.image_kit_id_16_9 ??
                content.image_kit_ids?.[0]
              "
              alt=""
              class="rounded-[16px] object-contain w-max h-full transition-all"
            />
            <div
              class="z-20 absolute inset-0 bg-black/30 opacity-0 group-hover:opacity-100 transition-opacity"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <h1 class="w-full text-start text-xl font-bold mb-2">
      {{ content.title }}
    </h1>
    <p
      v-if="content.description"
      class="w-full text-sm line-clamp-3 whitespace-pre-line"
      ref="clamped"
      :class="{ 'line-clamp-none': ReadmoreToggle }"
    >
      <span v-html="content.description" ref="full"></span>
    </p>
    <span
      v-if="Readmore"
      class="text-blue-400 cursor-pointer"
      @click="ReadmoreToggle = !ReadmoreToggle"
      >Read {{ ReadmoreToggle ? "less" : "more" }} ...</span
    >
    <hr class="bg-main-divider h-[1px] w-full" />
    <div class="flex justify-between w-full gap-[40px] flex-wrap">
      <section
        class="space-y-4 w-full lg:max-w-[530px]"
        id="left"
        v-if="showProjectDetails || content.tags.length"
      >
        <ProjectDetails :content="content" v-if="showProjectDetails" />
        <Tags :tags="content.tags" v-if="content.tags.length" />
      </section>
      <section
        class="flex flex-col gap-4 w-full"
        id="right"
        :class="{
          'lg:max-w-[400px]': showProjectDetails || content.tags.length,
        }"
      >
        <Collaborators
          v-if="collaborators.length || content.owner_roles?.length"
          :collaborators="collaborators"
          :owner="content.owner_roles?.length ? content.bizcard.user : null"
          :ownerRoles="content.owner_roles"
        />
        <Publisher
          :published="content.created_on"
          :publisher="content.bizcard.user"
          :full="!showProjectDetails && !content.tags.length"
        />
      </section>
    </div>
  </div>
  <ImageGalleryDialog
    :content="content"
    :selected="selectedImage"
    v-if="ImageGalleryDialogVisible"
    @close="ImageGalleryDialogVisible = false"
  />
</template>
<script setup>
import { reactive, ref, computed, onMounted, watch } from "vue";
import ImageGalleryDialog from "@/components/dialogs/PostContent/ImageGallery.vue";
import UserCard from "@/components/general/UserCard.vue";
import Publisher from "../parts/Publisher.vue";
import Collaborators from "../parts/Collaborators.vue";
import ProjectDetails from "../parts/ProjectDetails.vue";
import Tags from "../parts/Tags.vue";
import InfoBallon from "@/components/parts/InfoBallon.vue";
const props = defineProps({ content: { required: true, type: Object } });
const clamped = ref();
const full = ref();
const Readmore = ref(false);
const ReadmoreToggle = ref(false);
const collaborators = computed(
  () => props.content?.collaborators?.filter((c) => c.accepted_on) ?? []
);
onMounted(() => {
  if (clamped.value && clamped.value.offsetHeight < full.value.offsetHeight) {
    Readmore.value = true;
  }
});
const ImageGalleryDialogVisible = ref(false);
const selectedImage = ref();
const showProjectDetails = computed(
  () =>
    !!props.content.project_types?.length ||
    !!props.content.organizations?.length ||
    props.content.completion_year
);

const handleSelectImage = (idx) => {
  selectedImage.value = idx;
  ImageGalleryDialogVisible.value = true;
};
</script>
<style scoped>
.grid_container {
  -moz-column-gap: 10px;
  column-gap: 20px;
  -moz-columns: 3;
  -moz-column-width: 300px;
  columns: 300px 3;
  margin: 20px auto;
  max-width: 1100px;
  width: 100%;
}
</style>
