<template>
  <InvitationDialog v-if="visible" :visible="visible" @close="visible = false" />
  <Card class="px-[20px] py-[16px] h-auto max-h-[250px] w-full my-0">
    <template #content>
      <div class="flex flex-col gap-2">
        <div class="text-primary text-md font-medium leading-4">
          Boost the bizlyCommunity! 🌟
        </div>
        <p class="text-text-700 font-normal text-[14px] leading-5">
          Invite a friend and together, let's grow stronger.
        </p>
        <Button
          label="Invite your network"
          :pt="{
            root:
              '!bg-switcherBlue-500/10 !text-primaryBlue-500 border-0 hover:!bg-switcherBlue-600 hover:!text-white w-full py-2.5 text-sm',
          }"
          :ptOptions="{ mergeProps: true }"
          @click="visible = true"
        ></Button>
      </div>
    </template>
  </Card>
</template>
<script lang="ts" setup>
import InvitationDialog from "@/components/dialogs/invitations/Main.vue";
import { ref } from "vue";

const visible = ref(false);
</script>
