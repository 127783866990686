import baseApi from "./Base";
import { route } from "ziggy-js";

export class BizcardService extends baseApi {
  async updateLinks(links: any) {
    return await this.request(route("user.bizcard.links"), "POST", { links });
  }

  async getContents(user: number, payload: any) {
    return await this.request(
      route("user.profile.contents", user),
      "GET",
      payload,
    );
  }
  async getManageContents(payload: any) {
    return await this.request(route("user.contents.manage"), "GET", payload);
  }
  async updateManageContents(payload: any) {
    let contents = [...payload.featured, ...payload.unfeatured];
    contents = contents.map((c) => ({
      id: c.id,
      is_featured: c.is_featured,
    }));
    return await this.request(route("user.contents.update"), "POST", {
      contents,
    });
  }
}
