<template>
  <Layout
    :header="{
      title: props.user.full_name,
      subtitle: props.user.roles.map((v) => v.name),
      user: props.user,
    }">
    <template #subheaderActions>
      <CircleIcon
        @click="ContentDialogVisible = true"
        v-if="is_editing"
        size="2.5rem"
        class="bg-main-secondary hover:bg-main-secondary/80">
        <i class="fa-regular fa-circle-plus text-white"></i>
      </CircleIcon>
      <CircleIcon
        v-if="bizCardStore.own_profile"
        @click="bizCardStore.isEditing = !bizCardStore.isEditing"
        size="2.5rem"
        class="bg-main-secondary hover:bg-main-secondary/80"
        :class="{ 'bg-switcherBlue-500 hover:bg-switcherBlue-500/80': is_editing }">
        <i class="fa-regular fa-pen text-white"></i>
      </CircleIcon>
      <CircleIcon
        @click="sharePortfolio"
        size="2.5rem"
        class="bg-main-secondary hover:bg-main-secondary/80">
        <i class="fa-regular fa-share text-white"></i>
      </CircleIcon>
      <CircleIcon
        @click="toggleActionsMenu"
        v-if="is_editing"
        size="2.5rem"
        class="bg-main-secondary hover:bg-main-secondary/80">
        <i class="fa-regular fa-ellipsis-h text-white"></i>
      </CircleIcon>
    </template>
    <template #customHeader v-if="contentFeed.length > 0 && !isLoading">
      <CategoriesHeader
        :categories="categoriesList"
        v-model="selectedCategory"></CategoriesHeader>
    </template>
    <div
      :class="
        cn(
          'h-[calc(100vh-100px)] overflow-y-auto overscroll-contain scroll-smooth scroll-hidden',
          {
            'overscroll-auto':
              bizCardStore.portfolioFeed.lazyLoad.no_more_results,
          },
        )
      "
      v-infinite-scroll="[handleScroll, { distance: 10 }]"
      v-if="!isLoading">
      <div v-if="contentFeed.length > 0">
        <Header v-bind="props" :category="selectedCategory" class=""></Header>
        <section class="flex flex-col gap-8 w-full items-center pt-8">
          <div
            v-for="content in contentFeed"
            :key="content.id"
            class="flex flex-col gap-2 w-full max-w-[900px]">
            <div v-if="content.content_type !== 'collection'">
              <div class="flex justify-between items-center">
                <div class="flex flex-col gap-2">
                  <h1 class="text-2xl font-bold">{{ content.title }}</h1>
                  <Roles
                    v-if="
                      content.owner_roles && content.owner_roles.length !== 0
                    "
                    :items="content.owner_roles.map((v) => v.name)"
                    :fontSize="'12px'"
                    :textColor="'#fff'"
                    :separatorWidth="'4px'"
                    :separatorHeight="'4px'"
                    :separatorColor="'#8c8c8c'" />
                </div>
                <i
                  v-if="is_editing"
                  class="fa-solid fa-ellipsis-vertical text-main-disabled text-2xl p-2 cursor-pointer"
                  @click="(e) => toggleMenu(e, content)"></i>
              </div>
            </div>
            <component
              :is="cardMap[content.content_type]"
              :content="content"
              class="!p-0" />
          </div>
        </section>
      </div>
      <div v-else class="h-full w-full mx-auto max-w-[300px] flex flex-col items-center justify-center">
        <img :src="NoDataPortfolio" alt="No Data Portfolio" class="w-[300px]">
        <span class="text-lg text-primary-400 text-center">
            It looks like
          {{
            props.user.id === authUser.id
              ? "you have"
              : props.user.first_name +
                " " +
                props.user.last_name +
                " has "
          }}
          not uploaded any content yet.</span>
      </div>
    </div>
    <div
      v-else
      class="h-screen w-full flex flex-col items-center bg-main-lightBg">
      <div class="flex items-center h-screen">
        <BlockUI :blocked="true">
          <template #default>
            <div
              class="fixed inset-0 flex items-center justify-center z-[9999]">
              <div class="flex flex-col items-center justify-center">
                <i class="fad fa-spinner-third fa-spin fa-3x text-white"></i>
                <div class="text-white mt-4">Loading...</div>
              </div>
            </div>
          </template>
        </BlockUI>
      </div>
    </div>
    <ManageContent
      v-if="selectedContent"
      :content="selectedContent"
      ref="manageMenu"
      @updateContent="handleUpdateContent" />
    <ContentDialog
      v-if="ContentDialogVisible"
      @close="ContentDialogVisible = false" />
    <ManageCategories
      v-if="ManageCategoriesVisible"
      @close="ManageCategoriesVisible = false"
      @update="handleCategoriesUpdate" />
    <ManagePortfolio
      v-if="ManagePortfolioVisible"
      @close="ManagePortfolioVisible = false" />
    <Menu
      v-if="is_editing"
      id="ACTIONS_MENU"
      :model="ACTIONS"
      class="w-[21rem] content_menu overflow-hidden shadow-[0_2px_15px_0_rgba(0,0,0,0.3)]"
      ref="ACTIONS_MENU"
      :popup="true"
      :pt="{
        root: 'min-w-[12rem] rounded-md bg-surface-0 text-surface-700 p-2',
        content: 'hover:!bg-white divide-y divide-main-divider',
      }">
      <template #item="{ item }">
        <button :class="item.className" @click="item.clickEvent">
          <h1 class="font-semibold text-sm">{{ item.title }}</h1>
          <p class="text-xs text-main-secondary">{{ item.description }}</p>
        </button>
      </template>
    </Menu>
  </Layout>
</template>
<script setup lang="ts">
import { ref, computed, onMounted, type PropType } from "vue";
import {
  type BizCardInterface,
  type ContentCategoryInterface,
  type ContentInterface,
  type UserInterface,
} from "@/core/interfaces";
import ContentDialog from "@/components/dialogs/content/Main.vue";
import { cn } from "@/utils/cn";
import { useUserBizcardStore, FeedType } from "@/store/UserBizcard";
import { vInfiniteScroll } from "@vueuse/components";
import Layout from "@/components/bizcard/home/Layout.vue";
import CategoriesHeader from "@/components/bizcard/portfolio/CategoriesHeader.vue";
import Header from "./Header.vue";
import LinkCard from "@/components/bizcard/portfolio/cards/Link.vue";
import ImageCard from "@/components/bizcard/portfolio/cards/Image.vue";
import VideoCard from "@/components/bizcard/portfolio/cards/Video.vue";
import CollectionCard from "@/components/bizcard/portfolio/cards/Collection.vue";
import Roles from "@/components/general/Roles.vue";
import _debounce from "lodash/debounce";
import ManageContent from "@/components/menus/ManageContent.vue";
import { onUnmounted } from "vue";
import type { Component } from "vue";
import { watch } from "vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { useToastr } from "@/composables/useToastr";
import ManageCategories from "@/components/dialogs/bizcard/portfolio/ManageCategories.vue";
import ManagePortfolio from "@/components/dialogs/bizcard/portfolio/ManagePortfolio.vue";
import { route } from "ziggy-js";
import NoDataPortfolio from "@/assets/images/upload_content_icon/NoDataPortfolio.svg";
import { useAuthStore } from "@/store/Auth";

const { success } = useToastr();
/** --------- PROPS -------- */
const props = defineProps({
  card: {
    required: true,
    type: Object as PropType<BizCardInterface>,
  },
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
  categories: {
    required: true,
    type: Array as PropType<ContentCategoryInterface[]>,
  },
});

/** --------- STORES --------- */
const bizCardStore = useUserBizcardStore();
const authStore = useAuthStore();

/** --------- REACTIVE -------- */
const contentFeed = computed<ContentInterface[]>(
    () => bizCardStore.portfolioFeed.data || [],
);
const is_editing = computed(() => bizCardStore.isEditing);
const isLoading = computed(() => bizCardStore.portfolioFeed.lazyLoad.loading);
const authUser = computed(() => authStore.user as UserInterface);
const selectedCategory = ref<ContentCategoryInterface | null>(null);
const categoriesList = ref([]);
const ContentDialogVisible = ref(false);
const ManagePortfolioVisible = ref(false);
const ManageCategoriesVisible = ref(false);
const selectedContent = ref(null);
const manageMenu = ref();
const ACTIONS_MENU = ref();
const cardMap: Record<string, Component> = {
  link: LinkCard,
  link_gallery: LinkCard,
  image: ImageCard,
  image_gallery: ImageCard,
  video: VideoCard,
  video_gallery: VideoCard,
  collection: CollectionCard,
};
const ACTIONS = computed(() => [
  {
    title: "Manage portfolio",
    description: "Arrange the way your content is ordered.",
    className:
      "rounded-md px-1 py-3 w-full bg-white hover:bg-switcherBlue-500/20 transition-all flex items-start justify-center flex-col",
    clickEvent: () => {
      ManagePortfolioVisible.value = true;
    },
  },
  {
    title: "Manage categories",
    description: "Manage your portfolio’s sub-menu items.",
    className:
      "rounded-md px-1 py-3 w-full hover:bg-switcherBlue-500/20 transition-all flex items-start justify-center flex-col",
    clickEvent: () => {
      ManageCategoriesVisible.value = true;
    },
  },
]);
/** --------- METHODS -------- */
const handleScroll = async () => {
  await bizCardStore.loadFeed(props.user.id, FeedType.CONTENT);
};
const toggleMenu = (event, content) => {
  selectedContent.value = content;
  if (manageMenu.value) {
    manageMenu.value.toggle(event);
  }
};
const toggleActionsMenu = (event) => {
  if (ACTIONS_MENU.value) ACTIONS_MENU.value.toggle(event);
};

const handleCategoriesUpdate = (e) => {
  categoriesList.value = e;
};
const handleUpdateContent = (content) => {
  bizCardStore.editContent(content, FeedType.CONTENT);
};
const sharePortfolio = async () => {
  const permissionStatus = await navigator.permissions.query({
    name: "clipboard-write",
  });
  if (
    permissionStatus.state === "granted" ||
    permissionStatus.state === "prompt"
  ) {
    const url = route(
      "user.profile.portfolio",
      bizCardStore.user.public_username,
    );
    await navigator.clipboard.writeText(url);
    success(`${url} copied to clipboard!`);
  }
};
/** --------- WATCHERS -------- */
watch(selectedCategory, (newVal) => {
  bizCardStore.resetFeed(FeedType.CONTENT);
  bizCardStore.setFilters(
    FeedType.CONTENT,
    newVal != null ? { category: newVal?.id } : {},
  );
  bizCardStore.loadFeed(props.user.id, FeedType.CONTENT);
});
/** --------- LIFECYCLE HOOKS -------- */
onMounted(() => {
  categoriesList.value = props.categories;
  bizCardStore.init(props.card, props.user);
  if (!bizCardStore.isInitialized(FeedType.CONTENT))
    bizCardStore.loadFeed(props.user.id, FeedType.CONTENT);
});
</script>
