<template>
  <div class="flex flex-col justify-start">
    <div
      class="py-[30px] px-[3.5rem] flex flex-col sticky top-0 shadow-lg z-10 bg-title">
      <div class="flex flex-col w-full gap-2.5">
        <div class="flex flex-col gap-2" v-for="(link, index) in form.items">
          <div class="flex items-center gap-4 w-full">
            <div
              :class="
                cn(
                  'h-[2.75rem] p-1 flex gap-1 items-center rounded bg-white/5 border border-surface-700 text-white focus:!ring-0 min-h-[40px] w-full',
                  {
                    'border-red-500': link?.valid === false,
                    'border-switcherBlue-500': link?.valid === true,
                  },
                )
              ">
              <img
                :src="link?.thumbnail.default"
                alt=""
                v-if="link?.valid === true && link?.thumbnail.default"
                class="rounded aspect-video object-cover h-full w-auto" />

              <InputText
                @input="() => handleGetLink(index)"
                v-model="form.items[index].url"
                type="text"
                @focusin="handleMouseEnter"
                @focusout="handleMouseLeave"
                placeholder="Paste or type a link from the web here"
                class="bg-transparent !border-0 text-white focus:!ring-0 flex-1 h-full" />
              <i
                :class="{
                  'fa fa-circle-check !text-switcherBlue-500 text-lg':
                    link?.valid === true && link?.loading === false,
                  'fa fa-circle-x !text-red-500 text-lg':
                    link?.valid === false && link?.loading === false,
                  'fa fa-spin fa-spinner-third text-white': link?.loading,
                }"
                class="text-white"></i>
            </div>

            <i
              v-if="form.items.length > 1"
              class="fa fa-circle-x text-white cursor-pointer"
              @click="() => removeOption(index)"></i>
          </div>
          <span
            class="text-red-500 text-[0.75rem]"
            v-if="link?.valid === false && link?.error_message">
            {{ link?.error_message }}
          </span>
        </div>
        <Button
          @click="handleAdd"
          type="button"
          icon="far fa-plus-circle text-lg !mr-3"
          label="Add another link"
          outlined
          severity="secondary"
          class="!text-left !rounded !text-sm hover:bg-white/5"
          :disabled="AddLinkDisabled" />
      </div>
    </div>

    <div class="flex flex-col gap-4 self-center w-full px-12 py-4">
      <div
        class="rounded-lg"
        v-for="(url, index) in validUrls"
        v-if="editing === null">
        <div
          :class="
            cn('aspect-video  relative w-full', {
              'rounded-t-lg': validUrls.length > 1,
              'rounded-lg': validUrls.length === 1,
            })
          ">
          <img
            :class="
              cn('aspect-video object-cover w-full h-full', {
                'rounded-t-lg': validUrls.length > 1,
                'rounded-lg': validUrls.length === 1,
              })
            "
            v-if="url?.thumbnail.default"
            :src="url?.thumbnail.default"
            alt="" />
          <LinkNoImage v-else @upload="(e) => replaceThumbnail(e, index)" />
          <div class="absolute top-0 left-0">
            <InfoBallon>
              <template #icon>
                <i class="fa fa-chain text-white"></i>
              </template>
              <template #count v-if="validUrls.length > 1">
                {{ index + 1 }}/{{ validUrls.length }}
              </template>
            </InfoBallon>
          </div>
          <div class="absolute top-0 right-10">
            <FileUpload
              mode="advanced"
              name="demo[]"
              url="/api/upload"
              accept="image/*"
              customUpload
              :auto="true"
              @uploader="(e) => replaceThumbnail(e, index)">
              <template #header="{ chooseCallback }">
                <InfoBallon
                  position="right"
                  @click="chooseCallback"
                  class="cursor-pointer">
                  <template #icon>
                    <i class="fa fa-pencil text-white"></i>
                  </template>
                </InfoBallon>
              </template>
            </FileUpload>
          </div>
          <div class="absolute top-0 right-0">
            <InfoBallon
              position="right"
              @click="
                () => {
                  editing = index;
                  cropperLoading = true;
                }
              "
              class="cursor-pointer">
              <template #icon>
                <i class="fa fa-crop-simple text-white"></i>
              </template>
            </InfoBallon>
          </div>
        </div>

        <div
          class="flex flex-col divide-y-[1px] divide-surface-500 bg-main-lightBg rounded-b-lg"
          v-if="validUrls.length > 1">
          <div class="flex flex-col px-5 py-2.5">
            <h3 class="text-[10px] text-main-disabled uppercase">Link title</h3>
            <input
              class="bg-transparent text-base font-semibold text-white border-0 ring-0 outline-0 focus:border-0 focus:ring-0"
              type="text"
              v-model="url.title" />
          </div>
          <div
            class="flex flex-col px-5 py-2.5 cursor-pointer"
            @click="() => openDescriptionDialog(url)">
            <h3 class="text-[10px] text-main-disabled uppercase">
              Link description
            </h3>
            <p class="text-base font-semibold text-white border-0 truncate-2">
              {{ url.description }}
            </p>
          </div>
        </div>
      </div>
      <div class="relative" v-else>
        <div
          class="absolute z-50 inset-0 cropper aspect-video w-full h-full flex items-center justify-center rounded-[8px] object-cover text-white"
          v-if="cropperLoading">
          <i
            class="fa-sharp-duotone fa-solid fa-spinner-third animate-spin text-xl"></i>
        </div>
        <cropper
          ref="cropperEl"
          @ready="cropperLoading = false"
          class="cropper aspect-video object-cover w-full h-full"
          :src="validUrls?.[editing]?.thumbnail?.default"
          :stencil-props="{
            aspectRatio: 16 / 9,
          }"></cropper>
        <div class="flex justify-end items-center gap-4">
          <button
            @click="editing = null"
            class="mt-4 border-white text-white rounded text-xs w-[100px] h-[40px] disabled:bg-transparent disabled:hover:bg-transparent bg-red-600 disabled:hover:border-[1px] border-0 disabled:border-main-disabled">
            Cancel
          </button>
          <button
            @click="handleCrop"
            class="mt-4 border-white text-white rounded text-xs w-[100px] h-[40px] disabled:bg-transparent disabled:hover:bg-transparent bg-switcherBlue-500 disabled:hover:border-[1px] border-0 disabled:border-main-disabled">
            Save
          </button>
        </div>
      </div>
    </div>
    <DescriptionDialog
      @close="dialogStore.hide(DialogType.UPLOAD_CONTENT_CHILD_DESCRIPTION)"
      v-if="dialogStore.isOpened(DialogType.UPLOAD_CONTENT_CHILD_DESCRIPTION)"
      v-model="
        dialogStore[DialogType.UPLOAD_CONTENT_CHILD_DESCRIPTION].data
          .description
      " />
  </div>
</template>

<script setup lang="ts">
import { useForm } from "@inertiajs/vue3";
import { API } from "@/core/api";
import _debounce from "lodash/debounce";
import { computed, ref, watch } from "vue";
import { cn } from "@/utils/cn";
import InfoBallon from "@/components/parts/InfoBallon.vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { route } from "ziggy-js";
import { useDialogStore, DialogType } from "@/store/Dialog";
import DescriptionDialog from "@/components/dialogs/content/components/Description.vue";
import LinkNoImage from "./LinkNoImage.vue";
const dialogStore = useDialogStore();
const ContentAPI = new API.Content();

interface Item {
  title: string;
  description: string;
  url: string;
  thumbnail: {
    default?: string;
    square?: string;
    video?: string;
  };

  valid?: boolean | null;
  error_message?: string | null;
  loading?: boolean;
}

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
});

/* ---------- REACTIVE DATA ---------- */
const cropperEl = ref(true);
const cropperLoading = ref(false);
const showHelpHeader = ref(false);
const hovering = ref(false);
const editing = ref<number | null>(null);
const form = useForm<{
  items: Item[];
  thumbnail: {
    default: string | null;
    square: string | null;
    video: string | null;
  };
}>({
  items: props.modelValue.items.length
    ? props.modelValue.items
    : [
        {
          title: "",
          description: "",
          url: "",
          thumbnail: {
            default: "",
            square: null,
            video: null,
          },
          duration: "",
        },
      ],
  thumbnail: props.modelValue.thumbnail,
});
const validUrls = computed(() => form.items.filter((v) => v.valid));
const AddLinkDisabled = computed(() =>
  form.items.some((v: any) => v.valid === false || v.valid === undefined),
);

/* ---------- FUNCTIONS  ---------- */
const handleMouseEnter = () => {
  if (!hovering.value) {
    showHelpHeader.value = true;
    hovering.value = true;
  }
};

const handleMouseLeave = () => {
  showHelpHeader.value = false;
  hovering.value = false;
};

const handleGetLink = _debounce(async (index) => {
  const obj: Item = form.items[index];
  const url = obj.url;
  obj.loading = true;

  // Check if the URL is already in another index
  const duplicateUrl = form.items.some(
    (v, i) => v.url === obj.url && i !== index,
  );
  if (duplicateUrl) {
    obj.valid = false;
    obj.error_message = "This link is duplicated.";
    obj.loading = false;
    return;
  }
  // Proceed only if the URL is not already checked and valid
  if (!obj.url) {
    obj.loading = false;
    obj.valid = null;
    obj.error_message = null;
    return;
  }

  try {
    let response = await ContentAPI.getLinkMeta(url);
    if (response.error) throw new Error(response.error);
    response = response.data;
    updateData(obj, response, url);
    obj.loading = false;
  } catch (error) {
    obj.valid = false;
    obj.error_message =
      "This link is private or unavailable. Please try another.";
    obj.loading = false;
    return;
  }
}, 250);

const updateData = (urlObject: Item, response: any, url: any) => {
  try {
    urlObject.title = response?.title ?? "";
    urlObject.description = response?.description ?? "";
    urlObject.thumbnail = {
      default: getMaxResThumbnail(response?.image, urlObject) ?? "",
      square: getMaxResThumbnail(response?.image, urlObject) ?? "",
      video: getMaxResThumbnail(response?.image, urlObject) ?? "",
    };
    urlObject.valid = true;
    urlObject.url = url;
  } catch (error) {
    console.error(error);
  }
};

const removeOption = (index: number) => {
  try {
    const OriginalUrl = form.items[index];
    const is_duplicate_idx = form.items.findIndex(
      (u) => u.url === OriginalUrl.url && u.valid === false,
    );
    form.items.splice(is_duplicate_idx !== -1 ? is_duplicate_idx : index, 1);
  } catch (err) {
    console.error(err);
  }
};

const handleAdd = () => {
  form.items.push({
    title: "",
    description: "",
    url: "",
    thumbnail: {
      default: "",
    },
  });
};

const getMaxResThumbnail = (url: any, URL) => {
  URL.valid = false;
  return url ? route("images.fetch") + `?url=${url}` : null;
};

const handleCrop = () => {
  const { canvas } = cropperEl.value.getResult();
  if (canvas) {
    const base64 = canvas.toDataURL();
    if (editing.value !== null) {
      const item = form.items[editing.value];
      props.modelValue.thumbnail = {
        default:
          item.thumbnail.default === props.modelValue.thumbnail.default
            ? base64
            : props.modelValue.thumbnail.default,
        square:
          item.thumbnail.square === props.modelValue.thumbnail.square
            ? base64
            : props.modelValue.thumbnail.square,
        video:
          item.thumbnail.video === props.modelValue.thumbnail.video
            ? base64
            : props.modelValue.thumbnail.video,
      };
      form.items[editing.value].thumbnail = {
        square: base64,
        video: base64,
        default: base64,
      };
      validUrls.value[editing.value].thumbnail = {
        square: base64,
        video: base64,
        default: base64,
      };
    }
  }
  editing.value = null;
};

// testing
const replaceThumbnail = (event: any, index: number) => {
  const file = event.files[0];
  const reader = new FileReader();
  reader.onload = function (e: any) {
    const item = form.items[index];

    props.modelValue.thumbnail = {
      default:
        item.thumbnail.default === props.modelValue.thumbnail.default
          ? e.target.result
          : props.modelValue.thumbnail.default,
      square:
        item.thumbnail.square === props.modelValue.thumbnail.square
          ? e.target.result
          : props.modelValue.thumbnail.square,
      video:
        item.thumbnail.video === props.modelValue.thumbnail.video
          ? e.target.result
          : props.modelValue.thumbnail.video,
    };
    form.items[index].thumbnail = {
      square: e.target.result,
      video: e.target.result,
      default: e.target.result,
    };
  };
  props.modelValue.items[index].valid = true;

  reader.readAsDataURL(file);
};
/* ---------- WATCHERS ---------- */
watch(form.items, (newVal) => {
  if (
    newVal[0].valid &&
    !props.modelValue.title &&
    !props.modelValue.description
  ) {
    props.modelValue.title = newVal[0].title;
    props.modelValue.description = newVal[0].description;
    if (!props.modelValue.thumbnail.default) {
      props.modelValue.thumbnail = {
        default: newVal[0].thumbnail.default,
        square: newVal[0].thumbnail.default,
        video: newVal[0].thumbnail.default,
      };
    }
  }
  props.modelValue.items = newVal;
});

const openDescriptionDialog = (data) => {
  dialogStore.show(
    DialogType.UPLOAD_CONTENT_CHILD_DESCRIPTION,
    undefined,
    data,
  );
};
</script>
