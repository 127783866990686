<template>
    <div class="w-full flex justify-center">
        <div class="grid grid-cols-2 gap-4" v-if="item.items.length > 1">
            <div class="aspect-video relative flex flex-col gap-4 w-full" v-for="(child, index) in item.items">
                <div class="relative group cursor-pointer w-full shadow-default"
                    @click="() => handleSelectVideo(index)">
                    <InfoBallon class="!py-0.5">
                        <template #icon><i class="fal fa-clapperboard-play text-md"></i></template>
                        <template #count>{{
                            child?.duration
                            }}</template>
                    </InfoBallon>
                    <img :src="child.thumbnail.video" :alt="child.title"
                        class="w-full object-cover object-center rounded-lg" />
                    <div
                        class="bg-transparent group-hover:bg-black/40 flex transition-all absolute inset-0 justify-center items-center rounded-lg group">
                        <i class="fa fa-play text-white text-5xl group-hover:block hidden"></i>
                    </div>
                </div>
                <div class="">
                    <h1 class="font-semibold text-base text-black m-0 overflow-hidden text-ellipsis whitespace-nowrap">
                        {{ child.title }}
                    </h1>
                    <p class="font-normal text-sm text-[#4a4a4b] m-0 overflow-hidden text-ellipsis whitespace-nowrap">
                        {{ child.description }}
                    </p>
                </div>
            </div>
        </div>
        <div class="max-h-[450px] relative" v-else>
            <img class="object-cover max-h-[450px] rounded-lg" :src="item.items[0].thumbnail.video" alt="Image">
            <CircleIcon class="bg-black/50 text-white absolute top-3 left-3 !cursor-default" size="2rem">
                <i class="fa-thin fa-clapperboard-play"></i>
            </CircleIcon>
        </div>
    </div>
    <VideoGalleryDialog :content="contentToDB(item)" :selected="selectedVid" v-if="VideoGalleryDialogVisible"
        @close="VideoGalleryDialogVisible = false" />
</template>

<script setup lang="ts">
import { ref } from 'vue';
import VideoGalleryDialog from "@/components/dialogs/PostContent/VideoGallery.vue";
import CircleIcon from '@/components/icons/CircleIcon.vue';
import InfoBallon from '@/components/parts/InfoBallon.vue';
import { contentToDB } from "@/components/dialogs/content/helpers/contentToDB";
const props = defineProps({
    item: {
        type: Object,
        required: true
    }
})

const VideoGalleryDialogVisible = ref(false);
const selectedVid = ref();

const handleSelectVideo = (idx: number) => {
    selectedVid.value = idx;
    VideoGalleryDialogVisible.value = true;
};
</script>
