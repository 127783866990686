export default {
  root: ({ props, attrs }) => ({
    class: [
      "relative",

      // Alignment
      "inline-flex",
      "align-bottom",

      // Size
      {
        "size-[14px]": attrs.size === "sm",
        "size-[18px]": !attrs.size,
        "size-[24px]": attrs.size === "lg",
      },

      // Misc
      "cursor-pointer",
      "select-none",
    ],
  }),
  box: ({ props, context, attrs }) => ({
    class: [
      // Alignment
      "flex",
      "items-center",
      "justify-center",

      // Sizes
      {
        "size-[14px]": attrs.size === "sm",
        "size-[18px]": !attrs.size,
        "size-[24px]": attrs.size === "lg",
      },

      // Shape
      "rounded-full",
      "border",

      // Colors
      {
        "border-surface-200": !context.checked && !props.invalid,
        "bg-surface-100": !attrs.outlined && !context.checked,
        "bg-transparent": attrs.outlined && !context.checked,
      },

      // Invalid State
      { "border-red-500 dark:border-red-400": props.invalid },

      // States
      {
        "cursor-default opacity-60": props.disabled,
      },

      // PrimaryBlue
      {
        "border-primaryBlue-500 bg-primaryBlue-500":
          context.checked && !attrs.color,
        "peer-hover:border-primary":
          !props.disabled && !context.checked && !props.invalid && !attrs.color,
        "peer-hover:bg-primary-hover peer-hover:border-primary-hover":
          !props.disabled && context.checked && !attrs.color,
        "peer-focus-visible:border-primary-500 dark:peer-focus-visible:border-primary-400 peer-focus-visible:ring-2 peer-focus-visible:ring-primary-400/20 dark:peer-focus-visible:ring-primary-300/20":
          !props.disabled && !attrs.color,
      },

      // PrimaryBlue
      {
        "border-switcherBlue-500 bg-switcherBlue-500":
          context.checked && attrs.color === "lightBlue",
        "peer-hover:border-switcherBlue-500":
          !props.disabled &&
          !context.checked &&
          !props.invalid &&
          attrs.color === "lightBlue",
        "peer-hover:bg-switcherBlue-600 peer-hover:border-switcherBlue-600":
          !props.disabled && context.checked && attrs.color === "lightBlue",
        "peer-focus-visible:border-switcherBlue-500 dark:peer-focus-visible:border-switcherBlue-500 peer-focus-visible:ring-2 peer-focus-visible:ring-switcherBlue-500/20 dark:peer-focus-visible:ring-switcherBlue-500/10":
          !props.disabled && attrs.color === "lightBlue",
      },
      // Transitions
      "transition-colors",
      "duration-200",
    ],
  }),
  input: {
    class: [
      "peer",

      // Size
      "w-full ",
      "h-full",

      // Position
      "absolute",
      "top-0 left-0",
      "z-10",

      // Spacing
      "p-0",
      "m-0",

      // Shape
      "opacity-0",
      "rounded-md",
      "outline-none",
      "border-2 border-surface-200 dark:border-surface-700",

      // Misc
      "appearance-none",
      "cursor-pointer",
    ],
  },
  icon: {
    class: [
      // Font
      "text-base leading-none",

      "p-0.5",
      // Size
      "w-4",
      "h-4",

      // Colors
      "text-white dark:text-surface-900",

      // Transitions
      "transition-all",
      "duration-200",
    ],
  },
};
