<template>
  <span class="relative w-full">
    <span class="absolute top-2/4 -mt-2.5 right-3 z-10 cursor-pointer" @click="show = !show">
      <span v-if="show">
        <i class="far fa-eye text-primary-500"></i>
      </span>
      <span v-else>
        <i class="far fa-eye-slash text-surface-500"></i>
      </span>
    </span>
    <InputText :placeholder="props.placeholder" v-model="password" :type="!show ? 'password' : 'text'"
      class="pr-10 text-sm w-full" :invalid="props.invalid" :tabindex="props.tabindex" />
  </span>
</template>
<script setup>
import { ref } from "vue";
const password = defineModel();
const props = defineProps({
  placeholder: {
    type: String,
  },
  invalid: {
    type: Boolean,
    default: false,
  },
  tabindex: {
    type: Number,
  }
});
const show = ref(false);
</script>
