<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-0 w-full flex-1 flex flex-col ',
      root: 'bg-main-divider w-full h-screen scroll-hidden',
    }"
    :visible="true"
    modal
    :closable="false">
    <section
      class="p-2 bg-main-lightBg text-white border-b-[1px] border-surface-500 sticky top-0 z-20">
      <div class="flex justify-between items-center">
        <h1 class="font-poppins text-xl flex-1 text-center">Collection</h1>
        <CircleIcon class="bg-main-secondary" @click="close">
          <i class="fa fa-x"></i>
        </CircleIcon>
      </div>
    </section>
    <div class="flex h-[calc(100vh-50px)]">
      <div class="h-screen overflow-y-auto scroll-hidden flex-none">
        <div
          :class="
            cn('bg-main-lightBg', {
              'w-[750px] transition-all ease-in-out ':
                activeMenu != null && activeMenu.component,
              'w-[300px] transition-all ease-in-out': !activeMenu,
            })
          ">
          <SelectButton
            v-model="activeTab"
            :options="tabs"
            :pt="collectionTabsPreset"
            optionLabel="name"
            class="!w-[300px]"
            @change="handleChangeTab" />
        </div>
        <div
          class="flex h-[calc(100vh-50px)] scroll-hidden overflow-y-auto relative"
          v-if="activeTab.value == 1">
          <section
            id="left"
            :class="
              cn(
                'bg-main-lightBg overflow-y-auto z-10 w-[300px] h-[calc(100vh-50px)] scroll-hidden',
              )
            ">
            <div
              class="sticky top-0 p-4 bg-main-lightBg z-[10]"
              v-if="activeMenu == null">
              <Button
                @click="handleAddSection"
                type="button"
                icon="far fa-plus-circle text-lg"
                label="Add a section"
                severity="secondary"
                class="!rounded !text-sm !bg-main-darkGray hover:!bg-main-darkGray/80 border-none hover:!border-none w-full flex justify-center gap-2"
                :pt="{
                  label: 'flex-none',
                }"
                :ptOptions="{ mergeProps: true }" />
            </div>
            <div
              class="h-[calc(100vh-234px)] overflow-y-auto scroll-hidden"
              v-if="form.items.length && activeMenu == null">
              <draggableComponent
                class="draggable-container flex flex-col gap-4"
                item-key="order"
                :component-data="{
                  tag: 'ul',
                  name: 'flip-list',
                  type: 'transition',
                }"
                v-model="form.items"
                v-bind="dragOptions">
                <template #item="{ element, index }">
                  <Sections
                    :element="element"
                    @select="() => handleSectionSelect(index)"
                    @remove="() => handleRemoveItem(index)" />
                </template>
              </draggableComponent>
            </div>

            <ContentButton
              :button="contentTypeButton"
              v-if="activeMenu != null && !sectionForm.content_type" />
            <div
              class="flex flex-col gap-2"
              v-if="activeMenu != null && sectionForm.content_type">
              <ContentButton
                :button="contentTypeButton"
                @click="handleActive(0, true)" />
              <div class="px-5">
                <div class="flex flex-col gap-1 relative">
                  <div class="flex gap-2 items-center">
                    <div>
                      <label for="title" class="text-main-disabled">
                        {{
                          sectionForm.content_type == "text"
                            ? "Heading"
                            : "Title"
                        }}
                      </label>
                    </div>
                    <div class="flex items-center">
                      <Tag severity="danger" value="REQUIRED"></Tag>
                    </div>
                  </div>
                  <Textarea
                    v-model="sectionForm.title"
                    rows="1"
                    autoResize
                    maxlength="100"
                    placeholder="Add a section title"
                    class="resize-none scroll-hidden !bg-white/10 border-[1px] !border-main-darkGray rounded text-white text-sm font-normal overflow-y-auto py-2 pr-14 pl-4 w-full focus:outline-0 focus:ring-0 focus:border-0" />
                  <div
                    class="absolute bottom-2.5 right-3 text-main-disabled text-xs"
                    :class="{
                      '!text-red-500': sectionForm.title?.length === 100,
                    }">
                    {{ sectionForm.title?.length || 0 }} / 100
                  </div>
                </div>
              </div>
              <ContentButton
                :button="button"
                v-for="(button, index) in sideMenu.filter(
                  (i) => i.show === undefined || i.show === true,
                )"
                :key="index"
                @click="handleActive(index)"
                class="border-t-[1px] border-surface-600" />
            </div>
            <div class="p-4" v-if="activeMenu != null">
              <div class="bg-main-title w-full flex justify-between gap-4">
                <Button
                  class="text-white disabled:!border-[1px] disabled:!bg-transparent disabled:!border-main-disabled disabled:!text-main-disabled rounded text-xs w-[180px] h-[40px]"
                  outlined
                  severity="secondary"
                  label="Cancel"
                  @click="() => handleCancel()" />
                <Button
                  class="text-white border-0 !bg-switcherBlue-500 disabled:!border-[1px] disabled:!bg-transparent disabled:!border-main-disabled disabled:!text-main-disabled rounded text-xs w-[180px] h-[40px]"
                  label="Save"
                  @click="handleSave"
                  :disabled="!canSave" />
              </div>
            </div>
            <div
              class="absolute w-[300px] bottom-10 left-0 p-4 bg-main-lightBg z-[10]"
              v-if="form.items.length">
              <Button
                @click="handleUpload"
                type="button"
                icon="far fa-cloud-arrow-up text-lg"
                :label="form.id ? 'Save' : 'Upload'"
                class="!rounded !text-sm !border-0 w-full flex justify-center gap-2"
                :pt="{
                  label: 'flex-none',
                }"
                :ptOptions="{ mergeProps: true }"
                v-if="activeMenu == null"
                :loading="isUploading"
                :disabled="!canUpload" />
            </div>
          </section>
          <section
            id="right"
            :class="
              cn(
                'bg-title overflow-x-hidden overflow-y-auto max-w-[450px] relative scroll-hidden flex flex-col',
                {
                  'w-[450px]': activeMenu != null && activeMenu.component,
                  'max-w-[450px] -translate-x-[450px] transition-all ease-in-out':
                    !activeMenu,
                },
              )
            ">
            <Transition
              :enter-active-class="'animate-in slide-in-from-left-full'"
              :leave-active-class="'animate-out slide-out-from-right-full'"
              mode="out-in"
              v-if="activeMenu != null && activeMenu.component">
              <component
                :is="activeMenu.component"
                v-model="sectionForm"
                v-bind="activeMenu"
                :key="activeMenu.id"
                class="!h-[calc(100vh-95px)] overflow-y-auto scroll-hidden" />
            </Transition>
          </section>
        </div>
        <ContentComponent
          v-if="activeTab.value == 2"
          v-model="form"
          class="h-[calc(100vh-50px)] overflow-y-auto scroll-hidden"
          :classes="{
            left: 'z-10 w-[300px]',
            right: {
              'w-[450px]': activeMenu,
              'max-w-[450px] -translate-x-[450px] transition-all ease-in-out':
                !activeMenu,
            },
          }"
          @setMenu="setActiveMenu"></ContentComponent>
      </div>
      <div
        class="flex-1 flex justify-center transition-all h-[calc(100vh-50px)] overflow-y-auto">
        <ContentPreview v-model="form" />
      </div>
    </div>
  </Dialog>
</template>

<script lang="tsx" setup>
import { useDialogStore, DialogType } from "@/store/Dialog";
import { router, useForm } from "@inertiajs/vue3";
import { reactive, ref } from "vue";
import { cn } from "@/utils/cn";
import { markRaw } from "vue";
import { watch } from "vue";
import { BizCardContentType } from "@/core/enums";
import { computed } from "vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import ContentComponent from "@/components/dialogs/content/parts/Content.vue";
import collectionTabsPreset from "@/assets/tailwind-preset/selectbutton/collection-tabs";
import ImageGallery from "@/components/dialogs/content/components/ImageGallery.vue";
import VideoGallery from "@/components/dialogs/content/components/VideoGallery.vue";
import LinkGallery from "@/components/dialogs/content/components/LinkGallery.vue";
import InfoBallon from "@/components/parts/InfoBallon.vue";
import ContentButton from "@/components/dialogs/content/parts/Button.vue";
import GalleryDescription from "@/components/dialogs/content/components/GalleryDescription.vue";
import ContentOptions from "./components/ContentOptions.vue";
import ContentPreview from "./components/ContentPreview.vue";
import DefaultCover from "@/assets/images/bgPics/web-bg-min.jpg";
import GalleryStyle from "./buttons/GalleryStyle.vue";
import _ from "lodash"; // Import Lodash for deep cloning
import draggableComponent from "vuedraggable";
import { route } from "ziggy-js";
import { API } from "@/core/api";
import { useToastr } from "@/composables/useToastr";
import Sections from "./components/Sections.vue";

interface Button {
  id?: String;
  title?: String;
  description?: String;
  icon?: String;
  separator?: String;
  component?: Component;
  show?: Boolean;
  active?: Boolean;
  placeholder?: String;
  required?: Boolean;
}

const props = defineProps({
  content: {
    type: Object,
    default: null,
  },
});

const dragOptions = {
  animation: 200,
  group: "description",
  disabled: false,
  ghostClass: "ghost",
  scrollSensitivity: 200,
  forceFallback: true,
};
/** HOOKS */
const dialogStore = useDialogStore();
const contentAPI = new API.Content();
const { success, error } = useToastr();
const emit = defineEmits();

/** REACTIVE DATA */
const isUploading = ref(false);
const canUpload = computed(
  () => !isUploading.value && form.title && form.items.length > 0,
);

const canSave = computed(() => {
  const value = sectionForm.title ? true : false;
  switch (sectionForm.content_type) {
    case BizCardContentType.PlainText:
      return value && sectionForm.description;
    case BizCardContentType.ImageGallery:
      return (
        (value &&
          sectionForm.items.length == 1 &&
          !sectionForm.items.some((i: any) => !i.valid)) ||
        (sectionForm.items.length > 1 &&
          sectionForm.aspect_ratio &&
          !sectionForm.items.some((i: any) => !i.valid))
      );
    case BizCardContentType.VideoGallery:
      return (
        value &&
        sectionForm.items.length > 0 &&
        !sectionForm.items.some((i: any) => !i.valid)
      );
    case BizCardContentType.LinkGallery:
      return (
        value &&
        sectionForm.items.length > 0 &&
        !sectionForm.items.some((i: any) => !i.valid)
      );
  }

  return value;
});
let formBackup = reactive({}); // Define formBackup as a reactive object asdas
const form = useForm<any>({
  id: props?.content?.id ?? null,
  isValid: {},
  content_type: "collection",
  title: props?.content?.title ?? null,
  description: props?.content?.description ?? null,
  categories: props?.content?.categories ?? [],
  tags: props?.content?.tags ?? [],
  project_types: props?.content?.project_types ?? [],
  collaborators: props?.content?.collaborators ?? [],
  project_roles:
    props?.content?.owner_roles?.map((i) => ({ id: i.id, name: i.name })) ?? [],
  project_companies: props?.content?.organizations ?? [],
  project_completed_on: props?.content?.completion_year ?? null,
  related_services: props?.content?.related_services?.map((i) => i.id) ?? [],
  items: props?.content?.items ?? [],
  thumbnail: {
    video: props?.content?.image_kit_id_16_9 ?? DefaultCover,
    square: props?.content?.thumbnail_image_kit_id ?? DefaultCover,
    default: props?.content?.thumbnail_image_kit_id ?? DefaultCover,
  },
  is_featured: props?.content?.is_featured,
});

const sectionForm = useForm<any>({
  edit_index: null,
  title: null,
  description: null,
  content_type: null,
  isValid: {},
  items: [],
  thumbnail: {
    video: null,
    square: null,
    default: null,
  },
});

const hasSideMenuActive = computed(() =>
  sideMenu.value.some((button) => button.active),
);
const sectionsMenu = ref([
  {
    id: "content-type-selector",
    name: "Content Type Selector",
    component: markRaw(ContentOptions),
  },
  {
    id: "text",
    name: "Plain Text",
  },
  {
    id: "image_gallery",
    name: "Image Gallery",
    component: markRaw(ImageGallery),
  },
  {
    id: "video_gallery",
    name: "Video Gallery",
    component: markRaw(VideoGallery),
  },
  { id: "link_gallery", name: "Link Gallery", component: markRaw(LinkGallery) },
]);
const tabs = ref([
  { name: "Sections", value: 1 },
  { name: "Details", value: 2 },
]);
const activeTab = ref(tabs.value[0]);

const contentTypeButton = computed(() => {
  const contentType = sectionForm.content_type as BizCardContentType;
  let button: any = {
    id: "content-type-select",
    title: "Content Type",
    description: "Choose the content type",
    active: !sectionForm.content_type,
    component: markRaw(ContentOptions),
  };

  if (contentType) {
    switch (contentType) {
      case BizCardContentType.PlainText:
        button = {
          title: "Plain Text",
          icon: "fa fa-font",
          active: !hasSideMenuActive.value,
        };
        break;
      case BizCardContentType.ImageGallery:
      case BizCardContentType.Image:
        button = {
          title: "Image Gallery",
          icon: "fa fa-images",
          component: markRaw(ImageGallery),
          active: !hasSideMenuActive.value,
        };
        break;
      case BizCardContentType.VideoGallery:
      case BizCardContentType.Video:
        button = {
          title: "Video Gallery",
          icon: "fa fa-clapperboard-play",
          component: markRaw(VideoGallery),
          active: !hasSideMenuActive.value,
        };
        break;
      case BizCardContentType.LinkGallery:
      case BizCardContentType.Link:
        button = {
          title: "Link Gallery",
          icon: "fa fa-link",
          component: markRaw(LinkGallery),
          active: !hasSideMenuActive.value,
        };
        break;
    }
  }
  return button;
});
const sideMenu = ref<Button[]>([
  {
    id: "description",
    title: "Description",
    description: "Add a section description",
    placeholder: "Add a section description",
    component: markRaw(GalleryDescription),
  },
  {
    id: "gallery-style",
    title: "Gallery Style",
    description: "Choose your gallery style",
    component: markRaw(GalleryStyle),
    required: true,
    show: false,
  },
]);
const activeMenu = ref();

const handleActive = (index: number, isContentType: Boolean = false) => {
  if (isContentType) {
    activeMenu.value = contentTypeButton.value;
    return;
  }
  sideMenu.value.forEach((button, i) => {
    if (i === index && !isContentType) {
      if (button.active) {
        //if menu is already active, close it
        button.active = false;
        activeMenu.value = contentTypeButton.value;
      } else {
        button.active = true;
        activeMenu.value = button;
      }
    } else {
      button.active = false;
    }
  });
};

const setActiveMenu = (menu: string) => {
  activeMenu.value = menu;
};

const handleChangeTab = () => {
  activeMenu.value = null;
};
const handleAddSection = () => {
  sectionForm.reset();
  activeMenu.value = sectionsMenu.value[0];
  const galleryStyleMenu = sideMenu.value.find((i) => i.id == "gallery-style");
  if (galleryStyleMenu) galleryStyleMenu.show = false;
  const descriptionMenu = sideMenu.value.find((i) => i.id == "description");
  if (descriptionMenu)
    descriptionMenu.description = "Add a section description";
};

const handleSectionSelect = (index: number) => {
  const values = form.items[index];
  for (const key in values) {
    sectionForm[key] = values[key];
  }
  sectionForm.edit_index = index;
  formBackup = reactive(_.cloneDeep(form.data())); // Create a deep copy backup of the form
  handleActive(0, true);
};

const close = () => {
  dialogStore.hide(DialogType.UPLOAD_CONTENT_COLLECTION);
};

const handleCancel = () => {
  if (sectionForm.edit_index !== null) {
    Object.assign(form, formBackup); // Restore the form from the backup
  }
  sectionForm.reset();
  activeMenu.value = null;
};

const handleSave = () => {
  if (form.items.length === 0) {
    form.thumbnail = sectionForm.thumbnail;
  }
  if (sectionForm.edit_index !== null) {
    form.items.splice(sectionForm.edit_index, 1, { ...sectionForm });
  } else {
    form.items.push({ ...sectionForm });
  }
  sectionForm.reset();
  activeMenu.value = null;
};

const handleUpload = async () => {
  let response;
  try {
    console.log(form.items);
    isUploading.value = true;
    if (props.content) {
      response = await contentAPI.update(form);
    } else {
      response = await contentAPI.create(form);
    }
    if (response.hasOwnProperty("data")) {
      if (props.content) success("Content updated successfully!");
      emit("close", response.data.id);

      // UPDATE DATA BASED ON ROUTE
      if (route().current() === "social_posts.index")
        router.reload({ only: ["post"] });
      else if (route().current() === "home")
        console.log("Edit contnet home feed");
      else if (route().current() === "bizcards.content.get") {
        router.reload();
      }
    } else {
      error(response.error);
    }
  } catch (err) {
    console.error(err);
  }
  isUploading.value = false;
};

const handleRemoveItem = (index: number) => {
  const item = form.items[index];
  const ratios = ["default", "square", "video"];
  ratios.forEach((ratio) => {
    if (form.thumbnail[ratio] === item.thumbnail[ratio]) {
      form.thumbnail[ratio] = DefaultCover;
    }
  });
  form.items.splice(index, 1);
};
/** ------- WATCHERS -------- */
watch(
  () => sectionForm.content_type,
  (value) => {
    if (value) {
      const galleryDescriptionMenu = sideMenu.value.find(
        (i) => i.id == "description",
      );
      if (!galleryDescriptionMenu) return;
      if (value == BizCardContentType.PlainText) {
        galleryDescriptionMenu.title = "Paragraph";
        galleryDescriptionMenu.description = "Add a paragraph";
        galleryDescriptionMenu.placeholder = "Type here...";
        galleryDescriptionMenu.required = true;
      } else {
        galleryDescriptionMenu.required = false;
      }
      activeMenu.value = sectionsMenu.value.find((i) => i.id.includes(value));
    }
  },
);
watch(
  () => sectionForm.items,
  (value) => {
    const galleryStyleMenu = sideMenu.value.find(
      (i) => i.id == "gallery-style",
    );

    if (!galleryStyleMenu) return;
    if (sectionForm.content_type == "image_gallery")
      galleryStyleMenu.show = value.length > 1;
  },
  { deep: true },
);
watch(
  () => sectionForm.description,
  (newVal) => {
    sideMenu.value[0] = {
      ...sideMenu.value[0],
      description: newVal,
    };
  },
);
watch(sectionForm, (value) => {
  if (value.edit_index != null) {
    form.items.splice(value.edit_index, 1, { ...value });
  }
});
</script>
