<template>
  <div
    class="flex items-center justify-center max-w-[1920px] py-12 px-2.5 w-full mx-auto">
    <div class="rounded-xl shadow-default w-[40%] overflow-hidden box-border">
      <section id="header" class="bg-lightBlue py-2.5">
        <p class="text-white uppercase font-semibold text-lg">Coming Soon</p>
      </section>
      <section
        id="content"
        class="p-12 flex flex-col gap-5 items-center justify-center">
        <div class="flex flex-col gap-2.5 items-center justify-center">
          <i class="fa-solid fa-address-book text-2xl"></i>
          <p class="text-2xl">Resume</p>
        </div>
        <p class="text-main-secondaryBlue text-lg">
          We are working on a dynamic resume page that reflects your unique
          journey, seamlessly integrating your latest achievements. Designed
          with bizly's signature elegance, it will offer customizable options
          beyond the conventional, ensuring your professional narrative is
          personalized to you.
        </p>
      </section>
    </div>
  </div>
</template>
<script setup></script>
