<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-0 w-full flex flex-col relative',
      root: ' bg-white max-w-[850px] w-full rounded-[16px]  scroll-hidden',
    }"
    :visible="true"
    modal
    :closable="false">
    <section id="header" class="bg-lightBg-500 rounded-t-[16px]">
      <div
        class="flex justify-between items-center border-b border-main-divider py-3 px-4">
        <br />
        <h1 class="text-main-primary text-xl font-semibold">Edit intro</h1>
        <CircleIcon @click="$emit('close')">
          <i class="fa fa-xmark"></i>
        </CircleIcon>
      </div>
      <div class="mx-auto w-1/2">
        <SelectButton
          v-model="tab"
          :options="TABS"
          optionLabel="label"
          aria-labelledby="basic"
          :pt="{ button: '!rounded-lg' }"
          :ptOptions="{ mergeProps: true }" />
      </div>
    </section>
    <section
      id="content"
      class="flex-1 overflow-y-auto scroll-hidden max-h-[60vh] min-h-[60vh]">
      <component
        :is="tab.component"
        v-model="form"
        :user="user"
        @canSave="(v: boolean) => canSave = v" />
    </section>
    <section
      id="footer"
      class="bg-lightBg-500 flex justify-end items-center px-4 py-2 rounded-b-[16px]">
      <Button
        :disabled="!canSave"
        :loading="form.processing"
        label="Save"
        class="rounded text-white text-sm font-medium min-h-[40px] py-1.5 !px-12 text-center disabled:bg-[#bfbfbf] bg-switcherBlue-500"
        @click="handleSave"></Button>
    </section>
  </Dialog>
</template>
<script setup lang="ts">
import { router, useForm } from "@inertiajs/vue3";
import { reactive, ref, computed, onMounted, watch } from "vue";
import { useDialogStore, DialogType } from "@/store/Dialog";
import { markRaw } from "vue";
import type { UserInterface } from "@/core/interfaces";
import { useAuthStore } from "@/store/Auth";
import { API } from "@/core/api";
import Intro from "./Tabs/Intro.vue";
import Mobile from "./Tabs/Mobile.vue";
import Web from "./Tabs/Web.vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import DefaultMobileBg from "@/assets/images/bgPics/bg.png";
import DefaultWebBg from "@/assets/images/bgPics/web-bg-min.jpg";

const userAPI = new API.Users();

const dialogStore = useDialogStore();
const authStore = useAuthStore();

const data = computed(() => dialogStore.getData(DialogType.MANAGE_BIZCARD));
const user = computed(() => data.value.user as UserInterface);
const canSave = ref(true);
const form = useForm({
  pronouns: user.value.pronouns[0],
  gender: user.value.gender,
  is_discoverable: user.value.is_discoverable,
  public_username: user.value.public_username,
  location: user.value.location,
  roles: user.value.roles.map((el) => ({ id: el.id, name: el.name })),
  summary: user.value.elevator_pitch,
  mobile_covers:
    user.value.background_images.length > 0
      ? user.value.background_images.map((el) => ({ default: el, image: el }))
      : [{ default: DefaultMobileBg, image: DefaultMobileBg }],
  web_cover: user.value.web_background_image ?? DefaultWebBg,
});
const TABS = [
  { id: "intro", label: "Intro", value: "Intro", component: markRaw(Intro) },
  {
    id: "mobile_cover",
    label: "Mobile covers",
    value: "Mobile covers",
    component: markRaw(Mobile),
  },
  {
    id: "web_cover",
    label: "Web cover",
    value: "Web cover",
    component: markRaw(Web),
  },
];
const tab = ref(TABS.find((v) => v.id == data.value?.page) || TABS[0]);

/** ---------- METHODS ---------  */
const handleSave = async () => {
  form.processing = true;

  try {
    const formData = {
      ...form,
      mobile_covers: form.mobile_covers.map(
        (el: { image: string }) => el.image,
      ),
    };

    const response = await userAPI.updateIntro(formData);
    if (response.data) {
      authStore.setUser(response.data);
      dialogStore.hide(DialogType.MANAGE_BIZCARD);
      router.reload();
    }
  } catch (err) {
    console.log("[DEBUG][ERROR] :: handleSave :: ", err);
  }

  form.processing = false;
};
</script>
