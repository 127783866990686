import baseApi from "./Base";
import { route } from "ziggy-js";

export class SocialPostLikesService extends baseApi {
  async get(postId: number, form: Object) {
    return await this.request(
      route("social_posts.likes.index", { post: postId }),
      "GET",
      form,
    );
  }
}
