<template>
  <div class="p-4 max-h-[500px] scroll-hidden">
    <div class="space-y-4" v-if="loading">
      <Skeleton height="3rem" v-for="i in 3"></Skeleton>
    </div>

    <Listbox v-if="!loading && RelatedServices.length" @change="handleChange" v-model="selectedServices"
      :options="RelatedServices" optionLabel="title" optionValue="id" multiple :pt="{
        root: '!bg-transparent !border-0 ',
        item: '!bg-main-lightBg !text-white !p-4 !border-[1px] border-surface-600 !rounded-md !font-medium',
        list: '!space-y-4',
      }" :ptOptions="{ mergeProps: true }" class="w-full md:w-14rem" />
    <div class="text-center text-white text-sm w-full bg-main-lightBg p-6 rounded-md"
      v-else-if="!loading && !RelatedServices.length">
      At this time, you don’t have any services listed, but don't worry, you can
      start adding them anytime on your services page.
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { API } from "@/core/api";
const loading = ref(false);
const RelatedServicesAPI = new API.RelatedServices();
const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
});
const selectedServices = ref(props.modelValue.related_services || []);

const RelatedServices = ref([]);

onMounted(async () => {
  try {
    loading.value = true;
    const response = await RelatedServicesAPI.get();
    RelatedServices.value = response.data;
    loading.value = false;
  } catch (err) {
    loading.value = false;
  }
});

const handleChange = () => {
  props.modelValue.related_services = selectedServices.value;
};
</script>
