<template>
  <div class="flex flex-col gap-[16px] mt-[10px] lg:mt-[56px] w-full mb-[10px]">
    <Card><template #content>
        <div class="px-[18px] py-[10px] flex items-center justify-between h-[50px]">
          <span> Would you like to be discoverable?</span>
          <label class="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" class="sr-only peer" @change="handleIsDiscoverableForm"
              v-model="isDiscoverableForm.discoverable"
              :disabled="props.user?.is_on_waitlist || isDiscoverableForm.processing" />
            <div
              class="w-[36px] h-[20px] bg-gray-400 rounded-full peer-focus:ring-none peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-[#34C759] disabled:peer-checked:bg-[#BFBFBF]"
              :class="(props.user?.is_on_waitlist || isDiscoverableForm.processing) &&
                'opacity-50 peer-checked:bg-[#BFBFBF] after:border after:!border-main-lightGray peer-checked:after:border-main-lightGray peer-checked:after:bg-main-lightGray '
                "></div>
          </label>
        </div>
      </template>
    </Card>
    <Card>
      <template #content>
        <div class="px-[18px] py-[20px] flex flex-col">
          <span class="text-[16px] font-[500] text-main-primary">
            Account information
          </span>
          <h1 class="text-[18px] text-main-primary font-[600] leading-7 mt-[8px] mb-0">
            {{ props.user?.first_name + " " + props.user?.last_name }}
          </h1>
          <p class="text-[#6D6D6D] text-[16px] font-[400] leading-6 m-0">
            {{ props.user?.email }}
          </p>
          <p class="text-[#6D6D6D] text-[16px] font-[400] leading-6 m-0">
            {{ props.user?.phone_number }}
          </p>
        </div>
      </template>
    </Card>

    <Card>
      <template #content>
        <div class="px-[18px] py-[20px] flex flex-col">
          <span class="font-[500] text-[18px] mb-[0px]">Change email address</span>
          <p class="text-[#6D6D6D] text-[14px] font-[400] leading-6 m-0">
            {{ props.user?.email }}
          </p>
          <span class="text-main-secondaryBlue font-[400] text-[14px] leading-5 py-1">Should you change your email
            address, you will be sent an
            activation code to verify your identity.</span>
          <input :type="'text'" :placeholder="props.user?.email"
            class="focus:outline-none text-[#000BF] placeholder:text-input-border rounded-[4px] px-[16px] py-[8px] my-[10px] border-[1px] w-full md:w-[440px]"
            :class="emailForm.email ? 'border-main-primary' : 'border-input-border'
              " v-model="emailForm.email" />
          <Button label="Change email address" @click="DialogVisible = true" :disabled="!emailForm.isDirty"
            class="text-[14px] text-switcherBlue-500Bg h-[40px] w-[228px] py-[6px] rounded-[4px] bg-switcherBlue-500 hover:bg-[#1375c7] hover:text-white cursor-pointer mt-[10px] disabled:bg-[#BFBFBF]"
            :loading="emailForm.processing"></Button>
        </div>
      </template>
    </Card>

    <Card>
      <template #content>
        <div class="px-[18px] py-[20px] mb-[10px]">
          <div class="flex flex-col">
            <span class="font-[500] text-[18px] leading-7 mb-[0px]">Change password</span>
            <span class="text-main-secondaryBlue font-normal text-[14px] leading-5 py-1">Reset or change your password.
              Passwords need a minimum of 8
              characters, 1 capital letter, 1 number, and 1 special
              character.</span>
          </div>
          <div class="w-full md:w-[440px] mt-[30px]">
            <div class="flex flex-col gap-2">
              <PasswordInput v-model="passwordForm.current_password" placeholder="Current password" />
              <PasswordInput v-model="passwordForm.new_password" placeholder="New password" />
              <PasswordStrength :value="passwordForm.new_password" class="w-full my-1" @getPasswordStatus="(value) => {
                passwordStatus = value;
              }
                " />
              <PasswordInput v-model="passwordForm.new_password_confirmation" placeholder="Confirm password" />
            </div>
            <Button label="Change password" @Click="handleChangePassword"
              class="text-[14px] text-switcherBlue-500Bg h-[40px] w-[228px] py-[6px] rounded-[4px] bg-switcherBlue-500 hover:bg-[#1375c7] hover:text-white cursor-pointer mt-[10px] disabled:bg-[#BFBFBF]"
              :disabled="!(
                passwordForm.current_password?.length &&
                passwordForm.new_password?.length &&
                passwordForm.new_password_confirmation?.length &&
                passwordStatus == 'Good'
              )
                " :loading="passwordForm.processing" />
          </div>
        </div>
      </template>
    </Card>
  </div>
  <EmailChangeDialog :email="emailForm.email" :visible="DialogVisible" />
</template>
<script setup>
import PasswordStrength from "@/components/forms/passwordStrength.vue";
import { useForm } from "@inertiajs/vue3";
import { ref } from "vue";
import PasswordInput from "@/components/forms/passwordInput.vue";
import EmailChangeDialog from "@/components/dialogs/EmailChange.vue";
import { useToastr } from "@/composables/useToastr";
const { warn } = useToastr();
const DialogVisible = ref(false);
const props = defineProps(["user"]);
const passwordForm = useForm({
  current_password: "",
  new_password: "",
  new_password_confirmation: "",
});
const passwordStatus = ref("");

const emailForm = useForm({
  email: props.user?.email,
});
const isDiscoverableForm = useForm({
  discoverable: props.user?.is_discoverable || false,
});
const handleIsDiscoverableForm = () => {
  isDiscoverableForm.post(route("settings.discoverable"));
};
const handleChangePassword = () => {
  if (passwordForm.new_password !== passwordForm.new_password_confirmation) {
    return warn("Passwords do not match!");
  }
  passwordForm.post(route("settings.change_password"));
};
</script>
