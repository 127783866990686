<template>
    <div class="w-full flex justify-center">
        <div v-if="item.items.length > 1">
            <CarouselComponent :items-to-show="count" :wrap-around="false" class="w-full transition-all"
                snap-align="start">
                <template #slides>
                    <Slide v-for="(value, index) in item.items" :key="value.id"
                        class="overflow-visible !items-start z-[10]">
                        <CardLink class="mx-2" :max="item.items.length.toString()" :url="value.url" :title="value.title"
                            :text="value.description" :count="(index + 1).toString()" @click="(url) => handleOpen(url)">
                            <img :src="value.thumbnail.video" class="w-full object-cover aspect-video" />
                        </CardLink>
                    </Slide>
                </template>
                <template #addons="{ currentSlide }">
                    <navigation class="!w-[0px] !h-[0px]">
                        <template #prev>
                            <button v-if="currentSlide > 0" slot="next" class="absolute -left-8 text-neutral-400 ">
                                <i class="fa fa-chevron-left"></i>
                            </button>
                        </template>
                        <template #next>
                            <button slot="next" class="absolute -right-8 text-neutral-400">
                                <i class="fa fa-chevron-right"></i>
                            </button>
                        </template>
                    </navigation>
                </template>
            </CarouselComponent>
        </div>
        <div v-else class="flex flex-col relative shadow-lg rounded-lg">
            <img class="object-cover max-h-[450px] rounded-t-lg" :src="item.items[0].thumbnail.video" alt="Image">
            <CircleIcon class="bg-black/50 text-white absolute top-3 left-3 !cursor-default" size="2rem">
                <i class="fa-thin fa-link"></i>
            </CircleIcon>
            <div class="w-full p-4 bg-primary-100/30 hover:bg-primary-100/50 transition-all rounded-b-lg text-primary-400 uppercase text-sm cursor-pointer"
                @click="() => handleOpen(item.items[0].url)">
                {{ item.items[0].url }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import CardLink from '@/components/cards/CardLink.vue';
import CircleIcon from '@/components/icons/CircleIcon.vue';
import { DialogType, useDialogStore } from '@/store/Dialog';
import { useUserStore } from '@/store/User';
import { useWindowSize } from '@vueuse/core';
import { onMounted } from 'vue';
import { watch } from 'vue';
import { ref } from 'vue';
import { Carousel as CarouselComponent } from "vue3-carousel";
import { Navigation, Slide } from "vue3-carousel";
const dialogStore = useDialogStore();
const userStore = useUserStore();
const props = defineProps({
    item: {
        type: Object,
        required: true
    }
})

const { width, height } = useWindowSize();

const count = ref(2);

const isDragging = ref(false);

watch(isDragging, (value) => {
    console.log("[DEBUG] :: isDragging ", value);
    if (!value) {
        setTimeout(() => {
            isDragging.value = false;
        }, 100);
    }
});

const handleOpen = (url: string) => {
    if (isDragging.value) return;
    // Check if the URL does not start with 'http://' or 'https://'
    if (!/^https?:\/\//i.test(url)) {
        // Prepend 'https://' to the URL
        url = 'https://' + url;
    }

    if (url.includes('mybizly.com') || userStore.openExternalLinks) {
        window.open(url, '_blank');
        return;
    } else {
        dialogStore.show(DialogType.EXTERNAL_LINK, undefined, { url });
        dialogStore.setEvents(DialogType.EXTERNAL_LINK, {
            close: () => dialogStore.hide(DialogType.EXTERNAL_LINK),
            confirm: () => {
                window.open(url, '_blank');
                dialogStore.hide(DialogType.EXTERNAL_LINK);
            }
        });
    }
}

onMounted(() => {
    if (width.value < 600) {
        count.value = 1;
    }
});
</script>
