<template>
    <section class="flex flex-col bg-main-bg">
        <Header v-model="model"></Header>
        <div class="my-10 relative flex flex-col lg:flex-row w-4/5 lg:w-3/4 self-center gap-12">
            <div class="grow flex flex-col gap-7">
                <slot></slot>
            </div>
            <SideBar v-model="model"></SideBar>
        </div>
    </section>
</template>

<script lang="ts" setup>
import Header from './Header.vue';
import SideBar from './SideBar.vue';

const model = defineModel({ type: Object, required: true });
</script>