<template>
  <FileUpload
    ref="fileUpload"
    :name="props.name"
    :multiple="props.multiple || false"
    :accept="props.accept"
    :maxFileSize="props.maxFileSize"
    @progress="(p) => (progress = p.progress)"
    @select="onFileSelect"
    @remove="update">
    <template #header="{ chooseCallback }">
      <div
        class="flex items-center justify-center flex-col w-full text-lightBg-700"
        @click="
          (e) => {
            e.preventDefault();
            chooseCallback();
          }
        ">
        <label
          for="WebCover"
          class="flex flex-col items-center justify-center w-full bg-transparent h-full border-2 border-dashed border-lightBg-600 cursor-pointer rounded-lg">
          <div class="flex flex-col items-center justify-center pt-4 pb-4">
            <div class="bg-primary-100 p-3 mb-4 rounded">
              <i class="fa fa-cloud-arrow-up text-3xl"></i>
            </div>
            <p class="text-[0.8rem] tracking-[-0.08px] text-center mb-0">
              <span class="font-semibold underline">Click to upload</span>
              or drag and drop
              <br />
            </p>
          </div>
          <input type="file" id="WebCover" class="hidden" />
        </label>
      </div>
    </template>
    <template #content="{ files, progress }">
      <div class="flex flex-col gap-8" v-if="files && files.length > 0">
        <div>
          <div class="flex flex-wrap gap-4">
            <div
              v-for="(file, index) of files"
              :key="file.name + file.type + file.size"
              class="p-2">
              <div class="relative z-0">
                <span
                  class="cursor-pointer text-main-primary hover:text-main-primary/90 absolute top-2 right-2 z-50"
                  @click="() => removeFile(file)">
                  <i class="fa fa-circle-x text-xl"></i>
                </span>
                <img
                  role="presentation"
                  :alt="file.name"
                  :src="file.objectURL"
                  width="250"
                  height="200"
                  class="rounded-lg z-0 shadow-lg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </FileUpload>
</template>
<script setup>
import { ref } from "vue";
import { useToastr } from "@/composables/useToastr";
const { warn } = useToastr();
const progress = ref(0);
const props = defineProps([
  "multiple",
  "url",
  "name",
  "maxFileSize",
  "accept",
  "res",
]);
const fileUpload = ref(null);
const emit = defineEmits(["update"]);
const update = (event) => {
  let { files } = event;
  return emit("update", files);
};

const onFileSelect = async (event) => {
  const { files } = event;
  if (props.res) {
    for (let i = 0; i < files.length; i++) {
      await validateFile(files[i]);
    }
  }

  const base64Files = await Promise.all(
    fileUpload.value.files.map(fileToBase64),
  );

  emit("update", base64Files);
};

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const validateFile = async (file) => {
  const img = new Image();
  img.src = URL.createObjectURL(file);
  await new Promise((resolve) => (img.onload = resolve));

  // Check dimensions
  if (img.width < props.res.width || img.height < props.res.height) {
    removeFile(file);
    return warn(
      `Minimum upload resolution should be ${props.res.width}x${props.res.height}`,
    );
  }
};

const removeFile = async (file) => {
  const index = fileUpload.value.files.indexOf(file);
  if (index !== -1) {
    fileUpload.value.files.splice(index, 1);
  }
  const base64Files = await Promise.all(
    fileUpload.value.files.map(fileToBase64),
  );
  emit("update", base64Files);
};
</script>
