<template>
  <div>
    <div class="p-4 flex justify-between items-center">
      <p class="flex text-main-secondary gap-2">
        <i class="text-lg fa-regular fa-eye-slash"></i
        ><span class="flex items-center justify-center text-xs"
          ><b>{{ post.owner.first_name }}</b
          >'s post was hidden.</span
        >
      </p>
      <button
        class="rounded text-primaryBlue-500 text-xs opacity-80 py-1 px-2.5 bg-main-divider"
        @click="handleUndo"
      >
        Undo
      </button>
    </div>
  </div>
</template>
<script setup>
import { HomeFeedType } from "@/core/enums";
import { useHomeFeedStore } from "@/store/HomeFeed";
const homeFeedStore = useHomeFeedStore();
const props = defineProps(["post"]);
const handleUndo = async () => {
  await homeFeedStore.unhidePost(HomeFeedType.Community, props.post.id);
};
</script>
