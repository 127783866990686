<template>
  <div class="relative w-full">
    <div class="w-full" ref="inputContainer">
      <IconField :iconPosition="loading ? 'both' : 'left'" @click="toggle" class="cursor-pointer w-full z-[60]">
        <img :src="SearchIconHeader" alt="Search Icon" class="size-4" />

        <InputText @input="getData" v-model="form.keyword" placeholder="What are you looking for today?" :class="cn(
          `bg-white/10 w-full focus:!outline-none focus:!border-0 focus:!ring-0 hidden md:block transition-all duration-150 ease-in-out`,
          {
            'bg-white text-main-secondaryBlue !rounded-t-md !rounded-b-none':
              overlayVisible,
          },
        )
          " />
        <i class="pointer-events-none far fa-spinner-third animate-spin" v-if="loading"></i>
      </IconField>
    </div>
    <div ref="modalContainer" :class="cn(
      'rounded-t-lg md:rounded-t-none fixed top-[60px] max-w-[calc(100vw-20px)] inset-x-2 md:inset-x-0 md:absolute md:top-[35px] bg-white w-full opacity-100 transition-opacity duration-150 ease-in-out z-[60] rounded-b-lg h-max',
      { 'h-0 hidden': !overlayVisible },
    )
      ">
      <div class="flex flex-col items-start box-border w-full max-h-[450px] !overflow-auto scroll-hidden"
        @scroll="handleScroll" ref="infiniteScroll">
        <div class="flex flex-col gap-2.5 w-full">
          <IconField :iconPosition="loading ? 'both' : 'left'" class="cursor-pointer w-full z-[60] block md:hidden">
            <img :src="SearchIconHeader" alt="Search Icon" class="size-4" />
            <InputText @input="getData" v-model="form.keyword" placeholder="What are you looking for today?"
              class="focus:!outline-none focus:!border-0 focus:!ring-0 bg-white text-main-secondaryBlue !rounded-t-md !rounded-b-none" />
            <i class="fa-duotone fa-spinner-third" v-if="loading"></i>
          </IconField>
          <div class="py-0 md:py-4 px-4 md:px-[30px]" v-if="
            form.keyword == null ||
            form.keyword == '' ||
            (!data?.length && loading)
          ">
            <div class="text-black font-poppins text-[16px] md:text-[28px] font-normal leading-9 text-center">
              Type, Search, Discover!
            </div>
            <div
              class="text-main-secondaryBlue font-poppins text-[14px] md:text-[16px] font-normal leading-6 text-center">
              Whether you choose to search with specific keywords or describe
              your needs in a full sentence, bizly's AI is here to help.
            </div>

            <div class="pt-[20px] pb-2 w-full">
              <TextSeparator text="Search by keywords" classNames="text-[12px] md:text-[14px]" />
            </div>

            <div class="flex flex-wrap items-center w-full gap-[5px] justify-center">
              <div class="keyword text-[12px] md:text-[14px]" v-for="keyword in keywords">
                {{ keyword }}
              </div>
            </div>
            <div class="pt-[20px] pb-2 w-full">
              <TextSeparator text="Full sentence examples" classNames="text-[12px] md:text-[14px]" />
            </div>
            <div class="flex flex-col gap-2 w-full pb-4">
              <div class="exampleText text-[12px] md:text-[14px]" v-for="example in examples">
                <img :src="SearchIconHeader" />
                {{ example }}
              </div>
            </div>
          </div>
          <div class="noDataContainer" v-else-if="form.keyword?.length && data?.length == 0 && !loading">
            <div class="nodataWrapper">
              <div class="imgWrapper">
                <img src="@/assets/images/nav-search.svg" alt="" class="h-[30px] w-[30px]" />
              </div>
              <div class="heading">No results were found.</div>
              <div class="paragraph">
                You could try to broaden your search by adjusting your filters.
              </div>
            </div>
          </div>
          <div class="" v-else>
            <h1 class="font-medium py-4 px-4 md:px-[30px] text-lg">
              Suggestions
            </h1>
            <div class="flex flex-col divide-y">
              <div
                class="flex gap-2 items-center py-4 px-4 md:px-[30px] hover:bg-[#eaf5ff] transition-colors duration-500 cursor-pointer"
                v-for="user in data">
                <img :src="user.avatar_image" :alt="user.first_name" class="h-[60px] w-[60px] rounded-full my-auto" />
                <div class="flex flex-col justify-between flex-1 gap-1.5">
                  <div class="space-y-0.5">
                    <h1 class="font-semibold text-base leading-tight">
                      {{ user?.first_name }} {{ user?.last_name }}
                    </h1>
                    <Roles v-if="user.roles && user.roles.length !== 0" :items="user.roles.map((v) => v.name)"
                      :fontSize="'12px'" :textColor="'#000'" :separatorWidth="'4px'" :separatorHeight="'4px'"
                      :separatorColor="'#8c8c8c'" />
                  </div>

                  <div class="text-gray-400 flex gap-1" v-if="user.location">
                    <i class="far fa-location-dot text-xs"></i>
                    <p class="text-xs">{{ user.location }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fixed inset-0 bg-gray-700/25 z-50" v-if="overlayVisible" @click="handleCloseSearchModal"></div>
  </div>
</template>

<script setup>
import SearchIconHeader from "@/assets/images/search_icon_header.svg";
import Roles from "@/components/general/Roles.vue";
import TextSeparator from "@/components/general/TextSeparator.vue";
import { API } from "@/core/api";
import { cn } from "@/utils/cn";
import _debounce from "lodash/debounce";
import { reactive, ref, watch } from "vue";

const GlobalAPI = new API.Global();
const loading = ref(false);
const overlayVisible = ref(false);
const modalContainer = ref();
const inputContainer = ref();
const infiniteScroll = ref();
const no_more_results = ref(false);
let data = ref([]);

const toggle = () => {
  if (overlayVisible.value) return;
  overlayVisible.value = !overlayVisible.value;
  form.keyword = null;
  data.value = [];
};
const form = reactive({ keyword: null, start: null, size: 15 });
const handleCloseSearchModal = (event) => {
  if (
    !modalContainer?.value?.contains(event.target) &&
    !inputContainer?.value?.contains(event.target)
  ) {
    overlayVisible.value = false;
    form.keyword = null;
  }
};
const getData = _debounce(async () => {
  loading.value = true;
  let response = await GlobalAPI.search(form).finally(() => {
    loading.value = false;
  });
  response = response.data;
  if (form.start) {
    if (response.length) {
      data.value.push(...response);
    }
  } else {
    data.value = response;
  }
  if (response.length < form.size) {
    no_more_results.value = true;
  }
  form.start = data.value.length;
  form.size = 5;
}, 500);

watch(
  () => form.keyword,
  (newVal) => {
    loading.value = newVal && true;
    form.start = null;
    form.size = 15;
    no_more_results.value = false;
  },
);
const keywords = [
  "Location",
  "Category",
  "Role",
  "Industry",
  "Company",
  "School",
  "Professional Skills",
  "Service",
  "Workplace",
  "Opportunities",
];

const examples = [
  "A female actress who specializes in drama",
  "An editor who’s worked on social media content, and is online now",
  "A producer near me who’s available to work, and specializes in commercials",
];

const handleScroll = () => {
  if ((form.keyword && !data.value?.length) || no_more_results.value) return;
  const curr_pos = infiniteScroll.value.scrollTop;
  const offset = modalContainer.value.scrollHeight;
  const height = infiniteScroll.value.scrollHeight;
  if (Math.abs(parseInt(curr_pos.toFixed(0)) + offset - height) <= 1) {
    getData();
  }
};
</script>
<style scoped>
.keyword {
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background: #eff1f5;
  border-radius: 30px;
  color: #546b91;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.8;
  padding: 8px 10px;
  text-align: center;
  transition: 0.5s;
}

.exampleText {
  align-items: center;
  background: #eff1f5;
  border-radius: 32px;
  color: #7689a7;
  display: flex;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  gap: 10px;
  line-height: 20px;
  padding: 10px 16px;
  transition: 0.5s;
  width: 100%;
}

.noDataContainer {
  height: 50vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.nodataWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
}

.imgWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100000px;
  background: #39537d1f;
  padding: 1rem;
  margin-bottom: 10px;
}
</style>
