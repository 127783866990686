import baseApi from "./Base";
import { route } from "ziggy-js";

export class BizcardInfoService extends baseApi {
  async updateAvailability(form: any) {
    return await this.request(
      route("user.info.availability.update"),
      "POST",
      form,
    );
  }

  async updateDiscoverable(form: any) {
    return await this.request(
      route("user.discoverable.update"),
      "POST",
      form,
    );
  }

  async updateSocials(form: any) {
    return await this.request(route("user.info.socials.update"), "POST", form);
  }

  async updateBio(form: any) {
    return await this.request(route("user.info.bio.update"), "POST", form);
  }

  async updateContact(form: any) {
    return await this.request(route("user.info.contact.update"), "POST", form);
  }

  async updateRepresentations(form: any) {
    return await this.request(
      route("user.info.representations.update"),
      "POST",
      form,
    );
  }

  async updateGeneralInfo(form: any) {
    return await this.request(route("user.info.general.update"), "POST", form);
  }
  async updateKeywords(form: String[]) {
    return await this.request(route("user.info.keywords.update"), "POST", form);
  }
}
