<template>
  <div class="flex gap-2">
    <p class="text-white/80 font-extralight text-sm text-nowrap">
      {{ model[0].title }}
    </p>
    <span class="text-main-text font-extralight text-sm">•</span>
    <p class="text-white/80 font-extralight text-sm">+{{ model.length - 1 }}</p>
  </div>
</template>
<script lang="ts" setup>
import type { PropType } from "vue";
import type { ProjectTypeInterface } from "@/core/interfaces";

const model = defineModel({
  type: Array as PropType<ProjectTypeInterface[]>,
  required: true,
});
</script>
