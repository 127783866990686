<template>
  <aside
    class="transition-all hidden md:block w-[350px] lg:w-[400px] min-h-[calc(100vh-56px)] bg-white fixed top-[56px] left-0 bottom-0 border-r-[1px] border-surface-150">
    <div class="w-full text-black space-y-2.5 flex flex-col h-full" id="wrapper">
      <section class="flex justify-between items-center px-5 py-2.5" id="header">
        <div class="flex gap-4">
          <i class="p-1.5 fa-light fa-rectangle-history text-main-secondary rounded-full cursor-pointer"
            @click="setLayout('full')" :class="{
              ' bg-primaryBlue-500 text-white': selectedLayout === 'full',
            }"></i>
          <i class="p-1.5 fa-light fa-grid-2 text-main-secondary rounded-full cursor-pointer" :class="{
            ' bg-primaryBlue-500 text-white': selectedLayout === 'compact',
          }" @click="setLayout('compact')"></i>
        </div>
        <p v-if="userStore.friends?.length">{{ userStore.friends?.length }} Connections</p>
      </section>
      <section class="text-sm font-medium" id="content menu">
        <Link v-for="(tab, index) in TABS"
          class="bg-white flex justify-between items-center py-[18px] px-5 border-b-[1px] border-surface-150 hover:bg-lightBg-500 hover:text-primaryBlue-500 cursor-pointer"
          :class="{
            '!bg-lightBg-500 !text-primaryBlue-500':
              currentRoute === tab.href,
          }" :href="route(tab.href)">
        <div class="flex gap-4 items-center">
          <i :class="tab.icon"></i>
          <p>{{ tab.title }}</p>
        </div>
        <Badge v-bind="tab.badge" v-if="tab.badge && tab.badge.value > 0"></Badge>
        <p class="text-main-lightGray text-xs font-normal" v-if="tab.count">{{ tab.count }}</p>
        </Link>
      </section>
      <section id="groups" class="flex-1 max-h-[75%] 2xl:max-h-screen overflow-y-auto scroll-hidden">
        <div class="groups flex w-full flex-col !bg-[white]">
          <div v-if="groups.length" class="group-heading flex justify-between items-center !bg-[white] z-[10]">
            GROUPS
            <div class="flex items-center">
              <CircleIcon @click="handleCreateGroup" class="bg-transparent group">
                <i class="fal fa-plus-circle text-primary-500 text-xl group-hover:text-primary-400"></i>
              </CircleIcon>
              <CircleIcon @click="handleManageGroups" class="bg-transparent group">
                <i class="fa-light fa-pencil text-primary-500 text-xl group-hover:text-primary-400"></i>
              </CircleIcon>
            </div>
          </div>
        </div>
        <div class="my-[0px] flex flex-col w-full h-[calc(100%-50px)] scrollable-container z-[1]"
          :class="{ '!my-[0px]': !groups.length }">
          <div class="groups flex w-full flex-col !bg-[white]">
            <div v-if="!groups.length" class="custom_option_wrapper mt-[10px]">
              <button class="custom_option_no_option" @click="handleCreateGroup">
                <i class="fa-light fa-plus"></i>
                Create New Group
              </button>
            </div>
            <div class="group-item">
              <template v-for="(group, index) in groups" :key="group.id">
                <Link :href="route('contacts.groups.show', { group: group.id })" :class="'group-item-custom'"
                  @click.stop="">
                <div class="group-avatar-text-wrapper">
                  <div class="group-avatar">
                    <div class="imgGallery" v-for="(item, index) in group.users.slice(0, 4)" :key="item.id">
                      <img :src="item.avatar_image" alt="" :class="{
                        '!w-[38px] !h-[38px] !top-0':
                          group.users?.length <= 1,
                        '!w-[19px] !h-[38px] !top-0 object-cover':
                          group.users?.length > 1 &&
                          group.users?.length <= 2,
                      }" />
                    </div>
                  </div>
                  <p class="group-name mb-0">
                    {{ group.name }}
                  </p>
                </div>
                <div>
                  <span class="text-[#8C8C8C] text-[12px] font-[400]">
                    {{
                      group.users.length > 99
                        ? `99+`
                        : `${group.users.length}`
                    }}
                  </span>
                </div>
                </Link>
              </template>
            </div>
          </div>
        </div>
      </section>
      <section id="footer" class="border-[1px] border-surface-200 divide-y-[1px] divide-surface-200">
        <div class="hover:bg-[#eff1f5] hover:text-primaryBlue-500 cursor-pointer text-main-secondary w-full"
          v-for="item in FOOTERMENU">
          <Link v-if="item.type === 'inertia_link'" :href="item.href" class="mx-5 py-4 flex gap-4 items-center w-full">
          <i :class="item.icon"></i>
          <p>{{ item.title }}</p>
          </Link>
          <button v-else-if="item.type === 'button'" @click="item.clickEvent"
            class="mx-5 py-4 flex gap-4 items-center w-full">
            <i :class="item.icon"></i>
            <p>{{ item.title }}</p>
          </button>
        </div>
      </section>
    </div>
  </aside>
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch } from "vue";
import { Link } from "@inertiajs/vue3";
import { useContactsStore } from "@/store/Contacts";
import { DialogType, useDialogStore } from "@/store/Dialog";
import { route } from "ziggy-js";
import { API } from "@/core/api";
import { useUserStore } from "@/store/User";
import CircleIcon from "@/components/icons/CircleIcon.vue";

/* ---------- REACTIVE DATA ---------- */
const contactsAPI = new API.Contacts();
const contactsStore = useContactsStore();
const userStore = useUserStore();
const dialogStore = useDialogStore();
const selectedLayout = computed(() => contactsStore.layout);
const groups = computed(() => contactsStore.groups ?? []);
const currentRoute = computed(() => route().current());
const favorites_count = computed(() => contactsStore.favorites_count || 0);
const setLayout = (layout: "full" | "compact") => {
  contactsStore.setLayout(layout);
};

/* ---------- FUNCTIONS ---------- */
const handleCreateGroup = () => {
  dialogStore.show(DialogType.MANAGE_GROUP);
  dialogStore.setEvents(DialogType.MANAGE_GROUP, {
    close: () => dialogStore.hide(DialogType.MANAGE_GROUP),
  });
};
const handleManageGroups = () => {
  dialogStore.show(DialogType.MANAGE_GROUPS);
  dialogStore.setEvents(DialogType.MANAGE_GROUPS, {
    close: () => dialogStore.hide(DialogType.MANAGE_GROUPS),
  });
};

onMounted(async () => {
  contactsStore.init();
  if (!contactsStore.favorites.length) {
    let response = await contactsAPI.getFavorites();
    contactsStore.setFavorites(response.data);
  }
});

/* ---------- MENUS ---------- */
interface menu {
  title: String;
  type: "button" | "inertia_link" | "external_link";
  clickEvent?: Function;
  icon: String;
  href?: String;

  badge?: {
    value: number;
    severity?:
    | "secondary"
    | "success"
    | "info"
    | "warn"
    | "danger"
    | "contrast";
    size?: "small" | "large" | "xlarge";
  };
  count?: number;
}
const TABS = computed<menu[]>(() => [
  {
    title: "All Contacts",
    type: "button",
    href: "contacts.index",

    icon: "fa fa-users",
    count: userStore.friends?.length ?? 0
  },
  {
    title: "Manage Requests",
    type: "button",
    href: "contacts.manage-requests",

    icon: "fa fa-user-plus",
    badge: {
      value: userStore.friend_requests.received.length ?? 0,
      severity: "danger",
    },
  },
  {
    title: "Favorites",
    type: "button",
    href: "contacts.favorites",

    icon: "fa fa-star",
    count: favorites_count.value
  },
]);

const FOOTERMENU = computed<menu[]>(() => [
  {
    title: "Send an invite",
    type: "button",
    icon: "fa fa-envelope",
    clickEvent: () => {
      dialogStore.show(DialogType.INVITATIONS);
      dialogStore.setEvents(DialogType.INVITATIONS, {
        close: () => dialogStore.hide(DialogType.INVITATIONS),
      });
    },
  },
  {
    title: "Blocked",
    type: "inertia_link",
    href: route("contacts.blocked"),
    icon: "fa fa-ban",
  },
  {
    title: "Settings",
    type: "inertia_link",
    href: route("settings.index", { page: 1 }),
    icon: "fa fa-cog",
  },
  {
    title: "Support",
    type: "inertia_link",
    href: route("settings.index", { page: 4 }),
    icon: "fa fa-headset",
  },
]);
</script>
<style scoped lang="scss">
.group-item {
  .group-item-custom {
    display: flex;

    display: flex;
    padding: 14px 0px;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    background: #fff;
    margin: 0px 20px !important;
    box-sizing: border-box !important;
    color: #141414;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    text-decoration: none !important;
    border-bottom: 1px solid #cdd5e1;
    transition: background 0.1s linear;

    &.router-link-active,
    &:hover {
      background: #eff1f5 !important;
      color: #39537d !important;
      margin: 0px !important;
      width: 100%;
      padding: 14px 20px !important;
      border-bottom: 1px solid transparent;
    }

    &:last-child {
      border-bottom: none;
    }

    .group-avatar-text-wrapper {
      display: flex;
      align-items: center;
      column-gap: 10px;
      text-decoration: none;
      // color: #39537d;
      font-size: 14px;
      font-weight: 500;
      width: 100%;

      .group-avatar {
        width: 38px;
        height: 38px;
        border-radius: 4px;
        position: relative;
        overflow: hidden;

        .imgGallery {
          display: flex;
          width: 38px;
          height: 38px;

          img {
            position: absolute;
            width: 19px;
            height: 19px;
          }

          &:nth-child(1) {
            img {
              top: 0;
              left: 0;
            }
          }

          &:nth-child(2) {
            img {
              bottom: 0;
            }

            &:not(:last-child) {
              img {
                left: 0;
              }
            }

            &:is(:last-child) {
              img {
                right: 0 !important;
                width: 19px;
                height: 38px;
                object-fit: cover;
              }
            }
          }

          &:nth-child(3) {
            img {
              top: 0 !important;
              right: 0 !important;
            }

            &:is(:last-child) {
              img {
                width: 19px;
                height: 38px;

                object-fit: cover;
              }
            }
          }

          &:nth-child(4) {
            img {
              bottom: 0;
              right: 0;
            }
          }
        }
      }
    }

    .count {
      color: #8c8c8c;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

.groups {
  .group-heading {
    color: #546b91;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    padding: 8px 16px 0px;

    button {
      cursor: pointer !important;
      transform: scale(0.8);
      padding: 6px;
      border-radius: 50%;

      &:hover {
        background-color: #eff1f5;
      }
    }
  }
}

.custom_option_wrapper {
  width: 100%;
  box-sizing: border-box;
  margin: 10px 20px;
  width: calc(100% - 40px);

  .custom_option_no_option {
    width: calc(100%);
    box-sizing: border-box;
    padding: 10px 18px;
    display: flex;
    align-items: center;
    gap: 10px;
    background: #2998ff26;

    b {
      font-weight: 600;
    }

    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    color: #2998ff !important;
    border-radius: 4px !important;
  }
}
</style>
