<template>
  <SideImage :image="imagebg" :bg="bg" :attr="attrs">
    <div
      class="p-0 sm:p-5 m-0 w-[100%] lg:w-[80%] bg-white overflow-y-auto h-screen scroll-hidden">
      <section class="register px-3 lg:px-0">
        <div
          class="flex justify-between w-full relative mb-5 mx-auto flex-wrap gap-2 sm:gap-0">
          <Stepper>
            <StepperPanel header="Header I" v-for="step in steps">
              <template #header="{ index, active, highlighted, clickCallback }">
                <div
                  :class="
                    cn(
                      'min-h-[66px] sm:min-h-[50px] md:min-h-fit text-[#cecece] flex items-center justify-center border-[1px] border-[#cecece] rounded-[20px] text-xs max-w-40 py-1 px-4 relative z-[2]',
                      {
                        'bg-switcherBlue-500 border-switcherBlue-500 text-white':
                          active || highlighted,
                      },
                    )
                  ">
                  {{ `${index + 1} - ${step.name}` }}
                </div>
              </template>
              <template #content="{ index, nextCallback, prevCallback }">
                <div class="flex flex-col h-[12rem]">
                  <Suspense>
                    <KeepAlive>
                      <component
                        :is="step.component"
                        :nextCallback="() => next(nextCallback, index + 1)"
                        :prevCallback="() => next(prevCallback, index - 1)"
                        v-bind="step.props" />
                    </KeepAlive>
                    <template #fallback>Loading...</template>
                  </Suspense>
                </div>
              </template>
            </StepperPanel>
          </Stepper>
        </div>
      </section>
    </div>
  </SideImage>
</template>
<script setup>
import bg from "@/assets/images/bgPics/bg.png";
import image1 from "@/assets/images/bgPics/step-1.png";
import image2 from "@/assets/images/bgPics/step-2.png";
import image3 from "@/assets/images/bgPics/step-3.png";
import image4 from "@/assets/images/bgPics/step-4.png";
import SideImage from "@/components/layout/SideImage.vue";
import { cn } from "@/utils/cn";
import { ref } from "vue";
import GeneralInfo from "./Steps/GeneralInfo.vue";
import Occupation from "./Steps/Occupation.vue";
import Photos from "./Steps/Photos.vue";
import PitchYourself from "./Steps/PitchYourself.vue";
const props = defineProps(["roles", "industries", "companies"]);
const imagebg = ref(image1);
const attrs = ref("");
const steps = [
  {
    name: "General Info",
    component: GeneralInfo,
    image: image1,
    props: { roles: props.roles, industries: props.industries },
  },
  {
    name: "Occupation",
    component: Occupation,
    image: image2,
    props: { companies: props.companies, roles: props.roles },
  },
  { name: "Pitch yourself", component: PitchYourself, image: image3, attrs: "!top-[55%]" },
  { name: "Photos", component: Photos, image: image4, attrs: "!top-[55%]" },
];
const next = (callback, index) => {
    callback();
    imagebg.value = steps.at(index).image;
    attrs.value = steps.at(index).attrs;
};
const prev = (callback, index) => {
    callback();
    imagebg.value = steps.at(index).image;
    attrs.value = steps.at(index).attrs;
};
</script>
