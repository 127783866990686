export default {
  root: ({ props }) => ({
    class: [
      //Font
      "text-[8px] font-semibold",

      //Alignments
      "inline-flex items-center justify-center",

      //Spacing
      "px-1 py-0.5",

      //Shape
      {
        rounded: !props.rounded,
        "rounded-full": props.rounded,
      },

      //Colors
      "text-primary-inverse",
      {
        "bg-primary dark:bg-primary":
          props.severity == null || props.severity == "primary",
        "bg-green-500 dark:bg-green-400": props.severity == "success",
        "bg-blue-500 dark:bg-blue-400": props.severity == "info",
        "bg-orange-500 dark:bg-orange-400": props.severity == "warning",
        "bg-red-500 dark:bg-red-400": props.severity == "danger",
      },
    ],
  }),
  value: {
    class: "leading-normal",
  },
  icon: {
    class: "mr-1 text-sm",
  },
};
