import baseApi from "./Base";
import { route } from "ziggy-js";

export class InvitationService extends baseApi {
  async get() {
    return await this.request(route("invitations.index"), "GET");
  }

  async send(formData: any) {
    return await this.request(route("invitations.send"), "POST", formData);
  }
}
