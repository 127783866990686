<template>
  <Carousel :items-to-show="count" :wrap-around="false" class="w-full" snap-align="start">
    <Slide v-for="item in items" :key="item.id" class="overflow-visible">
      <CardLink :max="items.length.toString()" url="website.com" title="Audio Playlist Title"
        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie ipsum dolor consectetur."
        :count="item.id.toString()" class="mx-2">
        <img :src="item.image" class="aspect-video" />
      </CardLink>
    </Slide>
    <template #addons="{ currentSlide }">
      <navigation>
        <template #prev>
          <button v-if="currentSlide > 0" slot="next" class="absolute -left-8 text-neutral-400">
            <i class="fa fa-chevron-left"></i>
          </button>
        </template>
        <template #next>
          <button slot="next" class="absolute -right-8 text-neutral-400">
            <i class="fa fa-chevron-right"></i>
          </button>
        </template>
      </navigation>
    </template>
  </Carousel>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import { useWindowSize } from "@vueuse/core";

import CardLink from "./CardLink.vue";

import Turtle from "../../assets/images/turtle.png";
import Tower from "../../assets/images/tower.png";

export default defineComponent({
  name: "WrapAround",
  components: {
    Carousel,
    Slide,
    Navigation,
    CardLink,
  },
  setup() {
    const { width, height } = useWindowSize();
    const items = [
      { id: 1, image: Turtle },
      { id: 2, image: Tower },
      { id: 3, image: Turtle },
      { id: 4, image: Tower },
    ];
    const count = ref(2);
    if (width.value < 600) {
      count.value = 1;
    }

    return {
      items,
      count,
    };
  },
});
</script>

<style>
.carousel__viewport {
  padding: 20px 0px;
}
</style>
