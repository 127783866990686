<template>
  <section class="mx-auto p-0 w-[90%]">
    <!---->
    <section
      class="flex flex-column justify-center items-center flex-nowrap text-center w-full h-[70vh]"
    >
      <div class="w-full px-[2rem] lg:px-[5rem]">
        <h1 class="mb-3 text-3xl font-medium tracking-[-.7px] text-primary">
          What is your current occupation?
        </h1>
        <h5 class="mb-3 text-base tracking-[-.7px] text-text-700">
          Please share what you’re up to these days!
        </h5>
        <div class="" v-if="!skip">
          <div class="mb-3 w-full p-1">
            <SelectButton
              :allow-empty="false"
              v-model="form.occupation_status"
              :options="OccupationStatus"
              aria-labelledby="basic"
              optionLabel="text"
              optionValue="id"
              :pt="{
                root: '!p-1',
                button: '!py-1',
              }"
              :ptOptions="{ mergeProps: true }"
            />
          </div>
          <div
            class="flex flex-col gap-2 mb-12"
            v-if="form.occupation_status === 'employed'"
          >
            <RolesSelectGroup
              placeholder="Select a Role"
              v-model="form.role"
              :invalid="form.errors.role != null"
            />
            <ValidationError :errors="form.errors" name="role" class="self-start" />

            <CompaniesSelectGroup
              v-model="form.company"
              placeholder="Company / Organization*"
              :invalid="form.errors.company != null"
            />
            <ValidationError :errors="form.errors" name="company" class="self-start" />

            <SelectGroup
              :options="EMPLOYMENT_TYPES"
              filterProperty="name"
              value="value"
              placeholder="Employment Type"
              v-model="form.employment_type"
              :invalid="form.errors.employment_type != null"
            />
            <ValidationError
              :errors="form.errors"
              name="employment_type"
              class="self-start"
            />
          </div>
          <div
            class="flex flex-col gap-2 mb-12"
            v-if="form.occupation_status === 'student'"
          >
            <InputText
              v-model="form.degree"
              placeholder="Degree"
              :invalid="form.errors.degree != null"
            />
            <ValidationError :errors="form.errors" name="degree" class="self-start" />

            <CompaniesSelectGroup
              v-model="form.college"
              placeholder="College / University*"
              :invalid="form.errors.college != null"
            />
            <ValidationError :errors="form.errors" name="college" class="self-start" />
          </div>
        </div>
        <Button
          :label="
            form.occupation_status == 'hidden'
              ? 'Display my current occupation'
              : 'Don’t display my current occupation'
          "
          @click="handleHideOccupation"
          type="button"
          class="px-5 py-2 !border text-sm font-medium"
        />
      </div>
    </section>
    <div class="flex items-center justify-center gap-3">
      <Button
        label="Back"
        @click="props.prevCallback"
        type="button"
        class="w-full sm:w-[25%] py-2"
      />

      <Button
        label="Next"
        @click="handleNext"
        type="button"
        class="w-full sm:w-[25%] py-2"
      />
    </div>
  </section>
</template>
<script setup>
import { reactive, ref, computed, onMounted, watch } from "vue";
import CompaniesSelectGroup from "@/components/forms/SelectGroups/Companies.vue";
import RolesSelectGroup from "@/components/forms/SelectGroups/Roles.vue";
import SelectGroup from "@/components/forms/SelectGroup.vue";
import { useForm } from "@inertiajs/vue3";
import { EMPLOYMENT_TYPES, OccupationStatus } from "../menus";
import { useToastr } from "@/composables/useToastr";
import ValidationError from "@/components/forms/validationError.vue";
const { warn } = useToastr();
const props = defineProps(["roles", "companies", "nextCallback", "prevCallback"]);
const skip = ref(false);
const form = useForm({
  occupation_status: OccupationStatus.at(0).id,
  role: null, // employed
  employment_type: null, // employed
  degree: null, // Student
  company: null, // employed
  college: null, // student
});

const handleHideOccupation = () => {
  form.occupation_status =
    form.occupation_status == "hidden" ? OccupationStatus.at(0).id : "hidden";
  if (form.occupation_status === "hidden") skip.value = true;
  else skip.value = false;
};
const handleNext = () => {
  form.post(route("onboarding.occupation_info.submit"), {
    only: [],
    onSuccess: () => {
      props.nextCallback();
    },
    onError: () => {
      warn("Some fields are missing!");
    },
    preserveState: true,
  });
};
</script>
