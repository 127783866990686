<template>
  <section class="mx-auto p-0 w-[90%] flex flex-col gap-6">
    <section class="flex flex-col gap-4">
      <div class="w-[100%] mx-auto flex flex-col gap-2">
        <h1
          class="m-0 text-black text-3xl font-medium text-center mt-8 px-8 py-0">
          Add a splash of beauty to your bizlycard.
        </h1>
        <h1
          class="m-0 text-text-700 text-sm font-normal tracking-[0.5px] text-center">
          Elevate your
          <b><em>bizlyCard</em></b>
          with captivating photos that showcase your career, work, or life. Make
          it stand out on the web and mobile apps, leaving a lasting impression.
        </h1>
      </div>
      <TextSeparator text="Mobile cover photos"></TextSeparator>
      <section class="w-full text-center flex flex-col gap-4">
        <img
          :src="mobileSplashCover"
          class="mx-auto"
          alt="Mobile_Cover_Splash" />
        <h3 class="font-normal text-sm text-text-700">
          These photos will be used as the background of your
          <span class="italic font-semibold">bizly</span>
          Card on the mobile app.
        </h3>
        <em>
          <h3 class="text-lightBg-800 text-sm">
            Hint: 9x16 images will give you the best results. (Max : 5)
          </h3>
        </em>
        <FileInput
          @update="(files) => handleMobileCovers(files)"
          :maxFileSize="10000000"
          :multiple="true"
          accept="image/*"
          name="mobile[]"
          :fileLimit="5" />
      </section>
      <TextSeparator text="Webapp cover photo"></TextSeparator>
      <section class="w-full text-center flex flex-col gap-4">
        <img :src="webSplashCover" class="m-auto" alt="Web_Cover_Splash" />
        <h3 class="font-normal text-sm text-text-700">
          This photo will be the background image of your webapp
          <span class="font-semibold italic">bizly</span>
          Card.
        </h3>
        <em>
          <h3 class="text-lightBg-800 text-sm">
            Hint: A 16x9 image will give you the best results. (Max : 1)
          </h3>
        </em>
        <FileInput
          @update="(files) => (form.web = files)"
          :maxFileSize="10000000"
          :multiple="false"
          accept="image/*"
          name="web"
          :fileLimit="1"
          :res="{ height: 1080, width: 1920 }" />
      </section>
    </section>
    <div class="flex items-center justify-center gap-3">
      <Button
        label="Back"
        @click="props.prevCallback"
        type="button"
        class="w-full sm:w-[25%] py-2" />

      <Button
        :disabled="!canUpload"
        label="Next"
        @click="submit"
        type="button"
        class="w-full sm:w-[25%] py-2" />
    </div>
  </section>
</template>
<script setup>
import { reactive, ref, computed, onMounted, watch } from "vue";
import webSplashCover from "@/assets/images/register/web_cover_splash.jpg";
import mobileSplashCover from "@/assets/images/register/mobile_cover_splash.jpg";
import TextSeparator from "@/components/general/TextSeparator.vue";
import { useForm } from "@inertiajs/vue3";
import FileInput from "@/components/forms/FileInput.vue";
import { useToastr } from "@/composables/useToastr";
const { error } = useToastr();
const props = defineProps(["nextCallback", "prevCallback"]);
const form = useForm({
  mobile: [],
  web: null,
});
const canUpload = computed(
  () => form.mobile.length <= 5 && form.mobile.length !== 0 && form.web,
);
const mobileCovers = ref();
const submit = () => {
  form.post(route("onboarding.upload_images")); // Backend redirect
};
const handleMobileCovers = (files) => {
  if (files.length > 5) {
    error("You should upload a maximum of 5 covers");
  }
  form.mobile = files;
};
</script>
