<template>
    <HomeLayout v-bind="props">
        <HomeFeed />
    </HomeLayout>
</template>
<script lang="ts" setup>
import HomeFeed from "./parts/HomeFeed.vue";
import HomeLayout from "@/components/layout/HomeLayout.vue";

const props = defineProps({
});
</script>