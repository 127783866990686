<template>
    <Layout>

        <div
            class="block justify-between items-center p-4 mx-4 mt-4 mb-6 bg-white rounded-2xl shadow-xl shadow-gray-200 lg:p-5 sm:flex h-[100%]">
            <div class="mb-1 w-full">
                <div class="mb-4">
                    <nav class="flex mb-5" aria-label="Breadcrumb">
                        <ol class="inline-flex items-center space-x-1 md:space-x-2">
                            <li>
                                <div class="flex items-center">
                                    <i class="fad fa-industry text-primary-500"></i>
                                    <Link :href="route('admin.social_posts.index')"
                                        class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2">Social Posts
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <div class="flex items-center">
                                    <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                </div>
                            </li>
                        </ol>
                    </nav>
                    <div class="flex items-center justify-between">
                        <h1 class="text-xl font-semibold text-gray-900 sm:text-2xl">Edit social post</h1>
                        <div class="flex gap-2">
                            <Button type="button" @click="window.history.back()" icon="fad fa-arrow-left" label="Back"
                                severity="secondary"></Button>
                            <Divider layout="vertical" type="dashed" severity="secondary" />
                            <Button @click="submit" icon="fad fa-check" label="Save"></Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-col mt-6 mb-8 mx-4 rounded-2xl bg-white shadow-xl shadow-gray-200 p-10">
            <div class="grid grid-cols-10 gap-x-6 gap-y-8 border-b border-gray-900/10 pb-12">

                <div class="col-span-4">
                    <label for="title" class="block text-sm font-medium leading-6 text-gray-900">Title</label>
                    <div class="mt-2">
                        <InputText v-model="form.title" placeholder="Title" class="w-full"
                            :invalid="form.errors.title != null" />
                        <ValidationError :errors="form.errors" name="Title" class="self-start" />
                    </div>
                </div>

            </div>
            <div class="mt-6 space-y-6 border-gray-900/10">
                <div class="relative flex gap-x-3">
                    <div class="flex h-6 items-center">
                        <Button type="button" @click="form.is_active = !form.is_active"
                            :icon="form.is_active ? 'fa-solid fa-toggle-on fa-xl' : 'fa-solid  fa-toggle-off fa-xl'"
                            text :pt="{
                                        root: {
                                            class: '!p-1 !w-8'
                                        }
                                    }" :ptOptions="{ mergeProps: true }">
                        </Button>
                    </div>
                    <div class="text-sm leading-6">
                        <label for="is_active" class="font-medium text-gray-900">Is active</label>
                        <ValidationError :errors="form.errors" name="is_active" class="self-start" />
                    </div>
                </div>
                <div class="relative flex gap-x-3">
                    <div class="flex h-6 items-center">
                        <Button type="button" @click="form.is_network_only = !form.is_network_only"
                            :icon="form.is_network_only ? 'fa-solid fa-toggle-on fa-xl' : 'fa-solid  fa-toggle-off fa-xl'"
                            text :pt="{
                                        root: {
                                            class: '!p-1 !w-8'
                                        }
                                    }" :ptOptions="{ mergeProps: true }">
                        </Button>
                    </div>
                    <div class="text-sm leading-6">
                        <label for="is_network_only" class="font-medium text-gray-900">Is network only</label>
                        <ValidationError :errors="form.errors" name="is_network_only" class="self-start" />
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-col mt-6 mb-8 mx-4 rounded-2xl bg-white shadow-xl shadow-gray-200 p-10">
            <div class="sm:flex">
                    <div class="hidden items-center mb-3 sm:flex sm:divide-x sm:divide-gray-100 sm:mb-0">
                        <form class="lg:pr-3" action="#" method="GET">
                            <label for="comments-search" class="sr-only">Search</label>
                            <div class="relative mt-1 lg:w-64 xl:w-96">
                                <input type="text" name="search" v-model="lazyParams.search" id="comments-search"
                                    @input="globalSearch"
                                    class="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-2 focus:ring-fuchsia-50 focus:border-fuchsia-300 block w-full p-2.5"
                                    placeholder="Search for comments">
                            </div>
                        </form>
                        <label class="mr-2">Active Only</label>
                        <Button type="button"
                            @click="{ lazyParams.active_only = !lazyParams.active_only; globalSearch() }"
                            :icon="lazyParams.active_only ? 'fa-solid fa-toggle-on fa-xl' : 'fa-solid  fa-toggle-off fa-xl'"
                            v-tooltip.top="'Show active records only'" rounded text :pt="{
                                        root: {
                                            class: '!p-1 !w-8'
                                        }
                                    }" :ptOptions="{ mergeProps: true }">
                        </Button>
                    </div>
                    <div class="flex items-center ml-auto space-x-2 sm:space-x-3">
                        <Button type="button" class="bg-red-500 hover:bg-red-600" @click="deleteRecords"
                            v-if="selectedRows.length" :badge="selectedRows.length.toString()"
                            badgeSeverity="danger-inverse" icon="fa-regular fa-trash-alt" label="Delete">
                        </Button>
                    </div>
                </div>
            <div class="overflow-x-auto rounded-2xl">
                <div class="inline-block min-w-full align-middle">
                    <div class="overflow-hidden shadow-lg">
                        <DataTable :value="rows" sortField="id" :sortOrder="-1" lazy paginator :first="first" :rows="10"
                            :rowsPerPageOptions="[5, 10, 20, 50]" ref="dt" dataKey="id" :totalRecords="totalRecords"
                            :loading="loading" @page="onPage($event)" @sort="onSort($event)"
                            v-model:selection="selectedRows" :selectAll="selectAll"
                            @select-all-change="onSelectAllChange" @row-select="onRowSelect"
                            @row-unselect="onRowUnselect" tableStyle="min-width: 75rem" resizableColumns
                            columnResizeMode="fit" :pt="AdminDatatablePreset">
                            <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                            <Column field="id" header="# ID" sortable>
                            </Column>
                            <Column field="comment" header="Comment" sortable>
                            </Column>
                            <Column field="owner.full_name" header="Owner" sortable>
                            </Column>
                            <Column field="active" header="Active" sortable>
                                <template #body="slotProps">
                                    <Button type="button"
                                        @click="toggleCommentActive(slotProps.data.id, !slotProps.data.is_active)"
                                        :icon="slotProps.data.is_active ? 'fa-solid fa-toggle-on fa-xl' : 'fa-solid  fa-toggle-off fa-xl'"
                                        v-tooltip.top="'Activate/Deactivate Comment'" rounded text :pt="{
                                        root: {
                                            class: '!p-1 !w-8'
                                        }
                                    }" :ptOptions="{ mergeProps: true }">
                                    </Button>

                                </template>
                            </Column>
                            <Column field="actions" header="Actions">
                                <template #body="slotProps">

                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
</template>
<script lang="ts" setup>

import Layout from "@/components/layout/admin/Layout.vue";
import { route } from "ziggy-js";
import { useForm, router } from "@inertiajs/vue3";
import ValidationError from "@/components/forms/validationError.vue";
import { Link } from "@inertiajs/vue3";
import AdminDatatablePreset from "@/assets/tailwind-preset/datatable/admin-datatable";
import { API } from "@/core/api";
import { onMounted, ref } from "vue";
import _debounce from "lodash/debounce";

const props = defineProps({
    post: Object
});

const form = useForm({
    title: props.post?.title,
    is_active: props.post?.is_active ?? false,
    is_network_only: props.post?.is_network_only ?? false
});

const submit = () => {
    form.post(route('admin.social_posts.update', props.post?.id), {
        preserveScroll: true
    });
};

onMounted(() => {
    loading.value = true;

    lazyParams.value = {
        first: 0,
        rows: 10,
        sortField: null,
        sortOrder: null,
        search: null,
        active_only: true
    };

    loadLazyData();
});

const SocialPostCommentsAPI = new API.AdminSocialPostComments();
const dt = ref();
const loading = ref(false);
const totalRecords = ref(0);
const rows = ref();
const selectedRows = ref([]);
const selectAll = ref(false);
const first = ref(0);

const lazyParams = ref<any>({});
const loadLazyData = (event: any | null = null) => {
    resetSelectAll();
    loading.value = true;
    lazyParams.value = { ...lazyParams.value, first: event?.first || first.value };

    SocialPostCommentsAPI.get(props.post.id, lazyParams.value).then((res) => {
        rows.value = res.data;
        totalRecords.value = res.meta.total;
        loading.value = false;
    });
};
const onPage = (event: any) => {
    lazyParams.value = event;
    loadLazyData(event);
};
const onSort = (event: any) => {
    lazyParams.value = event;
    loadLazyData(event);
    console.log(event);
};

const globalSearch = _debounce(async () => {
    loadLazyData();
}, 500);

const resetSelectAll = () => {
    selectAll.value = false;
    selectedRows.value = [];
};

const onSelectAllChange = (event: any) => {
    selectAll.value = event.checked;

    if (selectAll.value) selectedRows.value = rows.value;
    else {
        selectAll.value = false;
        selectedRows.value = [];
    }
};
const onRowSelect = () => {
    selectAll.value = selectedRows.value.length === totalRecords.value;
};
const onRowUnselect = () => {
    selectAll.value = false;
};

const deleteRecords = () => {
    if (selectedRows.value.length) {
        router.post(route('admin.social_posts.comments.delete.selected'), { ids: selectedRows.value.map((comment: any) => comment.id) },
            {
                onSuccess: (res) => {
                    loadLazyData();
                    resetSelectAll();
                },
                onError: (error) => {
                    console.log('[DEBUG] :: social_posts/Manage.vue :: deleteRecords :: ', error);
                },
            }
        );
    }
}

const toggleCommentActive = (comment: number, active: boolean) => {
    router.post(route('admin.social_posts.comments.toggle_active', { comment }), { active: active },
        {
            onSuccess: (res) => {
                loadLazyData();
            },
            onError: (error) => {
                console.log('[DEBUG] :: social_posts/Index.vue :: toggleCommentActive :: ', error);
            },
        }
    );
}

</script>
