import { defineStore } from "pinia";

interface State {
  layout: string;
}

export const useExploreStore = defineStore("exploreStore", {
  state: (): State => ({
    layout: "full",
  }),
  getters: {},
  actions: {
    setLayout(layout: string) {
      this.layout = layout;
    },
  },
});
