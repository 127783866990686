<template>
  <section id="content" class="pt-[30px] pb-[40px] px-8 flex flex-col gap-3">
    <div class="flex flex-col gap-2.5 items-center">
      <h1 class="text-center text-lg text-white">Add portfolio content</h1>
      <h2 class="text-center text-sm text-main-disabled">
        Upload content to showcase your content and projects, giving visitors a
        clear picture of your capabilities.
      </h2>
    </div>
    <Card v-for="card in cards" :card="card" @click="handleSelectCard(card.id)" />
  </section>
</template>
<script setup lang="ts">
import Card from "../parts/Card.vue";
const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
});

const handleSelectCard = (content_type: String) => {
  props.modelValue.content_type = content_type;
};

interface Option {
  id: String;
  icon: String;
  title: String;
  description: String;
}
const cards: Option[] = [
  {
    id: "video_gallery",
    title: "video gallery",
    icon: "fa fa-play translate-x-[155%] translate-y-1",
    description: "Import video(s) from YouTube or Vimeo.",
  },
  {
    id: "image_gallery",
    title: "image gallery",
    icon: "fa fa-images translate-x-3 translate-y-1",
    description: "Upload image(s) from your device.",
  },
  {
    id: "link_gallery",
    title: "link gallery",
    icon: "fa fa-link translate-x-3 translate-y-1",
    description: "Add link(s) from the web.",
  },
  {
    id: "collection",
    title: "collection",
    icon: "fa fa-rectangle-history translate-x-[0.850rem] translate-y-1",
    description: "Host multiple gallery types in one page.",
  },
];
</script>
