<template>
    <div class="self-stretch justify-between items-center inline-flex">
        <div class="justify-center items-center gap-2.5 flex">
            <div class="size-[45px] lg:size-[50px] relative">
                <slot name="image"></slot>
                <div class="w-3 h-3 p-0.5 left-[30px] top-[32px] lg:left-[35px] lg:top-[37px] absolute  rounded-full border border-white justify-start items-start gap-2.5 inline-flex"
                    :class="{
                        'hidden': status === 'none',
                        'bg-[#434343]': status === 'offline',
                        'bg-main-lightGreen': status === 'online'
                    }">
                </div>
            </div>
            <div class="flex-col justify-center items-start gap-1 inline-flex">
                <div class="text-black text-xs lg:text-sm font-semibold capitalize leading-tight tracking-wide">
                    <slot name="title"></slot>
                </div>
                <div class="justify-start items-center gap-1 inline-flex">
                    <slot name="subtitle"></slot>
                </div>
            </div>
        </div>
        <div class="w-7 h-7 lg:w-8 lg:h-7 p-1.5 bg-switcherBlue-500 bg-opacity-10 rounded-full justify-center items-center flex"
            v-if="!!this.$slots.icon">
            <slot name="icon"></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        status: {
            type: String as () => 'online' | 'offline' | 'none',
            default: 'none'
        }
    }
});
</script>