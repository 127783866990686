<template>
  <Layout>
    <template #sidebar>
      <Sidebar
        @search="handleSearch"
        :loading="lazyLoading.loading"
        :count="count" />
    </template>

    <InfiniteScroll
      :callback="fetchUsers"
      scroll-region
      :showLoading="false"
      ref="feedContainer"
      id="feedContainer"
      class="px-8 pt-8"
      v-if="users.length">
      <div class="grid grid-cols-2 gap-4">
        <FullCard :user="user" v-for="user in users" />
      </div>
    </InfiniteScroll>
    <div
      class="h-[80vh] flex justify-center items-center"
      v-else-if="!users.length && !lazyLoading.loading">
      <div class="py-4 px-2 bg-white shadow-default w-1/2 text-center rounded">
        <p>
          Oh no! It looks like we didn’t find any matches with your filters.
          Maybe try tweaking them a bit to see more options.
        </p>
      </div>
    </div>
    <div
      v-else
      :class="
        cn('grid grid-cols-2 gap-4', {
          'px-8 pb-8': users.length,
          'p-8': !users.length,
        })
      ">
      <Skeleton
        v-for="i in lazyLoading.start == 0 ? 6 : 2"
        :key="i"
        width="100%"
        height="260px" />
    </div>
  </Layout>
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch } from "vue";
import FullCard from "@/components/contacts/content/FullCard.vue";
import Layout from "@/components/explore/Layout.vue";
import Sidebar from "@/components/explore/Sidebar.vue";
import InfiniteScroll from "@/components/infinite-scroll/InfiniteScroll.vue";
import { API } from "@/core/api";
import type { UserInterface } from "@/core/interfaces";
import { cn } from "@/utils/cn";
import type { PropType } from "vue";

/** ----------- PROPS ----------- */

/** ------------ COMPOSABLES ----------- */
const exploreAPI = new API.Explore();
const form = ref();
const lazyLoading = reactive({
  start: 0,
  size: 20,
  hasMore: true,
  loading: false,
});
/** ----------- STATE ----------- */
const users = ref<UserInterface[]>([]);
const count = ref<number | null>(null);

/** ----------- METHODS ----------- */
const handleSearch = async (payload: any) => {
  form.value = payload;
  lazyLoading.start = 0;
  lazyLoading.hasMore = true;
  users.value = [];
  await fetchUsers();
};

const fetchUsers = async () => {
  try {
    lazyLoading.loading = true;
    const response = await exploreAPI.search({ ...form.value, ...lazyLoading });
    users.value = [...users.value, ...response.data.users];
    count.value = response.data.count;
    lazyLoading.start += lazyLoading.size;
    lazyLoading.hasMore = response.data.length === lazyLoading.size;
  } catch (error) {
    console.log(error);
  } finally {
    lazyLoading.loading = false;
  }
};
onMounted(() => {
  fetchUsers();
});
</script>
