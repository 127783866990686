<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-0 w-full',
      root: 'mx-auto my-auto flex flex-col max-h-[90vh] items-center bg-lightBg-500 justify-center max-w-[600px] overflow-y-auto w-full rounded-[16px] scroll-hidden scroll-smooth',
    }"
    :visible="true"
    modal
    :closable="false">
    <div
      class="flex justify-between items-center p-3 sticky top-0 z-10 bg-lightBg-500 border-b-[1px] border-[#CDD5E1]"
      :class="{ 'mb-12': post.content.content_type.includes('image') }"
      id="header">
      <br />
      <h1 class="text-xl font-medium">Edit Social Post</h1>
      <CircleIcon @click="handleClose">
        <i class="fa-solid fa-x"></i>
      </CircleIcon>
    </div>

    <div id="content" class="space-y-2.5 px-[15px] py-2.5 z-0">
      <div
        class="py-[15px] px-[15px] rounded-[8px] flex flex-col w-full gap-2.5 bg-[#FeFeFe] boxShadowClass">
        <div class="flex gap-2">
          <img
            :src="user.avatar_image"
            class="rounded-full object-cover w-[50px] aspect-square" />
          <div class="flex flex-col flex-1">
            <span class="text-[#141414] text-sm font-semibold">
              {{ user.full_name }}
            </span>
            <Roles
              v-if="user.roles && user.roles.length !== 0"
              :items="user.roles.map((v) => v.name)"
              :fontSize="'12px'"
              :textColor="'#000'"
              :separatorWidth="'4px'"
              :separatorHeight="'4px'"
              :separatorColor="'#8c8c8c'" />
            <p
              v-if="
                post?.owner?.occupation?.role?.name &&
                post?.owner?.occupation?.company?.name
              "
              class="mb-0 text-xs font-semibold !text-main-secondary occupation-container">
              {{ post?.owner?.occupation?.role?.name }}
              <span class="font-normal">at</span>
              {{ post?.owner?.occupation?.company?.name }}
            </p>
          </div>
          <div
            @click="PostAccessibilityDialogVisible = true"
            class="rounded-[6px] px-2 py-2 flex ml-auto h-[30px] w-[140px] items-center justify-between gap-[5px] cursor-pointer bg-[#F7F8FB] border-[1px] border-[#CDD5E1] text-main-secondary text-xs">
            <div class="flex items-center gap-2" v-if="!form.accessibility">
              <i class="fa-sharp fa-light fa-globe"></i>
              <span class="text-sm !text-[#141414]">Everyone</span>
            </div>
            <div class="flex items-center gap-2" v-else>
              <i class="fa-light fa-user-group"></i>
              <span class="text-sm !text-[#141414]">Network</span>
            </div>
            <i class="fa fa-chevron-down"></i>
          </div>
        </div>
        <div
          class="w-full min-h-[44px] h-auto rounded-r-[16px] rounded-bl-[16px] rounded-tl-[4px] scroll-hidden relative bg-[#F7F8FB]">
          <div class="w-full">
            <div class="position-relative">
              <div class="relative">
                <Mentions
                  v-model="form.title"
                  placeholder="Add a message"
                  :heightBox="180"
                  :emptyOnEnter="true"
                  :inputHeight="35"
                  maxlength="1000" />
                <p
                  class="text-xs absolute right-4 top-1/2 -translate-y-1/2 text-main-secondary"
                  v-if="!form.title || form.title === ''">
                  (Optional)
                </p>
              </div>
            </div>
          </div>
        </div>
        <p class="mb-0 text-xs leading-4 text-[#8F9FB8]">
          Type @ to mention someone.
        </p>
      </div>
      <div class="w-full relative rounded-[8px] overflow-hidden">
        <div>
          <img
            :src="
              post?.content?.image_kit_id_16_9 ??
              post?.content?.thumbnail_image_kit_id ??
              post?.content?.image_kit_ids?.[0] ??
              'https://storage.googleapis.com/demo-api.bizly.net/static/app-templates/web-bg-min.jpg'
            "
            alt=""
            class="w-full object-cover"
            :class="{
              'aspect-video':
                post.content.content_type.includes('video') ||
                post.content.content_type.includes('link'),
              'aspect-square': post.content.content_type.includes('image'),
            }" />
          <InfoBallon :type="post.content.content_type">
            <template #count v-if="post?.content?.children?.length">
              1/{{ post?.content?.children?.length }}
            </template>
          </InfoBallon>
        </div>
      </div>
    </div>
    <div id="footer" class="sticky bottom-0">
      <div
        class="py-2.5 px-4 flex justify-end items-center gap-2 border-t-[1px] border-[#CDD5E1] content-modal-footer bg-[#F7F8FB]">
        <Button
          type="button"
          @click="handleSubmit"
          :disabled="isEditing && !form.isDirty"
          :label="isEditing ? 'Update' : 'Post'"
          :loading="form.processing"
          class="text-sm text-white px-4 h-[36px] w-[185px] rounded-[4px] !bg-[#1375c7] disabled:!bg-[#434343] hover:!bg-[#1375c7] hover:text-white cursor-pointer"></Button>
      </div>
    </div>
  </Dialog>
  <PostAccessibilityDialog
    v-if="PostAccessibilityDialogVisible"
    @close="PostAccessibilityDialogVisible = false"
    @change="handleAccessibilityChange"
    :accessibility="form.accessibility" />
  <DiscardChangesDialog
    v-if="DiscardChangesDialogVisible"
    @close="DiscardChangesDialogVisible = false"
    @discard="handleDiscardChanges" />
</template>
<script setup>
import CircleIcon from "@/components/icons/CircleIcon.vue";
import Roles from "@/components/general/Roles.vue";
import PostAccessibilityDialog from "./PostAccessibility.vue";
import DiscardChangesDialog from "./PostUnsavedChanges.vue";
import InfoBallon from "@/components/parts/InfoBallon.vue";
import { useForm } from "@inertiajs/vue3";
import { useHomeFeedStore } from "@/store/HomeFeed";
import { ref, computed } from "vue";
import { HomeFeedType } from "@/core/enums";
import { DialogType, useDialogStore } from "@/store/Dialog";
import Mentions from "@/components/mentions/Mentions.vue";
import { useToastr } from "@/composables/useToastr";
import { useUserBizcardStore } from "@/store/UserBizcard";
import { API } from "@/core/api";

const { success, error } = useToastr();
const dialogStore = useDialogStore();
const homeFeedStore = useHomeFeedStore();
const bizcardStore = useUserBizcardStore();
const socialPostAPI = new API.SocialPost();

const props = defineProps(["isVisible"]);
const emit = defineEmits(["close", "confirm"]);

const user = computed(() => dialogStore.getData(DialogType.POST_MANAGER)?.user);
const post = computed(() => dialogStore.getData(DialogType.POST_MANAGER)?.post);
const isEditing = computed(
  () => dialogStore.getData(DialogType.POST_MANAGER)?.post?.id !== null,
);
const PostAccessibilityDialogVisible = ref(false);
const DiscardChangesDialogVisible = ref(false);
const form = useForm({
  accessibility: post?.value?.is_network_only,
  title: post?.value?.title,
  content_id: post?.value?.content.id,
});
const handleAccessibilityChange = (value) => {
  form.accessibility = value;
  PostAccessibilityDialogVisible.value = false;
};
const handleDiscardChanges = () => {
  form.reset();
  PostAccessibilityDialogVisible.value = false;
  emit("close");
};

const handleClose = () => {
  if (form.isDirty) DiscardChangesDialogVisible.value = true;
  else emit("close");
};

const handleSubmit = async () => {
  if (isEditing.value) {
    form.post(route("social_posts.update", { post: post.value.id }), {
      onSuccess() {
        homeFeedStore.editPost(HomeFeedType.Community, post.value.id, {
          title: form.title,
          is_network_only: form.accessibility,
        });
        if (route().current() === "user.profile.index") {
          bizcardStore.editPost(post.value.id, {
            title: form.title,
            is_network_only: form.accessibility,
          });
        }
        emit("close");
      },
    });
  } else {
    const response = await socialPostAPI.store(form);
    if (response.error) {
      error(response.error);
    } else {
      dialogStore.hide(DialogType.POST_MANAGER);
      success("Post created successfully");
      homeFeedStore.addPost(HomeFeedType.Community, response.data);
      homeFeedStore.addPost(HomeFeedType.Connections, response.data);
    }
  }
};
</script>
