<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-0',
      root: 'overflow-hidden max-w-[500px] md:max-w-[650px] lg:max-w-[800px]  xl:max-w-[1150px] sm:mx-4 bg-white shadow-lg z-40 rounded-t-xl rounded-b-xl my-4',
    }"
    :visible="true"
    modal
    :closable="false"
  >
    <div class="w-full lg:w-[564px] p-[30px]">
      <div class="flex flex-col justify-center items-center gap-[16px]">
        <img
          :src="InviteFriendLetter"
          alt="InviteFriendLetter"
          width="75"
          height="75"
        />
        <div class="text-[28px] text-black leading-5 font-semibold">
          Invite a friend
        </div>
        <div class="text-main-secondaryBlue text-center text-[16px] w-[90%]">
          Make sure to double-check the details you're about to enter. Once
          they're in, we won't be able to make changes. Thanks a bunch!
        </div>
        <form class="w-full" autocomplete="off" @submit.prevent="submit">
          <div class="flex items-center justify-center mx-auto flex-col gap-2">
            <div class="w-[100%] flex gap-2">
              <div class="w-[100%] md:w-[50%] flex">
                <InputText
                  v-model="form.first_name"
                  class="w-full text-[#000510] pl-[15px] pr-[30px] py-[8px] border-[1px] rounded placeholder:text-[#8F9FB8] focus:outline-none border-[#8F9FB8] focus:border focus:border-[#2998FF]"
                  placeholder="First name"
                />
              </div>
              <div class="w-[100%] md:w-[50%] flex">
                <InputText
                  v-model="form.last_name"
                  class="w-full text-[#000510] pl-[15px] pr-[30px] py-[8px] border-[1px] rounded placeholder:text-[#8F9FB8] focus:outline-none border-[#8F9FB8] focus:border focus:border-[#2998FF]"
                  placeholder="Last name"
                />
              </div>
            </div>
            <div class="flex justify-start w-full">
              <div class="flex items-center mr-3">
                <input
                  v-model="form.type"
                  id="default-radio-1"
                  type="radio"
                  class="w-4 h-4 focus:ring-0 focus:outline-0 focus-border-0 text-blue-600 bg-gray-100 border-gray-300 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                  value="email"
                /><label
                  for="default-radio-1"
                  class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                  >Email</label
                >
              </div>
              <div class="flex items-center">
                <input
                  v-model="form.type"
                  id="default-radio-2"
                  type="radio"
                  class="w-4 h-4 focus:ring-0 focus:outline-0 focus-border-0 text-blue-600 bg-gray-100 border-gray-300 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                  value="phone"
                /><label
                  for="default-radio-2"
                  class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                  >Phone</label
                >
              </div>
            </div>
            <div class="w-[100%] flex">
              <InputText
                v-if="form.type === 'email'"
                v-model="form.email"
                type="email"
                class="w-full text-[#000510] pl-[15px] pr-[30px] py-[8px] border-[1px] rounded placeholder:text-[#8F9FB8] focus:outline-none border-[#8F9FB8] focus:border focus:border-[#2998FF]"
                placeholder="Enter email address"
              />
            </div>
            <div class="w-[100%]">
              <CountryInput
                class="w-full"
                @getPhoneValue="(value) => (form.phone = value.number)"
                @isValid="(value) => (isPhoneValid = value)"
                v-model="form.phone"
                v-if="form.type === 'phone'"
              />
            </div>
          </div>
          <div class="flex w-full pt-[30px] gap-[8px]">
            <button
              @click="$emit('close')"
              type="button"
              class="w-[40%] bg-transparent border border-primaryBlue-500 text-primaryBlue-500 px-[15px] py-[8px] rounded text-sm"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="w-[60%] text-white px-[15px] py-[8px] rounded text-sm bg-switcherBlue-500 disabled:bg-[#adadad]"
              :disabled="
                form.processing ||
                (form.type === 'phone' && !isPhoneValid) ||
                (form.type === 'email' && !isEmailValid) ||
                !form.first_name ||
                !form.last_name
              "
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  </Dialog>
</template>
<script setup>
import InviteFriendLetter from "@/assets/images/InviteFriendLetter.svg";
import CountryInput from "@/components/forms/countryInput.vue";
import { API } from "@/core/api";
import { useForm } from "@inertiajs/vue3";
import axios from "axios";
import { computed } from "vue";
import { ref } from "vue";
import { useToastr } from "@/composables/useToastr";

const invitationsAPI = new API.Invitations();
const { success, error } = useToastr();

const emit = defineEmits(["close", "append-invite"]);
const isPhoneValid = ref(false);
const form = useForm({
  first_name: null,
  last_name: null,
  email: null,
  phone: null,
  type: "email",
});

const isEmailValid = computed(() =>
  String(form.email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    ),
);

const submit = async () => {
  invitationsAPI.send(form).then((res) => {
    if (res.hasOwnProperty("error")) {
      error(res.error);
      return;
    }
    const response = res.data;
    if (response) {
      success("Invitation sent successfully");
      emit("append-invite", response);
    }
    form.first_name = null;
    form.last_name = null;
    form.email = null;
    form.phone = null;
    form.type = "email";
    isPhoneValid.value = false;
    emit("close");
  });
};
</script>
