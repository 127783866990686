<template>
    <div
        class="xs:w-full md:w-1/2 lg:w-auto p-5 bg-white rounded-lg shadow-lg flex-col justify-start items-center gap-4 inline-flex">
        <div class="self-stretch justify-between items-start inline-flex">
            <div class=" text-main-primary text-lg font-medium leading-7">20 Collaborators</div>
            <div class="text-right text-switcherBlue-500 text-sm font-medium leading-7">See All</div>
        </div>
        <div class="self-stretch flex-col justify-start items-start gap-2 lg:gap-3 flex">
            <UserList status="offline">
                <template #image>
                    <img class="rounded-full border-2 border-white" src="../../../../assets/images/sevag-collab.png" />
                </template>
                <template #title>Sevag chahinian</template>
                <template #subtitle>
                    <div class="opacity-80 text-black text-[11px] lg:text-sm font-light leading-3">
                        Cinematographer</div>
                    <div class="w-0.5 h-0.5 bg-zinc-300 rounded-full"></div>
                    <div class="opacity-80 text-black text-[11px] lg:text-sm font-light leading-3">
                        Director</div>
                </template>
                <template #icon><img src="../../../../assets/icons/message-outline.svg"></template>
            </UserList>
            <SectionDivider :margin=false />
            <UserList status="online">
                <template #image>
                    <img class="rounded-full border-2 border-white" src="../../../../assets/images/gordon.png" />
                </template>
                <template #title>Gordon Chan</template>
                <template #subtitle>
                    <div class="opacity-80 text-black text-[11px] lg:text-sm font-light leading-3">
                        Director</div>
                </template>
                <template #icon><img src="../../../../assets/icons/message-outline.svg"></template>
            </UserList>
            <SectionDivider :margin=false />
            <UserList>
                <template #image>
                    <img class="rounded-full border-2 border-white" src="../../../../assets/images/kyle.png" />
                </template>
                <template #title>Kyle Kartman</template>
                <template #subtitle>
                    <div class="opacity-80 text-black text-[11px] lg:text-sm font-light leading-3">
                        Swing</div>
                </template>
                <template #icon><img src="../../../../assets/icons/user-plus.svg"></template>
            </UserList>
            <SectionDivider :margin=false />
            <UserList>
                <template #image>
                    <img class="rounded-full border-2 border-white" src="../../../../assets/images/mason.png" />
                </template>
                <template #title>Mason Mullet</template>
                <template #subtitle>
                    <div class="opacity-80 text-black text-[11px] lg:text-sm font-light leading-3">
                        Key Grip
                    </div>
                </template>
                <template #icon><img src="../../../../assets/icons/user-plus.svg"></template>
            </UserList>
            <SectionDivider :margin=false />
            <UserList>
                <template #image>
                    <img class="rounded-full border-2 border-white" src="../../../../assets/images/megan.png" />
                </template>
                <template #title>Megan Zhaou</template>
                <template #subtitle>
                    <div class="opacity-80 text-black text-[11px] lg:text-sm font-light leading-3">
                        Colorist
                    </div>
                </template>
                <template #icon><img src="../../../../assets/icons/user-plus.svg"></template>
            </UserList>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SectionDivider from "../../../parts/SectionDivider.vue";
import UserList from '../../../parts/UserList.vue';

export default defineComponent({
    components: {
        UserList,
        SectionDivider
    }
});
</script>