import baseApi from "./Base";
import { route } from "ziggy-js";

export class ProjectTypesService extends baseApi {
  async get(form: any) {
    return await this.request(
      route("bizcards.project-types.get"),
      "POST",
      form,
    );
  }
}
