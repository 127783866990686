<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-0 w-full flex flex-col relative',
      root: ' bg-white max-w-[450px] w-full rounded-[16px]  scroll-hidden',
    }"
    :visible="true"
    modal
    :closable="false">
    <section id="header" class="bg-lightBg-500 rounded-t-[16px]">
      <div
        class="flex justify-between items-center border-b border-main-divider py-3 px-4">
        <br />
        <h1 class="text-main-primary text-xl font-semibold">
          {{ isEditing ? "Edit link" : "Add a link" }}
        </h1>
        <CircleIcon @click="$emit('close')">
          <i class="fa fa-xmark"></i>
        </CircleIcon>
      </div>
    </section>
    <section id="content" class="space-y-4 p-4">
      <p class="text-center text-xs text-main-secondaryBlue">
        Add up to 5 links to show up on your profile!
        <br />
        Edit the title and link to bring it to life.
      </p>
      <div class="flex flex-col gap-2">
        <IconField shape="rounded">
          <p
            class="text-xs"
            :class="{ '!text-red-500': form.title?.length >= 50 }">
            {{ form.title?.length ?? 0 }}/50
          </p>
          <InputText
            v-model="form.title"
            type="text"
            placeholder="Title"
            maxlength="50"
            :class="
              cn('!border !border-main-divider !pr-12', {
                '!border-switcherBlue-500 focus:!ring-switcherBlue-500':
                  form.title?.length > 0 && form.title?.length < 50,
                '!border-red-500 focus:!ring-red-500': form.title?.length >= 50,
              })
            " />
        </IconField>
        <div class="relative w-full">
          <IconField shape="rounded">
            <i
              :class="
                cn('fa ', {
                  'fa-spinner-third fa-spin': isLoading,
                  'fa-check-circle !text-switcherBlue-500':
                    !isLoading && isValid.valid,
                  'fa-times-circle !text-red-500':
                    !isLoading && isValid.valid === false,
                })
              "></i>
            <InputText
              v-model="form.link"
              type="text"
              placeholder="Link to"
              :class="
                cn('w-full !border !border-main-divider', {
                  '!border-switcherBlue-500 focus:!ring-switcherBlue-500':
                    !isLoading && isValid.valid,
                  '!border-red-500 focus:!ring-red-500':
                    !isLoading && isValid.valid === false,
                })
              "
              @input="handleMetaData" />
          </IconField>
          <p
            v-if="isValid.valid === false"
            class="text-xs text-red-500 absolute -bottom-4 left-0">
            {{ isValid.message }}
          </p>
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <div class="flex gap-2">
          <Button
            type="button"
            label="Preview"
            class="flex-1 focus:!ring-0"
            :disabled="isValid.valid !== true"
            @click="handleOpenUrl"></Button>
          <Button
            type="button"
            :label="isEditing ? 'Update' : 'Add'"
            class="flex-1 focus:!ring-0"
            @click="handleConfirm"></Button>
        </div>
        <Button
          v-if="isEditing"
          type="button"
          label="Delete"
          outlined
          severity="danger"
          class="w-full"
          @click="handleDelete" />
      </div>
    </section>
  </Dialog>
</template>
<script setup>
import { useForm } from "@inertiajs/vue3";
import { reactive, ref, computed, onMounted, watch } from "vue";
import { useDialogStore, DialogType } from "@/store/Dialog";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { API } from "@/core/api";
import _debounce from "lodash/debounce";
import { cn } from "@/utils/cn";

const dialogStore = useDialogStore();
const contentAPI = new API.Content();

const emit = defineEmits(["close", "delete", "confirm"]);
const isEditing = computed(
  () => dialogStore[DialogType.MANAGE_LINK].data?.link !== undefined,
);
const form = useForm({
  title: dialogStore[DialogType.MANAGE_LINK].data?.title ?? "",
  link: dialogStore[DialogType.MANAGE_LINK].data?.link ?? [],
});

const isValid = reactive({
  valid: isEditing.value ? true : null,
  message: "",
});
const isLoading = ref(false);

const handleConfirm = () => {
  console.log("here?>");
  emit("confirm", form);
};
const handleDelete = () => emit("delete", form);

const handleOpenUrl = () => {
  let url = form.link;
  // Check if the URL does not start with 'http://' or 'https://'
  if (!/^https?:\/\//i.test(url)) {
    // Prepend 'https://' to the URL
    url = "https://" + url;
  }

  window.open(url, "_blank");
};

const handleMetaData = _debounce(async () => {
  if (!form.link) {
    isValid.valid = null;
    return;
  }
  isLoading.value = true;
  const response = await contentAPI.getLinkMeta(form.link);
  isLoading.value = false;
  if (response.hasOwnProperty("data")) {
    isValid.valid = true;
  } else if (response.hasOwnProperty("error")) {
    isValid.valid = false;
    isValid.message = response.error;
  }
}, 250);
</script>
