<template>
  <div class="relative h-full flex flex-col justify-between pt-[18px]">
    <div class="flex flex-col mb-[15px] items-center justify-center">
      <span
        class="text-main-secondaryBlue font-normal text-xs leading-5 text-center md:text-sm">
        These photos will be used as the background of your bizlyCard on the
        mobile app.
      </span>
      <span
        class="text-main-secondaryBlue font-normal text-xs leading-5 text-center md:text-sm">
        (Hint: 9x16 images will give you the best results.)
      </span>
    </div>
    <div class="bg-black px-0 w-full scroll-hidden overflow-y-auto">
      <div class="m-1 flex flex-wrap md:-m-2 items-center justify-center">
        <div class="flex flex-col gap-1 mt-2 items-center w-full py-4">
          <div class="relative" style="width: 190px; aspect-ratio: 9 / 16">
            <div v-if="editing == null">
              <img
                alt="gallery"
                class="w-full object-cover relative rounded-[4px] h-full"
                :src="selectedImage.image" />
              <div
                class="absolute bottom-5 items-center w-full justify-center hidden md:flex gap-2">
                <CircleIcon class="bg-black/50" @click="handleDeleteImage">
                  <i class="fal fa-trash-can text-white text-sm"></i>
                </CircleIcon>
                <FileUpload
                  mode="advanced"
                  name="demo[]"
                  url="/api/upload"
                  accept="image/*"
                  customUpload
                  :auto="true"
                  @uploader="handleReplace"
                  :pt="{
                    root: 'flex items-center',
                    content: 'hidden',
                  }">
                  <template #header="{ chooseCallback }">
                    <CircleIcon class="bg-black/50" @click="chooseCallback">
                      <i class="fal fa-pencil text-white text-sm"></i>
                    </CircleIcon>
                  </template>
                </FileUpload>
                <CircleIcon class="bg-black/50" @click="editing = true">
                  <i class="fal fa-crop-simple text-white text-sm"></i>
                </CircleIcon>
                <span
                  class="flex items-center justify-center p-2 rounded-full h-8 text-[11px] text-white bg-[#141414a3] w-8">
                  {{ form.images.length }}/5
                </span>
              </div>
            </div>
            <div class="relative" v-else>
              <div
                class="absolute z-50 inset-0 cropper aspect-video w-full h-full flex items-center justify-center rounded-[8px] object-cover text-white"
                v-if="cropperLoading">
                <i
                  class="fa-sharp-duotone fa-solid fa-spinner-third animate-spin text-xl"></i>
              </div>
              <Cropper
                ref="cropperEl"
                @ready="cropperLoading = false"
                class="cropper aspect-[9/16] object-cover w-full h-full"
                :src="selectedImage.default"
                :stencil-props="{
                  aspectRatio: 9 / 16,
                }"></Cropper>
              <div
                class="absolute bottom-5 items-center w-full justify-center hidden md:flex gap-2">
                <CircleIcon class="bg-black/50" @click="() => (editing = null)">
                  <i class="fal fa-times text-white text-sm"></i>
                </CircleIcon>
                <CircleIcon
                  class="bg-switcherBlue-500 hover:bg-switcherBlue-600"
                  @click="handleCrop">
                  <i class="fal fa-check text-white text-sm"></i>
                </CircleIcon>
              </div>
            </div>
          </div>
          <div class="w-full overflow-x-auto scroll-hidden">
            <div class="flex gap-2 justify-center">
              <draggableComponent
                class="draggable-container flex gap-4"
                item-key="order"
                :component-data="{
                  tag: 'ul',
                  name: 'flip-list',
                  type: 'transition',
                }"
                v-model="form.images"
                v-bind="dragOptions">
                <template #item="{ element, index }">
                  <div
                    :class="
                      cn(
                        'relative border-[2px] border-transparent rounded-[4px]',
                        {
                          'border-switcherBlue-500':
                            selectedImage.default === element.default,
                        },
                      )
                    "
                    @click.stop="() => (selectedImage = element)">
                    <img
                      alt="gallery"
                      class="rounded-[2px] object-cover object-center cursor-pointer max-none h-[100px] w-[100px]"
                      :src="element.image" />
                    <InfoBallon class="top-1 !left-1">
                      <template #count>
                        <span class="text-xs">
                          {{ index + 1 }}/{{ form.images.length }}
                        </span>
                      </template>
                    </InfoBallon>
                  </div>
                </template>
              </draggableComponent>
              <FileUpload
                v-if="form.images.length < maxImages"
                mode="advanced"
                name="demo[]"
                url="/api/upload"
                accept="image/*"
                customUpload
                :auto="true"
                @uploader="handleAdd"
                :pt="{
                  root: 'flex items-center',
                  content: 'hidden',
                }">
                <template #header="{ chooseCallback }">
                  <div
                    class="flex items-center justify-center flex-col text-[#6D6D6D] min-w-[100px] aspect-square">
                    <div
                      @click="chooseCallback"
                      for="WebCover"
                      class="flex flex-col items-center justify-center w-full bg-transparent h-full border-[2px] border-dashed border-[#39537D] cursor-pointer rounded-lg">
                      <div
                        class="flex flex-col items-center justify-center py-3">
                        <div class="p-2">
                          <svg
                            width="23"
                            height="27"
                            viewBox="0 0 23 27"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M2.06667 0C0.924833 0 0 0.924833 0 2.06667V19.6333C0 20.7752 0.924833 21.7 2.06667 21.7H10.3333V8.69453L7.96393 11.0639C7.5599 11.468 6.90677 11.468 6.50273 11.0639C6.0987 10.6599 6.0987 10.0068 6.50273 9.60273L10.6361 5.4694C10.8376 5.2679 11.1021 5.16667 11.3667 5.16667C11.6312 5.16667 11.8958 5.2679 12.0973 5.4694L16.2306 9.60273C16.6346 10.0068 16.6346 10.6599 16.2306 11.0639C15.8266 11.468 15.1734 11.468 14.7694 11.0639L12.4 8.69453V21.7H20.6667C21.8085 21.7 22.7333 20.7752 22.7333 19.6333V2.06667C22.7333 0.924833 21.8085 0 20.6667 0H2.06667ZM12.4 21.7H10.3333V25.8333C10.3333 26.4037 10.7963 26.8667 11.3667 26.8667C11.9371 26.8667 12.4 26.4037 12.4 25.8333V21.7Z"
                              fill="#CDD5E1"></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </FileUpload>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch } from "vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import type { UserInterface } from "@/core/interfaces";
import type { PropType } from "vue";
import { route } from "ziggy-js";
import draggableComponent from "vuedraggable";
import DefaultBg from "@/assets/images/bgPics/bg.png";
import { cn } from "@/utils/cn";
import InfoBallon from "@/components/parts/InfoBallon.vue";
import { useForm } from "@inertiajs/vue3";
import { Cropper } from "vue-advanced-cropper";

const props = defineProps({
  user: {
    type: Object as PropType<UserInterface>,
    required: true,
  },
});
const emit = defineEmits(["canSave"]);

const modelForm = defineModel({
  type: Object,
  required: true,
});

/** --------- STATIC ------- */
const maxImages = 5;
const dragOptions = {
  animation: 200,
  group: "description",
  disabled: false,
  ghostClass: "ghost",
  scrollSensitivity: 200,
  forceFallback: true,
};

/** ---------- REACTIVE --------- */
const cropperEl = ref<null | any>(null);
const editing = ref<null | Boolean>(null);
const cropperLoading = ref(false);
const user = computed(() => props.user);
const form = useForm({
  images: modelForm.value.mobile_covers,
});
const selectedImage = ref(form.images[0] ?? DefaultBg);

/** ---------- METHODS ---------- */

const handleReplace = (event: any) => {
  const file = event.files[0];
  const reader = new FileReader();
  const idx = form.images.findIndex((i) => i === selectedImage.value);
  if (idx === -1) return;
  reader.onload = function (e: any) {
    form.images[idx] = {
      default: e.target.result,
      image: e.target.result,
    };
    selectedImage.value = form.images[idx];
  };
  reader.readAsDataURL(file);
};
const handleAdd = (event: any) => {
  const file = event.files[0];
  const reader = new FileReader();
  reader.onload = function (e: any) {
    form.images.push({
      default: e.target.result,
      image: e.target.result,
    });
    selectedImage.value = form.images.at(-1);
  };
  reader.readAsDataURL(file);
};
const handleCrop = () => {
  const { canvas } = cropperEl.value.getResult();
  if (canvas) {
    const base64 = canvas.toDataURL();
    if (editing.value !== null) {
      form.images = form.images.map((image) => {
        if (image.default === selectedImage.value.default) {
          return { ...image, image: base64 };
        }
        return image;
      });
      selectedImage.value = { ...selectedImage.value, image: base64 };
    }
  }
  editing.value = null;
};

const handleDeleteImage = () => {
  const idx = form.images.findIndex((i) => i === selectedImage.value);
  if (idx !== -1) form.images.splice(idx, 1);
  selectedImage.value = form.images[0];
};

/* ---------- WATCHERS ---------- */
watch(
  () => form.images,
  (newVal) => {
    modelForm.value.mobile_covers = newVal;
  },
  { immediate: true },
);

watch(editing, (val) => {
  emit("canSave", val === null);
});
</script>
