import { API } from "@/core/api";
import { type ContactGroupInterface } from "@/core/interfaces";
import { defineStore } from "pinia";
import type { UserInterface } from "@/core/interfaces";
import { useToastr } from "@/composables/useToastr";
const { success, error } = useToastr();
const contactsAPI = new API.Contacts();
interface State {
  layout: string;
  groups: ContactGroupInterface[];
  favorites: UserInterface[];
}

export const useContactsStore = defineStore("contactsStore", {
  state: (): State => ({
    layout: "full",
    groups: [],
    favorites: [],
  }),
  getters: {
    favorites_count: (state) => state.favorites.length,
  },
  actions: {
    init() {
      this.fetchRequests();
      this.fetchGroups();
    },
    async fetchRequests() {
      if (this.requests) return;
      const connAPI = new API.Connections();
      const response = await connAPI.getRequestsCount();
      this.requests = response.data.count;
    },
    fetchGroups(force: Boolean = false) {
      if (this.groups.length && !force) return;
      const contactGroupsAPI = new API.ContactGroups();
      contactGroupsAPI.get().then((response) => {
        this.groups = response.data;
      });
    },
    addGroup(group: ContactGroupInterface) {
      this.groups.push(group);
    },
    updateGroup(group: ContactGroupInterface) {
      if (!group.id) return;
      const idx = this.groups.findIndex((g) => g.id === group.id);
      if (idx !== -1) this.groups[idx] = group;
    },
    removeGroup(groupId: number) {
      const idx = this.groups.findIndex((g) => g.id === groupId);
      if (idx !== -1) this.groups.splice(idx, 1);
    },
    setLayout(layout: string) {
      this.layout = layout;
    },
    setFavorites(users: UserInterface[]) {
      this.favorites = users;
    },
    isFavorite(userId: number) {
      return this.favorites.findIndex((f) => f.id === userId);
    },
    async toggleFavorite(user: UserInterface) {
      try {
        const FavoriteIdx = this.isFavorite(user.id);
        if (FavoriteIdx !== -1) {
          await contactsAPI.RemoveFromFavorites(user.id);
          this.favorites.splice(FavoriteIdx, 1);
        } else {
          await contactsAPI.AddToFavorites(user.id);
          this.favorites.push(user);
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
});
