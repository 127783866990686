<template>
  <SideImage :image="imagebg" :bg="bg">
    <OtpDialog
      :form="form"
      @completed="onCompletedOTP"
      ref="registerOtpDialog" />
    <div
      class="relative px-8 md:px-[2rem] py-3 lg:py-1 m-auto h-[100vh] overflow-y-auto scroll-hidden">
      <div class="h-screen flex justify-center items-center">
        <div class="w-full flex flex-col !justify-center my-auto">
          <div class="flex flex-col items-center text-center gap-3">
            <div class="leading-6 text-3xl font-medium">Sign up to bizly</div>
            <div class="text-sm text-primary tracking-tight font-normal">
              Already have an account?
              <span class="font-semibold text-md">
                <Link
                  :href="route('login')"
                  class="hover:!text-switcherBlue-500">
                  Log in
                </Link>
              </span>
            </div>
          </div>
          <form
            @submit.prevent="showOTPDialog"
            class="w-[80%] mx-auto flex flex-col gap-2"
            autocomplete="off">
            <TextSeparator text="Profile Picture" />
            <div class="flex items-center gap-2 justify-center">
              <label for="profile-picture">
                <img
                  :src="
                    image || 'https://ik.imagekit.io/bizapp/Sillhouette.png'
                  "
                  alt="profilePicture"
                  class="w-12 h-12 object-cover rounded-full cursor-pointer"
                  v-if="!imgLoading" />
              </label>
              <label
                for="profile-picture"
                class="flex flex-col cursor-pointer gap-0.5">
                <span class="text-xs text-primary">Upload your picture</span>
                <span class="flex gap-1 text-main-secondaryBlue items-center">
                  <i class="fa fa-upload text-primary"></i>
                  <span class="text-xs font-semibold text-primary">Upload</span>
                  <input
                    type="file"
                    id="profile-picture"
                    @change="onAvatarLoad"
                    class="hidden" />
                </span>
              </label>
            </div>

            <TextSeparator text="General Info" />
            <div class="flex gap-2">
              <div class="flex flex-col gap-1 flex-1">
                <InputText
                  placeholder="First Name"
                  v-model="form.first_name"
                  :invalid="validateStore.errors.first_name != null"
                  @input="(e) => handleValidation(e, 'first_name')" />
                <ValidationError
                  :errors="validateStore.errors"
                  name="first_name" />
              </div>
              <div class="flex flex-col gap-1 flex-1">
                <InputText
                  placeholder="Last Name"
                  v-model="form.last_name"
                  :invalid="validateStore.errors.last_name != null"
                  @input="(e) => handleValidation(e, 'last_name')" />
                <ValidationError
                  :errors="validateStore.errors"
                  name="last_name" />
              </div>
            </div>
            <div class="flex flex-col gap-1 flex-1">
              <InputText
                name="email"
                placeholder="Email"
                v-model="form.email"
                type="email"
                @change="checkEmail"
                :invalid="validateStore.errors.email != null"
                @input="(e) => handleValidation(e, 'email')" />
              <ValidationError :errors="validateStore.errors" name="email" />
            </div>
            <div class="flex flex-col gap-1">
              <CountryInput
                class="w-full mb-1"
                @getPhoneValue="(value) => handlePhoneChange(value)"
                @isValid="(v) => v && checkPhone()"
                :invalid="validateStore.errors.phone_number != null"
                :errors="validateStore.errors"
                errorName="phone_number" />
            </div>
            <div class="flex flex-col gap-1">
              <span class="relative w-full">
                <span
                  class="absolute top-2/4 -mt-2.5 right-3 z-10 cursor-pointer"
                  @click="showPassword = !showPassword">
                  <span v-if="showPassword">
                    <i class="far fa-eye text-primary-500"></i>
                  </span>
                  <span v-else>
                    <i class="far fa-eye-slash text-surface-500"></i>
                  </span>
                </span>
                <InputText
                  placeholder="Password"
                  v-model="form.password"
                  :type="!showPassword ? 'password' : 'text'"
                  class="pr-10 w-full"
                  :invalid="validateStore.errors.password != null"
                  @input="(e) => handleValidation(e, 'password')" />
              </span>
              <ValidationError :errors="validateStore.errors" name="password" />
            </div>

            <PasswordStrength
              :value="form.password"
              :page="'signup'"
              class="w-full"
              @getPasswordStatus="
                (value) => {
                  passwordStatus = value;
                }
              " />
            <TextSeparator :text="'Legal Disclaimers'" class="mt-[12px]" />
            <div class="text-primary text-[14px] text-center">
              Before we proceed, please make sure you have read our terms and
              conditions as well as our privacy policy.
            </div>
            <div class="flex justify-center flex-col sm:flex-row gap-3 mt-2">
              <Button
                @click="openUrl('https://www.bizly.net/terms')"
                label="Terms and conditions" />

              <Button
                @click="openUrl('https://www.bizly.net/privacy-policy')"
                label="Privacy policy" />
            </div>
            <hr class="!text-[#D4D7DF] !opacity-[1] my-3" />
            <div
              class="card flex flex-col justify-center gap-2 text-main-secondaryBlue">
              <div class="flex flex-col">
                <div class="flex items-center gap-1.5">
                  <Checkbox
                    v-model="form.checks"
                    inputId="termsOfService"
                    value="terms_of_service"
                    color="lightBlue"
                    @change="
                      (e) => handleValidation(e, 'checks.terms_of_service')
                    " />
                  <label
                    for="termsOfService"
                    class="text-xs flex items-center text-primary">
                    I have read and agree to the &nbsp;
                    <a
                      href="https://bizly.net/terms"
                      target="_blank"
                      class="underline"
                      @click.stop="
                        () => {
                          return false;
                        }
                      ">
                      Terms of Service
                    </a>
                    .
                    <span class="text-[red]">*</span>
                  </label>
                </div>
                <ValidationError
                  :errors="validateStore.errors"
                  name="checks.terms_of_service" />
              </div>
              <div class="flex flex-col">
                <div class="flex items-center gap-1.5">
                  <Checkbox
                    v-model="form.checks"
                    inputId="privacy_policy"
                    value="privacy_policy"
                    color="lightBlue"
                    @change="
                      (e) => handleValidation(e, 'checks.privacy_policy')
                    " />
                  <label
                    for="privacy_policy"
                    class="text-xs flex items-center text-primary">
                    I have read and agree to the &nbsp;
                    <a
                      href="https://www.bizly.net/privacy-policy"
                      target="_blank"
                      class="underline mx-0"
                      @click.stop="
                        () => {
                          return false;
                        }
                      ">
                      Privacy Policy
                    </a>
                    .
                    <span class="text-[red] inline">*</span>
                  </label>
                </div>
                <ValidationError
                  :errors="validateStore.errors"
                  name="checks.privacy_policy" />
              </div>
              <div class="flex items-center gap-1.5">
                <Checkbox
                  v-model="form.checks"
                  inputId="marketing"
                  value="marketing"
                  color="lightBlue" />
                <label
                  for="marketing"
                  class="text-xs flex items-center text-primary">
                  I would like to receive marketing communications, such as
                  newsletters and promotional offers, from bizly.
                </label>
              </div>
              <ValidationError :errors="form.errors" name="checks" />
            </div>
            <div class="flex w-52 mt-8 self-center">
              <Button
                type="submit"
                :loading="isLoading"
                label="Sign Up"
                class="flex-1 flex justify-center items-center !border-none text-sm py-2.5 focus:!ring-0"
                :disabled="validateStore.hasErrors || !form.isDirty"></Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </SideImage>
</template>
<script lang="ts" setup>
import OtpDialog from "@/components/dialogs/Otp.vue";
import bg from "@/assets/images/bgPics/bg.png";
import imagebg from "@/assets/images/bgPics/step-1.png";
import bizlyLogo from "@/assets/images/register/bizlyLogo.svg";
import CountryInput from "@/components/forms/countryInput.vue";
import PasswordStrength from "@/components/forms/passwordStrength.vue";
import TextSeparator from "@/components/general/TextSeparator.vue";
import SideImage from "@/components/layout/SideImage.vue";
import { imageToBase64 } from "@/utils/getBsse64Url.js";
import { Link, useForm } from "@inertiajs/vue3";
import { onMounted, onUnmounted, reactive, ref } from "vue";
import { route } from "ziggy-js";
import ValidationError from "@/components/forms/validationError.vue";
import _debounce from "lodash/debounce";
import axios from "axios";
import { useValidateStore } from "@/store/Validate";

// Define the type for the component instance
type OtpDialogInstance = InstanceType<typeof OtpDialog>;
const registerOtpDialog = ref<OtpDialogInstance | null>(null);

const validateStore = useValidateStore();
const showPassword = ref(false);
const stepImage = ref("");
const stepBg = ref("");
const profilePic = ref("");
const imgLoading = ref(false);
const isLoading = ref(false);
const passwordStatus = ref("");
const isOtpDialogVisible = ref(false);
const errors = reactive<any>({});
const form = useForm({
  first_name: undefined,
  last_name: undefined,
  email: undefined,
  password: undefined,
  phone_number: undefined,
  checks: [],
  avatar: undefined,
  otp_id: undefined,
});
const disclaimerAgreed = reactive({
  isTerms: false,
  isPolicy: false,
});
const image = ref("");
const isFormFilled = ref(false);

onMounted(() => {
  stepImage.value = imagebg;
  stepBg.value = bg;
  profilePic.value = bizlyLogo;
});
onUnmounted(() => {
  form.reset();
  validateStore.reset();
  disclaimerAgreed.isPolicy = false;
  disclaimerAgreed.isTerms = false;
});

const signUp = async () => {
  form.post(route("register.store"), {
    preserveScroll: true,

    onError: (errors) => {
      console.error(errors);
    },
  });
};

const validationSchema = {
  first_name: { required: true, type: "string" },
  last_name: { required: true, type: "string" },
  email: { required: true, type: "email" },
  phone_number: { required: true },
  password: { required: true },
  checks: { in_array: "terms_of_service,privacy_policy" },
};

const handleValidation = _debounce((e, inputName) => {
  if (!form.isDirty) return;
  validateStore.resetInputValidation(inputName);
  validateStore.validate(form, validationSchema);
}, 250);

const onAvatarLoad = async (e: any) => {
  const { files } = e.target;
  form.avatar = files[0];
  const url = await imageToBase64(files?.[0]);
  image.value = url;
};

/* SERVER SIDE VALIDATION FOR PHONE AND EMAIL */
const checkEmail = _debounce(async () => {
  if (!form.email) return;
  isLoading.value = true;
  try {
    const { data: response } = await axios
      .post(route("registration.verify_email"), { email: form.email })
      .finally(() => {
        isLoading.value = false;
      });
    if (response?.exists) {
      validateStore.setError({ email: response?.message });
    }
  } catch (error) {
    console.error(error);
    isLoading.value = false;
  }
}, 500);

const checkPhone = _debounce(async () => {
  if (!form.phone_number) return;
  try {
    isLoading.value = true;

    const response = await axios
      .post(route("registration.verify_phone_number"), {
        phone_number: form.phone_number,
      })
      .finally(() => {
        isLoading.value = false;
      });
    if (response?.data?.exists)
      validateStore.setError({ phone_number: response?.data?.message });
  } catch (error) {
    console.error(error);
    isLoading.value = false;
  }
}, 500);

const handlePhoneChange = (phone: any) => {
  form.phone_number = phone.number;
  handleValidation(null, "phone_number");
};

const onCompletedOTP = (data: any) => {
  form.otp_id = data.otp_id;
  isOtpDialogVisible.value = false;
  form.post(route("register.store"), {
    preserveScroll: true,
    onError: (errors) => {
      console.error(errors);
    },
  });
};

const showOTPDialog = () => {
  if (isOtpDialogVisible.value) return;
  else {
    isLoading.value = true;
    isOtpDialogVisible.value = true;
    if (registerOtpDialog.value) registerOtpDialog.value.show();
    isLoading.value = false;
  }
};

const openUrl = (url: string) => {
  window.open(url, "_blank");
};
</script>
