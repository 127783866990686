<template>
  <Header :search="false" :plusIcon="false" />
  <div
    class="mt-14 mb-4 w-full max-w-[1920px] flex items-center justify-center h-full overflow-hidden"
    @wheel="handleColumnScroll">
    <div class="flex flex-col lg:flex-row h-full w-full justify-center">
      <div
        class="w-full min-w-full lg:w-[300px] lg:min-w-[300px] lg:max-w-[300px] xl:w-[350px] xl:min-w-[350px] xl:max-w-[350px] mt-[28px] h-max lg:h-full px-[20px] lg:px-0 lg:mx-[20px] flex flex-col gap-[15px]"
        @wheel.stop="() => {}">
        <span class="text-[18px] font-[600] text-[#1C2B33]">
          Account & Settings
        </span>
        <Sidebar
          :btnWidth="'100%'"
          :disabled="user?.is_on_waitlist"
          module="setting"
          :active="step"
          @toggle="toggleActiveStep" />
      </div>
      <div class="min-h-[calc(100vh-60px)] bg-main-divider size-0.5"></div>
      <div
        class="w-full lg:w-[25px] flex items-center justify-center h-[25px] lg:h-full">
        <div class="bg-[#DEE3E9] w-full lg:w-[1px] h-[1px] lg:h-full"></div>
      </div>
      <div
        class="w-auto overflow-auto h-full lg:w-[817px] mx-[10px] lg:mx-[20px] xl:mx-[38px]">
        <div
          ref="centerColumn"
          class="py-1.5 w-auto lg:w-[600px] xl:w-[720px] rounded-[8px] h-full overflow-y-auto scroll-hidden px-[10px] lg:px-[15px] xl:px-[20px]">
          <div class="flex flex-col w-full lg:w-[580px] xl:w-[680px] h-auto">
            <component :is="settingsMenu.at(step).component" :user="user" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Sidebar from "./parts/Sidebar.vue";
import { settingsMenu } from "./menu";
import { ref, computed, watch } from "vue";
import Header from "@/components/layout/Header.vue";
import { parseFlashData } from "@/utils/toast";
import { usePage } from "@inertiajs/vue3";
const props = defineProps(["user"]);
const step = ref(0);

const toggleActiveStep = (idx) => {
  step.value = idx;
};

const page = usePage();
const ToastList = computed(() => page.props.flash_data);
watch(
  ToastList,
  (newVal) => {
    parseFlashData(newVal);
  },
  { immediate: true, deep: true },
);
</script>
