<template>
    <div class="w-full rounded-lg shadow-default relative" :class="class">
        <slot></slot>
        <div
            class="absolute top-0 left-0 w-full h-full opacity-0 transition-opacity duration-300 ease-in-out hover:opacity-100 rounded-lg shadow-inner bg-neutral-900/50">
            <div
                class="absolute top-2 left-2 px-1.5 py-1 lg:px-3 lg:py-2 bg-black bg-opacity-50 rounded-full border border-white border-opacity-20 justify-end items-center gap-2 inline-flex">
                <div class="text-white text-[7px] lg:text-xs font-light leading-none">
                    <i class="fal fa-images"></i>
                    {{ count }}/6
                </div>
            </div>
            <div class="absolute bottom-2 left-0 text-[8px] lg:text-lg text-white px-4">
                Lorem ipsum dolor sit amet consecetur lorem ipsum dor sit ame ipsum od...
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        count: {
            type: String,
            required: true,
        },
        class: {
            type: String,
            required: false,
            default: '',
        }
    },
    data() {
        return {
            isHovering: false,
        };
    },
});
</script>

<style scoped>
/* No additional styles needed as Tailwind CSS classes handle the hover effect */
</style>
