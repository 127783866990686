<template>
  <div
    class="w-full fixed top-0 bg-main-lightBg flex justify-center items-center z-[100]">
    <Menubar :model="menu" class="h-14 shadow-lg p-5 flex-1 max-w-[1920px]">
      <template #start>
        <img
          :src="bizlyLogoWhite"
          alt="Bizly Logo"
          class="h-[70%] w-auto pr-8" />
      </template>
      <template #item="{ item, props, hasSubmenu, root }">
        <div
          :class="
            cn(
              'h-14 align-items-center border-b-2 font-semibold text-main-lightGray hover:text-white hidden lg:flex',
              item.class,
            )
          ">
          <a v-bind="props.action">{{ item.label }}</a>
        </div>
      </template>
      <template #end>
        <div class="flex items-center gap-4 flex-1">
          <GeneralSearch />
          <div class="cursor-pointer size-5">
            <i
              @click="dialogStore.show(DialogType.UPLOAD_CONTENT)"
              v-if="props.plusIcon"
              class="fa fa-plus-circle text-[20px] text-main-text hover:text-white"></i>
          </div>

          <div class="cursor-pointer size-5">
            <i
              class="fa-regular fa-comment text-[20px] text-main-lightGray hover:text-white"></i>
          </div>
          <NotificationHeader />
          <AvatarHeader />
        </div>
      </template>
    </Menubar>
  </div>
  <ContentDialog
    v-if="dialogStore.isOpened(DialogType.UPLOAD_CONTENT)"
    @close="handleClose" />
  <Confirm
    v-if="dialogStore.isOpened(DialogType.CONTENT_SHARE_POST)"
    class="bg-neutral-900"
    layout="vertical"
    color="primary-light"
    action="Share as post"
    @confirm="handleSharePost"
    @close="dialogStore.hide(DialogType.CONTENT_SHARE_POST)">
    <template #icon>
      <i
        class="fa-duotone fa-solid fa-party-horn fa-3x"
        style="
          --fa-primary-color: #fed920;
          --fa-secondary-color: #d80e0e;
          --fa-secondary-opacity: 0.6;
        "></i>
    </template>
    <template #title>
      <p class="text-white text-lg font-semibold">
        Congratulations, your content is now available to view on your
        portfolio!
      </p>
    </template>
    <template #description>
      <p class="text-sm text-main-disabled text-center">
        Would you like to share your latest portfolio addition with the
        bizlyCommunity?
      </p>
    </template>
  </Confirm>
  <EditPost
    v-if="dialogStore.isOpened(DialogType.POST_MANAGER)"
    @close="dialogStore.hide(DialogType.POST_MANAGER)" />
</template>

<script lang="ts" setup>
import { reactive, ref, computed, onMounted, watch } from "vue";
import { cn } from "@/utils/cn";
import { router, usePage } from "@inertiajs/vue3";
import { route } from "ziggy-js";
import { API } from "@/core/api";
import { useDialogStore } from "@/store/Dialog";
import { DialogType } from "@/store/Dialog";
import type { UserInterface } from "@/core/interfaces";
import EditPost from "@/components/dialogs/social-posts/EditPost.vue";
import Confirm from "@/components/dialogs/Confirm.vue";
import ContentDialog from "@/components/dialogs/content/Main.vue";
import bizlyLogoWhite from "@/assets/images/bizlyLogoWhite.svg";
import AvatarHeader from "./parts/AvatarHeader.vue";
import NotificationHeader from "./parts/NotificationHeader.vue";
import GeneralSearch from "@/components/home/GeneralSearch.vue";
import { useAuthStore } from "@/store/Auth";
const authStore = useAuthStore();
const user = computed(() => authStore.getUser);

const props = defineProps({
  search: {
    type: Boolean,
    default: true,
  },
  plusIcon: {
    type: Boolean,
    default: true,
  },
});

/** HEADER MENU */
const classes = {
  active: "text-white border-b-white",
  inactive: "border-b-main-lightBg",
};

const isActive = (name: String) => {
  return route().current()?.startsWith(name.toLowerCase());
};

const menu = ref([
  {
    label: "Home",
    class: isActive("home") ? classes.active : classes.inactive,
    command: () => {
      router.visit(route("home"));
    },
  },
  {
    label: "Contacts",
    class: isActive("contacts") ? classes.active : classes.inactive,
    command: () => {
      router.visit(route("contacts.index"));
    },
  },
  {
    label: "Explore",
    class: isActive("explore") ? classes.active : classes.inactive,
    command: () => {
      router.visit(route("explore.index"));
    },
  },
  {
    label: "Opportunities",
    class: `${classes.inactive} text-main-lightGray opacity-50 hover:text-main-lightGray !cursor-default`,
    command: () => {},
  },
]);

/**  UPLOAD CONTENT */
const dialogStore = useDialogStore();
const contentAPI = new API.Content();

const handleClose = (id?: number) => {
  dialogStore.hide(DialogType.UPLOAD_CONTENT);
  if (id !== undefined) {
    dialogStore.show(DialogType.CONTENT_SHARE_POST, id, { id });
  }
};

const handleSharePost = async () => {
  const data = dialogStore.getData(DialogType.CONTENT_SHARE_POST);

  if (data.id === null) {
    return;
  }
  let response = await contentAPI.shareAsPost(data.id);
  response = response.data;
  dialogStore.hide(DialogType.CONTENT_SHARE_POST);
  dialogStore.show(DialogType.POST_MANAGER, undefined, {
    post: response,
    user: user,
  });
};
</script>
