<template>
    <!-- < 600 && > 900 -->
    <div
        class="lg:sticky lg:top-20 flex-none self-start w-full lg:w-[400px] flex sm:flex-col md:max-h-[800px] lg:max-h-max flex-wrap gap-6">
        <component v-for="(component, index) in components" :key="index" :is="component" />
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUpdated, ref } from 'vue';
import ProjectDetails from './parts/ProjectDetails.vue';
import Collaborators from './parts/Collaborators.vue';
import Tags from './parts/Tags.vue';
import Publisher from './parts/Publisher.vue';
import { useEventListener, useWindowSize } from '@vueuse/core';

export default defineComponent({
    components: {
        ProjectDetails
    },
    setup() {
        const { width, height } = useWindowSize({ listenOrientation: true });
        const components = ref([
            ProjectDetails, Collaborators, Tags, Publisher
        ]);
        const update = () => {
            if (width.value > 640 && width.value < 1024) {
                components.value = [
                    ProjectDetails, Tags, Collaborators, Publisher
                ];
            }
        }
        update();
        useEventListener('resize', update, { passive: true })

        return {
            components
        }
    }
});
</script>