export default {
  root: ({ props }) => ({
    class: [
      // Flex Alignment
      "flex gap-4 bg-transparent",
      {
        "opacity-60 select-none pointer-events-none cursor-default":
          props.disabled,
      },
    ],
  }),
  button: ({ context, props }) => ({
    class: [
      "relative",
      // Font
      "text-[14px] leading-5 font-semibold",
      "text-main-disabled font-normal",

      // Flex Alignment
      "flex-1 flex flex-col gap-4 justify-center items-center align-bottom text-center",

      // Border
      "border rounded-md",

      // Spacing
      "px-6 py-4",

      // Color
      {
        "bg-transparent border-surface-600": !context.active,
        "border-switcherBlue-500 bg-switcherBlue-500/25": context.active,
      },
      // Invalid State
      { "border-red-500 dark:border-red-400": props.invalid },

      // States
      "focus:outline-none focus:outline-offset-0",
      {
        "opacity-60 select-none pointer-events-none cursor-default":
          context.disabled,
      },
      // Transition
      "transition duration-200",

      // Misc
      "cursor-pointer select-none overflow-hidden",
    ],
  }),
  label: {
    class: "",
  },
};
