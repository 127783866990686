<template>
    <div
        class="xs:w-full md:w-auto lg:w-auto p-5 bg-white rounded-lg shadow-lg flex-col justify-start items-start gap-5 inline-flex">
        <div class="flex-col justify-center items-start gap-1 flex">
            <Subtitle>Published on</Subtitle>
            <div class="text-main-primary text-sm font-medium font-['Roboto'] leading-tight">Jul 23, 2023</div>
        </div>
        <SectionDivider :margin=false />
        <div class="flex-col justify-start items-start gap-3 flex">
            <Subtitle>Published BY</Subtitle>
            <UserList status="offline">
                <template #image>
                    <img class="rounded-full border-2 border-white" src="../../../../assets/images/sevag-collab.png" />
                </template>
                <template #title>Sevag chahinian</template>
                <template #subtitle>
                    <div class="opacity-80 text-black text-[11px] lg:text-sm font-light leading-3">
                        Editor</div>
                    <div class="w-0.5 h-0.5 bg-zinc-300 rounded-full"></div>
                    <div class="opacity-80 text-black text-[11px] lg:text-sm font-light leading-3">
                        Director</div>
                    <div class="w-0.5 h-0.5 bg-zinc-300 rounded-full"></div>
                    <div class="opacity-80 text-black text-[11px] lg:text-sm font-light leading-3">
                        Producer</div>
                </template>
            </UserList>
        </div>
        <div
            class="self-stretch px-5 py-2.5 bg-switcherBlue-500 rounded-md justify-center items-center gap-2 inline-flex">
            <div class="text-center text-white text-sm font-medium leading-tight flex gap-2 items-center">
                <img src="../../../../assets/icons/message-filled.svg"> Message
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SectionDivider from '../../../parts/SectionDivider.vue';
import UserList from '../../../parts/UserList.vue';
import Subtitle from '../../../parts/Subtitle.vue';

export default defineComponent({
    components: {
        SectionDivider,
        UserList,
        Subtitle
    }
});
</script>