import baseApi from "../Base";
import { route } from "ziggy-js";

export class OTPManagerService extends baseApi {
  async get(form: any) {
    return await this.request(route("admin.api.otp.get"), "GET", form);
  }

  async delete(form: any) {
    return await this.request(route("admin.otp.delete.selected"), "POST", form);
  }
}
