import baseApi from "./Base";
import { route } from "ziggy-js";

export class ContactGroupsService extends baseApi {
  async get() {
    return await this.request(route("contacts.groups.index"), "GET");
  }

  async create(data: any) {
    return await this.request(route("contacts.groups.create"), "POST", data);
  }

  async update(group: number, data: any) {
    return await this.request(
      route("contacts.groups.update", group),
      "POST",
      data,
    );
  }

  async delete(group: number) {
    return await this.request(route("contacts.groups.delete", group), "POST");
  }

  async deleteMany(groups: number[]) {
    return await this.request(route("contacts.groups.delete.many"), "POST", {
      groups,
    });
  }
}
