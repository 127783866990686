<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-0 w-full flex flex-col relative h-[720px]',
      root: ' bg-white max-w-[800px] w-full rounded-[16px]  scroll-hidden',
    }"
    :visible="true"
    modal
    :closable="false">
    <section id="header" class="bg-lightBg-500 rounded-t-[16px]">
      <div
        class="flex justify-between items-center p-4 border-b border-main-divider">
        <br />
        <h1 class="text-main-primary text-xl font-semibold">
          {{ form.id ? "Edit Group" : "Create Group" }}
        </h1>
        <i
          class="text-lg flex items-center justify-center fa fa-xmark size-8 bg-main-disabled rounded-full cursor-pointer"
          @click="$emit('close')"></i>
      </div>
    </section>
    <section id="group name" class="bg-lightBg-500">
      <div class="p-4 border-b border-main-divider">
        <input
          v-model="form.name"
          type="text"
          class="font-semibold text-lg text-main-primary w-full focus:ring-0 focus:border-0 focus:outline-none bg-transparent"
          placeholder="Enter Group Name" />
      </div>
    </section>
    <section id="users list" class="px-4 py-2.5 flex-1">
      <div class="flex flex-col gap-4 h-full">
        <IconField
          :iconPosition="loading ? 'both' : 'left'"
          class="cursor-pointer w-full z-[60]">
          <i class="fa fa-search size-4"></i>

          <InputText
            v-model="keyword"
            placeholder="Search User..."
            class="bg-white w-full focus:!outline-none focus:!border-0 focus:!ring-0 hidden md:block shadow-default" />
          <i
            v-if="loading"
            class="pointer-events-none far fa-spinner-third animate-spin"></i>
        </IconField>
        <div class="flex gap-2 w-full h-full">
          <div class="flex flex-col flex-1">
            <h1 class="text-lg font-medium mb-4">Suggestions</h1>
            <div
              id="list"
              class="flex-1 h-full max-h-[400px] overflow-y-auto scroll-hidden divide-y divide-main-divider space-y-2">
              <div v-for="user in filteredList" :key="user.id" class="">
                <label
                  :for="`user_${user.id}`"
                  class="hover:bg-primaryBlue-500 transition-all">
                  <UserCard
                    :user="user"
                    :showLocation="true"
                    :showRelationship="false"
                    roleClass="max-w-[30%]">
                    <template #actions>
                      <Checkbox
                        v-model="form.users"
                        :value="user"
                        :inputId="`user_${user.id}`"
                        :outlined="true"
                        color="lightBlue"
                        size="lg" />
                    </template>
                  </UserCard>
                </label>
              </div>
            </div>
          </div>
          <div
            v-if="form.users.length"
            class="bg-lightBg-500 rounded-lg p-1.5 max-h-[450px] overflow-y-auto scroll-hidden flex-1">
            <p class="text-xs mb-4">{{ form.users.length }} Selected</p>
            <div class="flex flex-col gap-4">
              <div
                v-for="(selectedUser, index) in form.users"
                :key="selectedUser.id"
                class="flex justify-between items-center">
                <div class="flex gap-2 items-center">
                  <img
                    :src="selectedUser.avatar_image"
                    alt=""
                    class="rounded-full size-10" />
                  <p>{{ selectedUser.full_name }}</p>
                </div>
                <i
                  class="fa fa-xmark text-lg cursor-pointer"
                  @click="() => handleRemoveUser(index)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      id="footer"
      class="bg-lightBg-500 flex justify-end items-center px-4 py-2 sticky bottom-0 z-50">
      <Button
        :disabled="!canCreate"
        :loading="form.processing"
        :label="form.id ? 'Update' : 'Create'"
        class="rounded text-white text-sm font-medium min-h-[40px] py-1.5 px-6 text-center disabled:bg-[#bfbfbf] bg-switcherBlue-500"
        @click="handleCreate"></Button>
    </section>
  </Dialog>
</template>
<script setup>
import { router, useForm, usePage } from "@inertiajs/vue3";
import { reactive, ref, computed, onMounted, watch } from "vue";
import UserCard from "@/components/general/UserCard.vue";
import { useUserStore } from "@/store/User";
import { DialogType, useDialogStore } from "@/store/Dialog";
import { useContactsStore } from "@/store/Contacts";
import { API } from "@/core/api";
import { route } from "ziggy-js";

const dialogStore = useDialogStore();
const contactStore = useContactsStore();
const userStore = useUserStore();
const groupAPI = new API.ContactGroups();
const emit = defineEmits(["close"]);
const friends = computed(() => userStore.friends ?? []);
const loading = ref(false);
const keyword = ref("");
const canCreate = computed(
  () => form.isDirty && form.name && form.users.length > 0,
);
const filteredList = computed(() =>
  friends.value.filter((f) =>
    f.full_name.toLowerCase().includes(keyword.value.toLowerCase()),
  ),
);
const form = useForm({
  id: dialogStore[DialogType.MANAGE_GROUP].data?.id ?? null,
  name: dialogStore[DialogType.MANAGE_GROUP].data?.name ?? "",
  users: dialogStore[DialogType.MANAGE_GROUP].data?.users ?? [],
});

const handleRemoveUser = (idx) => {
  form.users.splice(idx, 1);
};

const handleCreate = async () => {
  const formattedForm = {
    id: form.id,
    name: form.name,
    users: form.users.map((v) => v.id),
  };
  const response = form.id
    ? await groupAPI.update(form.id, formattedForm)
    : await groupAPI.create(formattedForm);

  if (!form.id) contactStore.addGroup(response.data);
  emit("close");
  router.visit(route("contacts.groups.show", { group: response.data.id }));
};

onMounted(() => {
  contactStore.updateGroup(form);
});
</script>
