export default {
  accordiontab: {
    root: {
      class: "mb-1 relative",
    },
    header: ({ props }) => ({
      class: [
        // State
        {
          "select-none pointer-events-none cursor-default opacity-60":
            props?.disabled,
        },
      ],
    }),
    headerAction: ({ context }) => ({
      class: [
        // Transition
        "transition-all duration-200 ease-in-out",
        "transition-shadow duration-200",
      ],
    }),
    headerIcon: {
      class: "hidden inline-block mr-2",
    },
    headerTitle: {
      class: "leading-none",
    },
    content: {
      class: [
        // Spacing
        "",

        //Shape
        "rounded-tl-none rounded-tr-none rounded-br-lg rounded-bl-lg",

        // Color
        "text-surface-700 dark:text-surface-0/80",
      ],
    },
    transition: {
      enterFromClass: "max-h-0",
      enterActiveClass:
        "overflow-hidden transition-[max-height] duration-1000 ease-[cubic-bezier(0.42,0,0.58,1)]",
      enterToClass: "max-h-[1000px]",
      leaveFromClass: "max-h-[1000px]",
      leaveActiveClass:
        "overflow-hidden transition-[max-height] duration-[450ms] ease-[cubic-bezier(0,1,0,1)]",
      leaveToClass: "max-h-0",
    },
  },
};
