<template>
  <div
    class="flex"
    :class="
      props?.module === 'setting'
        ? 'flex-col gap-1'
        : 'profileTabs gap-2 px-4  overflow-x-auto scroll-hidden'
    "
  >
    <template v-for="(step, index) in settingsMenu" :key="index">
      <button
        class="px-[0.75rem] py-[.375rem] md:min-w-[160px] w-full whitespace-nowrap auto rounded text-center inline-block text-primaryBlue-500 hover:text-white hover:bg-primaryBlue-500 transition-colors"
        @click="toggleActive(index)"
        :class="{ 'text-white bg-primaryBlue-500': index === isActiveTab }"
      >
        <div class="flex items-center justify-start gap-4">
          <i class="fad text-lg" :class="step.Icon"></i>
          <p>{{ step.btnText }}</p>
        </div>
      </button>
    </template>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { settingsMenu } from "../menu";
const props = defineProps({
  module: String,
  btnWidth: String,
  disabled: Boolean,
  active: Number,
});

const isActiveTab = ref(0);

const emit = defineEmits(["toggle"]);
const toggleActive = (idx) => {
  isActiveTab.value = idx;
  window.history.replaceState(null, null, `?page=${idx + 1}`);
  emit("toggle", idx);
};
let page = new URL(location.href).searchParams.get("page");
if (page && page - 1 < settingsMenu.length) {
  toggleActive(page - 1);
}
</script>
