<template>
  <Layout>
    <div class="flex flex-col gap-5 py-[28px] px-2.5 xl:px-[90px]">
      <IconField
        :iconPosition="lazyload.loading ? 'both' : 'left'"
        class="cursor-pointer w-full z-[60]">
        <i class="fa fa-search size-4"></i>

        <InputText
          placeholder="Search connections"
          v-model="lazyload.keyword"
          class="bg-white w-full focus:!outline-none focus:!border-0 focus:!ring-0 hidden md:block shadow-default" />
        <i
          v-if="lazyload.loading"
          class="pointer-events-none far fa-spinner-third animate-spin"></i>
      </IconField>
      <UserCard
        :user="authUser"
        class="bg-white py-5 px-4 rounded-lg shadow-default" />
      <section id="usersList" class="pb-4">
        <InfiniteScroll
          :callback="getData"
          scroll-region
          ref="feedContainer"
          id="feedContainer"
          class="space-y-4">
          <div
            v-for="(group, key) in sortedUsers"
            :key="key"
            class="flex flex-col gap-4">
            <div class="sticky z-10">
              <p
                class="text-[#39537D] bg-[#EFF1F5] px-4 py-[5px] rounded-[4px] font-[500] uppercase">
                {{ key }}
              </p>
            </div>
            <div
              class="grid-cols-1 min-[1366px]:grid-cols-2 gap-[20px] w-full"
              :class="{
                grid: layout === 'full',
                'flex flex-wrap gap-4': layout === 'compact',
              }">
              <component
                :is="userCard"
                v-for="friend in group"
                :key="friend.id"
                :user="friend"
                @reload="() => router.reload()">
                <template #actions>
                  <i
                    class="text-white/80 fa-regular fa-comment fa-xl"
                    @click="handleMobileDialog"></i>
                  <i
                    @click="() => toggleFavorite(friend)"
                    class="text-white/80 fa-regular fa-star fa-xl"
                    :class="{
                      'fa-solid text-red-500': is_favorite(friend),
                    }"></i>
                </template>
              </component>
            </div>
          </div>
        </InfiniteScroll>
      </section>
    </div>
  </Layout>
</template>

<script setup lang="ts">
import { router } from "@inertiajs/vue3";
import { reactive, ref, computed, onMounted, watch } from "vue";
import { API } from "@/core/api";
import type { UserInterface } from "@/core/interfaces";
import { useAuthStore } from "@/store/Auth";
import { useContactsStore } from "@/store/Contacts";
import { useDialogStore, DialogType } from "@/store/Dialog";
import Layout from "@/components/contacts/Layout.vue";
import UserCard from "@/components/general/UserCard.vue";
import FullCard from "@/components/contacts/content/FullCard.vue";
import CompactCard from "@/components/contacts/content/CompactCard.vue";
import _debounce from "lodash/debounce";
import InfiniteScroll from "@/components/infinite-scroll/InfiniteScroll.vue";

const contactsAPI = new API.Contacts();
const authStore = useAuthStore();
const authUser = computed(() => authStore.getUser);
const contactsStore = useContactsStore();
const dialogStore = useDialogStore();

const lazyload = reactive({
  start: 0,
  size: 10,
  loading: false,
  hasMore: true,
  keyword: "",
});

const users = ref<Record<string, UserInterface[]>>({});

const sortedUsers = computed(() => {
  return Object.fromEntries(
    Object.entries(users.value).sort(([a], [b]) => a.localeCompare(b)),
  );
});

onMounted(async () => {});

const getData = async () => {
  if (lazyload.loading || !lazyload.hasMore) return;
  try {
    lazyload.loading = true;
    const response = await contactsAPI.getAllContacts(lazyload);

    // Group new users by first letter of first name
    Object.entries(response.data).forEach(
      (value: [string, UserInterface[]]) => {
        const group = value[0];
        const userArray = value[1];
        if (!users.value[group]) {
          users.value[group] = [];
        }
        users.value[group].push(...userArray);
      },
    );

    lazyload.start += lazyload.size;
    const totalUsersCount = Object.values(response.data).reduce(
      (sum, users: any) => sum + users.length,
      0,
    );
    lazyload.hasMore = totalUsersCount === lazyload.size;
  } catch (error) {
    console.error(error);
  } finally {
    lazyload.loading = false;
  }
};

const layout = computed(() => contactsStore.layout);
const userCard = computed(() =>
  layout.value === "full" ? FullCard : CompactCard,
);
const favorites = computed(() => contactsStore.favorites || []);
const is_favorite = (user: UserInterface): Boolean => {
  return favorites.value.findIndex((f) => f.id === user.id) !== -1;
};
const handleMobileDialog = () => {
  dialogStore.show(DialogType.MOBILE_APP);
  dialogStore.setEvents(DialogType.MOBILE_APP, {
    close: () => dialogStore.hide(DialogType.MOBILE_APP),
  });
};
const toggleFavorite = _debounce(async (user: any) => {
  contactsStore.toggleFavorite(user);
}, 250);

watch(
  () => lazyload.keyword,
  () => {
    users.value = {};
    lazyload.start = 0;
    lazyload.hasMore = true;
    getData();
  },
);
</script>
