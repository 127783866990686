export default {
  root: ({ props }) => ({
    class: [
      // Flex Alignment
      "flex gap-2 bg-lightBg-500 p-1 rounded-[30px]",
      {
        "opacity-60 select-none pointer-events-none cursor-default":
          props.disabled,
      },
    ],
  }),
  button: ({ context, props }) => ({
    class: [
      "relative",
      // Font
      "leading-none",

      // Flex Alignment
      "flex-1 inline-flex justify-center items-center align-bottom text-center",

      // Spacing
      "px-3 py-2.5",

      // Shape
      "border border-r-0",
      "rounded-full",

      // Color
      {
        "bg-lightBg-500": !context.active,
        "text-primary-700": !context.active,
        "border-none": !context.active && !props.invalid,
        "bg-primary-500 border-primary text-primary-inverse": context.active,
      },
      // Invalid State
      { "border-red-500 dark:border-red-400": props.invalid },

      // States
      "focus:outline-none focus:outline-offset-0",
      {
        "opacity-60 select-none pointer-events-none cursor-default":
          context.disabled,
      },
      // Transition
      "transition duration-200",

      // Misc
      "cursor-pointer select-none overflow-hidden",
    ],
  }),
  label: {
    class: "font-medium text-sm",
  },
};
