<template>
    <div :class="class" class="bg-gray-100 justify-center items-start gap-2.5 flex">
        <slot name="pre"></slot>
        <div class="text-primaryBlue-500 text-xs lg:text-sm font-normal leading-tight">
            <slot></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        class: {
            type: String,
            default: 'px-2.5 py-1 rounded'
        }
    }
});
</script>