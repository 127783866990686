<template>
  <Layout v-model="model">
    <Section
      v-for="(item, index) in model.children"
      :key="item.componentKey"
      :hasDivider="!(index == model.children.length - 1)"
    >
      <template #title>{{ item.title }}</template>
      <template #content>
        <span
          class="text-[15px] font-normal text-[#141414] opacity-90 whitespace-pre-line"
          :class="{
            'line-clamp-none': item.ReadmoreToggle,
            'line-clamp-3': !item.ReadmoreToggle,
          }"
        >
          {{ item.description }}
        </span>
        <span
          v-if="item.description?.length > 300"
          class="text-blue-400 cursor-pointer"
          @click="item.ReadmoreToggle = !item.ReadmoreToggle"
        >
          Read {{ item.ReadmoreToggle ? "less" : "more" }} ...
        </span>
      </template>
      <template #body>
        <component :is="getComponent(item)" :item="getContent(item)"></component>
      </template>
    </Section>
  </Layout>
</template>
<script setup lang="ts">
import { ref, watch } from "vue";
import { transformData } from "@/components/dialogs/content/helpers/transformData";
import Section from "@/components/parts/Section.vue";
import Layout from "./layout/Layout.vue";
import Videos from "@/components/dialogs/collection/components/Videos.vue";
import Images from "@/components/dialogs/collection/components/Images.vue";
import Links from "@/components/dialogs/collection/components/Links.vue";
import PlainText from "../texts/PlainText.vue";

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
});
const model = ref(props.content);

const getComponent = (value: any) => {
  switch (value.content_type) {
    case "video":
    case "video_gallery":
      return Videos;
    case "image":
    case "image_gallery":
      return Images;
    case "link":
    case "link_gallery":
      return Links;
    case "text":
      return PlainText;
  }
};

const getContent = (value: any) => {
  const result = transformData(value);
  return result;
};

watch(
  () => props.content,
  () => {
    model.value = props.content;
  }
);
</script>
