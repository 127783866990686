export default {
  root: {
    class: [
      // Sizing and Shape
      "min-w-[12rem]",
      // Colors
      "text-primary-700",
    ],
  },
  menu: {
    class: [
      // Spacings and Shape
      "list-none",
      "m-0",
      "p-0",
      "outline-none",
    ],
  },
  content: ({ context, attrs }) => {
    return {
      class: [
        "my-1",
        //Shape
        "rounded-lg",
        // Colors
        "text-primary-700",
        {
          "bg-primary-200 text-primary-700 dark:bg-primary-300/10 dark:text-white":
            context.focused,
        },
        // Transitions
        "transition-shadow",
        "duration-200",
        // States
        "hover:text-primary-700 dark:hover:text-white/80",
        "hover:bg-primary-100 dark:bg-primary-700 dark:hover:bg-primary-400/10",
      ],
    };
  },
  activeMenu: {
    class: [
      // Colors
      "rounded-lg",
      "text-primary-700",
      "bg-primary-100 dark:bg-primary-700",
    ],
  },
  action: {
    class: [
      "relative",
      // Flexbox

      "flex",
      "items-center",

      // Spacing
      "py-3",
      "px-5",

      // Color
      "text-primary-700 dark:text-white/80",

      // Misc
      "no-underline",
      "overflow-hidden",
      "cursor-pointer",
      "select-none",
    ],
  },
  icon: {
    class: [
      // Spacing
      "mr-4",

      //Background
      "bg-white shadow-md p-2 rounded-md",
      // Color
      "text-primary-600",
    ],
  },
  label: {
    class: ["leading-none"],
  },
  separator: {
    class: [
      // Spacing
      "my-2",
      // Colors
      "border-t border-primary-50 dark:border-primary-700",
    ],
  },
  submenuheader: {
    class: [
      // Font
      "font-bold",
      // Spacing
      "m-0",
      "py-3 px-5",
      // Shape
      "rounded-tl-none",
      "rounded-tr-none",
      // Colors
      "bg-primary-0 dark:bg-primary-700",
      "text-primary-700 dark:text-white",
    ],
  },
  transition: {
    enterFromClass: "opacity-0 scale-y-[0.8]",
    enterActiveClass:
      "transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]",
    leaveActiveClass: "transition-opacity duration-100 ease-linear",
    leaveToClass: "opacity-0",
  },
};
