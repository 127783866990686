import axios, {
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse,
  type CancelTokenSource,
} from "axios";
import { useAuthStore } from "@/store/Auth";
import { router } from "@inertiajs/vue3";
enum RequestMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
  // Add other HTTP methods as needed
}

export default class baseApi {
  private axiosInstance: AxiosInstance;
  private cancelTokens: { [key: string]: CancelTokenSource } = {};

  constructor() {
    this.axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    // Optional: You can set default base URL here
    // this.axiosInstance.defaults.baseURL = 'https://api.example.com';
  }

  private getAuthHeader() {
    const auth = useAuthStore();
    if (!auth.isLoggedIn()) {
      router.get("login");
      throw new Error("Unauthorized");
    }
    return { Authorization: `Bearer ${auth.token}` };
  }

  private generateCancelTokenKey(url: string, method: string) {
    return `${method}:${url}`;
  }

  private cancelPendingRequest(key: string) {
    if (this.cancelTokens[key]) {
      this.cancelTokens[key].cancel("Request canceled due to new request.");
      delete this.cancelTokens[key];
    }
  }

  public async request(
    url: string,
    method: string,
    data: any = null,
    customConfig: AxiosRequestConfig = {},
  ): Promise<any> {
    const authHeader = this.getAuthHeader();

    const key = this.generateCancelTokenKey(url, method);
    this.cancelPendingRequest(key);

    const cancelTokenSource = axios.CancelToken.source();
    this.cancelTokens[key] = cancelTokenSource;

    const config: AxiosRequestConfig = {
      url,
      method,
      headers: {
        ...authHeader,
      },
      cancelToken: cancelTokenSource.token,
      ...customConfig,
    };

    if (method === RequestMethod.GET && data) {
      config.params = data;
    } else if (data) {
      config.data = data;
    }

    try {
      const response: AxiosResponse = await this.axiosInstance(config);
      delete this.cancelTokens[key];
      return response.data;
    } catch (error) {
      // Error handling
      if (axios.isCancel(error)) {
        console.warn("[SPAM_PREVENTION] :: ", error.message);
        throw error.message;
      } else throw error;
    }
  }
}
