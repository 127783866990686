export default {
    root: ({ props, context, parent }) => ({
        class: [
            // Font
            "leading-2 text-sm",

            // Flex
            { "flex-1 w-[1%]": parent.instance.$name == "InputGroup" },

            // Spacing
            "m-0",
            {
                "px-4 py-3": props.size == "large",
                "px-2 py-1": props.size == "small",
                "px-3 py-2": props.size == null,
            },

            // Shape
            { rounded: parent.instance.$name !== "InputGroup" },
            {
                "first:rounded-l rounded-none last:rounded-r":
                    parent.instance.$name == "InputGroup",
            },
            {
                "border-0 border-y border-l last:border-r":
                    parent.instance.$name == "InputGroup",
            },
            {
                "first:ml-0 -ml-px":
                    parent.instance.$name == "InputGroup" && !props.showButtons,
            },

            // Colors
            "text-surface-800 dark:text-white/80",
            "placeholder:text-surface-400 dark:placeholder:text-surface-500",
            "bg-surface-0 dark:bg-surface-900",
            "border",
            {
                "border-lightBg-600": !props.invalid,
            },
            // Invalid State
            "invalid:focus:ring-red-200",
            "invalid:hover:border-red-500",
            { "border-red-500 dark:border-red-400": props.invalid },

            // States
            {
                "focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-switcherBlue-500 focus:z-10":
                    !context.disabled,
                "opacity-60 select-none pointer-events-none cursor-default":
                    context.disabled,
            },

            // Filled State *for FloatLabel
            {
                filled:
                    parent.instance?.$name == "FloatLabel" && context.filled,
            },

            // Misc
            "appearance-none",
            "transition-colors duration-200",
        ],
    }),
};
