<template>
    <div class="self-stretch h-px bg-main-divider" :class="{ 'mb-8': margin }"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        margin: {
            type: Boolean,
            default: true
        }
    }
});
</script>