<template>
    <Layout>

        <div
            class="block justify-between items-center p-4 mx-4 mt-4 mb-6 bg-white rounded-2xl shadow-xl shadow-gray-200 lg:p-5 sm:flex h-[100%]">
            <div class="mb-1 w-full">
                <div class="mb-4">
                    <nav class="flex mb-5" aria-label="Breadcrumb">
                        <ol class="inline-flex items-center space-x-1 md:space-x-2">
                            <li>
                                <div class="flex items-center">
                                    <i class="fad fa-industry text-primary-500"></i>
                                    <Link :href="route('admin.social_posts.index')"
                                        class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2">Social Posts
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <div class="flex items-center">
                                    <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                    <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2" aria-current="page">Preview</span>
                                </div>
                            </li>
                        </ol>
                    </nav>
                    <div class="flex items-center justify-between">
                        <h1 class="text-xl font-semibold text-gray-900 sm:text-2xl">Preview social post</h1>
                        <div class="flex gap-2">
                            <Button type="button" @click="window.history.back()" icon="fad fa-arrow-left" label="Back"
                                severity="secondary"></Button>
                            <Divider layout="vertical" type="dashed" severity="secondary" />
                            <!-- <Button @click="submit" icon="fad fa-check" label="Save"></Button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-col mt-6 mb-8 mx-4 rounded-2xl bg-white shadow-xl shadow-gray-200 p-10">
            <SocialPost v-bind="props" class="!rounded-none" showInteractions />
        </div>

    </Layout>
</template>
<script lang="ts" setup>

import Layout from "@/components/layout/admin/Layout.vue";
import { route } from "ziggy-js";
import { useForm, router } from "@inertiajs/vue3";
import ValidationError from "@/components/forms/validationError.vue";
import { Link } from "@inertiajs/vue3";
import SelectGroup from "@/components/forms/SelectGroup.vue";
import SocialPost from "./parts/SocialPost.vue";

const props = defineProps({
    post: Object
});


</script>
