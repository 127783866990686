import baseApi from "./Base";
import { route } from "ziggy-js";

export class ContactsService extends baseApi {
  async AddToFavorites(userId: number) {
    return await this.request(
      route("contacts.favorites.add", { user: userId }),
      "POST",
    );
  }
  async RemoveFromFavorites(userId: number) {
    return await this.request(
      route("contacts.favorites.remove", { user: userId }),
      "POST",
    );
  }
  async getFavorites() {
    return await this.request(route("contacts.get-favorites"), "GET");
  }
  async getAllContacts(form: any) {
    const requestForm = {
      size: form.size,
      start: form.start,
      keyword: form.keyword,
    };
    return await this.request(route("contacts.index"), "GET", requestForm);
  }
}
