<template>
    <Avatar shape="circle" @click="togglePanel">
        <template #default>
            <div class="size-[30px] rounded-full overflow-hidden border cursor-pointer">
                <div class="absolute -right-[4px] bottom-0.5 z-10 rounded-full size-3 m-0 p-0">
                    <img :src="dropdownIcon" />
                </div>
                <img :src="user.avatar_image" alt="User Avatar" class="object-cover" />
            </div>
        </template>
    </Avatar>
    <OverlayPanel ref="op" :pt="{
        content: '!p-0'
    }" :ptOptions="{ mergeProps: true }">
        <UserPanel />
    </OverlayPanel>
</template>
<script lang="ts" setup>
import UserPanel from "@/components/overlay-panels/UserPanel.vue";
import dropdownIcon from "@/assets/images/new_icons/active_status_dropdown_icon.svg";
import { usePage } from "@inertiajs/vue3";
import { computed, onMounted, ref } from "vue";
import type { UserInterface } from "@/core/interfaces";

const page = usePage();
const user = computed(() => page?.props?.user as UserInterface);

const op = ref();

const togglePanel = (e: any) => {
    if (op.value) {
        op.value.toggle(e);
    }
}
</script>