import { defineStore } from "pinia";

export enum DialogType {
  UPLOAD_CONTENT,
  POST_MANAGER,
  CONTENT_SHARE_POST,
  CONTENT_MANAGER,
  UI_BLOCK_FULL,
  UPLOAD_CONTENT_COLLECTION,
  UPLOAD_CONTENT_CHILD_DESCRIPTION,
  EXTERNAL_LINK,
  MANAGE_GROUP,
  MANAGE_GROUPS,
  INVITATIONS,
  MOBILE_APP,
  MANAGE_LINK,
  MANAGE_BIZCARD,
}

interface DialogState {
  id?: number | string;
  show: boolean;
  events?: any;
  data: any;
}

interface State {
  [key: number]: DialogState;
}

export const useDialogStore = defineStore("dialogStore", {
  state: (): State => ({
    [DialogType.UPLOAD_CONTENT]: {
      show: false,
      data: null,
    },
    [DialogType.POST_MANAGER]: {
      show: false,
      data: null,
    },
    [DialogType.CONTENT_SHARE_POST]: {
      show: false,
      data: null,
    },
    [DialogType.CONTENT_MANAGER]: {
      show: false,
      data: null,
    },
    [DialogType.UPLOAD_CONTENT_COLLECTION]: {
      show: false,
      data: null,
    },
    [DialogType.UI_BLOCK_FULL]: {
      show: false,
      data: null,
    },
    [DialogType.UPLOAD_CONTENT_CHILD_DESCRIPTION]: {
      show: false,
      data: null,
    },
    [DialogType.EXTERNAL_LINK]: {
      show: false,
      data: null,
    },
    [DialogType.MANAGE_GROUP]: {
      show: false,
      data: null,
    },
    [DialogType.MANAGE_GROUPS]: {
      show: false,
      data: null,
    },
    [DialogType.INVITATIONS]: {
      show: false,
      data: null,
    },
    [DialogType.MOBILE_APP]: {
      show: false,
      data: null,
    },
    [DialogType.MANAGE_LINK]: {
      show: false,
      data: null,
    },
    [DialogType.MANAGE_BIZCARD]: {
      show: false,
      data: null,
    },
  }),
  getters: {},
  actions: {
    show(dialog: DialogType, id?: number | string, content?: any) {
      this[dialog] = {
        show: true,
        data: content,
        id,
      };
    },
    hide(dialog: DialogType) {
      this[dialog] = {
        show: false,
        data: null,
      };
    },
    setEvents(dialog: DialogType, events: any) {
      this[dialog].events = events;
    },
    isOpened(dialog: DialogType, id?: number | string) {
      return id
        ? this[dialog].id == id && this[dialog].show
        : this[dialog].show;
    },
    getData(dialog: DialogType) {
      return this[dialog].data;
    },
  },
});
