import { API } from "@/core/api";
import { useDialogStore, DialogType } from "@/store/Dialog";

const contentAPI = new API.Content();

export const transform = async (post) => {
  const dialogStore = useDialogStore();
  let content;
  if (post.content.collaborators) {
    content = post.content;
  } else {
    dialogStore.show(DialogType.UI_BLOCK_FULL);
    let response = await contentAPI.get(post.content.id);
    content = response.data;
    dialogStore.hide(DialogType.UI_BLOCK_FULL);
  }

  return transformData(content);
};

export const transformData = (content: any) => {
  let transformed = {
    ...content,
  };
  console.log(content);
  if (content.content_type.includes("video")) {
    if (content.content_type === "video_gallery") {
      transformed.items = content.children.map((child) => ({
        id: child.id,
        title: child.title,
        description: child.description,
        duration: child.external_url,
        thumbnail: {
          video: child.image_kit_id_16_9,
          square: child.thumbnail_image_kit_id,
          default: child.thumbnail_image_kit_id,
        },
        url: child.image_kit_id,
        embed: child.embed,
        valid: true,
        loading: false,
      }));
    } else {
      transformed.items = [
        {
          title: content.title,
          description: content.description,
          duration: content.external_type,
          thumbnail: {
            video: content.image_kit_id_16_9,
            square: content.thumbnail_image_kit_id,
            default: content.thumbnail_image_kit_id,
          },
          url: content.image_kit_id,
          embed: content.embed,
          valid: true,
          loading: false,
        },
      ];
      transformed.thumbnail = {
        video: content.image_kit_id_16_9,
        square: content.thumbnail_image_kit_id,
        default: content.thumbnail_image_kit_id,
      };
    }
  } else if (content.content_type.includes("image")) {
    if (content.content_type === "image_gallery") {
      transformed.items = content.children.map((child) => ({
        id: child.id,
        valid: true,
        thumbnail: {
          video: child.image_kit_id_16_9,
          square: child.thumbnail_image_kit_id,
          default: child.thumbnail_image_kit_id,
        },
      }));
      transformed.aspect_ratio = content.display_type;
    } else {
      transformed.items = [
        {
          valid: true,
          thumbnail: {
            video: content.image_kit_id_16_9,
            square: content.thumbnail_image_kit_id,
            default: content.thumbnail_image_kit_id,
          },
        },
      ];
      transformed.thumbnail = {
        video: content.image_kit_id_16_9,
        square: content.thumbnail_image_kit_id,
        default: content.thumbnail_image_kit_id,
      };
    }
  } else if (content.content_type.includes("link")) {
    if (content.content_type === "link_gallery") {
      transformed.items = content.children.map((child) => ({
        id: child.id,
        title: child.title,
        description: child.description,

        thumbnail: {
          video: child.image_kit_id_16_9,
          square: child.thumbnail_image_kit_id,
          default: child.thumbnail_image_kit_id,
        },
        url: child.image_kit_id,
        valid: true,
        loading: false,
      }));
    } else {
      transformed.items = [
        {
          title: content.title,
          description: content.description,
          thumbnail: {
            video: content.image_kit_id_16_9,
            square: content.thumbnail_image_kit_id,
            default: content.thumbnail_image_kit_id,
          },
          url: content.image_kit_id,
          valid: true,
          loading: false,
        },
      ];
      transformed.thumbnail = {
        video: content.image_kit_id_16_9,
        square: content.thumbnail_image_kit_id,
        default: content.thumbnail_image_kit_id,
      };
    }
  } else if (content.content_type === "collection") {
    transformed = {
      ...content,
      items: content.children.map((child) => transformData(child)),
      thumbnail: {
        video: content.image_kit_id_16_9,
        square: content.thumbnail_image_kit_id,
        default: content.thumbnail_image_kit_id,
      },
    };
    console.log(transformed);
  } else {
    return content;
  }

  return transformed;
};
