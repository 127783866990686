<template>
  <div class="p-2 bg-transparent">
    <div
      class="relative max-w-[900px] w-full shadow-default rounded-[16px] group cursor-pointer">
      <img
        :src="
          content.image_kit_id_16_9 ??
          content.thumbnail_image_kit_id ??
          content.image_kit_ids?.[0]
        "
        alt=""
        class="w-full object-cover aspect-video rounded-[16px] shadow-default" />
      <div
        class="absolute inset-0 bg-black opacity-0 z-50 rounded-[16px] group-hover:opacity-30 transition-opacity"></div>
      <i
        class="fa-thin fa-rectangle-history text-white absolute top-2 left-3 p-1 size-7 rounded-full bg-black/40 flex items-center justify-center"></i>
      <i
        v-if="is_editing"
        class="fa-solid fa-ellipsis-vertical text-white absolute top-2 right-3 p-2"></i>
      <div
        class="w-full max-w-[75%] text-white absolute bottom-8 left-3 flex flex-col gap-2">
        <h1 class="text-lg font-medium">{{ content.title }}</h1>
        <Roles
          v-if="content.owner_roles && content.owner_roles.length !== 0"
          :items="content.owner_roles.map((v) => v.name)"
          :fontSize="'12px'"
          :textColor="'#fff'"
          :separatorWidth="'4px'"
          :separatorHeight="'4px'"
          :separatorColor="'#8c8c8c'" />
      </div>
    </div>

    <ManageContent
      ref="manageMenu"
      :content="content"
      @updateContent="handleUpdateContent" />
  </div>
</template>
<script setup>
import { reactive, ref, computed, onMounted, watch } from "vue";
import InfoBallon from "@/components/parts/InfoBallon.vue";
import Roles from "@/components/general/Roles.vue";
import ManageContent from "@/components/menus/ManageContent.vue";
import { useUserBizcardStore } from "@/store/UserBizcard";
const bizcardStore = useUserBizcardStore();
const is_editing = computed(() => bizcardStore.isEditing);
const props = defineProps({
  content: { required: true, type: Object },
});
const handleUpdateContent = () => {
  bizCardStore.editContent(props.content, FeedType.CONTENT);
};
</script>
