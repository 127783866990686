<template>
  <div class="bg-no-repeat bg-center link_view relative cursor-pointer">
    <div :class="{ gradientClass: props.data?.content?.children?.length > 1 }">
      <img
        :src="
          props.data?.content?.image_kit_id_16_9 ??
          props.data?.content?.thumbnail_image_kit_id ??
          props.data?.content?.image_kit_id ??
          props.data?.content?.image_kit_ids?.[0] ??
          'https://storage.googleapis.com/demo-api.bizly.net/static/app-templates/web-bg-min.jpg'
        "
        alt=""
        class="w-full h-full object-cover aspect-video" />
      <InfoBallon>
        <template #icon><i class="fal fa-link text-md"></i></template>
        <template
          #count
          v-if="
            props.data?.content?.children?.length &&
            props.data?.content?.children?.length > 1
          ">
          {{ props.data?.content?.children?.length }}
        </template>
      </InfoBallon>
    </div>

    <div class="" v-if="props.data?.content?.content_type == 'link_gallery'">
      <div class="flex gap-2" v-if="props.data?.content?.children?.length < 1">
        <img
          v-for="child in props.data?.content?.children"
          :src="child.thumbnail_image_kit_id"
          class="w-1/2" />
      </div>
      <div
        class="relative w-full flex items-end bg-black h-[130px] z-[50]"
        v-else>
        <div class="w-full flex scroll-hidden gap-2.5 py-[15px] relative">
          <swiper
            :slides-per-view="2"
            :space-between="10"
            class="h-fit link_child_swiper w-full !px-[15px] !mx-0">
            <swiper-slide v-for="child in props.data?.content?.children">
              <div
                class="aspect-video overflow-hidden w-full min-w-full rounded-sm border-b-0.5 border-b-main-divider">
                <img
                  :src="child.thumbnail_image_kit_id"
                  alt=""
                  class="w-full object-cover h-full" />
              </div>
              <div
                class="bg-[#262626] rounded-b-sm h-[46px] max-h-[46px] py-2.5 px-2 w-full">
                <h6
                  class="text-main-divider text-[8px] mb-0 uppercase overflow-hidden text-ellipsis whitespace-nowrap">
                  {{ child.image_kit_id }}
                </h6>
                <p
                  class="text-white text-xs w-full overflow-hidden text-ellipsis whitespace-nowrap">
                  {{ child.title }}
                </p>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
    <div
      class="textColorWrapper bg-main-lightBg pt-2 px-3 pb-3 transition-all ease-in duration-[.2s] w-full border-t-[1px] border-main-divider"
      v-else>
      <div class="text-main-divider text-xs">
        <a
          href="https://www.poetryfoundation.org/poems"
          target="_blank"
          class="no-underline text-main-divider hover:text-main-divider active:text-main-divider visited:text-main-divider uppercase">
          {{ CleanUrl(data.content?.image_kit_id) }}
        </a>
        <p class="leading-4 text-white text-base font-medium my-2.5">
          {{ data.content.title }}
        </p>
        <span
          class="text-main-disabled leading-5 font-normal text-sm line-clamp-4">
          {{ data.content.description }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
/*
POST WITH 1 LINK : 829
POST WITH 2 LINKS : 785
POST WITH > 3 LINKS : 836
*/
import { CleanUrl } from "@/utils/formatter";
import "swiper/css";
import InfoBallon from "../parts/InfoBallon.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { cn } from "@/utils/cn";
const props = defineProps(["data"]);
</script>
<style scoped lang="scss">
.gradientClass {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 65%,
      rgba(0, 0, 0, 1) 100%
    );
  }
}

.link_view {
  &::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    z-index: 5;
    top: 0;
    left: 0;
  }

  &:hover {
    .textColorWrapper {
      background: #373839 !important;
    }
  }
}
</style>
