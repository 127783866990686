<template>
  <HomeLayout v-bind="(page.props as any)">
    <div
      class="overflow-y-auto relative bg-white rounded-[16px] my-[30px] shadow-2xl max-w-[500px] mx-[1rem] lg:mx-0 h-[90%] flex flex-col overflow-hidden"
    >
      <!-- Header -->
      <div
        class="sticky top-0 z-[60] bg-[#F7F8FB] py-[10px] px-[20px] border-b border-[#CDD5E1] flex items-center gap-[16px]"
      >
        <button class="text-gray-500 hover:text-gray-700">
          <img
            src="@/assets/images/arrow-back.svg"
            alt="back"
            @click="window.history.back()"
          />
        </button>
        <h2 class="!text-[16px] font-[500] mb-0">Portfolio Social Post</h2>
      </div>

      <!-- Scrollable Content -->
      <div
        class="flex-1 overflow-y-auto scroll-hidden overscroll-none scroll-smooth"
      >
        <div class="flex flex-col custom-scrollbar activity-feed">
          <SocialPost v-bind="props" class="!rounded-none" showInteractions />
        </div>
      </div>

      <!-- Footer -->
      <div class="activity-feed-footer sticky bottom-0 z-[20]">
        <Footer
          :data="Post"
          @update="(updated : SocialPostInterface) => Post = updated"
        />
      </div>
    </div>
  </HomeLayout>
</template>
<script setup lang="ts">
import HomeLayout from "@/components/layout/HomeLayout.vue";
import Footer from "@/components/social-posts/Footer.vue";
import { router, Link, usePage } from "@inertiajs/vue3";
import SocialPost from "./parts/SocialPost.vue";
import type { SocialPostInterface } from "@/core/interfaces";
import { computed } from "vue";

const page = usePage();

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
});
const Post = computed(() => props.post);
</script>

<style scoped lang="scss">
@import "../../assets/scss/feed.scss";

.socialPostActivtyFeed {
  width: 600px;

  @media screen and (max-width: 1340px) {
    width: 450px;
  }

  @media screen and (max-width: 1040px) {
    width: 100%;
  }
}

.socialPostActivtyFeedInner {
  width: 500px;

  @media screen and (max-width: 1340px) {
    width: 400px;
  }

  @media screen and (max-width: 1040px) {
    width: 500px;
  }
}
</style>
