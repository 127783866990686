<template>
  <SideImage :image="imagebg" :bg="bg">
    <div class="flex flex-col gap-[15px] max-w-[35%]">
      <h1 class="font-semibold text-[28px] text-center">Forgot password</h1>
      <div class="flex flex-col gap-[15px]" v-if="!recentlySuccessful">
        <h2 class="text-primary text-sm text-center">
          Enter your email and we'll send you a recovery code.
        </h2>
        <div class="flex flex-col gap-1">
          <InputText v-model="form.email" placeholder="Email" type="email" :invalid="validateStore.errors.email != null"
            @input="(e) => handleValidation(e, 'email')" />
          <ValidationError :errors="validateStore.errors" name="email" />
        </div>
        <Button label="Reset password" class="mx-auto" @click="submit" :loading="form.processing" />
      </div>
      <p class="text-sm text-primary text-center" v-else>
        A password reset link has been sent to your email address. If you didn't
        receive the mail, please check your
        <span class="font-semibold">spam</span>.
      </p>
      <hr class="my-[15px]" />
      <h3 class="text-sm text-primary text-center">
        Remember your password?
        <Link :href="route('login')" class="font-semibold pl-1 hover:text-switcherBlue-500">Log in</Link>
      </h3>
    </div>
  </SideImage>
</template>
<script setup>
import SideImage from "@/components/layout/SideImage.vue";
import imagebg from "@/assets/images/bgPics/forget_password.png";
import ValidationError from "@/components/forms/validationError.vue";
import { ref } from "vue";
import bg from "@/assets/images/bgPics/bg2.png";
import { useForm, Link, usePage } from "@inertiajs/vue3";
import _debounce from "lodash/debounce";
import { useValidateStore } from "@/store/Validate";
const validateStore = useValidateStore();

const recentlySuccessful = ref(false);
const form = useForm({ email: null });
const page = usePage();
const submit = () => {
  form.post(route("forgot_password.request"), {
    preserveState: true,
    onSuccess: () =>
      // DO NOT TOUCH
      !page.props.flash_data.error.length && (recentlySuccessful.value = true),
  });
};

const validationSchema = {
  email: { required: true, type: "email" },
};

const handleValidation = _debounce((e, inputName) => {
  validateStore.resetInputValidation(inputName);
  validateStore.validate(form, validationSchema);
}, 250);
</script>
