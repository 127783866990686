import baseApi from "./Base";
import { route } from "ziggy-js";

export class CategoriesService extends baseApi {
  async get() {
    return await this.request(route("bizcards.categories.get"), "POST");
  }
  async update(form) {
    return await this.request(
      route("bizcards.categories.update"),
      "POST",
      form,
    );
  }
  async delete(id) {
    return await this.request(
      route("bizcards.categories.delete", { category: id }),
      "POST",
    );
  }
}
