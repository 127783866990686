<template>
  <div class="flex flex-col min-h-screen overscroll-contain">
    <div class="sticky top-0 h-[102px] z-[999]">
      <HeaderComponent />
      <SubHeader :header="header">
        <template #menu>
          <Link
            v-for="item in MENU.filter((i) => i.show)"
            :key="item.title"
            :href="item.href"
            class="rounded-md text-white/75 text-sm font-semibold py-2.5 px-5 uppercase hover:bg-white hover:text-main-primary transition-all leading-none"
            :class="{ 'bg-white !text-main-primary': item.active }">
            {{ item.title }}
          </Link>
        </template>
        <template #actions>
          <slot name="subheaderActions" />
        </template>
      </SubHeader>
      <slot name="customHeader" />
    </div>
    <slot />
    <Footer />
  </div>
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch } from "vue";
import { type PropType } from "vue";
import Footer from "@/components/bizcard/content/Footer.vue";
import SubHeader from "@/components/bizcard/content/Header.vue";
import type { headerProps } from "@/components/bizcard/content/Header.vue";
import HeaderComponent from "./Header.vue";
import { Link } from "@inertiajs/vue3";
import { cn } from "@/utils/cn";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { route } from "ziggy-js";
import { useUserBizcardStore } from "@/store/UserBizcard";
const bizcardStore = useUserBizcardStore();
const props = defineProps({
  header: {
    required: true,
    type: Object as PropType<headerProps>,
  },
});

const containerSlot = ref();

const MENU = computed(() => [
  {
    title: "home",
    href: route("user.profile.index", props.header.user.public_username),
    active: route().current() === "user.profile.index",
    show: true,
  },
  {
    title: "portfolio",
    href: route("user.profile.portfolio", props.header.user.public_username),
    active: route().current() === "user.profile.portfolio",
    show: true,
  },
  {
    title: "info",
    href: route("user.profile.info", props.header.user.public_username),
    active: route().current() === "user.profile.info",
    show: true,
  },
  {
    title: "services",
    href: route("user.profile.services", props.header.user.public_username),
    active: route().current() === "user.profile.services",
    show: true,
  },
  { title: "resume", href: "#", active: false, show: bizcardStore.own_profile },
]);
</script>
