<template>
  <div>
    <vue-tel-input
      v-bind="bindProps"
      v-model="phNumber"
      :readonly="isValid"
      class="text-sm input placeholder:text-primary"
      :disabled="props.disabled"
      :class="{
        'border-switcherBlue-500': phNumber,
        'border-lightBg-500 focus:border-switcherBlue-500': !phNumber && !props.invalid,
        '!border-red-500': (!isValid && phNumber) || props.invalid,
      }"
      @validate="onCountryChange"
      @on-input="phoneNumberChange"
    />
    <small
      v-if="
        (!isValid && phNumber) ||
        props.invalid ||
        (props.errors && props.errors[errorName])
      "
      class="text-red-500"
    >
      {{ props.errors[props.errorName] ?? "This is not a valid number" }}
    </small>
  </div>
</template>

<script setup>
import { onUnmounted, onMounted, ref, watch } from "vue";
const props = defineProps({
  errors: { type: Object, default: () => {} },
  errorName: { type: String, default: "phone" },
  clearInput: {
    type: Boolean,
    default: false,
  },
  initValue: {
    type: String,
    default: "",
  },
  invalid: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const phValue = defineModel();
const phNumber = ref(props.initValue);
const isValid = ref(null);
const emit = defineEmits(["getPhoneValue", "isValid"]);
const onCountryChange = (phoneObject) => {
  isValid.value = phoneObject?.valid;
  phValue.value = phoneObject;
  emit("isValid", isValid.value);
};

const phoneNumberChange = (e, p) => {
  isValid.value = p?.valid ?? false;
  phValue.value = p;
  phNumber.value = p?.formatted?.replace(`+${p.country?.dialCode}`, "");
  emit("isValid", isValid.value);
  emit("getPhoneValue", p);
};
watch(
  () => props.initValue,
  (newValue) => {
    phNumber.value =
      typeof newValue == "object"
        ? newValue?.formatted?.replace(`+${p.country?.dialCode}`, "")
        : newValue;
  }
);
onUnmounted(() => {
  phNumber.value = "";
  phValue.value = {};
});

const bindProps = {
  mode: "international",
  defaultCountry: "US",
  disabledFetchingCountry: false,
  disabled: false,
  disabledFormatting: false,
  placeholder: "Phone number",
  required: false,
  enabledCountryCode: true,
  onlyCountries: import.meta.env.VITE_ALLOWED_COUNTRIES?.split(" "),
  ignoredCountries: [],
  autocomplete: "off",
  name: "telephone",
  wrapperClasses: "",
  inputClasses: "",
  validCharactersOnly: true,
  dropdownOptions: {
    showFlags: true,
    showDialCodeInSelection: true,
    showDialCodeInList: true,
    showSearchBox: true,
  },
  inputOptions: { placeholder: "Phone number" },
};
</script>

<style lang="scss" scoped>
.input :deep(.vti__dropdown-list) {
  z-index: 9999 !important;
}

.input :deep(.vti__input)::placeholder {
  color: #8f9fb8 !important;
}

.input :deep(.vti__dropdown-item) strong {
  display: none !important;
}

.input :deep(.vti__selection) {
  border-right: 1px solid #8f9fb8 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
</style>
