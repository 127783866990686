<template>
  <div>
    <slot />

    <EditContentDialog
      v-if="dialogStore.isOpened(DialogType.UPLOAD_CONTENT)"
      :content="dialogStore[DialogType.UPLOAD_CONTENT].data?.content"
      @close="dialogStore[DialogType.UPLOAD_CONTENT].events?.close"
      @update="dialogStore[DialogType.UPLOAD_CONTENT].events?.update" />
    <CollectionDialog
      v-if="dialogStore.isOpened(DialogType.UPLOAD_CONTENT_COLLECTION)"
      :content="dialogStore[DialogType.UPLOAD_CONTENT_COLLECTION].data?.content"
      @close="
        dialogStore[DialogType.UPLOAD_CONTENT_COLLECTION].events?.close
      " />
    <ExternalLink v-if="dialogStore.isOpened(DialogType.EXTERNAL_LINK)" />
    <ManageGroup
      v-if="dialogStore.isOpened(DialogType.MANAGE_GROUP)"
      @close="dialogStore[DialogType.MANAGE_GROUP].events?.close" />
    <ManageGroups
      v-if="dialogStore.isOpened(DialogType.MANAGE_GROUPS)"
      @close="dialogStore[DialogType.MANAGE_GROUPS].events?.close" />
    <Invitations
      :visible="dialogStore.isOpened(DialogType.INVITATIONS)"
      v-if="dialogStore.isOpened(DialogType.INVITATIONS)"
      @close="dialogStore[DialogType.INVITATIONS].events?.close" />
    <MobileApp
      v-if="dialogStore.isOpened(DialogType.MOBILE_APP)"
      @close="dialogStore[DialogType.MOBILE_APP].events?.close" />
    <ManageLink
      v-if="dialogStore.isOpened(DialogType.MANAGE_LINK)"
      @close="dialogStore[DialogType.MANAGE_LINK].events?.close"
      @confirm="dialogStore[DialogType.MANAGE_LINK].events?.confirm"
      @delete="dialogStore[DialogType.MANAGE_LINK].events?.delete" />
    <ManageBizcard
      v-if="dialogStore.isOpened(DialogType.MANAGE_BIZCARD)"
      @close="dialogStore[DialogType.MANAGE_BIZCARD].events?.close" />
  </div>
</template>
<script setup lang="ts">
// THIS COMPONENT WRAPS THE WHOLE APPLICATION
import { reactive, ref, computed, onMounted, watch } from "vue";
import { useAuthStore } from "@/store/Auth";
import { router, usePage } from "@inertiajs/vue3";
import { onBeforeMount } from "vue";
import { parseFlashData } from "@/utils/toast";
import CollectionDialog from "@/components/dialogs/collection/Index.vue";
import { useDialogStore, DialogType } from "./store/Dialog";
import { useUserStore } from "@/store/User";
import { useStore } from "@/store";
import ExternalLink from "@/components/dialogs/ExternalLink.vue";
import ManageGroup from "@/components/dialogs/contacts/ManageGroup.vue";
import ManageGroups from "@/components/dialogs/contacts/ManageGroups.vue";
import Invitations from "@/components/dialogs/invitations/Main.vue";
import MobileApp from "@/components/dialogs/MobileApp.vue";
import ManageLink from "@/components/dialogs/bizcard/home/ManageLink.vue";
import ManageBizcard from "@/components/dialogs/bizcard/home/ManageBizcard.vue";
import EditContentDialog from "@/components/dialogs/content/Main.vue";

import type { UserInterface } from "@/core/interfaces";
const page = usePage();
const authStore = useAuthStore();
const store = useStore();
const userStore = useUserStore();
const dialogStore = useDialogStore();
const ToastList = computed(() => page.props.flash_data);

const token = computed<string | null>(() => page.props.token);
const authUser = computed(() => ({
  ...(page.props.auth_user as UserInterface),
}));
watch(
  ToastList,
  (newVal) => {
    parseFlashData(newVal);
  },
  { immediate: true, deep: true },
);

// THIS CHECKS FOR TOKEN UPDATES AND RESETS THE TOKEN IN THE STORE
// WITHOUT IT YOU NEED TO REFRESH THE PAGE SO THAT THE TOKEN UPDATES
watch(
  () => token.value,
  () => {
    setUserData();
  },
);
watch(
  () => authUser.value,
  () => {
    authStore.setUser(authUser.value);
  },
  { deep: true },
);
onBeforeMount(() => {
  setUserData();
  store.getNewToBizlyUsers();
  store.getQuoteOfTheDay();
});
const setFriendsList = async () => {
  if (token.value) {
    await userStore.getFriends();
  } else {
    await userStore.emptyFriendsList();
  }
};

const setUserData = () => {
  authStore.setToken(token.value as string | null);
  authStore.setUser(authUser.value);
  setFriendsList();
};
</script>
