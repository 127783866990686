<template>
    <div :class="cn(
        'w-full rounded flex gap-2 text-white p-2 items-center cursor-pointer', props.class,
        {
            'bg-primary-700': !disabled,
            'bg-white/10 text-surface-400': disabled,
        }
    )
        " @click="() => callback()">
        <i class="far fa-plus-circle"></i>
        <p class="text-sm">
            Add <b>{{ value }}</b>
        </p>
    </div>
</template>
<script setup lang="ts">
import { cn } from '@/utils/cn';
const props = defineProps({
    class: String,
    value: {
        type: String || Number
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    callback: {
        type: Function,
        required: true,
    },
})
</script>