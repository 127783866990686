export const formatPhoneNumber = (rawNumber) => {
  const cleanedNumber = rawNumber?.replace(/\D/g, "");
  let countryCode;

  if (cleanedNumber?.startsWith("1")) {
    countryCode = cleanedNumber?.length === 10 ? "US" : "CA";
  } else if (cleanedNumber?.startsWith("44")) {
    countryCode = "UK";
  } else if (cleanedNumber?.startsWith("92")) {
    countryCode = "PK";
  } else if (cleanedNumber?.startsWith("961")) {
    countryCode = "LB";
  } else {
    countryCode = "Unknown";
  }
  let formattedNumber;

  switch (countryCode) {
    case "US":
    case "CA":
      const areaCode = cleanedNumber?.slice(1, 4);
      const firstPart = cleanedNumber?.slice(4, 7);
      const secondPart = cleanedNumber?.slice(7);
      formattedNumber = `+1 ${areaCode}-${firstPart}-${secondPart}`;
      break;
    case "UK":
      const ukAreaCode = cleanedNumber?.slice(2, 6);
      const ukSecondPart = cleanedNumber?.slice(7);
      formattedNumber = `+44 ${ukAreaCode} ${ukSecondPart}`;
      break;
    case "PK":
      const pkAreaCode = cleanedNumber?.slice(0, 3);
      const pkExchange = cleanedNumber?.slice(3, 7);
      const pkSubscriber = cleanedNumber?.slice(7);
      formattedNumber = `+92 ${pkAreaCode}-${pkExchange}-${pkSubscriber}`;
      break;
    case "LB":
      const lbAreaCode = cleanedNumber?.slice(0, 2);
      const lbExchange = cleanedNumber?.slice(2, 5);
      const lbSubscriber = cleanedNumber?.slice(8);
      formattedNumber = `+961 ${lbAreaCode}-${lbExchange}-${lbSubscriber}`;
      break;
    default:
      formattedNumber = cleanedNumber;
      break;
  }

  return formattedNumber;
};
export const getOrdinalSuffix = (day) => {
  if (day > 3 && day < 21) return "th"; // covers 11-20
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const formatDateLong = (date) => {
  const options = { month: "long" };
  const month = new Intl.DateTimeFormat("en-US", options)
    .format(date)
    .toUpperCase();
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}${getOrdinalSuffix(day)}, ${year}`;
};

export const useFormattedDate = (props) => {
  const date = new Date(props);
  const day = date.getDate();
  const ordinalIndicator = getOrdinalSuffix(day);
  const formattedDate =
    date?.toLocaleString("en-US", { month: "long" }) +
    ` ${day}${ordinalIndicator}, ${date.getFullYear()}`;
  return formattedDate;
};

export const CleanUrl = (url) => {
  return url?.replace(/https?:\/\/(www\.)?/, "")?.replace(/^www\./, "");
};

export const formatDateShort = (date) => {
  const shortDate = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Intl.DateTimeFormat("en-US", options).format(shortDate);
};

export const formatDateMonthYear = (date) => {
  const shortDate = new Date(date);
  const options = { year: "numeric", month: "long" };
  return new Intl.DateTimeFormat("en-US", options).format(shortDate);
};
