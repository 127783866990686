<template>
  <Dropdown
    v-model="service"
    editable
    @change="searchService"
    :options="optionList"
    optionLabel="title"
    :placeholder="placeholder"
    :class="cn('w-full md:w-14rem', pt.root)"
    :pt="{
      wrapper: 'max-h-[200px] overflow-auto scroll-hidden',
      input: pt.input,
      list: 'max-w-[350px]',
    }"
    :ptOptions="{ mergeProps: true }"
    :loading="loading"
    :showClear="showClear">
    <template #option="{ option }">
      <div class="flex gap-2 items-center">
        <p :class="cn('text-inherit', pt.optionLabel)">
          {{ option["title"] }}
        </p>
      </div>
    </template>
  </Dropdown>
</template>

<script lang="ts" setup>
import { reactive, ref, computed, onMounted, watch, PropType } from "vue";
import _debounce from "lodash/debounce";
import { API } from "@/core/api";
import { cn } from "@/utils/cn";

/* -------- PROPS -------- */
const props = defineProps({
  pt: {
    type: Object as PropType<{
      root?: string | object;
      input?: string | object;
      optionLabel?: string | object;
    }>,
    default: () => ({}),
  },
  selected: {
    type: Array,
    default: () => [],
  },
  showClear: {
    type: Boolean,
    default: false,
  },
  clearCallback: {
    type: Function,
    default: () => {},
  },
  placeholder: {
    type: String,
  },
});

const model = defineModel({
  type: String,
});
/* -------- COMPOSABLE -------- */
const servicesAPI = new API.Services();

/* -------- STATE -------- */
const loading = ref(false);
const optionList = ref([]);
const service = ref(model.value);

/* -------- METHODS -------- */
const searchService = _debounce(async (e: any) => {
  if (e.originalEvent.type === "input" && e.value) getData(e.value);
  if (e.originalEvent.type === "input" && !e.value) {
    props.clearCallback();
    model.value = undefined;
    getData("");
  }
  if (e.originalEvent.type === "click" && !e.value) {
    props.clearCallback();
    model.value = undefined;
    getData("");
  }
  if (e.originalEvent.type === "click" && e.value) {
    model.value = e.value;
  }
}, 250);

const getData = async (keyword: string = "") => {
  loading.value = true;
  optionList.value = [];
  const response = await servicesAPI.get({ keyword });

  optionList.value = response.data;
  loading.value = false;
};

/* -------- HOOKS -------- */
onMounted(() => {
  getData();
});
/* -------- WATCHERS -------- */
watch(model, (newVal) => {
  if (newVal === undefined) {
    service.value = undefined;
    getData("");
  }
});
</script>
