import { useToastr } from "./../composables/useToastr";
import type { ConnectionsInterface, UserInterface } from "@/core/interfaces";
import { defineStore } from "pinia";
import { API } from "@/core/api";
interface FriendRequests {
  sent: ConnectionsInterface[];
  received: ConnectionsInterface[];
}
interface State {
  user: Object | null;
  friends: UserInterface[] | null;
  friend_requests: FriendRequests;
  openExternalLinks: boolean; // false will open a warning modal every time an external link is clicked
}

export const useUserStore = defineStore("userStore", {
  state: (): State => ({
    user: null,
    friends: null,
    friend_requests: {
      sent: [],
      received: [],
    },
    openExternalLinks: false,
  }),
  getters: {},
  actions: {
    async getFriends() {
      const UsersAPI = new API.Users();

      try {
        let response = await UsersAPI.getFriends();
        this.friends = response.data.friends;
        this.friend_requests = response.data.friend_requests;
      } catch (err: any) {
        console.error("[DEBUG] :: getFriends :: ", err);
      }
    },
    async emptyFriendsList() {
      this.friends = [];
      this.friend_requests = {
        sent: [],
        received: [],
      };
    },
    async blockUser(userId: number) {
      const { success, error } = useToastr();
      const UsersAPI = new API.Users();

      try {
        const response = await UsersAPI.block(userId);
        this.friends = this.friends?.filter(
          (f) => f.id !== userId,
        ) as UserInterface[];
        success(response.success || "User blocked successfully");
      } catch (err: any) {
        console.error(err);
        error(err.message || "An error occurred while blocking the user");
      }
    },
    async connectUser(userId: number) {
      const UsersAPI = new API.Users();
      const { success, error } = useToastr();

      try {
        const response = await UsersAPI.connect(userId);
        this.friend_requests.sent.push(response.data);
        success("Connection request sent successfully");
      } catch (err: any) {
        error(
          err.message || "An error occurred while sending connection request",
        );
      }
    },
    async removeConnection(userId: number) {
      const UsersAPI = new API.Users();
      const { success, error } = useToastr();

      try {
        await UsersAPI.RemoveConnect(userId);
        this.friends = this.friends?.filter(
          (f) => f.id !== userId,
        ) as UserInterface[];
        success("Connection removed successfully");
      } catch (err: any) {
        error(err.message || "An error occurred while removing connection");
      }
    },
    async withdrawConnectionRequest(connection: ConnectionsInterface) {
      const connAPI = new API.Connections();
      const { success, error } = useToastr();

      try {
        await connAPI.withdraw(connection.id);
        this.friend_requests.sent = this.friend_requests.sent.filter(
          (f) => f.id !== connection.id,
        ) as ConnectionsInterface[];
        success("Connection request withdrawn successfully");
      } catch (err: any) {
        error(
          err.message ||
            "An error occurred while withdrawing connection request",
        );
      }
    },
    async acceptConnectionRequest(connection: ConnectionsInterface) {
      const connAPI = new API.Connections();
      const { success, error } = useToastr();

      try {
        await connAPI.accept(connection.id);
        this.friend_requests.received = this.friend_requests.received.filter(
          (f) => f.id !== connection.id,
        ) as ConnectionsInterface[];
        this.friends?.push(connection.sender);
        success("Connection request accepted successfully");
      } catch (err: any) {
        error(
          err.message || "An error occurred while accepting connection request",
        );
      }
    },
    async declineConnectionRequest(connection: ConnectionsInterface) {
      const connAPI = new API.Connections();
      const { success, error } = useToastr();

      try {
        await connAPI.decline(connection.id);
        this.friend_requests.received = this.friend_requests.received.filter(
          (f) => f.id !== connection.id,
        ) as ConnectionsInterface[];
        success("Connection request declined successfully");
      } catch (err: any) {
        error(
          err.message || "An error occurred while declining connection request",
        );
      }
    },
    getConnectionRequest(
      userId: number,
      type: "sent" | "received",
    ): ConnectionsInterface | undefined {
      if (type === "sent") {
        return this.friend_requests.sent.find(
          (request) => request.recipient.id === userId,
        );
      } else if (type === "received") {
        return this.friend_requests.received.find(
          (request) => request.sender.id === userId,
        );
      }
      return undefined;
    },
    hasSentRequest(user: UserInterface) {
      return this.friend_requests.sent.some(
        (request) => request.recipient.id === user.id,
      );
    },
    hasReceivedRequest(user: UserInterface) {
      return this.friend_requests.received.some(
        (request) => request.sender.id === user.id,
      );
    },
    isFriend(user: UserInterface) {
      return this.friends?.some((friend) => friend.id === user.id) ?? false;
    },
  },
});
