<template>
  <SideImage :image="featureImg" :bg="bg">
    <div class="w-10/12 flex h-screen justify-center items-center">
      <div class="w-10/12">
        <div
          class="pt-1 text-[28px] text-center font-semibold w-[70%] m-auto pb-[28px]"
        >
          Congratulations, you’re on the
          <br />
          <span class="italic">bizly</span> waitlist!
        </div>
        <TextSeparator :text="'Get Started'" />
        <div
          class="bg-primaryBlue-500 px-[15px] py-[12px] mt-[15px] cursor-pointer text-center w-[200px] h-[40px] flex items-center justify-center m-auto rounded !mb-[30xp] hover:bg-[#3578F8]"
        >
          <Link
            as="button"
            class="text-white text-base font-normal"
            :href="route('home')"
          >
            Start exploring
          </Link>
        </div>
        <TextSeparator :text="'Invite your network'" class="mt-3 mb-3" />
        <div
          class="bg-primaryBlue-500 px-[15px] py-[12px] cursor-pointer text-center w-[200px] h-[40px] flex items-center justify-center m-auto rounded hover:bg-[#3578F8]"
        >
          <button
            type="button"
            class="text-white text-base font-normal"
            @click="InvitationDialogVisible = true"
          >
            Invite network
          </button>
        </div>
      </div>
    </div>
    <InvitationDialog
      :visible="InvitationDialogVisible"
      @close="InvitationDialogVisible = false"
    />
  </SideImage>
</template>

<script setup>
import InvitationDialog from "@/components/dialogs/invitations/Main.vue";
import featureImg from "@/assets/images/bgPics/step-1.png";
import bg from "@/assets/images/bgPics/bg.png";
import TextSeparator from "@/components/general/TextSeparator.vue";
import SideImage from "@/components/layout/SideImage.vue";
import { ref } from "vue";
import { Link } from "@inertiajs/vue3";
const InvitationDialogVisible = ref(false);
</script>
