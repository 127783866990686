<template>
    <div
        class="xs:w-full md:w-1/2 lg:w-auto h-fit p-4 bg-white rounded-lg shadow-lg flex-col justify-start items-start gap-4 flex">
        <div class="text-main-primary text-lg font-medium">Project Details</div>
        <div class="self-stretch lg:h-60 flex-col justify-start items-start gap-3 flex">
            <div class="flex-col justify-center items-start gap-1.5 flex">
                <Subtitle>CLIENT / ORGANIZATION</Subtitle>
                <Label>
                    <template #pre>
                        <img class="size-4 lg:size-5 rounded-full" src="../../../../assets/images/usc-logo.png" />
                    </template>
                    University of Southern California
                </Label>
            </div>
            <SectionDivider :margin=false />
            <div class="self-stretch flex-col justify-center items-start gap-2 flex">
                <Subtitle>role on project</Subtitle>
                <div class="justify-start items-center gap-2 inline-flex">
                    <Label v-for="role in ['Colorist', 'Cinematographer']" :key="role">{{ role }}</Label>
                </div>
            </div>
            <div class="self-stretch flex-col justify-center items-start gap-2 flex">
                <Subtitle>project type</Subtitle>
                <div class="justify-start items-center gap-2 inline-flex">
                    <Label v-for="val in ['Photography', 'Music Video']" :key="val">{{ val }}</Label>
                </div>
            </div>
            <SectionDivider :margin=false />
            <div class="flex-col justify-center items-start gap-1 flex">
                <Subtitle>completed on</Subtitle>
                <div class="text-main-primary text-sm font-normal font-['Roboto'] leading-tight">2021</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Subtitle from '../../../parts/Subtitle.vue';
import SectionDivider from '../../../parts/SectionDivider.vue';
import Label from '../../../parts/Label.vue';

export default defineComponent({
    components: {
        SectionDivider,
        Label,
        Subtitle
    }
});
</script>