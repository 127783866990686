<template>
  <VueMultiselect
    v-model="model"
    :options="optionList"
    :multiple="true"
    placeholder="Add a location"
    :searchable="true"
    @search-change="searchLocation"
    track-by="name"
    label="name"
    :group-select="false"
    :showLabels="false"
    :tabindex="1"
    :openDirection="'below'"
    :closeOnSelect="false"
    :block-keys="['Tab', 'Enter']"
    :loading="loading"
    :taggable="false"
    :clearOnSelect="false"
    @open="handleSearchOpen"
    @close="handleSearchClose">
    <template #beforeList v-if="type == 'distance'">
      <div class="custom_option_no_option" @click="getLocation">
        <i class="fa fa-location-dot"></i>
        Use Current Location
        <b>{{ inputValue }}</b>
      </div>
    </template>
    <template #noResult>No option(s) found</template>
    <template #noOptions>No option(s) found</template>
  </VueMultiselect>
</template>

<script lang="ts" setup>
import { reactive, ref, computed, onMounted, watch, PropType } from "vue";
import VueMultiselect from "vue-multiselect";
import _debounce from "lodash/debounce";
import { API } from "@/core/api";
import { cn } from "@/utils/cn";
/* -------- PROPS -------- */
const props = defineProps({
  pt: {
    type: Object as PropType<{
      root?: string | object;
      input?: string | object;
      optionLabel?: string | object;
    }>,
    default: () => ({}),
  },
  selected: {
    type: Array,
    default: () => [],
  },
  showClear: {
    type: Boolean,
    default: false,
  },
  clearCallback: {
    type: Function,
    default: () => {},
  },
});

const model = defineModel({
  type: Array,
});
/* -------- COMPOSABLE -------- */
const locationsAPI = new API.Locations();

/* -------- STATE -------- */
const loading = ref(false);
const optionList = ref([]);
const locations = ref(model.value);
const searchOpen = ref(false);
/* -------- METHODS -------- */
const handleSearchOpen = () => {
  searchOpen.value = true;
};
const handleSearchClose = () => {
  searchOpen.value = false;
};
const searchLocation = _debounce(async (e: any) => {
  if (e.originalEvent.type === "input" && e.value) getLocations(e.value);
  if (e.originalEvent.type === "input" && !e.value) {
    props.clearCallback();
    model.value = undefined;
    getLocations("");
  }
  if (e.originalEvent.type === "click" && !e.value) {
    props.clearCallback();
    model.value = undefined;
    getLocations("");
  }
  if (e.originalEvent.type === "click" && e.value) {
    model.value = e.value;
  }
}, 250);

const getLocations = async (keyword: string = "") => {
  console.log(keyword);
  loading.value = true;
  optionList.value = [];
  const response = await locationsAPI.get({ keyword });
  optionList.value = response;
  console.log(optionList.value);
  loading.value = false;
};

const checkIfDisabled = (e: any) => {
  return props.selected.includes(e.name);
};
/* -------- HOOKS -------- */
onMounted(() => {
  getLocations();
});
/* -------- WATCHERS -------- */
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
.industry-select-custom {
  border: 1px solid #cdd5e1;
  width: 100%;
  font-size: 14px;
  border-radius: 4px;
  padding: 10px 6px !important;
  height: 40px !important;
  position: relative;
}

.custom_option_no_option {
  padding: 10px 16px;
  display: flex;
  border-bottom: 1px solid #cdd5e1;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #2998ff26;
  cursor: pointer !important;
  b {
    font-weight: 600;
  }

  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  color: #2998ff !important;
}
</style>
