<template>
  <Menu
    id="overlay_menu"
    :model="ellipsesMenu"
    class="w-[21rem] content_menu overflow-hidden shadow-[0_2px_15px_0_rgba(0,0,0,0.3)]"
    ref="headerMenu"
    :popup="true"
    :pt="{ content: 'hover:!bg-transparent' }"
    :ptOptions="{ mergeProps: true }">
    <template #item="{ item }">
      <div
        :class="{
          'pointer-events-none text-main-disabled hover:bg-white':
            item.disabled,
        }">
        <div
          class="flex items-center gap-4 py-3 px-4 w-full justify-between"
          v-if="item.type == 'toggle'">
          <div class="flex flex-col gap-2">
            <h1 class="text-base font-medium">
              {{ item.label }}
              <span
                :class="{ 'text-red-500': user.featured_count >= 5 }"
                class="text-xs">
                ({{ user.featured_count }}/5)
              </span>
            </h1>
            <p v-if="item.description" class="text-main-secondary text-xs">
              {{ item.description }}
            </p>
          </div>
          <div class="">
            <InputSwitch
              v-model="is_featured"
              :pt="{ root: '!w-11 !h-6' }"
              :ptOptions="{ mergeProps: true }"
              :disabled="user.featured_count >= 5" />
          </div>
        </div>
        <a
          class="flex items-center gap-4 py-3 px-4 w-full"
          :class="{
            'border-b border-main-divider': item.border,
            'hover:bg-switcherBlue-500-transparent': !item.disabled,
          }"
          v-else-if="item.type == 'external_link'"
          :href="item.href"
          target="_blank">
          <CircleIcon>
            <i :class="item.icon"></i>
          </CircleIcon>

          <div class="flex flex-col gap-[2px] items-start justify-start">
            <p
              class="mb-0 w-max text-[16px] font-medium leading-5"
              :class="{
                'text-[#ff3b30]': item.danger,
              }">
              {{ item?.label }}
            </p>
            <small
              class="text-xs font-normal leading-4 text-main-secondary text-left"
              v-if="item.description">
              {{ item?.description }}
            </small>
          </div>
        </a>
        <Link
          v-else-if="item.type === 'inertia_link'"
          :href="item.href"
          class="flex items-center gap-4 py-3 px-4 w-full"
          :class="{
            'border-b border-main-divider': item.border,
            'hover:bg-switcherBlue-500-transparent': !item.disabled,
          }">
          <CircleIcon :class="{ 'bg-[#ff3b30]/30': item.danger }">
            <i :class="item.icon"></i>
          </CircleIcon>

          <div class="flex flex-col gap-[2px] items-start justify-start">
            <p
              class="mb-0 w-max text-[16px] font-medium leading-5"
              :class="{
                'text-[#ff3b30]': item.danger,
              }">
              {{ item?.label }}
            </p>
            <small
              class="text-xs font-normal leading-4 text-main-secondary text-left"
              v-if="item.description">
              {{ item?.description }}
            </small>
          </div>
        </Link>
        <button
          v-else-if="item.type === 'button'"
          @click="item.clickEvent"
          class="flex items-center gap-[12px] py-3 px-4 w-full"
          :class="{
            'border-b border-main-divider': item.border,
            'hover:bg-switcherBlue-500-transparent': !item.disabled,
          }">
          <CircleIcon size="2rem">
            <i :class="item.icon"></i>
          </CircleIcon>
          <div class="flex flex-col gap-[2px] items-start justify-start">
            <p
              class="mb-0 w-max text-[16px] font-medium leading-5"
              :class="{
                'text-[#ff3b30]': item.danger,
              }">
              {{ item?.label }}
            </p>
            <small
              class="text-xs font-normal leading-4 text-main-secondary text-left"
              v-if="item.description">
              {{ item?.description }}
            </small>
          </div>
        </button>
      </div>
    </template>
  </Menu>
  <ConfirmDialog
    v-if="DeletePostDialogVisible"
    color="danger"
    @confirm="handleDelete"
    @close="DeletePostDialogVisible = false"
    action="Delete"
    cancel="cancel">
    <template #icon>
      <div
        class="size-16 p-1 bg-red-700 rounded-full flex items-center justify-center">
        <i class="fa-2x fa fa-trash text-white"></i>
      </div>
    </template>
    <template #title>Delete content</template>
    <template #description>
      Are you sure you would like to delete this content? This action cannot be
      undone.
    </template>
  </ConfirmDialog>
  <EditPostDialog
    v-if="dialogStore.isOpened(DialogType.POST_MANAGER)"
    @close="dialogStore.hide(DialogType.POST_MANAGER)" />
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch } from "vue";
import type { ContentInterface, MenuButtonInterface } from "@/core/interfaces";
import { useAuthStore } from "@/store/Auth";
import type { PropType } from "vue";
import { transformData } from "../dialogs/content/helpers/transformData";
import { DialogType, useDialogStore } from "@/store/Dialog";
import { route } from "ziggy-js";
import { useToastr } from "@/composables/useToastr";
import { Link } from "@inertiajs/vue3";
import CircleIcon from "../icons/CircleIcon.vue";
import ConfirmDialog from "@/components/dialogs/Confirm.vue";
import EditPostDialog from "@/components/dialogs/social-posts/EditPost.vue";

import { API } from "@/core/api";
const contentAPI = new API.Content();
const props = defineProps({
  content: {
    required: true,
    type: Object as PropType<ContentInterface>,
  },
});
const emit = defineEmits(["updateContent"]);

const authStore = useAuthStore();
const dialogStore = useDialogStore();
const headerMenu = ref();
const DeletePostDialogVisible = ref(false);
const { success, error } = useToastr();
const is_featured = ref(props.content.is_featured);
const user = computed(() => authStore.getUser);
const isOwned = computed<Boolean>(
  () => props.content?.bizcard?.user?.id === user.value?.id,
);
const ellipsesMenu = computed<MenuButtonInterface[]>(() => {
  return [
    {
      active: isOwned.value,
      label: "Feature content",
      description: "Pin content to the top of your portfolio",
      border: false,
      type: "toggle",
    },
    {
      active: isOwned.value,
      label: "Edit this content",
      border: false,
      type: "button",
      icon: "fa-regular fa-pencil",
      clickEvent: handleOpenContentDialog,
    },
    {
      disabled: true,
      active: isOwned.value,
      label: "Convert into a collection",
      border: true,
      type: "button",
      icon: "fa-regular fa-rectangle-history",
    },
    {
      disabled: true,
      active: true,
      label: "Send in Messenger",
      border: false,
      type: "button",
      icon: "fa-regular fa-comment",
    },
    {
      disabled: true,
      active: true,
      label: "Share content",
      border: false,
      type: "button",
      icon: "fa-regular fa-share",
    },
    {
      active: isOwned.value,
      label: "Share as a post",
      border: false,
      type: "button",
      icon: "fa-light fa-pen-to-square",
      clickEvent: handleSharePost,
    },
    {
      active: true,
      label: "Copy content link",
      border: true,
      type: "button",
      icon: "fa-regular fa-link",
      clickEvent: async () => {
        const permissionStatus = await navigator.permissions.query({
          name: "clipboard-write",
        });
        if (
          permissionStatus.state === "granted" ||
          permissionStatus.state === "prompt"
        ) {
          const url = route("bizcards.content.get", props.content.id);
          await navigator.clipboard.writeText(url);
          success(`${url} copied to clipboard!`);
        }
      },
    },
    {
      active: !isOwned.value,
      label: "Report content",
      border: false,
      type: "button",
      icon: "fa-sharp fa-regular fa-brake-warning",
    },
    {
      active: isOwned.value,
      label: "Delete Content",
      border: false,
      type: "button",
      icon: "fa-regular fa-trash-can",
      clickEvent: () => "asd",
    },
  ].filter((i) => i.active);
});
const handleSharePost = async () => {
  try {
    let response = await contentAPI.shareAsPost(props.content.id);
    if (response.error) throw new Error("An error has occurred!");
    dialogStore.show(DialogType.POST_MANAGER, undefined, {
      post: response.data,
      user: user.value,
    });
  } catch (err) {
    console.error(err.message);
    error(response.error);
  }
};
const handleOpenContentDialog = async () => {
  const transformedData = await transformData(props.content);

  if (props.content.content_type === "collection") {
    dialogStore.show(DialogType.UPLOAD_CONTENT_COLLECTION, undefined, {
      content: transformedData,
    });
    dialogStore.setEvents(DialogType.UPLOAD_CONTENT_COLLECTION, {
      close: () => {
        dialogStore.hide(DialogType.UPLOAD_CONTENT_COLLECTION);
      },
    });
  } else {
    dialogStore.show(DialogType.UPLOAD_CONTENT, undefined, {
      content: transformedData,
    });
    dialogStore.setEvents(DialogType.UPLOAD_CONTENT, {
      close: () => {
        dialogStore.hide(DialogType.UPLOAD_CONTENT);
      },
      update: (content: ContentInterface) => {
        emit("updateContent", content);
      },
    });
  }
};
const toggle = (event: any) => {
  headerMenu.value.toggle(event);
};
const handleDelete = (e) => {
  emit("delete", e);
};
defineExpose({
  toggle,
});
</script>
