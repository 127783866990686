<template>
  <Layout>
    <div class="w-full flex items-center justify-center bg-white py-2 gap-8 border-b border-black/20">
      <button class="min-w-[200px] text-sm py-2.5 px-8 text-primaryBlue-500 text-center rounded font-semibold"
        :class="{ 'bg-primaryBlue-500 text-white': tab === 'requests' }" @click="tab = 'requests'">
        Connection Requests ({{ received.length }})
      </button>
      <button class="min-w-[200px] text-sm py-2.5 px-8 text-primaryBlue-500 text-center rounded font-semibold"
        :class="{ 'bg-primaryBlue-500 text-white': tab === 'pending' }" @click="tab = 'pending'">
        Pending Connections ({{ sent.length }})
      </button>
    </div>
    <div
      class="w-full relative h-full overflow-y-auto py-[28px] scroll-hidden sm:pl-[60px] xl:pl-[90px] pr-[4px] sm:pr-[60px] xl:pr-[90px] min[1350px]:[180px]"
      v-if="ConnectionsList?.length > 0">
      <div v-if="contactsStore.layout === 'full'" class="grid grid-cols-1 min-[1366px]:grid-cols-2 gap-[20px] w-full">
        <div v-for="connection in ConnectionsList" :key="connection.id" class="p-4 bg-white rounded-md shadow-default">
          <UserCard :user="tab === 'requests' ? connection.sender : connection.recipient
            " :showLocation="true" :showRelationship="false">
            <template #actions>
              <div v-if="tab === 'requests'" class="flex gap-2 items-center justify-end">
                <button
                  class="text-[10px] md:text-[14px] text-primaryBlue-500 h-[26px] md:h-[36px] w-full px-[4px] md:px-[15px] rounded-[5px] bg-switcherBlue-500/10 hover:bg-switcherBlue-600 hover:text-white cursor-pointer"
                  @click="() => handleAccept(connection)">
                  Accept
                </button>
                <button
                  class="text-[10px] md:text-[14px] text-primaryBlue-500 w-full h-[26px] md:h-[36px] px-[4px] md:px-[15px] border-[1px] border-primaryBlue-500 rounded-[5px] bg-[white] hover:bg-switcherBlue-600 hover:text-white cursor-pointer"
                  @click="() => handleDecline(connection)">
                  Decline
                </button>
              </div>
              <button v-else
                class="md:flex text-[14px] text-primaryBlue-500 w-max py-[12px] px-[12px] rounded-[5px] bg-switcherBlue-500 hover:bg-switcherBlue-600 hover:text-white bg-opacity-[0.1] cursor-pointer"
                @click="() => handleWithdraw(connection)">
                Withdraw Request
              </button>
            </template>
          </UserCard>
        </div>
      </div>
      <div v-else class="flex flex-wrap gap-x-[25px] gap-y-[20px] justify-start scroll-hidden">
        <CompactCard v-for="connection in ConnectionsList" :key="connection?.id"
          :user="tab === 'requests' ? connection.sender : connection.recipient">
          <template #actions>
            <div v-if="tab === 'requests'" class="flex flex-end gap-[5px] justify-center items-start h-[40px]">
              <button
                class="text-[14px] text-[white] h-[30px] w-max px-[12px] rounded-[500px] bg-main-primary border-[1px] border-[white] hover:bg-[white] hover:text-[black] hover:border-[1px] cursor-pointer"
                @click="() => handleAccept(connection)">
                Accept
              </button>
              <button
                class="text-[14px] text-[white] w-max h-[30px] px-[12px] border-[1px] border-[white] rounded-[500px] bg-transparent hover:!bg-[white] hover:text-[black] cursor-pointer"
                @click="() => handleDecline(connection)">
                Decline
              </button>
            </div>
            <button v-else
              class="font-[500] text-[12px] text-[black] w-[184px] py-[3px] px-[10px] h-[30px] rounded-full bg-[white] hover:bg-switcherBlue-600 hover:text-white cursor-pointer"
              @click="() => handleWithdraw(connection)">
              Withdraw Request
            </button>
          </template>
        </CompactCard>
      </div>
    </div>
  </Layout>
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch } from "vue";
import Layout from "@/components/contacts/Layout.vue";
import UserCard from "@/components/general/UserCard.vue";
import { useContactsStore } from "@/store/Contacts";
import CompactCard from "@/components/contacts/content/CompactCard.vue";
import { API } from "@/core/api";
import { useUserStore } from "@/store/User";
import type { ConnectionsInterface } from "@/core/interfaces";
import type { PropType } from "vue";

const contactsStore = useContactsStore();
const userStore = useUserStore();
const props = defineProps({
  received: {
    type: Array as PropType<ConnectionsInterface[]>,
    default: []
  },
  sent: {
    type: Array as PropType<ConnectionsInterface[]>,
    default: []
  },
});

const received = computed<ConnectionsInterface[]>(() => userStore.friend_requests.received);
const sent = computed<ConnectionsInterface[]>(() => userStore.friend_requests.sent);

const tab = ref("requests");
const ConnectionsList = computed<ConnectionsInterface[]>(() =>
  tab.value === "requests" ? received.value : sent.value,
);

const handleAccept = (connection: ConnectionsInterface) => {
  try {
    userStore.acceptConnectionRequest(connection);
  } catch (err) {
    console.log("[FATAL_ERROR] :: handleAccept :: ", err);
  }
};

const handleDecline = (connection: ConnectionsInterface) => {
  try {
    userStore.declineConnectionRequest(connection);
  } catch (err) {
    console.log("[FATAL_ERROR] :: handleDecline :: ", err);
  }
};

const handleWithdraw = (connection: ConnectionsInterface) => {
  try {
    userStore.withdrawConnectionRequest(connection);
  } catch (err) {
    console.log("[FATAL_ERROR] :: handleWithdraw :: ", err);
  }
};
</script>
<style scoped></style>
