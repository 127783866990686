import baseApi from "./Base";
import { route } from "ziggy-js";

export class NotificationService extends baseApi {
  async get(form: any) {
    return await this.request(route("notifications.index"), "GET", form);
  }

  async count() {
    return await this.request(route("notifications.count"), "GET");
  }

  async markAllAsRead() {
    return await this.request(route("notifications.read.all"), "POST");
  }

  async markAsRead(id: number) {
    return await this.request(route("notifications.read", { id }), "POST");
  }

  async toggleConnection(notification: number, type: string) {
    return await this.request(
      route("notifications.toggle-request.connection", notification),
      "POST",
      {
        type,
      },
    );
  }

  async toggleCollaboration(notification: number, type: string) {
    return await this.request(
      route("notifications.toggle-request.collaboration", notification),
      "POST",
      {
        type,
      },
    );
  }
}
