<template>
  <Card
    class="rounded-lg px-[20px] py-[16px] h-auto max-h-[250px] w-full my-0"
    :pt="{
      content: 'flex flex-col',
    }"
    :ptOptions="{ mergeProps: true }"
    v-if="quote"
  >
    <template #content>
      <div
        class="flex justify-between pb-2 text-xs border-main-divider border-b-[0.5px] w-full"
      >
        <span
          class="text-main-secondary flex items-end justify-end text-xs font-medium leading-4 pt-1"
        >
          QUOTE OF THE DAY
        </span>
        <span
          class="text-main-secondary flex items-end justify-end text-xs font-normal leading-4 pt-1"
        >
          {{ formatDateShort(new Date()) }}
        </span>
      </div>
      <p class="text-main-primary pt-2 text-base font-medium leading-6">
        "{{ quote?.quote }}"
      </p>
      <span
        class="text-main-secondary flex items-end justify-end text-xs font-normal leading-4 pt-5"
      >
        {{ quote?.author }}
      </span>
    </template>
  </Card>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import { useStore } from "@/store";
import { formatDateShort } from "@/utils/formatter.js";
const store = useStore();
const quote = computed(() => store.quoteOfTheDay);
</script>
