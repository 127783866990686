<template>
    <div class="bg-main-lightBg">
        <Header />
        <div class="flex overflow-hidden bg-white pt-16">
            <SideMenu />
            <div class="hidden fixed inset-0 z-10 bg-gray-900 opacity-50" id="sidebarBackdrop"></div>
            <div id="main-content" class="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64">
                <main>
                    <slot></slot>
                </main>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useAuthStore } from "@/store/Auth";
import Header from "./Header.vue";
import { parseFlashData } from "@/utils/toast";
import { usePage } from "@inertiajs/vue3";
import { computed, onMounted, watch, ref, onBeforeMount } from "vue";
import SideMenu from "./parts/SideMenu.vue";

const page = usePage();
const ToastList = computed(() => page.props.flash_data);
const authStore = useAuthStore();
onBeforeMount(() => {
    authStore.setToken(page.props.token as string | null);
});
watch(
    ToastList,
    (newVal) => {
        parseFlashData(newVal);
    },
    { immediate: true, deep: true }
);
</script>