<template>
  <div class="flex gap-6 flex-wrap" v-if="card?.social_networks?.length">
    <CircleIcon
      v-if="is_editing && is_own_profile"
      size="3.5rem"
      class="bg-switcherBlue-500/10 hover:bg-switcherBlue-500/20"
      @click="manageSocialsVisible = true">
      <i class="fal fa-pencil text-2xl text-switcherBlue-500"></i>
    </CircleIcon>
    <CircleIcon
      v-for="value in card.social_networks"
      size="3.5rem"
      class="bg-white shadow-default hover:bg-white/20"
      @click="handleOpen(value)">
      <i
        :class="
          cn(
            'text-2xl',
            socialNetworks[value.network.toLowerCase()]?.icon ??
              'fa fa-question',
          )
        " />
    </CircleIcon>
  </div>
  <div
    v-else
    class="py-3 px-5 rounded-lg flex justify-between items-center bg-white shadow-default w-full">
    <h1 class="text-lg font-medium">Social Links</h1>
    <button
      @click="manageSocialsVisible = true"
      class="bg-switcherBlue-500/30 text-switcherBlue-500 rounded-[4px] py-2 px-4 cursor-pointer h-max">
      + Add
    </button>
  </div>
  <ManageSocials
    :socials="card?.social_networks ?? []"
    v-if="manageSocialsVisible"
    @close="manageSocialsVisible = false" />
</template>

<script lang="ts" setup>
import { reactive, ref, computed, onMounted, watch, PropType } from "vue";
import ManageSocials from "@/components/dialogs/bizcard/info/ManageSocials.vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import type {
  BizCardInterface,
  SocialNetworkInterface,
  UserInterface,
} from "@/core/interfaces";
import { socialNetworks } from "@/core/interfaces/SocialNetwork";
import { cn } from "@/utils/cn";
import { useUserBizcardStore } from "@/store/UserBizcard";
/* -------- PROPS -------- */
const props = defineProps({
  card: {
    required: true,
    type: Object as PropType<BizCardInterface>,
  },
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
});
/* -------- COMPOSABLE -------- */
const bizcardStore = useUserBizcardStore();

/* -------- STATE -------- */
const manageSocialsVisible = ref(false);
const is_editing = computed(() => bizcardStore.isEditing);
const is_own_profile = computed(() => bizcardStore.own_profile);

/* -------- METHODS -------- */
const handleOpen = (value: SocialNetworkInterface) => {
  console.log("[DEBUG] :: value", value);
  window.open(
    `${socialNetworks[value.network.toLowerCase()]?.placeholder}/${value.url}`,
    "_blank",
  );
};
/* -------- HOOKS -------- */

/* -------- WATCHERS -------- */
</script>
