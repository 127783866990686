<template>
  <div class="flex flex-col gap-4">
    <component
      :is="Components[SelectType]"
      v-model="keyword"
      :placeholder="placeholder"
      showClear
      @update:modelValue="handleAdd"
      :clearCallback="() => handleClear()"
      :selected="model" />
    <div class="flex flex-wrap gap-2">
      <CustomChip
        v-for="(value, index) in model"
        :key="value"
        :label="value.name || value.title || value"
        :removable="true"
        @remove="() => handleRemove(index)"
        rootClass="text-primary-500 bg-primary-50" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive, ref, computed, onMounted, watch, PropType } from "vue";
import LocationSelectGroup from "@/components/forms/SelectGroups/Location.vue";
import CustomChip from "@/components/general/CustomChip.vue";
import { useToastr } from "@/composables/useToastr";
import { API } from "@/core/api";
import type { BizCardInterface, UserInterface } from "@/core/interfaces";
import { useUserBizcardStore } from "@/store/UserBizcard";
import { router, useForm } from "@inertiajs/vue3";
import Roles from "@/components/forms/SelectGroups/Roles.vue";
import Skills from "@/components/forms/SelectGroups/Skills.vue";
import Industries from "@/components/forms/SelectGroups/Industries.vue";
import Services from "@/components/forms/SelectGroups/Services.vue";
import { markRaw } from "vue";
import { nextTick } from "vue";

/* -------- PROPS -------- */
const props = defineProps({
  SelectType: {
    type: String,
  },
  placeholder: {
    type: String,
  },
});

const Components = {
  roles: markRaw(Roles),
  locations: markRaw(LocationSelectGroup),
  skills: markRaw(Skills),
  industries: markRaw(Industries),
  services: markRaw(Services),
};
/* -------- COMPOSABLE -------- */
const bizcardStore = useUserBizcardStore();
const bizCardServicesAPI = new API.BizcardServices();
const { success, error } = useToastr();

/* -------- STATE -------- */
const keyword = ref();
const model = defineModel({
  type: Array as PropType<string[]>,
  default: () => [],
});
/* -------- METHODS -------- */
const handleAdd = async () => {
  await nextTick();
  if (keyword.value === undefined || keyword.value === "") return;
  model.value.push(keyword.value);
  keyword.value = undefined;
};
const handleRemove = (idx: number) => {
  model.value.splice(idx, 1);
};

const handleClear = () => {
  keyword.value = undefined;
};

/* -------- HOOKS -------- */

/* -------- WATCHERS -------- */
</script>
