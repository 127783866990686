<template>
  <div class="px-[20px]">
    <div
      class="flex flex-col items-center justify-center h-full min-h-[200px]"
      v-if="data.likes_count == 0"
    >
      <span class="text-lg font-bold">No Likes yet.</span
      ><span class="text-sm">Be the first to like</span>
    </div>
    <div class="" v-else>
      <div
        class="overflow-y-scroll max-h-[500px] space-y-4 scroll-hidden px-[10px] py-[10px]"
        v-infinite-scroll="getLikes"
      >
        <UserCard
          class="py-3"
          v-for="like in likes"
          :key="like.id"
          :user="like"
          :showLocation="false"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import UserCard from "@/components/general/UserCard.vue";
import { API } from "@/core/api";
import { reactive, ref, computed } from "vue";
import { vInfiniteScroll } from "@vueuse/components";
const likesAPI = new API.SocialPostLikes();
const props = defineProps({
  data: { type: Object, required: true },
  user: { type: Object, required: true },
});
const likes = computed(() => props.data.likes);
const lazyLoad = reactive({
  start: props.data?.likes?.length,
  size: 10,
});
const loading = ref(false);
// 10 is the first batch size sent from the backend on page load
const likes_no_more_results = ref<Boolean>(likes.value.length < 10);
const getLikes = async () => {
  if (!loading.value) {
    loading.value = true;
    if (!likes_no_more_results.value) {
      const response = await likesAPI.get(props.data.id, { ...lazyLoad });
      if (response.length) {
        likes.value.push(...response);
      }

      if (response.length < (lazyLoad.size ?? 0)) {
        likes_no_more_results.value = true;
      }
      lazyLoad.start = likes.value.length;

      lazyLoad.size = 10;
      if (loading.value === true) {
        loading.value = false;
      }
    }
  }
};
</script>
