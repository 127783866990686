<template>
    <div
      class="px-[15px] items-center !mb-[0px]"
      :class="
        isNoPaddingComponent && !isNoLineHeightComponent
          ? 'p-0'
          : isNoPaddingComponent && isNoLineHeightComponent
          ? 'p-0 leading-none'
          : null
      "
      :style="`padding:${padding}`"
      @click="
        (e) => {
          e.stopPropagation();
        }
      "
    >
      <p
        class="font-normal text-[14px] leading-[20px] mb-[0px]"
        :class="isNoPaddingComponent ? 'p-0' : 'pb-[10px] pt-[10px]'"
        :style="`color:#${color ?? '141414'}; font-size:${fsize};`"
      >
        <span
          :style="`color:#${color ?? '141414'}; font-size:${fsize};`"
          v-html="formatTextWithMention(formattedText)"
          class="text-content"
        ></span>
        <span
          @click.prevent="toggleText"
          class="text text-[#3578F8] text-sm cursor-pointer ml-1"
          v-if="shouldShowToggle"
          >{{ toggleButtonText }}</span
        >
      </p>
    </div>
  </template>

  <script setup>
  import { ref, computed, onMounted } from "vue";

  const props = defineProps({
    text: String,
    color: String,
    fsize: String,
    padding: String,
    limit: Number,
    id: {
      type: Number,
      required: true,
    },
    isNoPaddingComponent: Boolean,
    readMoreText: String,
    isNoLineHeightComponent: {
      type: Boolean,
      default: false,
    },
  });

  const isTruncated = ref(true);
  const Truncatelimit = ref("");

  const formattedText = computed(() => {
    if (!props.text) {
      return "";
    }
    const mentionMatches = props.text?.match(/<(.*?):(.*?)>/g);
    const mentionCount = mentionMatches ? mentionMatches.length : 0;

    // let limit;
    if (mentionCount > 1) {
      const lastIndexOfMention = props.text.slice(0, 140).lastIndexOf("<");
      const lastIndexOfMentionIn180 = props.text.slice(0, 180).lastIndexOf("<");

      if (lastIndexOfMention !== -1 && lastIndexOfMention !== 0) {
        Truncatelimit.value = 160; // If the last mention within the first 140 characters, set the Truncatelimit accordingly
      } else if (lastIndexOfMentionIn180 !== -1 && lastIndexOfMention !== 0) {
        Truncatelimit.value = 240; // If the last mention within the first 180 characters, set the Truncatelimit accordingly
      } else {
        Truncatelimit.value = 140; // Otherwise, set the Truncatelimit to 140
      }
    } else if (mentionCount === 1 && props.text.indexOf("<") < 80) {
      Truncatelimit.value = 120; // Increase Truncatelimit to 120 if the first mention is within the first 80 characters
    } else {
      Truncatelimit.value = props.limit; // else the limit that came from props
    }

    let truncatedText;
    if (!isTruncated.value) {
      truncatedText = props.text;
    } else {
      truncatedText = props.text.slice(0, Truncatelimit.value);
    }

    return isTruncated.value && props.text.length > Truncatelimit.value
      ? truncatedText + "..."
      : truncatedText;
  });
  const shouldShowToggle = computed(
    () => formattedText.value.length > Truncatelimit.value,
  );

  const toggleButtonText = computed(() =>
    isTruncated.value ? "see more" : "see less",
  );

  const toggleText = () => {
    isTruncated.value = !isTruncated.value;
  };

  function formatTextWithMention(text) {
    return text?.replace(
      /<([^:]+):([^:]+):([^>]+)>/g,
      (match, fullName, id, uuid) => {
        return `<a class="text-switcherBlue-500" href="/u/${uuid}">${fullName}</a>`;
      },
    );
  }
  </script>
