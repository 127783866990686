<template>
    <div
        class="xs:w-full md:w-1/2 lg:w-auto p-4 bg-white rounded-lg shadow-lg flex-col justify-center items-start gap-4 inline-flex">
        <div class="text-main-primary text-lg font-medium font-['Poppins']">Content Tags</div>
        <div class="gap-1 flex flex-wrap">
            <Label class="p-2 lg:p-2.5 rounded-3xl"
                v-for="tag in ['Painting', 'Watercolor', 'Architecture', 'Screen Printing', 'Etching', 'Typography', 'Sketching', 'Photography', 'Concepting', 'Art']"
                :key="tag">
                {{ tag }}
            </Label>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Label from '../../../parts/Label.vue';

export default defineComponent({
    components: {
        Label
    }
});
</script>