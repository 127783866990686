import baseApi from "../Base";
import { route } from "ziggy-js";

export class UsersService extends baseApi {
  async get(form: any) {
    return await this.request(route("admin.api.users.get"), "GET", form);
  }

  async delete(form: any) {
    return await this.request(
      route("admin.users.delete.selected"),
      "POST",
      form,
    );
  }
}
