<template>
  <Dropdown
    v-model="location"
    editable
    @change="searchLocation"
    :options="optionList"
    optionLabel="name"
    optionValue="name"
    placeholder="New York, NY"
    :class="cn('w-full md:w-14rem', pt.root)"
    :pt="{
      wrapper: 'max-h-[200px] overflow-auto scroll-hidden',
      input: pt.input,
    }"
    :ptOptions="{ mergeProps: true }"
    :loading="loading"
    :showClear="showClear"
    :optionDisabled="checkIfDisabled">
    <template #option="{ option }">
      <div class="flex gap-2 items-center">
        <i class="text-inherit fa-sharp far fa-location-dot"></i>
        <p :class="cn('text-inherit', pt.optionLabel)">
          {{ option["name"] }}
        </p>
      </div>
    </template>
  </Dropdown>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import _debounce from "lodash/debounce";
import { onMounted } from "vue";
import { API } from "@/core/api";
import { cn } from "@/utils/cn";
import type { PropType } from "vue";
import { watch } from "vue";

/* -------- PROPS -------- */
const props = defineProps({
  pt: {
    type: Object as PropType<{
      root?: string | object;
      input?: string | object;
      optionLabel?: string | object;
    }>,
    default: () => ({}),
  },
  selected: {
    type: Array,
    default: () => [],
  },
  showClear: {
    type: Boolean,
    default: false,
  },
  clearCallback: {
    type: Function,
    default: () => {},
  },
});

const model = defineModel({
  type: String,
});
/* -------- COMPOSABLE -------- */
const locationsAPI = new API.Locations();

/* -------- STATE -------- */
const loading = ref(false);
const optionList = ref();
const location = ref(model.value);

/* -------- METHODS -------- */
const searchLocation = _debounce(async (e: any) => {
  if (e.originalEvent.type === "input" && e.value) getLocations(e.value);
  if (e.originalEvent.type === "input" && !e.value) {
    props.clearCallback();
    model.value = undefined;
    getLocations("");
  }
  if (e.originalEvent.type === "click" && !e.value) {
    props.clearCallback();
    model.value = undefined;
    getLocations("");
  }
  if (e.originalEvent.type === "click" && e.value) {
    model.value = e.value;
  }
}, 250);

const getLocations = async (keyword: string = "") => {
  loading.value = true;
  optionList.value = [];
  const response = await locationsAPI.get({ keyword: keyword });

  optionList.value = response;
  loading.value = false;
};

const checkIfDisabled = (e: any) => {
  return props.selected.includes(e.name);
};
/* -------- HOOKS -------- */
onMounted(() => {
  getLocations();
});
/* -------- WATCHERS -------- */
watch(model, (newVal) => {
  if (newVal === undefined) {
    location.value = undefined;
    getLocations("");
  }
});
</script>
