<template>
  <div
    class="lg:flex hidden sticky top-0 px-4 md:px-12 lg:px-[0rem] min-w-full w-full lg:min-w-[300px] lg:w-[300px] 2xl:min-w-[350px] 2xl:w-[350px] h-[100%] box-border flex-col gap-[20px] pt-[30px]"
  >
    <Card
      :pt="{
        ...leftCardPreset,
        content: leftCardPreset.content + ' items-center',
      }"
      :ptOptions="{
        mergeProps: true,
      }"
    >
      <template #content>
        <div
          class="w-[100px] h-[100px] !min-w-[100px] justify-center rounded-full overflow-hidden"
        >
          <img
            :src="user.avatar_image"
            alt="Contact"
            class="object-cover h-full w-full"
          />
        </div>
        <div class="flex flex-col gap-2 items-center">
          <div>
            <span class="text-primary font-semibold text-lg">
              {{ user?.full_name ?? "Guest User" }}
            </span>
          </div>
          <div class="flex flex-col gap-1">
            <div
              class="flex gap-1 flex-wrap justify-center items-baseline text-center"
              v-if="user?.roles"
              v-for="(role, index) in user?.roles.map((v) => v.name)"
            >
              <p
                class="mb-0 font-normal text-[14px] text-text-700 flex item-center gap-y-[5px]"
              >
                {{ role }}
              </p>
              <span
                class="text-main-text font-extralight text-[6px] lg:text-sm"
                v-if="index != user?.roles.length - 1"
              >
                •
              </span>
            </div>
          </div>
          <div
            class="text-[14px] flex items-center gap-2 text-text-500 font-normal"
            v-if="user?.location"
          >
            <i class="fa-sharp far fa-location-dot"></i>
            <span>{{ user.location }}</span>
          </div>
        </div>
        <hr class="w-full bg-disabled-500 my-4" />
        <div class="w-full flex gap-2.5 flex-col">
          <Link
            :href="route('user.profile.index', user.public_username)"
            class="text-center text-[14px] text-primaryBlue-500 w-full py-[8px] rounded-[4px] bg-switcherBlue-500 hover:bg-switcherBlue-600 hover:text-white bg-opacity-[0.1] cursor-pointer"
          >
            View my bizlyCard
          </Link>
          <button
            class="text-[14px] text-primaryBlue-500 w-full py-[8px] rounded-[4px] bg-switcherBlue-500 hover:bg-switcherBlue-600 hover:text-white bg-opacity-[0.1] disabled:opacity-[0.4]"
          >
            Account &amp; Settings
          </button>
        </div>
      </template>
    </Card>
    <Card
      :pt="leftCardPreset"
      :ptOptions="{
        mergeProps: true,
      }"
    >
      <template #content>
        <div class="flex flex-col gap-4">
          <div class="flex flex-col gap-0.5">
            <span class="text-primary font-[500] text-base self-start">
              Network on the Go!
            </span>
            <span class="text-text-700 text-[14px] font-normal">
              Grab your phone and download Bizly to discover and connect with others on
              the go.
            </span>
          </div>
          <div class="flex self-start">
            <a
              href="https://apps.apple.com/us/app/bizly/id6443831329"
              rel="noopener noreferrer"
              class="flex items-center"
            >
              <img :src="AppStoreIcon" alt="app-store-icon" />
            </a>
            <div class="border-l border-l-main-divider mx-4"></div>
            <img :src="playStoreIcon" alt="play-store-icon" />
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>
<script lang="ts" setup>
import type { UserInterface } from "@/core/interfaces";
import { Link, usePage } from "@inertiajs/vue3";
import { computed } from "vue";
import AppStoreIcon from "@/assets/images/appStoreIcon.svg";
import playStoreIcon from "@/assets/images/playStoreIcon.svg";
import { useAuthStore } from "@/store/Auth";
const authStore = useAuthStore();
const user = computed(() => authStore.getUser as UserInterface);

const leftCardPreset = {
  content:
    "rounded-[10px] bg-white w-full box-shadow flex flex-col p-[16px] min-h-[153px] gap-[5px]",
};
</script>
