<template>
    <Layout>
        <Section>
            <template #title>Single Video</template>
            <template #content>
                Lorem ipsum dolor
                sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit
                amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.
            </template>
            <template #body>
                <div class="w-full rounded-lg shadow-lg justify-start items-center inline-flex relative">
                    <img :src="Turtle" class="w-full h-auto object-cover rounded-lg" />
                    <InfoBallon>
                        <template #icon><i class="fal fa-clapperboard-play text-md"></i></template>
                        <template #count>02:23</template>
                    </InfoBallon>
                </div>
            </template>
        </Section>
        <SectionDivider />
        <Section>
            <template #title>Video Grid</template>
            <template #content>
                Lorem ipsum dolor
                sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit
                amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.
            </template>
            <template #body>
                <div class="self-stretch grid grid-cols-2 gap-3">
                    <GridCard count="1">
                        <img src="../assets/images/tower.png" class="object-cover h-full w-full rounded-lg" />
                    </GridCard>
                    <GridCard count="2">
                        <img src="../assets/images/tower.png" class="object-cover h-full w-full rounded-lg" />
                    </GridCard>
                    <GridCard count="3">
                        <img src="../assets/images/barcelona.png" class="object-cover h-full w-full rounded-lg" />
                    </GridCard>
                    <GridCard count="4">
                        <img src="../assets/images/letuce.png" class="object-cover h-full w-full rounded-lg" />
                    </GridCard>
                    <GridCard count="5">
                        <img src="../assets/images/nature.png" class="object-cover h-full w-full rounded-lg" />
                    </GridCard>
                    <GridCard count="6">
                        <img src="../assets/images/man.png" class="object-cover h-full w-full rounded-lg" />
                    </GridCard>
                </div>
            </template>
        </Section>
        <SectionDivider />
        <Section>
            <template #title>Rich Text</template>
            <template #content>
                Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet
                sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam
                quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu. Curabitur
                pellentesque nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo lacus at sodales
                sodales. Quisque sagittis orci ut diam condimentum, vel euismod erat placerat. In iaculis arcu eros,
                eget tempus orci facilisis id.<br /><br />Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula
                consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum
                auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at maximus ante
                fermentum sit amet. Pellentesque commodo lacus at sodales sodales. Quisque sagittis orci ut diam
                condimentum, vel euismod erat placerat. In iaculis arcu eros, eget tempus orci facilisis
                id.<br /><br />Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in
                hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris.
                Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non
                suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at maximus ante fermentum sit amet.
                Pellentesque commodo lacus at sodales sodales. Quisque sagittis orci ut diam condimentum, vel
                euismod erat placerat. In iaculis arcu eros, eget tempus orci facilisis id.Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet
                sapien fringilla, mattis ligula consectetur, ultrices mauris.<br /><br />Maecenas vitae mattis
                tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu.
                Curabitur pellentesque nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo lacus at
                sodales sodales. Quisque sagittis orci ut diam condimentum, vel euismod erat placerat. In iaculis
                arcu eros, eget tempus orci facilisis id.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula
                consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum
                auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at maximus ante
                fermentum sit amet. Pellentesque commodo lacus at sodales sodales. Quisque sagittis orci ut diam
                condimentum, vel euismod erat placerat. In iaculis arcu eros, eget tempus orci facilisis id.
            </template>
        </Section>
        <SectionDivider />
        <Section>
            <template #title>Image Gallery 16x9 Ratio</template>
            <template #content>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna.
                Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae
                mattis tellus.
            </template>
            <template #body>
                <GridRounded>
                    <img src="../assets/images/desert-party.png">
                    <img src="../assets/images/desert-party.png">
                    <img src="../assets/images/desert-party.png">
                    <img src="../assets/images/desert-party.png">
                    <img src="../assets/images/desert-party.png">
                    <img src="../assets/images/desert-party.png">
                </GridRounded>
            </template>
        </Section>
        <SectionDivider />
        <Section>
            <template #title>Image Gallery 1x1 Ratio</template>
            <template #content>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna.
                Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae
                mattis tellus.
            </template>
            <template #body>
                <GridRounded class="grid-cols-3">
                    <img class="aspect-square object-cover" src="../assets/images/desert-party.png">
                    <img class="aspect-square object-cover" src="../assets/images/desert-party.png">
                    <img class="aspect-square object-cover" src="../assets/images/desert-party.png">
                    <img class="aspect-square object-cover" src="../assets/images/desert-party.png">
                    <img class="aspect-square object-cover" src="../assets/images/desert-party.png">
                    <img class="aspect-square object-cover" src="../assets/images/desert-party.png">
                </GridRounded>
            </template>
        </Section>
        <SectionDivider />
        <Section>
            <template #title>Single Image</template>
            <template #content>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna.
                Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae
                mattis tellus.
            </template>
            <template #body>
                <div class="w-4/5 self-center">
                    <img src="../assets/images/desert-party.png"
                        class="self-center rounded-lg w-full aspect-square object-cover mt-2" />
                </div>
            </template>
        </Section>
        <SectionDivider />
        <Section>
            <template #title>Single Image</template>
            <template #content>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna.
                Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae
                mattis tellus.
            </template>
            <template #body>
                <img src="../assets/images/desert-party.png" class="self-center rounded-lg w-full object-cover mt-2" />
            </template>
        </Section>
        <SectionDivider />
        <Section>
            <template #title>Single Image</template>
            <template #content>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna.
                Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae
                mattis tellus.
            </template>
            <template #body>
                <img src="../assets/images/movie.png" class="self-center rounded-lg w-2/4 object-cover mt-2" />
            </template>
        </Section>
        <SectionDivider />
        <Section>
            <template #title>Multiple Links</template>
            <template #content>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna.
                Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae
                mattis tellus.
            </template>
            <template #body>
                <CardCarousel>
                </CardCarousel>
            </template>
        </Section>
        <Section>
            <template #title>Single Link</template>
            <template #content>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna.
                Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae
                mattis tellus.
            </template>
            <template #body>
                <div class="w-full md:w-1/2">
                    <CardLink max="1" url="website.com" title="Audio Playlist Title"
                        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie ipsum dolor consectetur."
                        count="1">
                        <img src="../assets/images/turtle.png" class="w-full h-auto rounded-t-lg object-cover" />
                    </CardLink>
                </div>
            </template>
        </Section>
    </Layout>
</template>

<script>
import { defineComponent, ref } from 'vue';
import Layout from '../components/layout/Layout.vue';
import Turtle from "../assets/images/turtle.png";
import GridCard from '../components/cards/GridCard.vue';
import GridRounded from '../components/cards/GridRounded.vue';
import CardCarousel from '../components/cards/CardCarousel.vue';
import CardLink from '../components/cards/CardLink.vue';
import Section from '../components/parts/Section.vue';
import InfoBallon from '../components/parts/InfoBallon.vue';
import SectionDivider from '../components/parts/SectionDivider.vue';

export default defineComponent({
    components: {
        Layout,
        GridCard,
        GridRounded,
        CardCarousel,
        CardLink,
        Section,
        SectionDivider,
        InfoBallon
    },
    setup() {
        return {
            Turtle
        };
    },
});
</script>