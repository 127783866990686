<template>
  <section
    id="Feed"
    class="max-w-[550px] mx-auto mb-5 h-full"
    v-if="posts.length || posts_loading"
    ref="postsContainer">
    <div class="flex flex-col gap-6 p-4" v-if="posts.length">
      <div
        class="rounded-2xl social-post-feed scroll-smooth w-full max-w-[500px] cursor-pointer"
        v-for="post in posts"
        :key="post.id">
        <div>
          <SocialPost :post="post" @delete="(e) => handleDeletePost(e)" />
          <div class="activity-feed-footer">
            <SocialPostFooter :data="post" />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <Skeleton width="10rem" class="mb-2"></Skeleton>
      <Skeleton width="5rem" class="mb-2"></Skeleton>
      <Skeleton height="2rem" class="mb-2"></Skeleton>
      <Skeleton width="10rem" height="4rem"></Skeleton>
    </div>
  </section>
</template>
<script setup lang="ts">
import { FeedType, useUserBizcardStore } from "@/store/UserBizcard";
import { ref } from "vue";
import { onMounted } from "vue";
import { computed } from "vue";
import type { UserInterface } from "@/core/interfaces";
import type { PropType } from "vue";
import SocialPost from "@/pages/social-posts/parts/SocialPost.vue";
import SocialPostFooter from "@/components/social-posts/Footer.vue";

const props = defineProps({
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
});

/* ---------- STORES ----------*/
const bizCardStore = useUserBizcardStore();

/** -------- REACTIVE --------- */
const posts = computed(() => bizCardStore.homeFeed.data ?? []);
const posts_loading = computed(
  () => bizCardStore.homeFeed.lazyLoad.loading ?? false,
);
const postsContainer = ref();

/** --------- FUNCTIONS -------- */
const handleDeletePost = (e: any) => {
  bizCardStore.removePost(e);
};

/** ---------- HOOKS ---------- */
onMounted(async () => {
  if (!bizCardStore.isInitialized(FeedType.POST)) fetchPosts();
});

const fetchPosts = async () => {
  await bizCardStore.loadFeed(props.user.id);
};

/** ---------- EXPOSED --------- */
defineExpose({
  fetchPosts,
});
</script>
