<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-0 w-full flex flex-col relative',
      root: '!bg-white max-w-[600px] w-full rounded-[16px]  scroll-hidden',
    }"
    :visible="true"
    modal
    :closable="false">
    <section
      id="header"
      class="p-2 bg-surface-50 border-b-[1px] border-surface-300 sticky top-0 z-20 rounded-t-[16px]">
      <div class="flex justify-between items-center">
        <h1 class="font-poppins text-xl flex-1 text-center">Service</h1>
        <CircleIcon
          class="bg-surface-100 text-primary-500 hover:bg-surface-200"
          @click="$emit('close')">
          <i class="far fa-times"></i>
        </CircleIcon>
      </div>
    </section>
    <section
      id="content"
      class="p-6 flex flex-col gap-5 max-h-[80vh] overflow-y-auto scroll-hidden">
      <div class="flex flex-col gap-0.5">
        <span class="text-lg font-medium">Information</span>
        <span class="text-sm text-main-secondary">
          The details you provide below will be how people find you. Please be
          brief, use clear and searchable words.
        </span>
      </div>
      <div class="flex flex-col gap-2">
        <IconField
          iconPosition="right"
          shape="rounded"
          :ptOptions="{ mergeProps: true }"
          :pt="{ root: '[&>input]:border [&>*:last-child]:pr-14' }">
          <div
            :class="
              cn('text-main-lightGray text-[10px]', {
                '!text-red-500': (form.title?.length ?? 0) >= 100,
              })
            ">
            {{ form.title?.length ?? 0 }}/100
          </div>
          <InputText
            class="border-surface-300 !text-surface-600 placeholder:!text-surface-400"
            placeholder="Title of Service"
            v-model="form.title"
            :maxlength="100" />
        </IconField>
        <div
          class="w-full flex flex-col h-full gap-2 focus:border-primary-500 border-surface-300 px-4 py-[12px] border rounded">
          <!---->
          <div class="flex flex-col gap-1 items-center relative">
            <Textarea
              class="!border-0 hover:!border-0 !p-0 resize-none scroll-hidden !rounded placeholder:text-surface-400 text-sm focus:outline-0 focus:ring-0 w-full"
              rows="4"
              autoResize
              :maxlength="1000"
              placeholder="Please detail your service, what it encompasses, and any additional information you'd like to share."
              v-model="form.description" />
            <span
              :class="
                cn(
                  'right-[16px] bottom-[12px] ml-auto text-main-lightGray text-[10px]',
                  {
                    'text-red-500': (form.description?.length ?? 0) >= 1000,
                  },
                )
              ">
              {{ form.description?.length ?? 0 }}/1,000
            </span>
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="flex flex-col gap-2">
          <div class="flex flex-col">
            <span class="text-lg font-medium">Rate</span>
            <span class="text-sm text-main-secondary">
              What is the rate you tend to charge for this service?
            </span>
          </div>
          <div class="flex">
            <SelectButton
              v-model="form.rate_type"
              :options="rate_types"
              optionLabel="label"
              optionValue="value"
              optionDisabled="disabled"
              :pt="extendedOccupationTabsPreset" />
          </div>
          <div class="flex gap-2" v-if="form.rate_type !== 'flexible'">
            <InputNumber
              class="flex-1"
              v-model="form.min_rate"
              :min="0"
              :max="9999999999"
              :minFractionDigits="0"
              :maxFractionDigits="0"
              placeholder="$ From"
              mode="currency"
              currency="USD"
              locale="en-US" />
            <InputNumber
              class="flex-1"
              v-model="form.max_rate"
              :min="0"
              :max="9999999999"
              :minFractionDigits="0"
              :maxFractionDigits="0"
              placeholder="$ To (optional)"
              mode="currency"
              currency="USD"
              locale="en-US"
              :disabled="!form.min_rate" />
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="flex flex-col gap-2">
          <div class="flex flex-col">
            <span class="text-lg font-medium">Categories</span>
            <span class="text-sm text-main-secondary">
              Which categories do you associate with?
            </span>
          </div>
          <div class="flex">
            <SelectButton
              v-model="form.expertise"
              :options="categories"
              optionLabel="label"
              optionValue="value"
              optionDisabled="disabled"
              :pt="extendedOccupationTabsPreset" />
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-4">
        <div class="flex">
          <div class="flex flex-col flex-1">
            <span class="text-lg font-medium">Related Content</span>
            <span class="text-sm text-main-secondary">
              Label your portfolio pieces with this service to showcase your
              individual contributions to each project!
            </span>
          </div>
          <div class="flex-none">
            <Button
              type="button"
              label="Manage"
              class="text-sm !px-4 !py-1.5"
              @click="() => (ManageContentsVisible = true)" />
          </div>
        </div>
        <draggableComponent
          class="draggable-container flex flex-col gap-2"
          item-key="order"
          :component-data="{ tag: 'ul', name: 'flip-list', type: 'transition' }"
          v-model="form.contents"
          v-bind="dragOptions">
          <template #item="{ element, index }">
            <div class="flex items-center gap-2 cursor-grab">
              <div class="rounded-l-lg text-xl flex items-center">
                <i class="fa fa-grip-dots-vertical text-main-disabled"></i>
              </div>
              <div
                class="flex-1 flex rounded-lg bg-white shadow-default overflow-hidden items-center">
                <div class="basis-[20%] relative">
                  <img
                    :data-src="
                      element.thumbnail_image_kit_id ??
                      element.image_kit_id_16_9 ??
                      element.image_kit_ids?.[0] ??
                      element.image_kit_id
                    "
                    :src="
                      element.thumbnail_image_kit_id ??
                      element.image_kit_id_16_9 ??
                      element.image_kit_ids?.[0] ??
                      element.image_kit_id
                    "
                    alt=""
                    class="w-full object-cover object-center bg-no-repeat cursor-pointer aspect-square"
                    @error="handleImageError" />
                  <InfoBallon
                    :type="element.content_type"
                    class="top-1 !left-1">
                    <template
                      #count
                      v-if="element.is_gallery && element.total_children_count">
                      {{ element.total_children_count }}
                    </template>
                  </InfoBallon>
                </div>
                <div class="basis-[80%] px-4">
                  <div class="flex justify-between items-center gap-2">
                    <span class="text-sm font-medium">{{ element.title }}</span>
                    <CircleIcon
                      class="bg-primary-500 text-white hover:bg-primary-600"
                      @click="handleRemove(index)"
                      size="1.5rem">
                      <i class="far fa-times text-xs"></i>
                    </CircleIcon>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </draggableComponent>
      </div>
    </section>
    <section
      id="footer"
      class="bg-surface-50 border-t border-surface-300 sticky bottom-0 z-20 rounded-b-[16px]">
      <div class="flex justify-between items-center py-2.5 px-4 gap-2">
        <Button
          v-if="form.id != null"
          label="Delete"
          icon="fa fa-trash-can"
          severity="danger"
          :loading="form.processing"
          @click="handleDeleteConfirm"></Button>
        <Button
          label="Save"
          :disabled="!canSave"
          :loading="form.processing"
          class="min-w-[150px] ms-auto"
          @click="handleSave"></Button>
      </div>
    </section>
    <ManageContents
      v-if="ManageContentsVisible"
      v-model="form"
      @close="ManageContentsVisible = false" />

    <Confirm
      v-if="DeleteDialogVisible"
      color="danger"
      action="Yes, delete"
      @close="DeleteDialogVisible = false"
      @confirm="handleDelete">
      <template #icon>
        <CircleIcon class="bg-red-600 text-white" size="3.5rem">
          <i class="fa fa-trash-can text-2xl"></i>
        </CircleIcon>
      </template>
      <template #title>Delete Service?</template>
      <template #description>
        Are you sure you want to delete this service? This action cannot be
        undone.
      </template>
    </Confirm>
  </Dialog>
</template>
<script lang="ts" setup>
import { router, useForm } from "@inertiajs/vue3";
import { useToastr } from "@/composables/useToastr";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import type { PropType } from "vue";
import { type ServiceInterface } from "@/core/interfaces";
import { cn } from "@/utils/cn";
import OccupationTabsPreset from "@/assets/tailwind-preset/selectbutton/occupation-tabs";
import ManageContents from "./ManageContents.vue";
import { ref } from "vue";
import { computed } from "vue";
import DefaultBg from "@/assets/images/bgPics/web-bg-min.jpg";
import draggableComponent from "vuedraggable";
import InfoBallon from "@/components/parts/InfoBallon.vue";
import { API } from "@/core/api";
import Confirm from "@/components/dialogs/Confirm.vue";

/* -------- PROPS -------- */
const props = defineProps({
  service: {
    type: Object as PropType<ServiceInterface>,
  },
});

const emit = defineEmits(["close"]);

/* -------- COMPOSABLE -------- */
const { success, error } = useToastr();
const bizCardServiceAPI = new API.BizcardServices();

/* -------- STATE -------- */
const ManageContentsVisible = ref(false);
const DeleteDialogVisible = ref(false);
const canSave = computed(
  () =>
    form.isDirty &&
    form.title &&
    ((form.rate_type != "flexible" && form.min_rate) ||
      form.rate_type == "flexible"),
);
const dragOptions = {
  animation: 200,
  group: "description",
  disabled: false,
  ghostClass: "ghost",
};

const form = useForm({
  id: props.service?.id ?? null,
  title: props.service?.title,
  description: props.service?.description,
  is_public: props.service?.is_public,
  flexible_rate: props.service?.flexible_rate ?? false,
  min_rate: props.service?.min_rate,
  max_rate: props.service?.max_rate,
  rate_type: props.service?.rate_type.toLocaleLowerCase() ?? "flexible",
  expertise: props.service?.expertise.toLocaleLowerCase() ?? "beginner",
  contents: props.service?.content || [],
});

const rate_types = [
  { label: "Flexible", value: "flexible" },
  { label: "Hourly", value: "hourly" },
  { label: "Daily", value: "daily" },
  { label: "Monthly", value: "monthly" },
];

const categories = [
  { label: "Beginner", value: "beginner" },
  { label: "Intermediate", value: "intermediate" },
  { label: "Advanced", value: "advanced" },
  { label: "Pro", value: "pro" },
];
const extendedOccupationTabsPreset = {
  ...OccupationTabsPreset,
  root: (options: any) => ({
    class: cn(OccupationTabsPreset.root(options).class, "w-full"),
  }),
  button: (options: any) => ({
    class: cn(OccupationTabsPreset.button(options).class, "flex-1"),
  }),
};

/* -------- METHODS -------- */

const handleSave = async () => {
  try {
    form.processing = true;
    const response = form.id
      ? await bizCardServiceAPI.update(form.id, form)
      : await bizCardServiceAPI.create(form);

    if (response.data) {
      success(`Service ${form.id ? "updated" : "created"} successfully`);
      emit("close");
      router.reload();
    } else if (response.error) {
      error(response.error);
    }
  } catch (err: any) {
    error(err.message);
  } finally {
    form.processing = false;
  }
};

const handleImageError = (event: Event) => {
  const target = event.target as HTMLImageElement;
  target.src = DefaultBg;
};

const handleRemove = (index: number) => {
  form.contents.splice(index, 1);
};

const handleDeleteConfirm = () => {
  if (!form.id) return;
  DeleteDialogVisible.value = true;
};
const handleDelete = async () => {
  if (!form.id) return;

  DeleteDialogVisible.value = false;
  try {
    form.processing = true;
    const response = await bizCardServiceAPI.delete(form.id);
    if (response.error) {
      error(response.error);
    } else {
      success("Service deleted successfully");
      emit("close");
      router.reload();
    }
  } catch (err: any) {
    error(err.message);
  } finally {
    form.processing = false;
  }
};
/* -------- HOOKS -------- */

/* -------- WATCHERS -------- */
</script>
