<template>
  <section id="service Sections">
    <div class="flex flex-col gap-4">
      <div class="flex justify-end items-center" v-if="isEditing">
        <button
          @click="ManageServiceVisible = true"
          class="bg-switcherBlue-500/30 text-switcherBlue-500 rounded-[4px] py-2 px-4 cursor-pointer h-max">
          + Add
        </button>
      </div>
      <div
        v-for="(service, index) in card.services"
        class="py-5 bg-white rounded-md flex flex-col gap-4 shadow-default">
        <div class="flex flex-col gap-1 px-5">
          <div class="flex justify-between items-center">
            <h1 class="text-main-primary text-lg font-semibold">
              {{ service.title }}
            </h1>
            <i
              v-if="isEditing"
              @click="handleEdit(index)"
              class="font-light fa-regular fa-pen text-lg"></i>
          </div>
          <span class="text-main-secondary text-sm">
            {{ service.description }}
          </span>
        </div>
        <div class="flex px-5">
          <div class="w-1/2 flex flex-col justify-center">
            <h1 class="text-main-primary text-base font-semibold">
              {{ getRate(service) }}
            </h1>
            <p class="text-main-lightGray text-sm">Rate</p>
          </div>
          <div class="w-1/2 flex flex-col justify-center">
            <h1 class="text-main-primary text-base font-semibold capitalize">
              {{ service.expertise }}
            </h1>
            <p class="text-main-lightGray text-sm">Level of Expertise</p>
          </div>
        </div>
        <hr
          v-if="service.content.length > 0"
          class="h-[0.5px] bg-main-divider" />
        <div
          v-if="service.content.length > 0"
          class="flex flex-col gap-1 max-w-[700px]">
          <p class="text-base font-medium px-5">Related content</p>
          <ContentSlider :content="service.content" :user="user" />
        </div>
      </div>
    </div>

    <ManageService
      :service="selectedService"
      v-if="ManageServiceVisible"
      @close="handleCloseManageService" />
  </section>
</template>

<script lang="ts" setup>
import ManageService from "@/components/dialogs/bizcard/services/ManageService.vue";
import ContentSlider from "@/components/general/ContentSlider.vue";
import type {
  BizCardInterface,
  ServiceInterface,
  UserInterface,
} from "@/core/interfaces";
import { useUserBizcardStore } from "@/store/UserBizcard";
import type { PropType } from "vue";
import { computed } from "vue";
import { ref } from "vue";

/* -------- PROPS -------- */
const props = defineProps({
  card: {
    required: true,
    type: Object as PropType<BizCardInterface>,
  },
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
});

/* -------- COMPOSABLE -------- */
const bizcardStore = useUserBizcardStore();

/* -------- STATE -------- */
const isEditing = computed(() => bizcardStore.isEditing);
const ManageServiceVisible = ref(false);
const selectedService = ref<ServiceInterface>();

/* -------- METHODS -------- */
const getRate = (service: ServiceInterface) => {
  const formatCurrency = (amount: number) => `$${Math.floor(amount)}`;

  switch (service.rate_type.toLowerCase()) {
    case "flexible":
      return "Flexible";
    case "hourly":
      return service.max_rate
        ? `${formatCurrency(service.min_rate)}-${formatCurrency(
            service.max_rate,
          )}/hour`
        : `${formatCurrency(service.min_rate)}/hour`;
    case "daily":
      return service.max_rate
        ? `${formatCurrency(service.min_rate)}-${formatCurrency(
            service.max_rate,
          )}/day`
        : `${formatCurrency(service.min_rate)}/day`;
    case "monthly":
      return service.max_rate
        ? `${formatCurrency(service.min_rate)}-${formatCurrency(
            service.max_rate,
          )}/month`
        : `${formatCurrency(service.min_rate)}/month`;
    default:
      return "";
  }
};

const handleEdit = (index: number) => {
  selectedService.value = props.card.services[index];
  ManageServiceVisible.value = true;
};

const handleCloseManageService = () => {
  selectedService.value = undefined;
  ManageServiceVisible.value = false;
};

/* -------- HOOKS -------- */

/* -------- WATCHERS -------- */
</script>
