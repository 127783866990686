<template>
  <div class="flex border border-main-divider gap-2 rounded">
    <img
      class="ml-2 p-0.5 w-[7%] rounded-full"
      :src="model?.image ?? NoCompanyImage" />
    <Dropdown
      :disabled="!options || options.length === 0"
      dataKey="name"
      :loading="loading"
      v-model="innerModel"
      :editable="options && options.length !== 0"
      :invalid="props.invalid"
      @change="handleChange"
      :options="options"
      optionLabel="name"
      :placeholder="props.placeholder"
      class="w-full md:w-14rem"
      :showClear="true"
      panelClass="overflow-y-auto scroll-hidden max-h-[300px]"
      :panelProps="{
        id: 'dropdown-scroll',
      }"
      :ptOptions="{ mergeProps: true }"
      :pt="{
        emptyMessage: '',
        list: '',
        root: '!border-0 !outline-0 !ring-0',
      }">
      <template #header="{ value }">
        <AddItem
          v-if="value && typeof value === 'string'"
          :value="value"
          :callback="handleAddCustom"
          class="!rounded-b-none" />
      </template>

      <template #option="{ option }">
        <div class="flex gap-2 items-center">
          <img
            :src="option.image ?? NoCompanyImage"
            class="rounded-full w-6 h-6"
            alt="" />
          <p class="text-inherit">
            {{ option.name }}
          </p>
        </div>
      </template>
      <template #empty>
        <span></span>
      </template>
    </Dropdown>
  </div>
</template>

<script setup lang="ts">
import NoCompanyImage from "@/assets/images/new_icons/company_dummy.svg";
import AddItem from "@/components/general/AddItem.vue";
import { API } from "@/core/api";
import { useInfiniteScroll } from "@vueuse/core";
import _debounce from "lodash/debounce";
import { onMounted, reactive, ref } from "vue";
import { useToastr } from "@/composables/useToastr";
import type { CompanyInterface } from "@/core/interfaces";
import type { mergeProps, PropType } from "vue";
const props = defineProps({
  selected: {
    type: Array,
    default: () => [],
  },
  placeholder: {
    type: String,
    default: "Select an option",
  },
  icon: {
    type: Object,
    default: null,
  },
  invalid: {
    type: Boolean,
    default: false,
  },
});
const CompaniesAPI = new API.ClientCompanies();
const { error } = useToastr();
const innerModel = ref();
/* ---------- REACTIVE DATA ---------- */
const loading = ref(false);
const model = defineModel({
  type: Object as PropType<CompanyInterface>,
  get(value) {
    return value != null && typeof value === "object"
      ? {
          id: value.id ?? null,
          name: value.name,
          brandId: value.brandId,
          image: value.image,
        }
      : value;
  },
});

const lazyLoad = reactive({
  start: 0,
  size: 25,
  keyword: "",
  no_more_results: false,
  initialValues: model.value ? [model.value] : [],
});

const options = ref([]);
/* ---------- FUNCTIONS ---------- */
const getData = async (clear = false) => {
  try {
    if (!lazyLoad.no_more_results && !loading.value) {
      loading.value = true;
      let response = await CompaniesAPI.get(lazyLoad);
      response = response.data;
      if (response && response.length) {
        if (clear) {
          options.value = response;
        } else {
          options.value.push(...response);
        }
        lazyLoad.start = options.value.length;
        if (response.length < lazyLoad.size) lazyLoad.no_more_results = true;
      } else {
        options.value = [];
        lazyLoad.no_more_results = true;
      }
      loading.value = false;
    }
  } catch (err) {
    loading.value = false;
  }
};

onMounted(async () => {
  await getData(true);
  innerModel.value = model.value;
});

const filter = _debounce(() => {
  lazyLoad.no_more_results = false;
  lazyLoad.start = 0;
  getData(true);
}, 200);
const handleChange = (e) => {
  if (e.originalEvent.type === "input") {
    lazyLoad.keyword = e.value;
    filter();
  }
  if (e.originalEvent.type === "click" && !e.value) {
    lazyLoad.keyword = "";
  }
  if (e.originalEvent.type === "click" && e.value) {
    lazyLoad.keyword = "";
    lazyLoad.initialValues = [e.value];
    model.value = e.value;

    filter();
  }
};

const handleAddCustom = async () => {
  const custom = {
    id: null,
    name: innerModel.value,
    brandId: null,
    image: null,
  };
  lazyLoad.keyword = "";
  lazyLoad.initialValues = [custom];
  options.value.unshift(custom);
  model.value = custom;
  innerModel.value = custom;
  filter();
};

const initInfiniteScroll = () => {
  useInfiniteScroll(
    document.getElementById("dropdown-scroll"),
    () => getData(),
    {
      distance: 10,
    },
  );
};
</script>

<style>
.dropdown-scroll {
  max-height: 200px;
  overflow-y: auto;
}
</style>
