import { type UserInterface } from "@/core/interfaces";
import { defineStore } from "pinia";
import { router } from "@inertiajs/vue3";
import { reactive, ref, computed, onMounted, watch } from "vue";
interface State {
  token: string | null;
  user: UserInterface | null;
}

export const useAuthStore = defineStore("authStore", {
  state: (): State => ({
    token: null,
    user: null,
  }),
  getters: {
    getToken: (state): string | null => state.token,
    getUser: (state): any => Object.freeze({ ...state.user }),
  },
  actions: {
    setUser(user: UserInterface) {
      this.user = user;
    },
    setToken(token: string | null) {
      this.token = token;
      if (!token) router.visit("login");
    },
    logout() {
      this.token = null;
      this.user = null;
    },
    isLoggedIn() {
      return this.token !== null;
    },
    updateUser(data: any) {
      this.user = {
        ...this.user,
        ...data,
      };
    },
  },
});
