<template>
  <div
    class="basis-[80%] flex flex-col bg-white h-[calc(100vh-50px)] overflow-y-auto scroll-hidden">
    <div
      class="flex items-center justify-center w-full bg-black relative aspect-video">
      <img
        class="object-cover w-full h-full aspect-video"
        :src="mainCover"
        alt="CollectionCover" />
      <div
        class="absolute w-[416px] flex flex-col gap-4 items-center justify-center z-[10]"
        v-if="form.thumbnail.video === DefaultCover">
        <i class="fal fa-image text-white fa-3x"></i>
        <div class="flex flex-col gap-2.5 text-center items-center">
          <span class="text-white text-2xl font-medium">Manage cover</span>
          <span class="text-main-disabled text-base">
            Upload a cover photo for your socials and the top of your collection
            page, for both web and mobile use.
          </span>
          <Button
            label="Manage Cover"
            @click="ManageCoverDialogVisible = true"
            class="!w-1/2 focus:!ring-0 border-0" />
        </div>
      </div>
      <div v-else>
        <div class="absolute bottom-4 left-4">
          <Button
            label="Manage Cover"
            @click="ManageCoverDialogVisible = true"
            class="!bg-main-primary/50 hover:!bg-main-primary/80 focus:!ring-0 border-0 !min-w-48 text-sm !p-2" />
        </div>
      </div>
    </div>
    <div
      class="gallery-input-wrapper flex flex-col bg-main-lightBg relative px-4 pt-4 border-b-[0.0625rem] border-b-main-darkGray border-t-[0.0625rem] border-t-main-darkGray">
      <div class="flex items-center gap-2">
        <label
          for="collectionTitle"
          class="font-normal text-sm text-[white] m-0 uppercase">
          Collection Title
        </label>
        <div class="flex items-center">
          <Tag severity="danger" value="REQUIRED"></Tag>
        </div>
      </div>
      <input
        v-model="form.title"
        placeholder="Add a collection title*"
        maxlength="100"
        class="bg-transparent text-base font-medium resize-none border-none focus:outline-none text-white placeholder:text-[#ffffff50]"
        autocomplete="off"
        style="height: 40px" />
    </div>
    <div
      class="bg-main-lightBg flex gap-4 cursor-pointer items-center justify-between px-4 py-4 border-b-[0.0625rem] border-main-darkGray last:border-b-[0]">
      <div class="basis-[89%] cursor-pointer" @click="openDescriptionDialog">
        <h2 class="text-sm text-white font-normal m-0 uppercase mb-2">
          Collection Description
        </h2>
        <p
          class="text-base font-normal text-[#ffffff50] m-0 description-text min-h-5 overflow-hidden line-clamp-3">
          {{ form.description ?? "Add a collection description" }}
        </p>
      </div>
      <div class="basis-[10%] flex justify-end">
        <img
          src="https://development.demo.mybizly.com/_nuxt/right-arrow-icon.c303b597.svg"
          alt="Right Arrow Icon" />
      </div>
    </div>
    <div
      class="flex flex-col items-center divide-y divider-surface-600 space-y-4"
      v-if="form.items.length">
      <div
        class="w-4/6 flex flex-col gap-4 py-8"
        v-for="(item, index) in form.items"
        :key="index">
        <div class="flex justify-between">
          <div class="flex flex-col gap-2">
            <p class="text-main-primary text-lg font-medium m-0">
              {{ item.title }}
            </p>
            <span
              class="text-[15px] font-normal text-[#141414] opacity-90 whitespace-pre-line"
              :class="{
                'line-clamp-none': item.ReadmoreToggle,
                'line-clamp-3': !item.ReadmoreToggle,
              }">
              {{ item.description }}
            </span>
            <span
              v-if="item.description?.length > 300"
              class="text-blue-400 cursor-pointer"
              @click="item.ReadmoreToggle = !item.ReadmoreToggle">
              Read {{ item.ReadmoreToggle ? "less" : "more" }} ...
            </span>
          </div>
        </div>
        <component :is="getItemComponent(item)" :item="item" />
      </div>
    </div>
    <span class="text-sm text-input-border m-auto py-12" v-else>
      You currently have no sections in your collection.
    </span>

    <ManageCoverDialog
      v-if="ManageCoverDialogVisible"
      @close="ManageCoverDialogVisible = false"
      v-model="coverForm" />
    <DescriptionDialog
      @close="dialogStore.hide(DialogType.UPLOAD_CONTENT_CHILD_DESCRIPTION)"
      v-if="dialogStore.isOpened(DialogType.UPLOAD_CONTENT_CHILD_DESCRIPTION)"
      v-model="
        dialogStore[DialogType.UPLOAD_CONTENT_CHILD_DESCRIPTION].data
          .description
      " />
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import ManageCoverDialog from "@/components/dialogs/content/parts/ManageCover.vue";
import { computed } from "vue";
import DefaultCover from "@/assets/images/bgPics/web-bg-min.jpg";
import Images from "./Images.vue";
import Videos from "./Videos.vue";
import Links from "./Links.vue";
import { watch } from "vue";
import { reactive } from "vue";
import { DialogType, useDialogStore } from "@/store/Dialog";
import DescriptionDialog from "@/components/dialogs/content/components/Description.vue";

const form = defineModel({ type: Object, required: true });
const dialogStore = useDialogStore();
const ManageCoverDialogVisible = ref(false);
const coverForm = reactive({
  items: [],
  thumbnail: {
    default: form.value.thumbnail.default,
    video: form.value.thumbnail.video,
    square: form.value.thumbnail.square,
  },
});
const mainCover = computed(() => form.value.thumbnail.video ?? DefaultCover);

const openDescriptionDialog = () => {
  dialogStore.show(
    DialogType.UPLOAD_CONTENT_CHILD_DESCRIPTION,
    undefined,
    form.value,
  );
};

watch(
  form,
  (value) => {
    value.items.forEach((item: any) => {
      if (!item.hasOwnProperty("ReadmoreToggle")) {
        item.ReadmoreToggle = false;
      }
    });

    coverForm.items = form.value.items.flatMap(
      (item: any) =>
        item.items?.map((child: any) => ({
          thumbnail: child.thumbnail,
        })) ?? [],
    );
  },
  { deep: true, immediate: true },
);
watch(
  coverForm,
  (value) => {
    form.value.thumbnail.default = value.thumbnail.default;
    form.value.thumbnail.video = value.thumbnail.video;
    form.value.thumbnail.square = value.thumbnail.square;
  },
  { deep: true },
);
const getItemComponent = (item: any) => {
  switch (item.content_type) {
    case "image":
    case "image_gallery":
      return Images;
    case "video":
    case "video_gallery":
      return Videos;
    case "link":
    case "link_gallery":
      return Links;
  }
};
</script>
