<template>
  <small v-if="props.errors[props.name]" class="text-red-500">{{
    props.errors[props.name]
  }}</small>
</template>
<script setup lang="ts">
const props = defineProps({
  errors: {
    type: Object,
    required: false,
    default: () => [],
  },
  name: {
    type: String,
    required: true,
  },
});
</script>
