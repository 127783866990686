<template>
  <div class="flex flex-col gap-2">
    <SelectGroup
      v-model="form.employment_type"
      :options="EMPLOYMENT_TYPES"
      filterProperty="name"
      value="value"
      placeholder="Employment Type"
      :invalid="form.errors.employment_type != null" />
    <ValidationError
      :errors="form.errors"
      name="employment_type"
      class="self-start" />
    <RolesSelectGroup
      v-model="form.role"
      placeholder="Select a Role"
      :clearCallback="clearRole"
      :invalid="form.errors.role != null" />
    <ValidationError :errors="form.errors" name="role" class="self-start" />

    <CompaniesSelectGroup
      v-model="form.company"
      placeholder="Company / Organization*"
      :invalid="form.errors.company != null" />
    <ValidationError :errors="form.errors" name="company" class="self-start" />
  </div>
</template>

<script lang="ts" setup>
import CompaniesSelectGroup from "@/components/forms/SelectGroups/Companies.vue";
import RolesSelectGroup from "@/components/forms/SelectGroups/Roles.vue";
import SelectGroup from "@/components/forms/SelectGroup.vue";
import ValidationError from "@/components/forms/validationError.vue";
import { EMPLOYMENT_TYPES } from "@/pages/OnBoarding/menus";

/* -------- PROPS -------- */
const form = defineModel({
  type: Object,
  required: true,
});
/* -------- COMPOSABLE -------- */

/* -------- STATE -------- */

/* -------- METHODS -------- */
const clearRole = () => {
  form.value.role = null;
};
/* -------- HOOKS -------- */

/* -------- WATCHERS -------- */
</script>
