<template>
  <div
    :class="
      cn(
        'grid gap-0.5 rounded-lg overflow-hidden shadow-default w-full',
        props.class,
      )
    ">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { cn } from "@/utils/cn";

const props = defineProps({
  class: {
    type: String || Object,
    required: false,
    default: "grid-cols-2",
  },
});
</script>
