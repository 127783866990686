<template>
  <div class="w-full bg-main-lightBg flex justify-center items-center">
    <Menubar
      :model="menu"
      class="h-10 shadow-lg p-4 flex-1"
      breakpoint="1920px"
      :pt="{
        start: 'pr-4',
      }">
      <template #start>
        <bizlyLogoGray />
      </template>
      <template #item="{ item, props }">
        <div
          :class="
            cn(
              'text-sm align-items-center text-main-lightGray hover:text-white hidden lg:flex font-normal',
              item.class,
            )
          ">
          <a v-bind="props.action">{{ item.label }}</a>
        </div>
      </template>
      <template #end>
        <div class="flex items-center gap-4 flex-1 justify-end">
          <NotificationHeader badgeType="dot" />
          <AvatarHeader />
        </div>
      </template>
    </Menubar>
  </div>
</template>

<script lang="ts" setup>
import { cn } from "@/utils/cn";
import { router, usePage } from "@inertiajs/vue3";
import { computed, ref } from "vue";
import { route } from "ziggy-js";
import { API } from "@/core/api";
import { useDialogStore } from "@/store/Dialog";
import { DialogType } from "@/store/Dialog";
import type { UserInterface } from "@/core/interfaces";
import bizlyLogoGray from "@/assets/images/bizly/bizly_grey.vue";
import AvatarHeader from "@/components/layout/parts/AvatarHeader.vue";
import NotificationHeader from "@/components/layout/parts/NotificationHeader.vue";
import { useAuthStore } from "@/store/Auth";
const authStore = useAuthStore();
const user = computed(() => authStore.getUser);

const props = defineProps({
  search: {
    type: Boolean,
    default: true,
  },
  plusIcon: {
    type: Boolean,
    default: true,
  },
});

/** HEADER MENU */
const classes = {
  active: "text-white border-b-white",
  inactive: "border-b-main-lightBg",
};

const isActive = (name: String) => {
  return route().current()?.startsWith(name.toLowerCase());
};

const menu = ref([
  {
    label: "Home",
    class: isActive("home") ? classes.active : classes.inactive,
    command: () => {
      router.visit(route("home"));
    },
  },
  {
    label: "Contacts",
    class: isActive("contacts") ? classes.active : classes.inactive,
    command: () => {
      router.visit(route("contacts.index"));
    },
  },
  {
    label: "Explore",
    class: isActive("explore") ? classes.active : classes.inactive,
    command: () => {
      router.visit(route("explore.index"));
    },
  },
  {
    label: "Opportunities",
    class: "opacity-50 hover:text-main-lightGray !cursor-default",
    command: () => {},
  },
]);

/**  UPLOAD CONTENT */
const dialogStore = useDialogStore();
const contentAPI = new API.Content();

const handleClose = (id?: number) => {
  dialogStore.hide(DialogType.UPLOAD_CONTENT);
  if (id !== undefined) {
    dialogStore.show(DialogType.CONTENT_SHARE_POST, id, { id });
  }
};

const handleSharePost = async () => {
  const data = dialogStore.getData(DialogType.CONTENT_SHARE_POST);

  if (data.id === null) {
    return;
  }
  let response = await contentAPI.shareAsPost(data.id);
  response = response.data;
  dialogStore.hide(DialogType.CONTENT_SHARE_POST);
  dialogStore.show(DialogType.POST_MANAGER, undefined, {
    post: response,
    user: user,
  });
};
</script>
