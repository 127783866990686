export default {
  root: ({ props }) => ({
    class: [
      // Flex Alignment
      "flex bg-main-lightBg border-b border-surface-600",
      {
        "opacity-60 select-none pointer-events-none cursor-default":
          props.disabled,
      },
    ],
  }),
  button: ({ context, props }) => ({
    class: [
      "relative",
      // Font
      "text-[14px] leading-5 font-semibold",

      "border-b border-surface-600",

      // Flex Alignment
      "flex-1 inline-flex justify-center items-center align-bottom text-center",

      // Spacing
      "p-3",

      // Color
      {
        "bg-transparent": !context.active,
        "text-main-disabled font-normal": !context.active,
        "border-white text-white": context.active,
      },
      // Invalid State
      { "border-red-500 dark:border-red-400": props.invalid },

      // States
      "focus:outline-none focus:outline-offset-0",
      {
        "opacity-60 select-none pointer-events-none cursor-default":
          context.disabled,
      },
      // Transition
      "transition duration-200",

      // Misc
      "cursor-pointer select-none overflow-hidden",
    ],
  }),
  label: {
    class: "",
  },
};
