import { toast } from "vue3-toastify";

export const parseFlashData = (el) => {
    if (el.success) {
        toast.success(el.success);
    }
    if (el.error) {
        toast.error(el.error);
    }
    if (el.warning) {
        toast.warning(el.warning);
    }
    if (el.message) {
        toast.info(el.message);
    }
};
