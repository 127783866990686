export default {
  root: ({ props, attrs }) => ({
    class: [
      // Flex and Position
      "flex relative",
      { "justify-center": props.layout == "vertical" },
      { "items-center": props.layout == "vertical" },
      {
        "justify-start": props?.align == "left" && props.layout == "horizontal",
        "justify-center":
          props?.align == "center" && props.layout == "horizontal",
        "justify-end": props?.align == "right" && props.layout == "horizontal",
        "items-center": props?.align == "top" && props.layout == "vertical",
        "items-start": props?.align == "center" && props.layout == "vertical",
        "items-end": props?.align == "bottom" && props.layout == "vertical",
      },

      // Spacing
      {
        "my-2 mx-0 py-0 px-5": props.layout == "horizontal",
        "mx-2 md:mx-3 py-3": props.layout == "vertical",
      },

      // Size
      {
        "w-full": props.layout == "horizontal",
        "min-h-full": props.layout == "vertical",
      },

      // Before: Line
      "before:block",

      // Position
      {
        "before:absolute before:left-0 before:top-1/2":
          props.layout == "horizontal",
        "before:absolute before:left-1/2 before:top-0 before:transform before:-translate-x-1/2":
          props.layout == "vertical",
      },

      // Size
      {
        "before:w-full": props.layout == "horizontal",
        "before:min-h-full": props.layout == "vertical",
      },

      // Shape
      {
        "before:border-solid": props.type == "solid",
        "before:border-dotted": props.type == "dotted",
        "before:border-dashed": props.type == "dashed",
      },

      // Color
      "text-sm",
      {
        "before:border-t-[0.25px]": props.layout == "horizontal",
        "before:border-l-[0.25px]": props.layout == "vertical",
        "before:border-disabled-500":
          !attrs.severity || attrs.severity === "primary",
        "before:border-surface-500": attrs.severity === "secondary",
      },
    ],
  }),
  content: {
    class: [
      // Space and Position
      "px-4 z-10",

      // Color
      "bg-surface-0 dark:bg-surface-800",
    ],
  },
};
