<template>
  <div class="w-full flex items-center justify-between relative">
    <Mentionable
      :keys="['@', '＠']"
      :items="users"
      insert-space
      omit-key
      @apply="onApply"
      class="flex-1 relative"
    >
      <div
        ref="textareaCover"
        class="textarea-cover text-sm min-h-[45px] h-full"
        :class="maskingClassprops"
        v-html="commentMention"
      />
      <textarea
        id="textareaComment"
        ref="textareaComment"
        class="textArea scroll-hidden whitespace-pre-line"
        :class="classProps"
        :disabled="showUserList"
        :maxlength="maxlength"
        :placeholder="placeholder"
        v-model="comment"
        @keydown.enter="handleEnter"
        @input="onTextAreaInput"
        @scroll="commentScroll"
      />
      <template #item="{ item }">
        <div class="user">
          <ul>
            <li :key="item.id" class="flex">
              <UserCard :user="item" :showLocation="false" :showRelationship="false" />
            </li>
          </ul>
        </div>
      </template>
    </Mentionable>
  </div>
</template>

<script setup lang="ts">
import { Mentionable } from "vue-mention-tag";
import { ref, watch, nextTick, computed, onMounted } from "vue";
import { useStore } from "@/store";
import { useUserStore } from "@/store/User";
import type { RolesInterface } from "@/core/interfaces";
import { onUnmounted } from "vue";
import { cn } from "@/utils/cn";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import UserCard from "@/components/general/UserCard.vue";
import { useElementBounding } from "@vueuse/core";
import _debounce from "lodash/debounce";
interface UserMention {
  value: string;
  full_name: string;
  id: number;
  roles: RolesInterface[];
  avatar_image: string;
  valueWithBizcard: string;
}
nextTick(() => {
  adjustTextareaHeight();
});

/* ---------- REACTIVE DATA ----------*/
const model = defineModel();
const comment = ref("");
const textareaCover = ref(null);
const textareaComment = ref(null);
const showUserList = ref(false);
const store = useStore();
const userStore = useUserStore();
const users = ref<UserMention[]>([]);
const mentionedTextAvailable = ref(false);
const friends = computed(() => userStore.friends ?? []);
const emits = defineEmits([
  "handleComment",
  "handleCommentChange",
  "clear",
  "sendComment",
]);
const props = defineProps({
  isClear: Boolean,
  classProps: String,
  placeholder: String,
  maxlength: String,
  id: String,
  maskingClassprops: String,
  value: String,
  heightBox: Number,
  emptyOnEnter: Boolean,
  inputHeight: {
    type: Number,
    default: null,
  },
});

defineExpose({
  comment,
});

const commentMention = computed(() => {
  if (typeof comment.value?.replaceAll !== "function") {
    return comment.value;
  }
  const replaced = comment.value;

  const search = new RegExp(
    users.value
      .slice()
      .sort((a, b) => b.value?.length - a.value?.length)
      .map((user) => user.value)
      .join("|"),
    "g"
  );

  return replaced.replace(search, (match: any, offset: any) => {
    return `<span class="mention-str">${match}</span>`;
  });
});
/* ---------- FUNCTIONS ----------*/

onMounted(() => {
  setUsersList();

  if (model.value) {
    const formattedText = store.formatTextWithMentionInSocialPost(model.value) || "";
    comment.value = formattedText;
  }
});

onUnmounted(() => {
  comment.value = "";
});

const setUsersList = () => {
  users.value = friends.value.map((contact) => ({
    value: contact.first_name + " " + contact.last_name,
    full_name: contact.first_name + " " + contact.last_name,
    id: contact.id,
    roles: contact.roles || [],
    avatar_image: contact.avatar_image,
    valueWithBizcard: `<${contact.first_name} ${contact.last_name}:${contact.public_username}>`,
  }));
};

const commentScroll = () => {
  if (textareaCover.value && textareaComment.value) {
    textareaCover.value.scrollTop = textareaComment.value.scrollTop;
  }
};

function handleEnter(event: any) {
  if (event.key === "Enter" && event.ctrlKey) {
    // If the user is mentioning someone, prevent the default behavior.
    if (isMentioning(event)) {
      event.preventDefault();
    } else {
      event.preventDefault();
      // You can emit something here if necessary
    }
  } else if (event.key === "Enter") {
    // Allow Enter + Shift to create a line break
    return; // No need to prevent default, this will add a line break.
  }
}

function isMentioning(event: any) {
  const text = event.target.value.trim();
  const words = text.split(/\s+/);
  const lastWord = words[words.length - 1];

  return lastWord.includes("@");
}

const onTextAreaInput = _debounce(() => {
  adjustTextareaHeight();
  let commentToSend = mentionedTextAvailable.value ? commentMention.value : comment.value;
  const spanRegex = /<span class="mention-str">(.*?)<\/span>/g;
  commentToSend = commentToSend.replace(spanRegex, (match: any, p1: any) => {
    const user = users.value.find((user) => user.full_name === p1.trim());
    return user ? user.valueWithBizcard : match;
  });
  model.value = commentToSend;
}, 250);

const onApply = () => {
  showUserList.value = true;
  mentionedTextAvailable.value = true;
  setTimeout(() => {
    showUserList.value = false;
    textareaComment.value.focus();
  }, 100);
};

const onClose = () => {
  showUserList.value = false;
};

const adjustTextareaHeight = () => {
  if (textareaComment.value && comment.value.trim() !== "") {
    textareaComment.value.style.height = `${props.inputHeight ?? 40}px`;
    const minHeight = props.inputHeight ?? 40;
    const maxHeight = props.heightBox ?? 120;
    const scrollHeight = textareaComment.value.scrollHeight;
    textareaComment.value.style.height = `${Math.min(
      Math.max(scrollHeight, minHeight),
      maxHeight
    )}px`;
  } else if (comment.value.trim() == "") {
    if (textareaComment.value)
      textareaComment.value.style.height = `${props.inputHeight ?? 40}px`;
  }
};
/* ---------- WATCHERS ----------*/
watch(
  () => props.isClear,
  (newVal) => {
    if (props.isClear) {
      comment.value = "";
      emits("clear");
      adjustTextareaHeight();
    }
  }
);
watch(
  () => commentMention.value,
  (newVal) => {
    if (newVal.includes('<span class="mention-str">')) {
      mentionedTextAvailable.value = true;
    } else {
      mentionedTextAvailable.value = false;
    }
  }
);
watch(
  () => textareaComment.value,
  () => {
    adjustTextareaHeight();
  }
);
watch(
  () => friends.value,
  () => {
    setUsersList();
  }
);
</script>

<style scoped>
.textArea {
  @apply min-h-[45px] !scroll-smooth max-h-[180px] h-full w-full bg-transparent border-0 overflow-y-scroll resize-none  text-sm focus:ring-0 align-middle leading-[normal] m-0 p-3 rounded-md text-surface-600 placeholder:text-surface-400 focus:outline-none focus:outline-offset-0 appearance-none transition-colors duration-200;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}
</style>
