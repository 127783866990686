<template>
  <div class="h-[350px] w-[250px] rounded-2xl cursor-pointer shadow-default">
    <div class="relative h-[250px]">
      <img
        :src="user.avatar_image"
        alt="userImage"
        class="h-full w-full rounded-t-2xl object-cover" />
      <div class="absolute inset-0 gradient rounded-t-2xl"></div>
    </div>
    <div
      class="bg-[#141414] h-[100px] w-full rounded-b-2xl flex flex-col justify-end">
      <div class="flex flex-col mt-[-5px] mb-[2px]">
        <span class="text-[16px] font-medium text-white text-center">
          {{ user.full_name }}
        </span>
        <div
          v-if="user.roles?.length"
          class="flex flex-wrap justify-center items-center text-[#FFF] text-left text-[12px]">
          <span class="relative">
            <span class="role">
              {{
                user.roles[0].name.length > 25
                  ? user.roles[0].name.slice(0, 25) + "..."
                  : user.roles[0].name.slice(0, 25)
              }}
            </span>
          </span>
          <div v-if="user.roles.length > 1" class="flex items-center">
            <span class="separator"></span>
            <span>+{{ user.roles.length - 1 }}</span>
          </div>
        </div>
        <div class="text-[12px] flex text-[#BFBFBF] font-[400] justify-center">
          <img
            src="https://development.demo.mybizly.com/_nuxt/locationGrid.a490a41b.svg"
            class="mr-[3px]" />
          {{ user.location }}
        </div>
      </div>
      <div
        v-if="slots.actions"
        class="flex-end justify-center flex h-[40px] gap-[10px] cursor-pointer items-center">
        <slot name="actions"></slot>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, computed, onMounted, watch, useSlots } from "vue";
import Roles from "@/components/general/Roles.vue";

const props = defineProps({ user: { required: true, type: Object } });
const slots = useSlots();
const roleProps = computed(() => ({
  fontSize: "12px",
  textColor: "#fff",
  separatorWidth: "4px",
  separatorHeight: "4px",
  separatorColor: "#8c8c8c",
}));
</script>
<style scoped>
.gradient {
  background: linear-gradient(rgba(20, 20, 20, 0) 83.38%, rgb(20, 20, 20) 100%);
}
.separator {
  margin: auto 5px;
  /* Adjust margin as needed */
  border-radius: 50%;
  display: inline-block;
  background-color: #8c8c8c;
  height: 4px;
  width: 4px;
}
</style>
