<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-0 w-full flex flex-col relative',
      root: '!bg-white max-w-[800px] w-full rounded-[16px] ',
    }"
    :visible="true"
    modal
    :closable="false">
    <section
      id="header"
      class="p-2 bg-surface-50 border-b-[1px] border-surface-300 sticky top-0 z-20 rounded-t-[16px]">
      <div class="flex justify-between items-center">
        <h1 class="font-poppins text-xl flex-1 text-center">Related Content</h1>
        <CircleIcon
          class="bg-surface-100 text-primary-500 hover:bg-surface-200"
          @click="$emit('close')">
          <i class="far fa-times"></i>
        </CircleIcon>
      </div>
    </section>
    <section
      id="content"
      class="flex flex-col gap-6 max-h-[80vh] overflow-y-auto scroll-hidden"
      @scroll="handleScroll"
      ref="contentSection">
      <section
        class="p-2 bg-surface-50 border-b-[1px] border-surface-300 sticky top-0 z-20">
        <div class="w-full" ref="inputContainer">
          <IconField
            :iconPosition="loading ? 'both' : 'left'"
            class="cursor-pointer w-full z-[60]">
            <i class="fa fa-search"></i>
            <InputText
              v-model="lazyLoad.keyword"
              placeholder="Search your portfolio"
              :class="
                cn(
                  `bg-white/10 w-full focus:!outline-none focus:!border-0 focus:!ring-0 hidden md:block transition-all duration-150 ease-in-out`,
                )
              " />
            <i
              class="pointer-events-none far fa-spinner-third animate-spin"
              v-if="loading"></i>
          </IconField>
        </div>
      </section>
      <div class="grid grid-cols-3 gap-4 px-6 pb-6">
        <div
          v-if="contents.length"
          v-for="child in contents"
          :key="child.id"
          :class="
            cn(
              'bg-white shadow-default rounded-lg transition-transform duration-300 ease-in-out hover:scale-105 relative overflow-hidden',
              {
                'border-2 border-switcherBlue-500': selectedContentIds.includes(
                  child.id,
                ),
              },
            )
          "
          @click="handleSelect(child)">
          <SkeletonLoader v-if="!loadedImages.has(child.id)" aspect="4/3" />
          <img
            :data-src="
              child.thumbnail_image_kit_id ??
              child.image_kit_id_16_9 ??
              child.image_kit_ids?.[0] ??
              child.image_kit_id
            "
            :src="
              child.thumbnail_image_kit_id ??
              child.image_kit_id_16_9 ??
              child.image_kit_ids?.[0] ??
              child.image_kit_id
            "
            alt=""
            class="w-full object-cover object-center bg-no-repeat cursor-pointer aspect-[4/3]"
            @error="handleImageError"
            @load="handleImageLoad(child.id)"
            :class="{ hidden: !loadedImages.has(child.id) }" />
          <div
            v-if="child.is_featured"
            class="absolute top-3 right-3 bg-black/50 opacity-90 rounded-full flex items-center justify-center gap-2 p-2 text-xs text-white">
            <i class="fa fa-star leading-none"></i>
          </div>
          <div
            class="absolute top-2 left-3 bg-black/50 opacity-90 rounded-full flex items-center justify-center gap-2 p-2 text-xs text-white">
            <i
              class="leading-none"
              :class="{
                'fa fa-chain': child.content_type?.includes('link'),
                'fa fa-images': child.content_type?.includes('image'),
                'fa fa-clapperboard-play':
                  child.content_type?.includes('video'),
                'fa-thin fa-rectangle-history':
                  child.content_type === 'collection',
              }"></i>
            <span v-if="child.is_gallery && child.total_children_count">
              {{ child.total_children_count }}
            </span>
          </div>
          <div class="p-2 min-h-[55px] flex justify-between">
            <h3 class="font-semibold text-sm line-clamp-2">
              {{ child.title }}
            </h3>
            <i
              v-if="selectedContentIds.includes(child.id)"
              class="fa fa-check-circle leading-none text-switcherBlue-500 text-xl"></i>
          </div>
        </div>
        <Skeleton
          v-if="loading"
          v-for="i in 3"
          :key="i"
          width="100%"
          height="200px"></Skeleton>
      </div>
    </section>
    <section
      id="footer"
      class="bg-surface-50 border-t border-surface-300 sticky bottom-0 z-20 rounded-b-[16px]">
      <div class="flex justify-end items-center py-2.5 px-4 gap-2">
        <Button
          label="Save"
          :disabled="!form.isDirty"
          :loading="form.processing"
          class="min-w-[150px]"
          @click="handleSave"></Button>
      </div>
    </section>
  </Dialog>
</template>

<script lang="ts" setup>
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { API } from "@/core/api";
import { type ContentInterface } from "@/core/interfaces";
import { useForm } from "@inertiajs/vue3";
import { onMounted, onUnmounted, watch } from "vue";
import { ref, computed } from "vue";
import DefaultBg from "@/assets/images/bgPics/web-bg-min.jpg";
import { cn } from "@/utils/cn";
import _debounce from "lodash/debounce";
import { reactive } from "vue";
import SkeletonLoader from "@/components/SkeletonLoader.vue";

/* -------- PROPS -------- */

const emit = defineEmits(["close"]);

/* -------- COMPOSABLE -------- */
const bizCardAPI = new API.Bizcard();

/* -------- STATE -------- */
const model = defineModel({
  required: true,
  type: Object,
});

const contents = ref<ContentInterface[]>([]);
const form = useForm<any>({
  content: model.value.contents || [],
});
const selectedContentIds = computed(() =>
  form.content.map((content: any) => content.id),
);
const loading = ref(false);
const lazyLoad = reactive({
  keyword: "",
  start: 0,
  size: 15,
  hasMore: true,
});
const contentSection = ref<HTMLElement | null>(null);
const observer = ref<IntersectionObserver | null>(null);
const loadedImages = ref<Set<number>>(new Set());

/* -------- COMPUTED -------- */
const initialValues = computed(() => {
  return form.content.map((content: any) => ({ id: content.id }));
});

/* -------- METHODS -------- */
const handleSave = () => {
  model.value.contents = form.content;
  emit("close");
};

const getContents = async () => {
  if (!lazyLoad.hasMore || loading.value) return;

  loading.value = true;
  try {
    const response = await bizCardAPI.getManageContents({
      keyword: lazyLoad.keyword,
      start: lazyLoad.start,
      size: lazyLoad.size,
      initialValues: lazyLoad.start === 0 ? initialValues.value : undefined,
    });
    contents.value =
      lazyLoad.start === 0
        ? response.data
        : [...contents.value, ...response.data];
    lazyLoad.start += lazyLoad.size;
    lazyLoad.hasMore = response.data.length === lazyLoad.size;
    setupIntersectionObserver();
  } catch (error) {
    console.error("Error fetching contents:", error);
  } finally {
    loading.value = false;
  }
};

const handleImageError = (event: Event) => {
  const target = event.target as HTMLImageElement;
  target.src = DefaultBg;
};

const handleImageLoad = (id: number) => {
  loadedImages.value.add(id);
};

const handleSelect = (content: any) => {
  const index = form.content.findIndex((item: any) => item.id === content.id);
  if (index !== -1) {
    form.content.splice(index, 1);
  } else {
    form.content.push(content);
  }
};

const handleScroll = _debounce(() => {
  if (!contentSection.value) return;

  const { scrollTop, scrollHeight, clientHeight } = contentSection.value;
  if (
    scrollTop + clientHeight >= scrollHeight - 100 &&
    lazyLoad.hasMore &&
    !loading.value
  ) {
    getContents();
  }
}, 200);

const handleSearch = _debounce(() => {
  contents.value = [];
  lazyLoad.start = 0;
  lazyLoad.hasMore = true;
  getContents();
}, 250);

const setupIntersectionObserver = () => {
  observer.value = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = entry.target as HTMLImageElement;
          img.src = img.dataset.src || "";
          img.classList.remove("lazy-image");
          observer.value?.unobserve(img);
        }
      });
    },
    { rootMargin: "100px" },
  );

  document.querySelectorAll(".lazy-image").forEach((img) => {
    observer.value?.observe(img);
  });
};

onMounted(async () => {
  await getContents();
  setupIntersectionObserver();
});

onUnmounted(() => {
  observer.value?.disconnect();
});

watch(() => lazyLoad.keyword, handleSearch);

/* -------- HOOKS -------- */

/* -------- WATCHERS -------- */
onMounted(async () => {
  await getContents();
});
</script>

<style scoped>
.lazy-image {
  opacity: 0;
  transition: opacity 0.3s;
}

.lazy-image[src] {
  opacity: 1;
}

img {
  transition: opacity 0.3s ease-in-out;
}

img.hidden {
  opacity: 0;
}
</style>
