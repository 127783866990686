export const contentToDB = (content: any) => {
  let transformed: any = {
    ...content,
  };
  if (content.content_type.includes("video")) {
    if (content.content_type === "video_gallery") {
      transformed.children = content.items.map((child) => ({
        id: child.id,
        title: child.title,
        description: child.description,
        external_url: child.duration,
        image_kit_id_16_9: child.thumbnail.video,
        thumbnail_image_kit_id: child.thumbnail.square,
        image_kit_id: child.url,
        embed: child.embed,
      }));
    } else {
      transformed = {
        id: content.id,
        title: content.items[0].title,
        description: content.items[0].description,
        external_type: content.items[0].duration,
        image_kit_id_16_9: content.items[0].thumbnail.video,
        thumbnail_image_kit_id: content.items[0].thumbnail.square,
        image_kit_id: content.items[0].url,
        embed: content.items[0].embed,
      };
    }
  } else if (content.content_type.includes("image")) {
    if (content.content_type === "image_gallery")
      transformed.children = content.items.map((child) => ({
        id: child.id,
        title: child.title,
        description: child.description,
        image_kit_id: child.thumbnail.default,
        image_kit_id_16_9: child.thumbnail.video,
        thumbnail_image_kit_id: child.thumbnail.square,
      }));
    else {
      transformed = {
        id: content.id,
        title: content.items[0].title,
        description: content.items[0].description,
        image_kit_id: content.items[0].thumbnail.default,
        image_kit_id_16_9: content.items[0].thumbnail.video,
        thumbnail_image_kit_id: content.items[0].thumbnail.square,
      };
    }
  } else if (content.content_type.includes("link")) {
    if (content.content_type === "link_gallery") {
      transformed.children = content.items.map((child) => ({
        id: child.id,
        title: child.title,
        description: child.description,
        image_kit_id: child.url,
        image_kit_id_16_9: child.thumbnail.video,
        thumbnail_image_kit_id: child.thumbnail.square,
      }));
    } else {
      transformed = {
        id: content.id,
        title: content.items[0].title,
        description: content.items[0].description,
        image_kit_id: content.items[0].url,
        image_kit_id_16_9: content.items[0].thumbnail.video,
        thumbnail_image_kit_id: content.items[0].thumbnail.square,
      };
    }
  } else {
    return content;
  }

  console.log("transformed", transformed);
  return transformed;
};
