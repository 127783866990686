<template>
  <div class="py-3 flex flex-col gap-4 overflow-hidden">
    <p class="text-center text-sm text-main-secondaryBlue">
      This photo will be the background image of your webapp bizlyCard.
      <br />
      (Hint: A 16x9 image will give you the best results.)
    </p>
    <div v-if="!editing" class="relative h-[479px]">
      <img
        :src="form.web_cover ?? DefaultBg"
        alt="web_background_image"
        class="w-full object-cover aspect-video" />

      <div
        class="absolute bottom-2 left-4 flex gap-2 items-center"
        v-if="form.web_cover">
        <button
          @click="handleRemove"
          class="flex items-center justify-center gap-2 px-6 py-3 rounded bg-main-primary/65 text-xs text-white">
          <i class="fa fa-trash-can"></i>
          Delete
        </button>
        <FileUpload
          mode="advanced"
          name="demo[]"
          url="/api/upload"
          accept="image/*"
          customUpload
          :auto="true"
          @uploader="handleReplace"
          :pt="{
            root: 'flex items-center',
            content: 'hidden',
          }">
          <template #header="{ chooseCallback }">
            <button
              @click="chooseCallback"
              class="flex items-center justify-center gap-2 px-6 py-3 rounded bg-main-primary/65 text-xs text-white">
              <i class="fa fa-rotate"></i>
              Replace
            </button>
          </template>
        </FileUpload>
        <button
          class="flex items-center justify-center gap-2 px-6 py-3 rounded bg-main-primary/65 text-xs text-white"
          @click="editing = true">
          <i class="fa fa-crop-simple"></i>
          Crop
        </button>
      </div>
      <div v-else class="absolute bottom-2 left-4 flex gap-2 items-center">
        <FileUpload
          mode="advanced"
          name="demo[]"
          url="/api/upload"
          accept="image/*"
          customUpload
          :auto="true"
          @uploader="handleReplace"
          :pt="{
            root: 'flex items-center',
            content: 'hidden',
          }">
          <template #header="{ chooseCallback }">
            <button
              @click="chooseCallback"
              class="flex items-center justify-center gap-2 px-6 py-3 rounded bg-main-primary/65 text-xs text-white">
              <i class="fa fa-plus"></i>
              Add a cover image
            </button>
          </template>
        </FileUpload>
      </div>
    </div>
    <div v-else class="relative h-[479px]">
      <Cropper
        ref="cropperEl"
        :stencil-props="{
          aspectRatio: 16 / 9,
        }"
        class="cropper aspect-video object-cover w-full h-full"
        :src="form.web_cover"
        @ready="cropperLoading = false"></Cropper>
      <div
        class="absolute bottom-5 items-center w-full justify-center hidden md:flex gap-2">
        <CircleIcon class="bg-black/50" @click="() => (editing = null)">
          <i class="fal fa-times text-white text-sm"></i>
        </CircleIcon>
        <CircleIcon
          class="bg-switcherBlue-500 hover:bg-switcherBlue-600"
          @click="handleCrop">
          <i class="fal fa-check text-white text-sm"></i>
        </CircleIcon>
      </div>
      <div
        v-if="cropperLoading"
        class="absolute z-50 inset-0 cropper aspect-video w-full h-full flex items-center justify-center rounded-[8px] object-cover text-white">
        <i
          class="fa-sharp-duotone fa-solid fa-spinner-third animate-spin text-xl"></i>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch } from "vue";
import { Cropper } from "vue-advanced-cropper";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import DefaultBg from "@/assets/images/bgPics/web-bg-min.jpg";

const props = defineProps({ user: { required: true, type: Object } });
const emit = defineEmits(["canSave"]);

const form = defineModel({
  type: Object,
  required: true,
});
const cropperEl = ref<null | any>(null);
const editing = ref<null | Boolean>(false);
const cropperLoading = ref(false);

const handleCrop = () => {
  const { canvas } = cropperEl.value.getResult();
  if (canvas) {
    const base64 = canvas.toDataURL();
    if (editing.value !== null) {
      form.value.web_cover = base64;
    }
  }
  editing.value = null;
};

const handleReplace = (event: any) => {
  const file = event.files[0];
  const reader = new FileReader();
  reader.onload = function (e: any) {
    form.value.web_cover = e.target.result;
  };
  reader.readAsDataURL(file);
};
const handleRemove = () => {
  form.value.web_cover = null;
};

watch(editing, (val) => {
  emit("canSave", val === null);
});
</script>
