export enum BizCardContentType {
  ImageGallery = "image_gallery",
  Image = "image",
  LinkGallery = "link_gallery",
  Link = "link",
  VideoGallery = "video_gallery",
  Video = "video",
  Collection = "collection",
  PlainText = "text",
}
