import baseApi from "./Base";
import { route } from "ziggy-js";

export class MiscellaneousService extends baseApi {
  async quoteOfTheDay() {
    return await this.request(route("api.daily-quotes"), "GET");
  }
  async newToBizlyUsers() {
    return await this.request(route("api.new-users"), "GET");
  }
}
