<template>
    <div class="flex-col justify-start items-center flex bg-black">
        <div
            class="w-full lg:w-4/6 px-6 lg:px-12 py-7 bg-zinc-800 justify-between items-center flex flex-col md:flex-row gap-6">
            <div class="justify-start items-center gap-3.5 flex self-start">
                <img class="w-12 h-12 rounded-full" src="../../../assets/images/sevag.png" />
                <div class="flex-col justify-start items-start gap-2.5 flex">
                    <div class="text-white text-lg font-semibold capitalize leading-3 tracking-wide">
                        Sevag chahinian</div>
                    <div class="self-stretch justify-start items-center gap-2 inline-flex">
                        <div class="opacity-80 text-white text-xs md:text-sm font-extralight leading-3">Cinematographer
                        </div>
                        <div class="w-0.5 h-0.5 bg-zinc-300 rounded-full"></div>
                        <div class="opacity-80 text-white text-xs md:text-sm font-extralight leading-3">Director</div>
                        <div class="w-0.5 h-0.5 bg-zinc-300 rounded-full"></div>
                        <div class="opacity-80 text-white text-xs md:text-sm font-extralight leading-3">Producer</div>
                    </div>
                </div>
            </div>
            <div class="md:justify-end items-center gap-2 md:gap-5 flex flex-col md:flex-row w-full">
                <div class="px-5 py-2.5 bg-switcherBlue-500 rounded-md justify-center items-center flex w-full md:w-auto">
                    <div class="text-center text-white text-sm font-normal leading-tight flex gap-2">

                        <i class="fa fa-user-plus"></i>
                        Connect with Sevag
                    </div>
                </div>
                <div class="px-5 py-2.5 bg-white rounded-md justify-center items-center flex w-full md:w-auto">
                    <div
                        class="text-center text-black text-sm font-medium leading-tight flex justify-center gap-2 w-full">
                        <img src="../../../assets/icons/share-filled.svg">
                        Share bizlyCard
                    </div>
                </div>
            </div>
        </div>
        <!-- < 900px -->
        <div class="w-full flex flex-col gap-2 lg:hidden">
            <div class="flex gap-4 px-6 pt-7">
                <div class="self-center">
                    <img src="../../../assets/images/logo.png">
                </div>
                <div class="flex-col justify-start items-start gap-0.5 inline-flex">
                    <div class="text-white text-md font-medium">Network. Work. All in one
                        place.</div>
                    <div class="opacity-80 text-white text-xs font-extralight">Start using
                        bizly today to build your online persona, find collaborations, and opportunities.
                    </div>
                </div>
            </div>
            <div class="justify-end items-start gap-5 flex px-6 pb-7 pt-2">
                <div class="flex-1 py-3 rounded border border-white justify-center items-center flex">
                    <div class="text-white text-xs font-normal capitalize leading-3 tracking-wide">
                        Learn more</div>
                </div>
                <div class="flex-1 px-3 py-3 bg-switcherBlue-500 rounded justify-center items-center gap-2 flex">
                    <div class="text-white text-xs font-normal capitalize leading-3 tracking-wide">
                        Invite your network</div>
                </div>
            </div>
            <div class="self-stretch h-px border border-neutral-700"></div>
            <div class="self-center">
                <div class="opacity-70 text-white text-xs font-normal">Copyright © 2023 BIZ
                    Techologies LLC. All rights reserved.</div>
            </div>
            <div class="justify-evenly flex px-6 pb-4">
                <div class="opacity-70 text-white text-xs font-normal">Privacy Policy</div>
                <div class="opacity-70 text-white text-xs font-normal">Terms of Use</div>
            </div>
        </div>
        <!-- > 900px -->
        <div class="w-4/6 p-12 flex-col justify-start items-start gap-5 hidden lg:flex">
            <div class="self-stretch h-24 flex-col justify-start items-start gap-5 flex">
                <div class="self-stretch justify-between items-center inline-flex">
                    <div class="justify-start items-center gap-7 flex">
                        <div class="w-36 h-12 relative">
                            <div class="left-0 top-0 absolute justify-center items-center inline-flex">
                                <img src="../../../assets/images/logo.png">
                            </div>
                        </div>
                        <div class="flex-col justify-start items-start gap-0.5 inline-flex w-1/2">
                            <div class="text-white text-lg font-medium">Network. Work. All in one
                                place.</div>
                            <div class="opacity-80 text-white text-sm font-extralight">Start using
                                bizly today to build your online persona, find collaborations, and opportunities.
                            </div>
                        </div>
                    </div>
                    <div class="justify-start items-start gap-5 flex">
                        <div class="px-5 py-3.5 rounded border border-white justify-start items-start gap-2 flex">
                            <div class="text-white text-sm font-normal capitalize leading-3 tracking-wide">
                                Learn more</div>
                        </div>
                        <div class="px-5 py-3.5 bg-switcherBlue-500 rounded justify-start items-start gap-2 flex">
                            <div class="text-white text-sm font-normal capitalize leading-3 tracking-wide">
                                Invite your network</div>
                        </div>
                    </div>
                </div>
                <div class="self-stretch h-px border border-neutral-700"></div>
            </div>
            <div class="self-stretch justify-between items-start inline-flex">
                <div class="h-4 justify-end items-center flex">
                    <div class="opacity-70 text-white text-xs font-normal">Copyright © 2023 BIZ
                        Techologies LLC. All rights reserved.</div>
                </div>
                <div class="justify-start items-start gap-10 flex">
                    <div class="opacity-70 text-white text-sm font-normal">Privacy Policy</div>
                    <div class="opacity-70 text-white text-sm font-normal">Terms of Use</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
});
</script>