<template>
  <div
    class="w-full bg-white px-4 py-[12px] border-b border-main-divider flex items-center justify-between"
    :class="
      page.url?.includes('social-posts') ? 'rounded-t-none' : 'rounded-t-[16px]'
    ">
    <span
      class="info-container flex gap-2 md:gap-[0.5rem] items-center w-[90%]">
      <span
        class="flex items-center gap-[5px] justify-center py-[3px] px-[10px] bg-primaryBlue-500 text-switcherBlue-500Bg rounded-[20px] text-[14px] focus:shadow-none">
        <svg
          width="14"
          height="13"
          viewBox="0 0 14 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.0833 1.24811H9.33333C9.2435 1.24811 9.1525 1.22711 9.07258 1.18686L7.23158 0.266365C6.9895 0.145615 6.71942 0.0820312 6.44933 0.0820312H5.25C3.64175 0.0820312 2.33333 1.39045 2.33333 2.9987V7.66478C2.33333 9.27303 3.64175 10.5814 5.25 10.5814H11.0833C12.6916 10.5814 14 9.27303 14 7.66478V4.16478C14 2.55653 12.6916 1.24811 11.0833 1.24811ZM5.25 1.24811H6.44933C6.53917 1.24811 6.63017 1.26911 6.71008 1.30936L8.5505 2.22986C8.792 2.35061 9.06267 2.4142 9.33333 2.4142H11.0833C11.844 2.4142 12.4921 2.90186 12.733 3.58086H3.5V2.99753C3.5 2.0327 4.28517 1.24753 5.25 1.24753V1.24811ZM11.0833 9.41478H5.25C4.28517 9.41478 3.5 8.62961 3.5 7.66478V4.74811H12.8333V7.66478C12.8333 8.62961 12.0482 9.41478 11.0833 9.41478ZM11.6667 12.3314C11.6667 12.654 11.4059 12.9148 11.0833 12.9148H2.91667C1.30842 12.9148 0 11.6064 0 9.99811V4.16478C0 3.8422 0.261333 3.58145 0.583333 3.58145C0.905333 3.58145 1.16667 3.8422 1.16667 4.16478V9.99811C1.16667 10.9629 1.95183 11.7481 2.91667 11.7481H11.0833C11.4059 11.7481 11.6667 12.0089 11.6667 12.3314Z"
            fill="#F7F8FB" />
        </svg>
        Portfolio
      </span>
      <DotSeparator />
      <i
        class="text-main-lightGray"
        :class="
          !post?.is_network_only
            ? 'fa-solid fa-globe'
            : 'fa-solid fa-user-group'
        " />
      <DotSeparator />
      <small class="text-main-lightGray mb-0 text-xs whitespace-nowrap w-max">
        {{ usePostCreationTime(post?.created_on) }}
      </small>
    </span>
    <button
      class="flex gap-2.5 items-center rounded-[50%] hover:bg-[#e4e4e4] focus:bg-[#e4e4e4]"
      type="button"
      @click.stop="toggle"
      aria-haspopup="true">
      <svg
        width="27"
        height="26"
        viewBox="0 0 27 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.83237 14.3317C7.56875 14.3317 8.16571 13.7347 8.16571 12.9984C8.16571 12.262 7.56875 11.665 6.83237 11.665C6.09598 11.665 5.49902 12.262 5.49902 12.9984C5.49902 13.7347 6.09598 14.3317 6.83237 14.3317Z"
          fill="#595959" />
        <path
          d="M13.4979 14.3317C14.2343 14.3317 14.8312 13.7347 14.8312 12.9984C14.8312 12.262 14.2343 11.665 13.4979 11.665C12.7615 11.665 12.1646 12.262 12.1646 12.9984C12.1646 13.7347 12.7615 14.3317 13.4979 14.3317Z"
          fill="#595959" />
        <path
          d="M20.1668 14.3317C20.9032 14.3317 21.5002 13.7347 21.5002 12.9984C21.5002 12.262 20.9032 11.665 20.1668 11.665C19.4305 11.665 18.8335 12.262 18.8335 12.9984C18.8335 13.7347 19.4305 14.3317 20.1668 14.3317Z"
          fill="#595959" />
      </svg>
    </button>

    <Menu
      id="overlay_menu"
      :model="ellipsesMenu"
      class="w-[21rem] content_menu overflow-hidden shadow-[0_2px_15px_0_rgba(0,0,0,0.3)]"
      ref="menu"
      :popup="true">
      <template #item="{ item }">
        <div class="" v-if="item.active">
          <a
            class="flex items-center gap-4 py-3 px-4 w-full hover:bg-switcherBlue-500-transparent"
            :class="{ 'border-t border-main-divider': item.border }"
            v-if="item.type == 'external_link'"
            :href="item.href"
            target="_blank">
            <CircleIcon>
              <i :class="item.icon"></i>
            </CircleIcon>

            <div class="flex flex-col gap-[2px] items-start justify-start">
              <p
                class="mb-0 w-max text-[16px] font-medium leading-5"
                :class="{
                  'text-[#ff3b30]': item.danger,
                }">
                {{ item?.label }}
              </p>
              <small
                class="text-xs font-normal leading-4 text-main-secondary text-left"
                v-if="item.description">
                {{ item?.description }}
              </small>
            </div>
          </a>
          <Link
            v-else-if="item.type === 'inertia_link'"
            :href="item.href"
            class="flex items-center gap-4 py-3 px-4 w-full hover:bg-switcherBlue-500-transparent"
            :class="{ 'border-t border-main-divider': item.border }">
            <CircleIcon :class="{ 'bg-[#ff3b30]/30': item.danger }">
              <i :class="item.icon"></i>
            </CircleIcon>

            <div class="flex flex-col gap-[2px] items-start justify-start">
              <p
                class="mb-0 w-max text-[16px] font-medium leading-5"
                :class="{
                  'text-[#ff3b30]': item.danger,
                }">
                {{ item?.label }}
              </p>
              <small
                class="text-xs font-normal leading-4 text-main-secondary text-left"
                v-if="item.description">
                {{ item?.description }}
              </small>
            </div>
          </Link>
          <button
            v-else-if="item.type === 'button'"
            @click="item.clickEvent"
            class="flex items-center gap-[12px] py-3 px-4 w-full hover:bg-switcherBlue-500-transparent"
            :class="{ 'border-t border-main-divider': item.border }">
            <CircleIcon size="2rem">
              <i :class="item.icon"></i>
            </CircleIcon>
            <div class="flex flex-col gap-[2px] items-start justify-start">
              <p
                class="mb-0 w-max text-[16px] font-medium leading-5"
                :class="{
                  'text-[#ff3b30]': item.danger,
                }">
                {{ item?.label }}
              </p>
              <small
                class="text-xs font-normal leading-4 text-main-secondary text-left"
                v-if="item.description">
                {{ item?.description }}
              </small>
            </div>
          </button>
        </div>
      </template>
    </Menu>
  </div>

  <div
    v-if="user?.is_on_waitlist && route().current().includes('social_posts')"
    class="h-[40px] text-[#546B91] bg-[#EFF1F5] border-b-[1px] border-b-[#CDD5E1] px-[20px] py-[10px] flex gap-[6px] items-center">
    <i class="fa-solid fa-triangle-exclamation"></i>
    <span class="text-xs text-[400]">Engagement and usage is limited.</span>
  </div>
  <!-- ----- DIALOGS ----- -->

  <!-- Delete Post -->
  <ConfirmDangerDialog
    v-if="PostOwner && DeletePostDialogVisible"
    @close="DeletePostDialogVisible = false"
    @confirm="handleDeletePost"
    title="Delete Social Post"
    icon="fa fa-trash"
    action="Delete">
    <div>
      <div
        class="text-main-secondaryBlue text-xs md:text-sm font-normal text-center mt-2.5 leading-5">
        Are you sure you would like to delete this post? This action cannot be
        undone.
      </div>
    </div>
  </ConfirmDangerDialog>
  <!-- Block User -->
  <ConfirmDangerDialog
    class=""
    v-if="BlockUserDialogVisible"
    @close="BlockUserDialogVisible = false"
    @confirm="handleBlockUser"
    title="Block User"
    icon="fa fa-trash"
    action="Block">
    <div>
      <div
        class="text-main-secondaryBlue text-xs md:text-sm font-normal text-center mt-2.5 leading-5 mx-auto w-full self-center">
        Are you sure you want to block
        <strong>{{ props.post.owner.first_name }}</strong>
        ?
      </div>
    </div>
  </ConfirmDangerDialog>

  <EditPostDialog
    v-if="PostOwner && EditPostDialogVisible"
    @close="EditPostDialogVisible = false"
    @confirm="handleEditPost"
    :user="user"
    :post="post" />
</template>

<script setup>
import ConfirmDangerDialog from "@/components/dialogs/ConfirmDanger.vue";
import EditPostDialog from "@/components/dialogs/social-posts/EditPost.vue";
import DotSeparator from "@/components/general/DotSeparator.vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { API } from "@/core/api";
import { HomeFeedType } from "@/core/enums";
import { useHomeFeedStore } from "@/store/HomeFeed";
import { usePostCreationTime } from "@/utils/commonFunctions.js";
import { Link, router, usePage } from "@inertiajs/vue3";
import Menu from "primevue/menu";
import { computed, ref } from "vue";
import { route } from "ziggy-js";
import { useAuthStore } from "@/store/Auth";
const authStore = useAuthStore();
const homeFeedStore = useHomeFeedStore();
const UserApi = new API.Users();

const props = defineProps({
  post: Object,
});

/* ---------- REACTIVE DATA ----------*/

const page = usePage();
const menu = ref();

/* ----- Dialogs -----*/
const DeletePostDialogVisible = ref(false);
const BlockUserDialogVisible = ref(false);
const EditPostDialogVisible = ref(false);

const user = computed(() => authStore.getUser);
const PostOwner = computed(() => props.post.owner.id === user.value.id);
const ellipsesMenu = computed(() => {
  return [
    {
      active: PostOwner.value,
      label: "Edit Post",
      border: false,
      description: "Edit the message on your post.",
      type: "button",
      clickEvent: () => (EditPostDialogVisible.value = true),
      icon: "fa-sharp fa-regular fa-pen-to-square text-primaryBlue-500",
    },
    {
      active: PostOwner.value,
      label: "Delete Post",
      border: false,
      description:
        "Remove this post from all feeds. This will not delete the content from your portfolio.",
      type: "button",
      icon: "fa-sharp fa-regular fa-trash text-primaryBlue-500",
      clickEvent: () => (DeletePostDialogVisible.value = true),
    },
    {
      active: !PostOwner.value,
      label: "Hide this post",
      border: false,
      description: "Remove this post from your feed.",
      clickEvent: handleHidePost,
      type: "button",
      icon: "fa-sharp fa-regular fa-eye-slash text-primaryBlue-500",
    },
    {
      active: !PostOwner.value,
      label: "Report this post",
      border: false,
      description: "This will automatically hide this post as well.",
      type: "button",
      clickEvent: handleReportPost,
      icon: "fa-sharp fa-regular fa-brake-warning text-primaryBlue-500",
    },
    {
      active: !PostOwner.value,
      label: "Copy Link",
      border: false,
      description: "click to copy link to the post",
      type: "button",
      icon: "fa-sharp fa-regular fa-copy text-primaryBlue-500",
    },
    {
      active: PostOwner.value,
      label: "Edit Content",
      border: true,
      description: "Edit this portfolio content",
      type: "button",
      icon: "fa-sharp fa-regular fa-pen text-primaryBlue-500",
    },
    {
      active: !PostOwner.value.is_friend && !PostOwner.value.has_friend_request,
      label: `Connect with ${props.post.owner.first_name}`,
      border: true,
      description: null,
      type: "button",
      icon: "fa-regular fa-user-plus",
    },
    {
      active: !PostOwner.value,
      label: `Block ${props.post.owner.first_name}`,
      border: true,
      description: null,
      type: "button",
      icon: "fa-sharp fa-regular fa-shield text-red-700",
      clickEvent: () => (BlockUserDialogVisible.value = true),
      danger: true,
    },
  ];
});

/* ---------- METHODS AND FUNCTIONS ---------- */

/* ----- Ellipsis Menu Methods ----- */

const handleDeletePost = () => {
  router.post(
    route("social_posts.delete", props.post.id),
    { redirect: true },
    {
      onSuccess() {
        if (route().current() === "social_posts.index") {
          router.get(route("home"));
        }
        homeFeedStore.removePost(HomeFeedType.Community, props.post.id);
        DeletePostDialogVisible.value = false;
      },
    },
  );
};

const handleBlockUser = () => {
  try {
    let response = UserApi.block(props.post.owner.id);
    homeFeedStore.hidePostsForUser(HomeFeedType.Community, props.post.owner.id);
  } catch (error) {
    console.error(error);
  }
};

const handleConnectUser = () => {};

const handleEditPost = () => {};

const handleEditContent = () => {};

const handleHidePost = async () => {
  await homeFeedStore.hidePost(HomeFeedType.Community, props.post.id);
};

const handleReportPost = async () => {
  await homeFeedStore.hidePost(HomeFeedType.Community, props.post.id);
  window.open(
    "https://form.asana.com/?k=fNK3H3eAMYJxDz8Mhm5nPw&d=1200288379680184",
    "_blank",
  );
};

const toggle = (event) => {
  menu.value.toggle(event);
};
</script>
