<template>
  <Dialog :visible="true" modal :pt="otpDialogPreset" :closable="false">
    <div
      class="text-[18px] sm:text-[28px] text-black text-center font-medium leading-5 pb-[15px]"
    >
      Insert code below
    </div>
    <div
      class="text-xs sm:text-[18px] leading-4 sm:leading-7 text-center text-[#112F60] font-medium"
    >
      {{ props.email }}
    </div>
    <div
      class="text-[10px] md:text-[14px] leading-1 md:leading-5 font-normal text-center"
    >
      A verification email is on the way!
    </div>

    <form @submit.prevent="submit">
      <div class="flex flex-col justify-center items-center pt-[20px]">
        <InputOtp v-model="form.token" :length="6" integerOnly />
        <div
          class="text-[10px] md:text-[14px] font-normal pt-[10px] flex items-center gap-2"
        >
          Haven't received a verification email?
          <button
            type="button"
            class="text-switcherBlue-500 disabled:text-gray-400"
            @click="sendOtp"
            :disabled="timer != 0"
          >
            Resend {{ timer > 0 ? `(${timer})` : "" }}
          </button>
        </div>
      </div>
      <div class="w-full flex">
        <Button
          type="submit"
          class="self-center text-white text-sm font-normal px-[12px] py-[12px] text-center w-full cursor-pointer sm:w-[200px] h-[40px] flex items-center justify-center mx-auto mt-[15px] hover:!bg-switcherBlue-500 !border-0"
          :class="form.token?.length == 6 ? 'bg-[#39537D]' : 'bg-[#adadad]'"
          :disabled="!form.token || form.token?.length < 6"
          :loading="form.processing"
        >
          Verify
        </Button>
      </div>
    </form>
  </Dialog>
</template>
<script setup>
import otpDialogPreset from "@/assets/tailwind-preset/dialog/otpDialog";
import { useToastr } from "@/composables/useToastr";
import { router, useForm } from "@inertiajs/vue3";
import InputOtp from "primevue/inputotp";
import { onMounted, onUnmounted, ref } from "vue";
import { route } from "ziggy-js";

const { error, success } = useToastr();

const props = defineProps(["isVisible", "email", "redirect"]);
const form = useForm({
  email: props.email,
  token: null,
});

defineEmits("close");

const submit = () => {
  form.post(route("settings.change_email"), { preserveState: true });
};

// TIMER //
const _TIMER = 60;
const interval = ref();
const timer = ref(_TIMER);
const beginTimer = () => {
  timer.value = _TIMER;
  interval.value = setInterval(() => {
    if (timer.value > 0) {
      timer.value--;
    } else {
      stopTimer();
    }
  }, 1000);
};
const stopTimer = () => {
  clearInterval(interval.value);
  interval.value = null;
};
onMounted(() => {
  beginTimer();
});
onUnmounted(() => {
  stopTimer();
});
// END TIMER //

const sendOtp = () => {
  if (timer.value <= 0) {
    router.post(
      route("settings.email_change_request"),
      {},
      {
        onSuccess: () => {
          beginTimer();
        },
      },
    );
  }
};
</script>
