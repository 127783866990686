<template>
  <div
    class="h-[40px] bg-main-lightBg w-full flex items-center justify-center gap-[40px] sticky z-20">
    <button
      :class="
        cn(
          'text-sm  text-main-disabled transition-colors font-medium uppercase tracking-tight',
          {
            'text-white font-bold': !model,
          },
        )
      "
      @click="() => handleChangeCatergory(null)">
      All
    </button>

    <button
      v-for="category in categories"
      :key="category.id"
      :class="
        cn(
          'text-sm text-main-disabled transition-colors font-medium uppercase tracking-tight',
          {
            'text-white': model?.id === category.id,
          },
        )
      "
      @click="() => handleChangeCatergory(category)">
      {{ category.name }}
    </button>
  </div>
</template>
<script setup lang="ts">
import type { ContentCategoryInterface } from "@/core/interfaces";
import { cn } from "@/utils/cn";
import type { PropType } from "vue";

defineProps({
  categories: {
    required: true,
    type: Array as PropType<ContentCategoryInterface[]>,
  },
});
const model = defineModel({
  type: Object as PropType<ContentCategoryInterface | null>,
});
const emit = defineEmits(["changeCategory"]);
const handleChangeCatergory = (category: ContentCategoryInterface | null) => {
  model.value = category;
};
</script>
