<template>
  <div class="inner-tabs-wrapper">
    <div class="" @click="handleOpen">
      <FeedPreHeader :post="post" @delete="(e) => $emit('delete', e)" />
      <FeedHeader :post="post" />
      <TruncateTextFeed
        :limit="80"
        :id="post?.content?.id"
        :key="post?.content?.id"
        :text="post?.title ?? post?.content?.title"
        v-if="
          (post?.title || post?.content?.title) && TruncateTextFeedVisible
        " />
    </div>

    <Link
      :href="route('bizcards.content.get', { content: post?.content?.id })"
      class="!no-underline !text-inherit">
      <component :is="mainComponent" :data="post" />
    </Link>
    <Interactions :data="post" v-if="showInteractions" />
  </div>
</template>

<script setup lang="ts">
import Interactions from "@/components/social-posts/Interactions.vue";
import FeedPreHeader from "@/pages/social-posts/parts/FeedPreHeader.vue";
import FeedHeader from "@/pages/social-posts/parts/FeedHeader.vue";
import Images from "@/components/social-posts/Images.vue";
import Videos from "@/components/social-posts/Videos.vue";
import Collection from "@/components/social-posts/Collection.vue";
import Links from "@/components/social-posts/Links.vue";
import TruncateTextFeed from "@/pages/social-posts/parts/TruncateTextFeed.vue";
import { Link, router } from "@inertiajs/vue3";
import { computed } from "vue";
import { route } from "ziggy-js";
const emit = defineEmits(["delete"]);
const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
  showInteractions: {
    type: Boolean,
    default: false,
  },
});

const mainComponent = computed(() => {
  switch (props.post?.content?.content_type) {
    case "video":
    case "video_gallery":
      return Videos;
    case "image":
    case "image_gallery":
      return Images;
    case "link":
    case "link_gallery":
      return Links;
    case "collection":
      return Collection;
    default:
      return Images;
  }
});

const handleOpen = () => {
  if (route().current() === "social_posts.index") return;
  else router.visit(route("social_posts.index", { post: props.post.id }));
};
// EXCLUDE TruncateTextFeed
const TruncateTextFeedVisible = computed(
  () => ["collection"].indexOf(props.post.content.content_type) == -1,
);
</script>
