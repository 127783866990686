<template>
    <div class="flex flex-col">
        <div class="fixed w-full px-2 lg:px-4 h-8 lg:h-14 bg-black/90 flex justify-center items-center z-10">
            <div class="flex w-full lg:w-2/3 justify-between">
                <div class="flex gap-2 lg:gap-5 items-center">
                    <button class="p-1 lg:p-2 rounded-full bg-main-lightBg" type="button">
                        <svg class="w-4 h-4 lg:w-7 lg:h-7 text-gray-800 dark:text-white" aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M6 18 17.94 6M18 18 6.06 6" />
                        </svg>
                    </button>
                    <div class="flex flex-col">
                        <h2 class="text-white text-[10px] lg:text-lg font-medium">Content title</h2>
                        <div class="flex gap-1 lg:gap-3">
                            <p class="text-white font-extralight text-[6px] lg:text-sm">Project type</p>
                            <span class="text-white font-extralight text-[6px] lg:text-sm">|</span>
                            <p class="text-white font-extralight text-[6px] lg:text-sm">Role on project #1</p>
                            <span class="text-main-text font-extralight text-[6px] lg:text-sm">•</span>
                            <p class="text-white font-extralight text-[6px] lg:text-sm">Role on project #2</p>
                            <span class="text-main-text font-extralight text-[6px] lg:text-sm">•</span>
                            <p class="text-white font-extralight text-[6px] lg:text-sm">Role on project #3</p>
                        </div>
                    </div>
                </div>
                <div class="flex gap-1 lg:gap-4 items-center">
                    <button class="p-1.5 lg:p-2.5 rounded-full bg-main-lightBg" type="button">
                        <img src="../../../assets/icons/share-outline.svg" class="w-3 h-3 lg:w-5 lg:h-5">
                    </button>
                    <button class="p-1 lg:p-2 rounded-full bg-main-lightBg" type="button">
                        <svg class="w-4 h-4 lg:w-6 lg:h-6 text-gray-800 dark:text-white" aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                                d="M6 12h.01m6 0h.01m5.99 0h.01" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <div class="mt-8 lg:mt-14 lg:mx-8 shadow-lg relative">
            <img src="../../../assets/images/background.png" class="object-cover w-full lg:rounded-b-xl">
            <div class="absolute top-2 right-2 lg:top-5 lg:right-5 rotate-90">
                <svg class="w-4 h-4 lg:w-8 lg:h-8 text-gray-800 dark:text-white" aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                        d="M6 12h.01m6 0h.01m5.99 0h.01" />
                </svg>
            </div>
            <div class="absolute bottom-5 left-5 flex-col text-white flex lg:hidden">
                <div class="text-white text-sm font-bold font-['Poppins'] tracking-wide">Adidas - Leeds
                    United Home Kit</div>
                <div class="flex gap-1 lg:gap-2.5 pb-1 lg:pb-1.5">
                    <p class="text-white/80 font-extralight text-[8px]">Project type</p>
                    <span class="text-white/80 font-extralight text-[8px]">|</span>
                    <p class="text-white/80 font-extralight text-[8px]">Role on project #1</p>
                    <span class="text-main-text font-extralight text-[8px]">•</span>
                    <p class="text-white/80 font-extralight text-[8px]">Role on project #2</p>
                    <span class="text-main-text font-extralight text-[8px]">•</span>
                    <p class="text-white/80 font-extralight text-[8px]">Role on project #3</p>
                </div>
                <div class="text-white text-[9px] font-light max-w-[80%]">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna.
                    Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae
                    mattis tellus.
                </div>
                <div class="pt-2 flex items-center gap-1">
                    <img src="../../../assets/images/sevag-collab.png" class="w-4">
                    <span class="text-white font-light text-[10px]">Sevag Chahinian</span>
                </div>
            </div>
            <div class="absolute bottom-20 left-16 flex-col text-white max-w-[35%] hidden lg:flex">
                <div class="text-white text-2xl font-bold font-['Poppins'] tracking-wide">Adidas - Leeds
                    United Home Kit</div>
                <div class="flex gap-3 pb-3">
                    <p class="text-white/80 font-extralight text-sm">Project type</p>
                    <span class="text-white/80 font-extralight text-sm">|</span>
                    <p class="text-white/80 font-extralight text-sm">Role on project #1</p>
                    <span class="text-main-text font-extralight text-sm">•</span>
                    <p class="text-white/80 font-extralight text-sm">Role on project #2</p>
                    <span class="text-main-text font-extralight text-sm">•</span>
                    <p class="text-white/80 font-extralight text-sm">Role on project #3</p>
                </div>
                <div class="text-white text-base font-light">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna.
                    Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae
                    mattis tellus.
                </div>
                <div class="pt-5 flex items-center gap-2">
                    <img src="../../../assets/images/sevag-collab.png" class="w-7">
                    <span class="text-white font-light">Sevag Chahinian</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    data() {
        return {
        };
    },
});
</script>