export default {
  root: ({ props }) => ({
    class: [
      // Flex Alignment
      "flex gap-2 bg-transparent flex-wrap rounded-[30px]",
      {
        "opacity-60 select-none pointer-events-none cursor-default":
          props.disabled,
      },
    ],
  }),
  button: ({ context, props }) => {
    return {
      class: [
        "relative",
        // Font
        "leading-none",
        "text-primary-500",

        // Flex Alignment
        "inline-flex justify-center items-center align-bottom text-center",

        // Spacing
        "px-2.5 py-2",

        // Shape
        "border border-primary-100",
        "rounded",

        // Color
        {
          "bg-primary-100/50": context.active,
        },
        // Invalid State
        { "border-red-500 dark:border-red-400": props.invalid },

        // States
        "focus:outline-none focus:outline-offset-0",
        {
          "opacity-60 select-none pointer-events-none cursor-default":
            context.disabled,
        },
        // Transition
        "transition duration-200",

        // Misc
        "cursor-pointer select-none overflow-hidden",
      ],
    };
  },
  label: {
    class: "font-normal text-[13px]",
  },
};
