<template>
  <div class="flex w-full" v-if="value">
    <div v-if="containsEightCharacters" class="w-[25%] h-[4px] mr-1" :class="{
      'bg-[#26bf93] rounded-full': containsEightCharacters,
      // 'sm:w-[130px]': props.page === 'signup',
      // 'sm:w-[82px]': props.page !== 'signup',
    }"></div>
    <div v-if="contains_number" class="w-[25%] h-[4px] mr-1" :class="{
      'bg-[#eef087] rounded-full': contains_number,
      // 'sm:w-[130px]': props.page === 'signup',
      // 'sm:w-[82px]': props.page !== 'signup',
    }"></div>
    <div v-if="contains_uppercase" class="w-[25%] h-[4px] mr-1" :class="{
      'bg-[#f0a787] rounded-full': contains_uppercase,
      // 'sm:w-[130px]': props.page === 'signup',
      // 'sm:w-[82px]': props.page !== 'signup',
    }"></div>
    <div v-if="contains_special_character" class="w-[25%] h-[4px] mr-1" :class="{
      'bg-[#f08787] rounded-full': contains_special_character,
      // 'sm:w-[130px]': props.page === 'signup',
      // 'sm:w-[82px]': props.page !== 'signup',
    }"></div>
  </div>
</template>

<script setup>
import { ref, watch, onBeforeUnmount } from "vue";
const props = defineProps({
  value: String | null | undefined,
  page: String,
});
const emit = defineEmits(["getPasswordStatus"]);
const containsEightCharacters = ref(false);
const contains_number = ref(false);
const contains_uppercase = ref(false);
const contains_special_character = ref(false);
watch(
  () => props.value,
  (newValue, oldValue) => {
    checkPassword(newValue);
  },
);
const checkPassword = (value) => {
  containsEightCharacters.value = value?.length >= 8;
  contains_number.value = /\d/.test(value);
  contains_uppercase.value = /[A-Z]/.test(value);
  contains_special_character.value =
    /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value);
  if (!contains_number.value || !contains_special_character.value) {
    emit(
      "getPasswordStatus",
      "Password must contain at least one number and one special character.",
    );
    return;
  }

  if (!contains_uppercase.value) {
    emit(
      "getPasswordStatus",
      "Password must contain at least one uppercase letter.",
    );
    return;
  }

  if (!containsEightCharacters.value) {
    emit("getPasswordStatus", "Password must be at least 8 characters long.");
    return;
  }
  emit("getPasswordStatus", "Good");

  return true;
};
onBeforeUnmount(() => {
  containsEightCharacters.value = false;
  contains_number.value = false;
  contains_uppercase.value = false;
  contains_special_character.value = false;
});
</script>
