<template>
  <div class="flex flex-col gap-8 w-full">
    <div
      class="w-full aspect-video relative bg-[url(@/assets/images/upload_content_icon/bg-img.png)] flex flex-col items-center justify-center gap-4 size-full rounded-lg">
      <div class="flex flex-col gap-2 items-center justify-center">
        <i class="fa fa-image text-white text-4xl"></i>
        <h1 class="text-base text-white font-medium">Upload a thumbnail</h1>
      </div>
      <p class="text-xs text-main-disabled px-12 text-center">
        Please upload a high-resolution file in one of the following formats:
        .jpg or .png.
      </p>
      <FileUpload
        @select="handleSelect"
        mode="advanced"
        accept="image/*"
        :pt="{
          root: 'flex items-center',
          content: 'hidden',
        }">
        <template #header="{ chooseCallback }">
          <button
            class="bg-main-lightBlue text-white text-center h-8 w-[190px] rounded"
            @click="chooseCallback">
            Upload
          </button>
        </template>
      </FileUpload>
    </div>
  </div>
</template>
<script setup>
const emit = defineEmits(["upload"]);
const handleSelect = (e) => {
  emit("upload", e);
};
</script>
