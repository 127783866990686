<template>
  <Divider :pt="{ content: classNames }" :ptOptions="{ mergeProps: true }">
    <span :class="cn('text-text-700', props.classNames)">
      {{ text }}
    </span>
  </Divider>
</template>
<script setup>
import { cn } from "@/utils/cn";
const props = defineProps({
  text: String,
  classNames: String,
});
</script>

<style lang="scss" scoped>
$color-primary: #cdd5e1;

.seperator {
  font-size: 0.75rem;
  letter-spacing: -0.08399999886751175px;
  text-align: center;
  color: $color-primary;
  position: relative;
  z-index: 2;

  span {
    padding: 0px 30px;
    font-size: 14px;
  }

  &::before {
    z-index: -1;
    content: "";
    position: absolute;
    height: 0.5px;
    top: calc(50% - 0.5px);
    left: 0%;
    background: $color-primary;
    width: 100%;
  }
}
</style>
