<template>
  <section id="publisher">
    <div class="bg-white rounded-md shadow-default w-full p-4">
      <div class="flex gap-1 flex-col">
        <p class="uppercase text-black text-xs font-semibold">published on</p>
        <p class="text-sm text-main-primary">{{ formattedDate }}</p>
      </div>

      <hr class="bg-main-secondary w-full my-4" />
      <p class="uppercase text-black text-xs font-semibold">PUBLISHED BY</p>
      <div class="flex gap-4 mt-2 flex-wrap justify-between">
        <div class="w-full lg:max-w-[700px]">
          <UserCard class="w-full" :user="publisher" :showLocation="false" :showRelation="false" />
        </div>
        <button
          class="flex items-center justify-center gap-2 text-sm text-[white] h-[40px] rounded-md bg-switcherBlue-500 hover:bg-switcherBlue-600 hover:text-white cursor-pointer w-full"
          :class="{ 'lg:max-w-[250px]': full }">
          <i class="fa fa-comment text-white"></i>
          Message
        </button>
      </div>
    </div>
  </section>
</template>
<script setup>
import InfoBallon from "@/components/parts/InfoBallon.vue";
import { reactive, ref, computed, onMounted, watch } from "vue";
import { usePage } from "@inertiajs/vue3";
import Roles from "@/components/general/Roles.vue";
import UserCard from "@/components/general/UserCard.vue";
import { formatDateShort } from "@/utils/formatter";

const props = defineProps({
  published: {
    required: true,
    type: String,
  },
  publisher: {
    required: true,
    type: Object,
  },
  full: {
    required: true,
    type: Boolean,
  },
});
const formattedDate = computed(() => {
  return formatDateShort(props.published);
});
</script>
