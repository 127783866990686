<template>
  <div
    class="bg-no-repeat bg-center relative cursor-pointer aspect-square w-[500px] collection_view"
  >
    <img
      :src="
        props.data?.content?.image_kit_id_16_9 ??
        props.data?.content?.thumbnail_image_kit_id ??
        props.data?.content?.image_kit_id ??
        props.data?.content?.image_kit_ids?.[0] ??
        'https://storage.googleapis.com/demo-api.bizly.net/static/app-templates/web-bg-min.jpg'
      "
      alt=""
      class="w-full h-full object-cover"
    />
    <InfoBallon class="rounded-full">
      <template #icon
        ><i class="fa-thin fa-rectangle-history text-md"></i
      ></template>
    </InfoBallon>

    <div
      class="absolute bottom-0 w-full h-1/2 collection_overlay font-[600] text-white leading-[23px] px-[15px] py-[10px] mb-0 flex justify-start items-end"
    >
      {{ props.data?.content?.title }}
    </div>
  </div>
</template>

<script setup>
import "swiper/css";
import InfoBallon from "../parts/InfoBallon.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
const props = defineProps(["data"]);
</script>
<style scoped lang="scss">
video {
  width: 80%;
  height: 100%;
}

.collection_view {
  .collection_overlay {
    opacity: 0;
  }
  &:hover {
    .collection_overlay {
      transition: all 0.2s ease-in;
      opacity: 1;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 50.08%,
        #000000 85.76%
      );
    }
  }
}
</style>
