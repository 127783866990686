<template>
  <div class="flex flex-col gap-4">
    <div class="px-4 py-2 flex flex-col gap-2 relative max-h-[750px]">
      <div class="overflow-auto scroll-hidden basis-[90%]">
        <Textarea
          v-model="description"
          rows="2"
          :autoResize="true"
          :maxlength="MAXCHARS"
          :placeholder="placeholder"
          class="shadow-inner resize-none scroll-hidden !bg-transparent !border-0 rounded text-white text-base font-normal py-2 pr-14 pl-4 w-full focus:outline-0 focus:ring-0 focus:border-[1px]" />
      </div>
      <div
        class="basis-[10%] absolute -bottom-3 right-5 text-main-disabled text-xs"
        :class="{ '!text-red-500': description?.length === MAXCHARS }">
        {{ description?.length || 0 }} / {{ MAXCHARS }}
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, watch } from "vue";

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  placeholder: {
    type: String,
    default: "Add a gallery description",
  },
});
const MAXCHARS = 5000;
const emit = defineEmits(["update:modelValue"]);

const description = ref(props.modelValue.description);

watch(description, (value) => {
  props.modelValue.description = value;
});
</script>
