<template>
  <div class="bg-white rounded-md shadow-default w-full p-4">
    <h1 class="mb-2">
      {{ collaboratorsCount }} Collaborator{{ collaboratorsCount > 1 ? "s" : "" }}
    </h1>
    <div class="flex flex-col">
      <div class="" v-if="owner">
        <UserCard
          :user="owner"
          :showLocation="false"
          :ownerTag="true"
          :showRoles="true"
        />
        <hr class="bg-main-divider h-[1px] w-full my-2" v-if="collaborators.length" />
      </div>
      <div class="" v-for="(collaborator, index) in collaborators">
        <UserCard class="" :user="collaborator" :showLocation="false" />
        <hr
          class="bg-main-divider h-[1px] w-full my-2"
          v-if="collaborators.at(-1) !== collaborators.at(index)"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch, type PropType } from "vue";
import UserCard from "@/components/general/UserCard.vue";
import type { RolesInterface, UserInterface } from "@/core/interfaces";
const props = defineProps({
  collaborators: {
    type: Array,
    required: true,
  },
  owner: {
    type: Object,
    default: null,
  },
  ownerRoles: {
    type: Array as PropType<RolesInterface[]>,
    default: () => [],
  },
});

const owner = computed(() => ({
    ...props.owner,
    roles: props.ownerRoles
}));
const collaboratorsCount = computed(() => {
  let count;
  count = props.collaborators.length;
  if (props.owner) count++;
  return count;
});
</script>
