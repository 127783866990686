import Legal from "./Legal.vue";
import Preferences from "./Preferences.vue";
import Security from "./Security.vue";
import Support from "./Support.vue";
export const settingsMenu = [
  {
    btnText: "Account & Preferences",
    Icon: "fa-user-gear",
    component: Preferences,
  },
  {
    btnText: "Sign in & Security",
    Icon: "fa-shield-check",
    component: Security,
  },
  { btnText: "Legal", Icon: "fa-gavel", component: Legal },
  { btnText: "Support", Icon: "fa-circle-question", component: Support },
];
export const LegalLinks = [
  {
    label: "Terms & Conditions",
    link: "https://www.bizly.net/terms",
  },
  {
    label: "Privacy Policy",
    link: "https://www.bizly.net/privacy-policy",
  },
  {
    label: "CCPA",
    link: "https://www.bizly.net/ccpa",
  },
];
export const SupportLinks = [
  {
    label: "Report a bug",
    link: "https://form.asana.com/?k=fNK3H3eAMYJxDz8Mhm5nPw&d=1200288379680184",
  },
  {
    label: "Report a user",
    link: "https://form.asana.com/?k=fNK3H3eAMYJxDz8Mhm5nPw&d=1200288379680184",
  },
  {
    label: "Report content",
    link: "https://form.asana.com/?k=fNK3H3eAMYJxDz8Mhm5nPw&d=1200288379680184",
  },
  {
    label: "General questions",
    link: "https://form.asana.com/?k=fNK3H3eAMYJxDz8Mhm5nPw&d=1200288379680184",
  },
  {
    label: "Request a feature",
    link: "https://form.asana.com/?k=fNK3H3eAMYJxDz8Mhm5nPw&d=1200288379680184",
  },
];
