import baseApi from "./Base";
import { route } from "ziggy-js";

export class BizcardServicesService extends baseApi {
  async create(form: any) {
    return await this.request(route("user.services.create"), "POST", form);
  }

  async update(service: number, form: any) {
    return await this.request(
      route("user.services.update", service),
      "POST",
      form,
    );
  }

  async delete(service: number) {
    return await this.request(route("user.services.delete", service), "POST");
  }

  async updateAvailability(form: any) {
    return await this.request(
      route("user.services.availability.update"),
      "POST",
      form,
    );
  }

  async updateLocations(form: any) {
    return await this.request(
      route("user.services.locations.update"),
      "POST",
      form,
    );
  }

  async updateWorkplace(form: any) {
    return await this.request(
      route("user.services.workplace.update"),
      "POST",
      form,
    );
  }

  async updateSkills(form: any) {
    return await this.request(
      route("user.services.skills.update"),
      "POST",
      form,
    );
  }

  async updateOpportunities(form: any) {
    return await this.request(
      route("user.services.opportunities.update"),
      "POST",
      form,
    );
  }
}
