<template>
  <div class="inner-tabs-wrapper">
    <FeedPreHeader :post="post" />
    <FeedHeader :post="post" />
    <TruncateTextFeed
      :limit="80"
      :id="post?.content?.id"
      :key="post?.content?.id"
      :text="post?.title ?? post?.content?.title"
      v-if="(post?.title || post?.content?.title) && TruncateTextFeedVisible"
    />

    <Link
      :href="`/u/${post?.owner?.public_username || post?.owner?.uuid}/content/${
        post?.content?.id
      }`"
      class="!no-underline !text-inherit"
    >
      <component :is="mainComponent" :data="post" />
    </Link>
    <Interactions :data="post" v-if="showInteractions" />
  </div>
</template>

<script setup lang="ts">
import Interactions from "@/pages/admin/social_posts/parts/Interactions.vue";
import FeedPreHeader from "@/pages/admin/social_posts/parts/FeedPreHeader.vue";
import FeedHeader from "@/pages/admin/social_posts/parts/FeedHeader.vue";
import Images from "@/components/social-posts/Images.vue";
import Videos from "@/components/social-posts/Videos.vue";
import Collection from "@/components/social-posts/Collection.vue";
import Links from "@/components/social-posts/Links.vue";
import TruncateTextFeed from "@/pages/admin/social_posts/parts/TruncateTextFeed.vue";
import { Link } from "@inertiajs/vue3";
import { computed } from "vue";
const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
  showInteractions: {
    type: Boolean,
    default: false,
  },
});

const mainComponent = computed(() => {
  switch (props.post?.content?.content_type) {
    case "video":
    case "video_gallery":
      return Videos;
    case "image":
    case "image_gallery":
      return Images;
    case "link":
    case "link_gallery":
      return Links;
    case "collection":
      return Collection;
    default:
      return Images;
  }
});
// EXCLUDE TruncateTextFeed
const TruncateTextFeedVisible = computed(
  () =>
    ["video", "image", "collection"].indexOf(props.post.content.content_type) ==
    -1,
);
</script>
