<template>
  <div
    class="px-[15px] items-center !mb-[0px]"
    :class="
      isNoPaddingComponent && !isNoLineHeightComponent
        ? 'p-0'
        : isNoPaddingComponent && isNoLineHeightComponent
        ? 'p-0 leading-none'
        : null
    "
    :style="`padding:${padding}`"
    @click="
      (e) => {
        e.stopPropagation();
      }
    "
  >
    <p
      class="font-normal text-[14px] leading-[20px] mb-[0px]"
      :class="isNoPaddingComponent ? 'p-0' : 'pb-[10px] pt-[10px]'"
      :style="`color:#${color ?? '141414'}; font-size:${fsize};`"
    >
      <span
        :style="`color:#${color ?? '141414'}; font-size:${fsize};`"
        class="text-content"
      >
        <formatTextWithMention />
      </span>
      <span
        @click.prevent="toggleText"
        class="text text-[#3578F8] text-sm cursor-pointer ml-1"
        v-if="shouldShowToggle"
        >{{ toggleButtonText }}</span
      >
    </p>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, resolveComponent, h } from "vue";
import { Link } from "@inertiajs/vue3";

const props = defineProps({
  text: String,
  color: String,
  fsize: String,
  padding: String,
  limit: Number,

  isNoPaddingComponent: Boolean,
  readMoreText: String,
  isNoLineHeightComponent: {
    type: Boolean,
    default: false,
  },
});

const isTruncated = ref(true);
const Truncatelimit = ref("");

const formattedText = computed(() => {
  if (!props.text) {
    return "";
  }
  const mentionMatches = props.text?.match(/<(.*?):(.*?)>/g);
  const mentionCount = mentionMatches ? mentionMatches.length : 0;

  // let limit;
  if (!props.limit) {
  }

  if (mentionCount > 1) {
    const lastIndexOfMention = props.text.slice(0, 140).lastIndexOf("<");
    const lastIndexOfMentionIn180 = props.text.slice(0, 180).lastIndexOf("<");

    if (lastIndexOfMention !== -1 && lastIndexOfMention !== 0) {
      Truncatelimit.value = 160; // If the last mention within the first 140 characters, set the Truncatelimit accordingly
    } else if (lastIndexOfMentionIn180 !== -1 && lastIndexOfMention !== 0) {
      Truncatelimit.value = 240; // If the last mention within the first 180 characters, set the Truncatelimit accordingly
    } else {
      Truncatelimit.value = 140; // Otherwise, set the Truncatelimit to 140
    }
  } else if (mentionCount === 1 && props.text.indexOf("<") < 80) {
    Truncatelimit.value = 120; // Increase Truncatelimit to 120 if the first mention is within the first 80 characters
  } else {
    Truncatelimit.value = props.limit; // else the limit that came from props
  }

  let truncatedText;
  if (!isTruncated.value) {
    truncatedText = props.text;
  } else {
    truncatedText = props.text.slice(0, Truncatelimit.value);
  }

  return isTruncated.value && props.text.length > Truncatelimit.value
    ? truncatedText + "..."
    : truncatedText;
});
const shouldShowToggle = computed(() => formattedText.value.length > Truncatelimit.value);

const toggleButtonText = computed(() => (isTruncated.value ? "see more" : "see less"));

const toggleText = () => {
  isTruncated.value = !isTruncated.value;
};

function formatTextWithMention() {
  const temp = formattedText.value
    ?.replace(/<(.*?):(.*?)>/g, (match, fullName, public_username) => {
      return `<Link class='text-switcherBlue-500' href="${route("user.profile.index", {
        user: public_username,
      })}">${fullName}</Link>`;
    })
    // Replace line breaks (\n) with <br /> tags to preserve newlines
    .replace(/\n/g, "<br />");

  return h({
    template: `<span class='whitespace-pre-line'>${temp}</span>`,
    components: { Link },
  });
}
</script>
