<template>
  <SideImage :image="talent" :bg="bg">
    <otpDialog :form="form" ref="loginOtpDialog" @completed="handleRedirect" />
    <div
      class="relative px-8 md:px-[2rem] py-3 lg:py-1 m-auto h-[100vh] overflow-y-auto scroll-hidden">
      <div class="h-screen flex justify-center items-center">
        <div class="w-full flex flex-col !justify-center my-auto">
          <div class="flex flex-col justify-center h-[auto] gap-4">
            <div>
              <div
                class="text-[1.125rem] flex sm:text-[2rem] text-center font-[500] text-black justify-center items-center leading-5 gap-2">
                Welcome to
                <img src="@/assets/images/blacklogosvg.svg" />
              </div>
            </div>
            <SelectButton
              :allow-empty="false"
              v-model="form.tab"
              :options="tabOptions"
              aria-labelledby="basic"
              optionLabel="name"
              option-value="value"
              @change="(e) => handleValidation(e, 'tab')" />
            <div>
              <form @submit.prevent="handleLogin" @keydown.enter="handleLogin">
                <div v-if="form.tab == 'email'">
                  <div class="flex flex-col justify-center items-center gap-2">
                    <div class="flex flex-col gap-1 w-full">
                      <InputText
                        placeholder="Email"
                        v-model="form.email"
                        aria-describedby="email-validation"
                        class="pr-10 text-sm"
                        :invalid="validateStore.errors.email != null"
                        @input="(e) => handleValidation(e, 'email')" />
                      <ValidationError
                        :errors="validateStore.errors"
                        name="email" />

                      <PasswordInput
                        placeholder="Password"
                        v-model="form.password"
                        :invalid="form.errors.password != null" />
                      <ValidationError
                        :errors="validateStore.errors"
                        name="password" />
                    </div>
                  </div>
                  <div
                    class="text-center text-sm text-[#8B9BB9] leading-5 py-[10px]">
                    <Button
                      type="button"
                      link
                      @click="router.visit(route('forgot_password.index'))"
                      label="Forgot password"
                      class="text-sm text-[#8B9BB9] leading-5 underline hover:!text-switcherBlue-500"></Button>
                  </div>
                </div>
                <div v-if="form.tab == 'phone'" class="mb-4">
                  <div class="flex flex-col justify-center items-center gap-2">
                    <CountryInput
                      class="w-full sm:w-[400px]"
                      @isValid="(v) => (form.phone_number_valid = v)"
                      :errors="validateStore.errors"
                      errorName="phone_number"
                      @getPhoneValue="
                        (v) => {
                          validateStore.resetInputValidation('phone_number');
                          validateStore.validate(form, validationSchema);
                          form.phone_number = v.number;
                        }
                      " />
                  </div>
                </div>
                <div class="flex justify-center items-center gap-[20px]">
                  <Button
                    type="submit"
                    label="Sign In"
                    class="w-max md:min-w-40"
                    :loading="form.processing"
                    :disabled="
                      validateStore.hasErrors ||
                      (!form.phone_number_valid && form.tab === 'phone')
                    " />
                </div>
              </form>
            </div>
            <TextSeparator :text="'Don’t have an account?'" class="" />
            <Button
              type="button"
              @click="router.visit(route('register'))"
              label="Register now"
              class="w-max md:min-w-40 self-center" />
            <HeadsUp :btnText="true" />
          </div>
        </div>
      </div>
    </div>
  </SideImage>
</template>

<script lang="ts" setup>
import bg from "@/assets/images/bgPics/bg.png";
import talent from "@/assets/images/bgPics/top.png";
import otpDialog from "@/components/dialogs/Otp.vue";
import CountryInput from "@/components/forms/countryInput.vue";
import PasswordInput from "@/components/forms/passwordInput.vue";
import ValidationError from "@/components/forms/validationError.vue";
import HeadsUp from "@/components/general/HeadsUp.vue";
import TextSeparator from "@/components/general/TextSeparator.vue";
import SideImage from "@/components/layout/SideImage.vue";
import { useToastr } from "@/composables/useToastr";
import { Link, router, useForm } from "@inertiajs/vue3";
import _debounce from "lodash/debounce";
import { onUnmounted, ref } from "vue";
import { route } from "ziggy-js";
import { useValidateStore } from "@/store/Validate";
const validateStore = useValidateStore();
const { success, warn, error, info } = useToastr();
// Define the type for the component instance
type OtpDialogInstance = InstanceType<typeof otpDialog>;
const loginOtpDialog = ref<OtpDialogInstance | null>(null);
const isLoginOtpDialogVisible = ref(false);
onUnmounted(() => validateStore.reset());
const form = useForm({
  email: null,
  password: null,
  phone_number: undefined,
  phone_number_valid: false,
  tab: "email",
});
const showPassword = ref(false);
const tabOptions = ref([
  { name: "Email", value: "email" },
  { name: "Phone Number", value: "phone" },
]);

const handleLogin = () => {
  if (form.tab === "email") submitForm();
  else getPhoneOtp();
};

const validationSchema = {
  email: {
    validate_if: { field: "tab", value: "email" },
    required_if: { field: "tab", value: "email" },
    type: "email",
  },
  phone_number: {
    validate_if: { field: "tab", value: "phone" },
    required_if: { field: "tab", value: "phone" },
  },
};

const handleValidation = _debounce((e, inputName) => {
  const ResetAllValidation = ["tab"];
  if (ResetAllValidation.includes(inputName)) {
    validateStore.reset();
    validateStore.validate(form, validationSchema);
    return;
  }
  validateStore.resetInputValidation(inputName);
  validateStore.validate(form, validationSchema);
}, 250);

const submitForm = () => {
  form.post(route("login"), {
    onError(errors) {
      validateStore.setErrors(errors);
    },
  });
};

const getPhoneOtp = async () => {
  if (isLoginOtpDialogVisible.value) return;
  if (!form.phone_number) {
    error("Please enter a phone number");
    return;
  }
  if (form.phone_number && !form.phone_number_valid) {
    error("Phone number is not valid");
    return;
  }
  isLoginOtpDialogVisible.value = true;
  if (loginOtpDialog.value) loginOtpDialog.value.show(route("otp.send.verify"));
};

const handleRedirect = async (data: any) => {
  router.post(route("login_otp"), {
    phone_number: form.phone_number,
    otp_id: data.otp_id,
  });
};
</script>
