<template>
  <div class="bg-no-repeat bg-center relative cursor-pointer">
    <div :class="{ gradientClass: props.data?.content?.children?.length > 1 }">
      <img
        :src="
          props.data?.content?.image_kit_id_16_9 ??
          props.data?.content?.thumbnail_image_kit_id ??
          props.data?.content?.image_kit_id ??
          props.data?.content?.image_kit_ids?.[0] ??
          'https://storage.googleapis.com/demo-api.bizly.net/static/app-templates/web-bg-min.jpg'
        "
        alt=""
        class="w-full h-full object-cover aspect-square"
      />
      <InfoBallon>
        <template #icon><i class="fa-thin fa-images text-md"></i></template>
        <template
          #count
          v-if="
            props.data?.content?.children?.length &&
            props.data?.content?.children?.length > 1
          "
          >{{ props.data?.content?.children?.length }}</template
        >
      </InfoBallon>
    </div>

    <div class="" v-if="props.data?.content?.content_type == 'image_gallery'">
      <div class="relative w-full flex items-end bg-black h-[130px] z-[50]">
        <div class="w-full flex scroll-hidden gap-2.5 py-[15px] relative">
          <swiper
            :slides-per-view="3"
            :space-between="5"
            class="w-full !px-[15px] !scroll-hidden !mx-0"
          >
            <swiper-slide
              v-for="child in props.data?.content?.children"
              class="w-fit"
            >
              <img
                :src="
                  child.image_kit_id_16_9 ??
                  child.thumbnail_image_kit_id ??
                  child.image_kit_id ??
                  child.image_kit_ids?.[0] ??
                  'https://storage.googleapis.com/demo-api.bizly.net/static/app-templates/web-bg-min.jpg'
                "
                alt=""
                class="w-[150px] h-[150px] min-w-[150px] min-h-[150px] rounded-[8px] object-cover"
              />
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import "swiper/css";
import InfoBallon from "../parts/InfoBallon.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
const props = defineProps(["data"]);
</script>
<style scoped lang="scss">
.gradientClass {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 65%,
      rgba(0, 0, 0, 1) 100%
    );
  }
}
</style>
