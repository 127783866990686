<template>
    <div class="absolute top-0 left-0 w-full h-full transition-opacity duration-300 ease-in-out  rounded-lg shadow-inner bg-neutral-900/50"
        :class="{
            'opacity-0 hover:opacity-100': trigger === 'hover'
        }">
        <InfoBallon>
            <template #icon>
                <slot name="ballonIcon"></slot>
            </template>
            <template #count>
                <slot name="ballonCount"></slot>
            </template>
        </InfoBallon>
        <div class="absolute bottom-2 left-0 text-[8px] lg:text-lg text-white px-4">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import InfoBallon from './InfoBallon.vue';

export default defineComponent({
    components: {
        InfoBallon
    },
    props: {
        trigger: {
            type: String as () => 'hover' | 'none',
            required: false,
            default: 'none'
        }
    },
});
</script>