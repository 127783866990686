<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-0',
      root: 'overflow-hidden w-screen h-screen bg-black ',
    }"
    :visible="true"
    :closable="false">
    <Header
      :header="{
        title: content.title,
        buttonCallback: () => emit('close'),
        class: '!z-[60]',
      }" />
    <div
      class="w-full fixed inset-0 z-[50] bg-black flex flex-col md:flex-row gap-[10px] top-[60px] px-[16px] md:px-2 overflow-auto scroll-hidden py-2">
      <div class="w-full basis-[75%] h-max">
        <div
          class="aspect-video rounded-lg mb-[30px] overflow-hidden w-full relative">
          <iframe
            class="h-full inset-0 absolute z-10 w-full"
            :src="content.children[selectedVid].embed"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen=""></iframe>
        </div>
        <div class="px-6 flex flex-col w-full gap-2 sm:gap-4 pb-2">
          <h2 class="font-medium text-white text-2xl">
            {{ content.children[selectedVid].title }}
          </h2>
          <div
            class="text-main-secondary"
            v-html="content.children[selectedVid].description"></div>
        </div>
      </div>
      <div
        class="bg-title rounded-lg h-full px-3 py-4 sticky top-0 basis-[25%] flex gap-2 flex-col scroll-hidden w-full">
        <h1>Up Next ()</h1>
        <div class="space-y-4">
          <div
            v-for="(child, index) in content.children"
            class="flex rounded-[10px] cursor-pointer gap-4 p-2 transition-all hover:bg-[#3f3f3f] group"
            :class="{ 'bg-[#3f3f3f]': index === selectedVid }"
            @click="() => handleSelectVid(index)">
            <div
              class="aspect-video rounded-[10px] max-h-[100px] overflow-hidden relative group-hover:group">
              <div
                class="z-[60] top-1 absolute py-[5px] px-2 left-1 gap-2 font-normal text-[10px] flex rounded-full bg-black/70 items-center text-white">
                <i class="fal fa-clapperboard-play text-sm"></i>
                <p v-if="child?.external_url || child?.external_type">
                  {{ child?.external_url || child?.external_type }}
                </p>
              </div>
              <img
                :src="
                  child.thumbnail_image_kit_id ??
                  child.image_kit_id_16_9 ??
                  child.image_kit_ids?.[0] ??
                  child.image_kit_id
                "
                :alt="child.title"
                class="h-full object-center object-cover w-full" />
              <div class="group-hover:bg-black">
                <div
                  class="bg-transparent group-hover:bg-black/40 flex transition-all absolute inset-0 justify-center items-center rounded-lg group">
                  <i
                    class="fa fa-play text-white text-5xl group-hover:block hidden"></i>
                </div>
              </div>
            </div>
            <div
              class="basis-[40%] flex flex-col gap-[5px] justify-center text-white">
              <p class="line-clamp-3 mb-0 text-sm">
                {{ child.title }}
              </p>
              <p class="text-xs">
                from - {{ child.embed.includes("youtu") ? "Youtube" : "Vimeo" }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>
<script setup>
import { reactive, ref, computed, onMounted, watch } from "vue";
import Header from "@/components/bizcard/content/Header.vue";

const props = defineProps({
  content: { required: true, type: Object },
  selected: { default: 0 },
});
const emit = defineEmits(["close"]);
const selectedVid = ref(props.selected);
const handleSelectVid = (idx) => {
  selectedVid.value = idx;
};
</script>
